import React, { useEffect, useRef, useState } from 'react';
import { Alert, Box, Link, Typography, useTheme } from '@mui/material';
import { IconImageUpload } from 'app/components/Icons/IconImageUpload';
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop';
import { IconArrowCounterClockWise } from 'app/components/Icons/IconArrowCounterClockWise';
import { AppModal } from 'app/components/AppModal';
import { RoundedButton } from 'app/components/RoundedButton';
import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview } from './canvasPreview';
import { handleHEICImage, handleReadImage } from 'utils/formattingHelper';

const MAX_CANVAS_AREA = 16777216; // Safari max canvas area (width * height)

export const ImageUploadModal = ({
  open,
  onClose,
  onUpload,
  aspect = 16 / 9,
  initialImage,
}: {
  open: boolean;
  onClose: () => void;
  onUpload: (image: string, fileSize: number) => void;
  aspect?: number;
  initialImage?: File;
}) => {
  const theme = useTheme();
  const hiddenFileInput = useRef(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>();
  const [rotate, setRotate] = useState<number | undefined>(0);
  const [scale, setScale] = useState<number | undefined>(1);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>({
    height: 300,
    unit: 'px',
    width: 300,
    x: 0,
    y: 0,
  });
  const [imgSize, setImageSize] = useState(0);
  const [isCropped, setIsCropped] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState('');

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  };

  useEffect(() => {
    const prepareCanvas = async () => {
      setTimeout(() => {
        if (
          completedCrop?.width &&
          completedCrop?.height &&
          imgRef.current &&
          previewCanvasRef.current
        ) {
          let { width, height } = completedCrop;

          // Check if the canvas area exceeds the maximum limit
          if (width * height > MAX_CANVAS_AREA) {
            const scaleFactor = Math.sqrt(MAX_CANVAS_AREA / (width * height));
            width = Math.floor(width * scaleFactor);
            height = Math.floor(height * scaleFactor);
          }

          // Update the completedCrop dimensions
          const scaledCrop = { ...completedCrop, width, height };

          // We use canvasPreview as it's much faster than imgPreview.
          canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            scaledCrop,
            scale,
            rotate,
          );
        }
      }, 100);
    };
    prepareCanvas();
  }, [completedCrop, scale, rotate, crop]);

  useEffect(() => {
    if (initialImage) {
      onSelectFile(initialImage);
    }
  }, [initialImage]);

  const onSelectFile = async (file: File) => {
    if (!file) return;
    const MAX_FILE_SIZE = 5; // 5MB
    const fileSize = file.size / 1024 / 1024; // in MiB
    setImageSize(fileSize);

    if (fileSize > MAX_FILE_SIZE)
      return setErrorMsg('File size is greater than the maximum limit.');

    const isHEIC =
      file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase() === 'heic';

    let blob: File | Blob = file;

    try {
      if (isHEIC) {
        blob = (await handleHEICImage(file, 'image/jpg')) as Blob;
      }
      const base64url = await handleReadImage(blob);
      setImageSrc(base64url);
      setCrop(undefined);
      setRotate(0);
      setScale(1);
      setIsCropped(false);
    } catch (err) {
      console.error(err);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    if (aspect) {
      setCrop(centerAspectCrop(width, height, aspect));
    } else {
      setCrop(centerAspectCrop(width, height, 1));
    }
  };

  const onConfirmAndContinue = () => {
    const canvas = previewCanvasRef.current;
    if (canvas) {
      const base64Image = canvas.toDataURL('image/jpeg', 0.7);
      const imageSize = calculateSize(base64Image);
      onUpload(base64Image, imageSize);
    }
    setImageSrc(null);
    onClose();
    setIsCropped(false);
  };

  const handleClose = () => {
    setImageSrc(null);
    setIsCropped(false);
    setErrorMsg('');
    onClose();
  };

  const calculateSize = (base64String: string): number => {
    type Padding = 0 | 1 | 2;
    let padding: Padding;

    const paddingRegex = /={0,2}$/;
    const match = paddingRegex.exec(base64String);
    padding = (match ? match[0].length : 0) as Padding;

    const base64StringLength = base64String.length;
    const inBytes = (base64StringLength / 4) * 3 - padding;
    return inBytes / (1024 * 1024); // size in MiB
  };

  return (
    <AppModal open={open} onClose={handleClose}>
      <Typography variant={'h5'} mb={2}>
        Add Photo
      </Typography>
      <Typography variant={'body2'} mb={2}>
        Select, crop and rotate your photo below
      </Typography>
      <Box>
        <Box
          sx={{
            display: imageSrc && !isCropped ? 'flex' : 'none',
          }}
        >
          <ReactCrop
            crop={crop}
            aspect={aspect}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={c => setCompletedCrop(c)}
          >
            <img
              ref={imgRef}
              src={imageSrc}
              alt="#"
              onLoad={onImageLoad}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            />
          </ReactCrop>
        </Box>
        <Box
          gap={0.5}
          sx={{
            minWidth: '280px',
            maxWidth: '340px',
            minHeight: '240px',
            border: '1px dashed #949696',
            borderRadius: '20px',
            alignItems: 'center',
            justifyContent: 'center',
            display: imageSrc ? 'none' : 'flex',
            flexDirection: 'column',
          }}
        >
          <IconImageUpload />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            onChange={e => {
              onSelectFile(e.target.files[0]);
            }}
            ref={hiddenFileInput}
          />
          <label htmlFor="contained-button-file">
            <Link>Upload the Image</Link>
          </label>
          <Typography align="center" variant="caption" whiteSpace={'pre'}>
            {
              '[ only jpeg (or jpg), png , gif and heic formats are supported ]\nMaximum file upload size is 5 MB'
            }
          </Typography>

          {errorMsg && (
            <Alert
              severity="info"
              sx={{
                backgroundColor: theme.palette.secondary.light,
                whiteSpace: 'pre',
                borderRadius: '8px',
                margin: '0 .5rem',
                '& .MuiAlert-icon': {
                  color: theme.palette.secondary.main,
                },
              }}
            >
              {errorMsg}
            </Alert>
          )}
        </Box>
      </Box>
      {imageSrc && !isCropped && (
        <Box
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}
          mt={'1rem'}
        >
          <Box
            sx={{
              display: 'flex',
              width: '50%',
              flexDirection: 'row',
              justifyContent: 'space-around',
              maxWidth: 200,
            }}
          >
            <Box>
              <RoundButton
                icon={
                  <IconArrowCounterClockWise
                    width={20}
                    height={20}
                    fill={'none'}
                  />
                }
                onClick={() => setRotate(rotate + 90)}
              />
              <Typography variant={'caption'}>Rotate</Typography>
            </Box>
          </Box>
        </Box>
      )}

      {/* display on crop button clicked */}
      <Box
        sx={{
          display: isCropped ? 'flex' : 'none',
        }}
      >
        {completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: '1px solid black',
              objectFit: 'contain',
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </Box>

      {imageSrc && (
        <>
          <Box
            display={'flex'}
            alignContent={'center'}
            justifyContent={'center'}
            mt={'1rem'}
          >
            <RoundedButton
              className={'white'}
              onClick={() => hiddenFileInput.current.click()}
            >
              Upload Another Photo
            </RoundedButton>
          </Box>
          <Box
            display={'flex'}
            alignContent={'center'}
            justifyContent={'center'}
            mt={'1rem'}
          >
            <RoundedButton onClick={() => onConfirmAndContinue()}>
              Confirm and continue
            </RoundedButton>
          </Box>
        </>
      )}
    </AppModal>
  );
};

const RoundButton = ({ icon, onClick }) => {
  return (
    <Box
      sx={{
        height: '38px',
        width: '38px',
        borderRadius: '65px',
        backgroundColor: '#F2F2F2',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
      onClick={onClick}
      display={'flex'}
    >
      {icon}
    </Box>
  );
};
