import * as React from 'react';

export const IconExpand = ({
  width = 20,
  height = 20,
  fill = 'white',
  stroke = 'none',
}: {
  width?: number;
  height?: number;
  fill?: string;
  stroke?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2849 7.31501L7.31497 14.285H8.6329C9.09507 14.285 9.46982 14.6548 9.46982 15.1183C9.46982 15.5785 9.094 15.9515 8.6329 15.9515H5.30673H5.30492H5.3031C5.07361 15.9515 4.86575 15.8581 4.71507 15.7073C4.56301 15.5573 4.46981 15.3494 4.46981 15.1183V15.1165V15.1148V11.7885C4.46981 11.3263 4.83963 10.9516 5.3031 10.9516C5.76335 10.9516 6.13638 11.3274 6.13638 11.7885V13.1064L13.1064 6.13643H11.7884C11.3263 6.13643 10.9515 5.76661 10.9515 5.30314C10.9515 4.84289 11.3273 4.46986 11.7884 4.46986H15.1147H15.1164H15.1182C15.3477 4.46986 15.5556 4.56333 15.7063 4.71414C15.8583 4.86411 15.9515 5.07199 15.9515 5.30314V5.30495V5.30676V8.63293C15.9515 9.09511 15.5817 9.46986 15.1182 9.46986C14.658 9.46986 14.285 9.09404 14.285 8.63293L14.2849 7.31501ZM0 1.67074C0 0.748033 0.747497 0 1.67074 0H18.3293C19.252 0 20 0.747497 20 1.67074V18.3293C20 19.252 19.2525 20 18.3293 20H1.67074C0.748033 20 0 19.2525 0 18.3293V1.67074ZM1.66671 18.3293C1.66671 18.3318 18.3291 18.3333 18.3291 18.3333C18.3317 18.3333 18.3332 1.67086 18.3332 1.67086C18.3332 1.66835 1.67075 1.66682 1.67075 1.66682C1.66824 1.66682 1.66671 18.3293 1.66671 18.3293Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};
