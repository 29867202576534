import ky, { Options } from 'ky';

const apiKey = process.env.REACT_APP_API_KEY;

const apiRS = ky.extend({
  prefixUrl: `${process.env.REACT_APP_RESERVATION_SERVICES_API_URL}/`,
  timeout: 60000,
  retry: 0,
  hooks: {
    beforeRequest: [
      request => {
        request.headers.set('x-functions-key', apiKey);
      },
    ],
  },
});

export async function apiRSGet(
  url: string,
  options?: Options,
  queryParams?: {},
): Promise<any> {
  const query = queryParams ? new URLSearchParams(queryParams).toString() : '';
  return await apiRS.get(url + (query ? `?${query}` : ''), options).json();
}

export async function apiRSGetFile(
  url: string,
  options?: Options,
  queryParams?: {},
): Promise<any> {
  const query = queryParams ? new URLSearchParams(queryParams).toString() : '';
  return await apiRS.get(url + (query ? `?${query}` : ''), options).blob();
}

export async function apiRSPost(
  url: string,
  data: {},
  options?: Options,
): Promise<any> {
  return await apiRS
    .post(url, {
      ...options,
      json: data,
    })
    .json();
}

export async function apiRSPut(
  url: string,
  data: {},
  options?: Options,
): Promise<any> {
  return await apiRS
    .put(url, {
      ...options,
      json: data,
    })
    .json();
}

export async function apiRSPatch(
  url: string,
  data: {},
  options?: Options,
): Promise<any> {
  return await apiRS
    .patch(url, {
      ...options,
      json: data,
    })
    .json();
}

export async function apiRSDelete(
  url: string,
  options?: Options,
  queryParams?: {},
): Promise<any> {
  const query = queryParams ? new URLSearchParams(queryParams).toString() : '';
  return await apiRS.delete(url + (query ? `?${query}` : ''), options).json();
}

export default apiRS;
