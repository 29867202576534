import * as React from 'react';

export const WeddingReservation = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2.5" y="4.5" width="19" height="17" rx="3.5" stroke="#2D3031" />
      <path d="M7 3V6" stroke="#2D3031" />
      <path d="M16 3V6" stroke="#2D3031" />
      <path d="M3 9H21.5" stroke="#2D3031" />
      <path d="M5 12H7" stroke="#2D3031" />
      <path d="M5 15H7" stroke="#2D3031" />
      <path d="M5 18H7" stroke="#2D3031" />
      <path d="M9 12H11" stroke="#2D3031" />
      <path d="M9 15H11" stroke="#2D3031" />
      <path d="M9 18H11" stroke="#2D3031" />
      <path d="M13 12H15" stroke="#2D3031" />
      <path d="M13 15H15" stroke="#2D3031" />
      <path d="M13 18H15" stroke="#2D3031" />
      <path d="M17 12H19" stroke="#2D3031" />
      <path d="M17 15H19" stroke="#2D3031" />
      <path d="M17 18H19" stroke="#2D3031" />
    </svg>
  );
};
