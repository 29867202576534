import { UserInfo } from '../types/UserInfo';
import { EUserRole } from '../types/EUserRole';

export const getUserInfo = () => {
  const userInfoString: string | null = localStorage.getItem('userInfo');
  const userInfo: UserInfo | null = JSON.parse(userInfoString);

  return userInfo;
};

export const logOut = () => {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('jwt');
  localStorage.removeItem('userSV');
  localStorage.removeItem('jwtSV');
  localStorage.removeItem('salesLead');
  window.location.href = '/log-in';
};

export const isLeader = (userInfo: UserInfo) => {
  return userInfo?.userRole?.includes(EUserRole.LEADER);
};

export const isLeaderLiveBooking = (userInfo: UserInfo) => {
  return (
    userInfo?.userRole?.includes(EUserRole.LEADER) ||
    window.location.href.includes('&groupLeader=true')
  );
};

export const isSalesLead = (userInfo: UserInfo) => {
  return userInfo?.userRole?.includes(EUserRole.SALESLEAD);
};

/**
 * @param {UserInfo} userInfo
 * @returns {boolean}
 * @description
 * We want sales leads who have not yet created an account to have a consistent experience.
 * This function checks localStorage to see if the user has previously entered the sales flow and selected a destination.
 */
export const isSalesLeadNoAccount = (userInfo: UserInfo) => {
  const salesLeadData = JSON.parse(localStorage.getItem('redux'))?.salesLead;
  const hasSalesLeadData =
    !!salesLeadData && Object.keys(salesLeadData).length > 0;
  return !userInfo && hasSalesLeadData;
};

export const isSalesLeadAny = (user: UserInfo) =>
  isSalesLead(user) || isSalesLeadNoAccount(user);

export const isGuest = (userInfo: UserInfo) => {
  return userInfo?.userRole?.includes(EUserRole.GUEST);
};

export const isLiveBooking = () => {
  return window.location.href.includes('live-booking');
};
