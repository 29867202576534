import * as React from 'react';

export const IconPlusCircled = () => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.49965 10.7158H5.4886C5.05472 10.7158 4.70264 11.0671 4.70264 11.5018C4.70264 11.9363 5.05395 12.2877 5.4886 12.2877H5.50026H16.5H16.511C16.9449 12.2877 17.297 11.9364 17.297 11.5018C17.297 11.0673 16.9457 10.7158 16.511 10.7158H16.4994H5.49965Z"
        fill="black"
      />
      <path
        d="M11.7863 6.00014V5.98909C11.7863 5.55521 11.435 5.20312 11.0003 5.20312C10.5658 5.20312 10.2144 5.55444 10.2144 5.98909V6.00075V6.00014V17.0006V17.0117C10.2144 17.4456 10.5657 17.7976 11.0003 17.7976C11.4348 17.7976 11.7863 17.4463 11.7863 17.0117V17V17.0006V6.00014Z"
        fill="black"
      />
      <path
        d="M11.0005 0.5C4.93407 0.5 0 5.43486 0 11.5005C0 17.5661 4.93486 22.501 11.0005 22.501C17.0669 22.501 22.001 17.5661 22.001 11.5005C22.001 5.43486 17.0661 0.5 11.0005 0.5ZM11.0005 2.07177C16.2178 2.07177 20.4302 6.28447 20.4302 11.5014C20.4302 16.7184 16.2182 20.9311 11.0005 20.9311C5.78314 20.9311 1.57083 16.7192 1.57083 11.5014C1.57083 6.28408 5.78274 2.07177 11.0005 2.07177V2.07177Z"
        fill="black"
      />
    </svg>
  );
};
