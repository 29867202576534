import * as React from 'react';
import Modal, { ModalProps } from '@mui/material/Modal';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { IconClose } from '../Icons/IconClose';

interface Props extends Omit<ModalProps, 'onClose' | 'children'> {
  // open?: boolean;
  onClose?: Function;
  children?: ReactNode;
  isLoading?: boolean;
  disableBackdropClick?: boolean;
  hideClose?: boolean;
}

export function AppModal({
  children,
  onClose,
  isLoading,
  disableBackdropClick,
  hideClose = false,

  ...modalProps
}: Props) {
  const handleClose = (event?, reason?) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return;
    }
    onClose && onClose(event, reason);
  };

  return (
    <Modal
      disableScrollLock={false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...modalProps}
    >
      <ModalContainer
        className={'modalContainer ' + (isLoading && 'loadingModal')}
      >
        <ModalContent className={'modalContent'}>
          {!hideClose && (
            <div
              data-testid="app-modal-button-close"
              className="close"
              onClick={handleClose}
            >
              <IconClose />
            </div>
          )}
          {children}
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
}

const ModalContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 60vw;
  min-width: 328px;

  max-height: 95vh;
  overflow-y: auto;

  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 19px 17px;

  &.loadingModal {
    max-width: 400px;
  }

  @media (max-width: 480px) {
    min-width: 95vw;
    max-width: 99vw;
  }
`;

const ModalContent = styled.div`
  position: relative;
  padding: 35px 0 25px 0;

  .close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 1;
  }
`;
