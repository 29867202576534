import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useSignUpForRoomSlice } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectSignUpForRoom } from '../slice/selectors';

const LoadingSkeleton = () => {
  return (
    <>
      <Skeleton variant="text" width={'100%'} height={40} />
      <Skeleton variant="text" width={'100%'} height={40} />
      <Skeleton variant="text" width={'100%'} height={40} />
    </>
  );
};

export default LoadingSkeleton;

export type WeddingDetailsProps = {
  roomId: string;
};

export function WeddingDetails({ roomId }: Readonly<WeddingDetailsProps>) {
  const dispatch = useDispatch();

  const { actions } = useSignUpForRoomSlice();
  const { room, group, isFetchingRoom } = useSelector(selectSignUpForRoom);

  useEffect(() => {
    if (roomId !== room?.id) {
      dispatch(actions.fetchRoom(roomId));
    }
  }, [actions, roomId, room?.id, dispatch]);

  const weddingDetails = [
    {
      title: 'Wedding Name',
      value: group?.groupName,
    },
    {
      title: 'Room Name',
      value: room?.roomName,
    },
    {
      title: 'Room Type',
      value: room?.roomType,
    },
  ].filter(info => !!info.value);

  const roomNotFound = !isFetchingRoom && weddingDetails.length === 0;

  return (
    <Box sx={{ mb: 4 }}>
      {weddingDetails.map(info => (
        <Typography
          key={info.title}
          align="center"
          variant="h5"
          sx={{ mb: 1, fontWeight: 600 }}
        >
          <b>{info.title}:</b> {info.value}
        </Typography>
      ))}

      {isFetchingRoom && <LoadingSkeleton />}

      {roomNotFound && (
        <Typography align="center" variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
          Room not found
        </Typography>
      )}
    </Box>
  );
}
