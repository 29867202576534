export const authErrorDescriptions = {
  DefaultError: 'An unhandled error occurred.',
  ConcurrencyFailure: 'A concurrency failure has occurred.',
  PasswordMismatch:
    'The provided password does not match the current password.',
  InvalidToken: 'The token provided is invalid.',
  RecoveryCodeRedemptionFailed: 'The recovery code redemption has failed.',
  LoginAlreadyAssociated:
    'An external login is already associated with this account.',
  InvalidUserName: 'The specified username is invalid.',
  InvalidEmail: 'The specified email is invalid.',
  DuplicateUserName: 'The specified username already exists.',
  DuplicateEmail: 'The specified email is already associated with an account.',
  InvalidRoleName: 'The specified role name is invalid.',
  DuplicateRoleName: 'The specified role name already exists.',
  UserAlreadyHasPassword: 'The user already has a password.',
  UserLockoutNotEnabled: 'User lockout is not enabled.',
  UserAlreadyInRole: 'The user is already in the specified role.',
  UserNotInRole: 'The user is not in the specified role.',
  PasswordTooShort: 'The password is too short.',
  PasswordRequiresUniqueChars:
    'The password does not contain the required number of unique characters.',
  PasswordRequiresNonAlphanumeric:
    'The password must contain at least one non-alphanumeric character.',
  PasswordRequiresDigit:
    'The password must contain at least one numeric character.',
  PasswordRequiresLower:
    'The password must contain at least one lowercase letter.',
  PasswordRequiresUpper:
    'The password must contain at least one uppercase letter.',
  ACCOUNT_ALREADY_EXISTS: 'An account with this email already exists.',
};

export const getAuthErrorDescription = (error: string) => {
  if (!error) return authErrorDescriptions.DefaultError;

  return authErrorDescriptions[error] ?? error;
};
