import * as React from 'react';

export const IconDestifyLogo = ({
  width = 37,
  height = 40,
}: {
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4454_29965)">
        <path
          d="M31.1452 1.61829L33.6267 3.00237L34.888 5.50437V6.52646L30.6119 8.52805L28.561 9.09233L28.3662 7.93183L29.4429 4.00317L29.6378 2.42744L31.1452 1.61829Z"
          fill="#E3F3F5"
        />
        <path
          d="M36.9466 6.06867L33.6755 3.05563L30.3326 0L28.1586 0.372638L28.3945 8.16609C25.4002 5.57892 21.5651 4.02449 17.3813 4.00319C7.83442 3.97125 0.0308277 12.0202 6.4518e-05 21.943C-0.0204443 29.6939 4.85039 36.6676 11.9054 39.095C9.80327 38.2859 6.63466 35.5922 5.18879 33.6758C2.62519 30.2795 1.75357 26.2763 1.77407 21.9537C1.78433 17.6417 3.42503 13.5853 6.36804 10.5403C9.32131 7.49534 13.2385 5.83444 17.3915 5.84509C21.5445 5.85574 25.4515 7.55922 28.3842 10.6149C31.317 13.6811 32.9167 17.7482 32.9064 22.0602C32.8962 26.3721 31.2657 30.4285 28.3125 33.4735C25.6873 36.1778 22.2213 37.8281 18.5503 38.1155L18.1709 38.1475L18.2529 11.1046C24.2004 12.1799 28.8457 17.5779 28.8252 24.2108C28.8252 25.35 28.5586 26.585 28.3022 27.6497C28.8354 26.1698 29.1328 24.5728 29.1328 22.9013C29.1533 16.1086 24.4465 10.2529 18.2631 9.60341C17.8222 9.56082 16.4994 9.48629 16.4994 9.48629L16.4071 39.9787C16.7352 40 17.0224 40.0107 17.289 40.0107C26.8461 40.0426 34.6497 31.9936 34.6804 22.0708C34.7009 16.971 32.6501 12.3609 29.3584 9.07107L36.8646 8.36838L36.9569 6.08997L36.9466 6.06867ZM28.8867 8.61326L30.4864 2.502L32.0553 2.77881L33.6242 3.05563L34.0754 4.642L34.5266 6.22837L28.8764 8.6239L28.8867 8.61326Z"
          fill="url(#paint0_linear_4454_29965)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4454_29965"
          x1="-1.5586"
          y1="28.2992"
          x2="33.4819"
          y2="17.4605"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F69C99" />
          <stop offset="0.27" stopColor="#FBD5E2" />
          <stop offset="0.49" stopColor="#CBB7D3" />
          <stop offset="0.53" stopColor="#9BB8D0" />
          <stop offset="0.56" stopColor="#71BACD" />
          <stop offset="0.59" stopColor="#50BBCB" />
          <stop offset="0.63" stopColor="#36BBC9" />
          <stop offset="0.67" stopColor="#23BCC8" />
          <stop offset="0.7" stopColor="#18BCC7" />
          <stop offset="0.74" stopColor="#15BDC7" />
        </linearGradient>
        <clipPath id="clip0_4454_29965">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
