import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { selectUser } from '../../../store/slice/common/selectors';
import appRoute from '../../../utils/appRoute';
import { isGuest, isLeader, isSalesLeadAny } from '../../../utils/userHelper';

/**
 * @param Component
 * @param rest
 * @constructor
 * @description
 * Routes that can accessed by any user, or any non-logged in saleslead.
 * The purpose of this component is to prevent new users or
 * non-logged-in users with accounts from accessing these screens, for example, a guest with an account
 * should not be able to access the My Trips page if they are logged out,
 * but a sales lead without an account should be able to view the empty version of the my trips page (see DWA-1491)
 */
const PrivateAnyUserRoute = ({ component: Component, ...rest }) => {
  const user = useSelector(selectUser);
  return (
    <Route
      {...rest}
      render={props =>
        isLeader(user) || isSalesLeadAny(user) || isGuest(user) ? (
          <Component {...props} />
        ) : (
          <Redirect to={appRoute.homePage()} />
        )
      }
    />
  );
};

export default PrivateAnyUserRoute;
