import * as React from 'react';
import { ReactChild, ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  ratio: null | number;
  children: ReactChild | ReactNode;
}

export function RationalWrapper({ ratio, children, ...boxProps }: Props) {
  return (
    <Box {...boxProps}>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'hidden',

          ...(ratio && {
            '& > *': {
              position: 'absolute',
              left: 0,
              top: 0,
              width: 'auto',
              height: '100%',
            },
            height: 0,
            paddingBottom: `${(100 / ratio).toPrecision(3)}%`,
          }),
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
