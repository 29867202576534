import styled from 'styled-components';

import { Box, useTheme } from '@mui/material';

import { Helmet } from 'react-helmet-async';

import { AppTopBar } from 'app/components/AppTopBar';
import { AppSideBarMenu } from 'app/components/AppMenu';

import { Footer } from 'app/components/Footer';

import { ProgressTrackerProps } from 'app/components/ProgressTracker';
import React, { ReactNode, useEffect } from 'react';

import { useIsMobile } from 'utils/responsiveHelper';
import destifyTheme from 'theme/baseTheme';

import { BreadCrumb } from 'app/components/BreadCrumb';

import {
  ScheduleACallBottomBanner,
  ScheduleACallLink,
} from 'app/components/ScheduleACall';

interface Props {
  children: ReactNode;
  showProgress?: boolean;
  isHomePage?: boolean;
  progressTracker?: ProgressTrackerProps;
  title?: string;
}

export const Layout = ({
  children,
  showProgress = true,
  isHomePage = false,
  progressTracker = null,
  title,
}: Props) => {
  const theme = useTheme();
  const [footerHeight, setFooterHeight] = React.useState(0);

  const handleOnScroll = () => {
    const body = document.body;
    const footerContainer = document.getElementById('footer-container-id');
    setFooterHeight(footerContainer?.getBoundingClientRect().height || 0);
    const h = body.getBoundingClientRect().height;
    const footerTop = footerContainer?.getBoundingClientRect().top;
    const shouldBe = footerTop <= h ? 'footerVisible' : 'footerInvisible';
    if (!body.className.includes(shouldBe)) {
      body.className =
        body.className.replace(/(footerVisible|footerInvisible)/, '').trim() +
        ' ' +
        shouldBe;
    }
  };

  const isMobile = useIsMobile('sm');
  const isStep1 = window.location.hash.match('#Destination');

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll);
    new ResizeObserver(handleOnScroll).observe(
      document.getElementById('content-container'),
    );
  }, []);

  useEffect(() => {
    handleOnScroll();
  });

  return (
    <Wrapper
      className={isMobile ? 'mobile' : 'desktop'}
      footerHeight={footerHeight}
      theme={destifyTheme}
    >
      {title !== null && <Helmet title={title} />}
      <AppTopBar
        title="Path to I Do"
        variant="light"
        logo={<img src={`/img/DestifyLogo_2022 2.svg`} alt="Destify" />}
      />
      {isMobile && <ScheduleACallLink />}
      <AppSideBarMenu />

      <Box
        id="content-container"
        sx={{
          padding: isHomePage || isStep1 ? '0' : '3rem 1rem',
          overflow: 'visible',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          position: 'relative',
          // UNCOMMENT WHEN AND IF WE GET PROGRESS BAR BACK
          // minHeight: showProgress
          //   ? `calc(100vh - ${isMobile ? '52px' : '65px'} - 90px - 150px)` // - header - progress - footer
          //   : `calc(100vh - ${isMobile ? '52px' : '65px'} - 150px)`, // - header - footer
          [theme.breakpoints.up('sm')]: {
            alignSelf: 'center',
            maxWidth: isHomePage || isStep1 ? 'inherit' : '1200px',
            padding: isStep1 && 0,
          },
        }}
      >
        <BreadCrumb />
        {children}
      </Box>

      <Box
        id={'footer-container-id'}
        sx={{ position: 'absolute', width: '100%', bottom: 0 }}
      >
        {window.location.hash === '#Confirmation' && (
          <ScheduleACallBottomBanner theme={theme} />
        )}
        <Footer />
      </Box>
    </Wrapper>
  );
};

interface WrapperProps {
  footerHeight: number;
}
const Wrapper = styled.div<WrapperProps>`
  background-color: ${props => props.theme.palette.primary.white};
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex-grow: 1;
  position: relative;
  padding-bottom: ${props => props.footerHeight}px; /* footer height */

  .numbers {
    font-family: 'Nunito', sans-serif;
  }

  .stepTitle {
    font-family: 'Nunito', sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 0.75em;

    ${props => props.theme.breakpoints.up('sm')} {
      font-size: 2rem;
      font-weight: 600;
    }
  }
`;
