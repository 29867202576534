import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { install } from 'resize-observer';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReactNotifications } from 'react-notifications-component';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
// Use consistent styling
import 'sanitize.css/sanitize.css';
import 'react-notifications-component/dist/theme.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { store } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';
import 'react-multi-carousel/lib/styles.css';
import './locales/i18n';

import { configSentry } from './sentry';
if (process.env.NODE_ENV !== 'development') {
  configSentry();
}

const gtmId = process.env.REACT_APP_GTM_ID;
if (!window.ResizeObserver) install();
TagManager.initialize({ gtmId });

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </HelmetProvider>
    <ReactNotifications />
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register();
