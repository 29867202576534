import * as React from 'react';

export const FAQs = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="3.5" width="16" height="16" rx="3.5" stroke="#2D3031" />
      <path
        d="M10.1111 10.4697V9.72223C10.1111 8.61766 11.0065 7.72223 12.1111 7.72223H12.8333C13.9379 7.72223 14.8333 8.61766 14.8333 9.72223V10.6596C14.8333 10.9726 14.6867 11.2676 14.4372 11.4567L12.3961 13.0029C12.1466 13.192 12 13.487 12 13.8V14.3333"
        stroke="#2D3031"
      />
      <path d="M12 15.75V16.6944" stroke="#2D3031" />
    </svg>
  );
};
