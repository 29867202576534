import * as React from 'react';

export const IconTrash = ({
  width = 24,
  height = 24,
  color = '#2D3031',
}: {
  width?: string | number;
  height?: string | number;
  color?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2495 5.25L3.74951 5.25001"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 9.75V15.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 9.75V15.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7495 5.25V19.5C18.7495 19.6989 18.6705 19.8897 18.5298 20.0303C18.3892 20.171 18.1984 20.25 17.9995 20.25H5.99951C5.8006 20.25 5.60983 20.171 5.46918 20.0303C5.32853 19.8897 5.24951 19.6989 5.24951 19.5V5.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 5.25V3.75C15.75 3.35218 15.592 2.97064 15.3107 2.68934C15.0294 2.40804 14.6478 2.25 14.25 2.25H9.75C9.35218 2.25 8.97064 2.40804 8.68934 2.68934C8.40804 2.97064 8.25 3.35218 8.25 3.75V5.25"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
