import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { selectUser } from '../../../store/slice/common/selectors';
import appRoute from '../../../utils/appRoute';
import { isSalesLead } from '../../../utils/userHelper';

/**
 *
 * @param Component
 * @param rest
 * @constructor
 */
const PrivateSalesLeadRoute = ({ component: Component, ...rest }) => {
  const user = useSelector(selectUser);
  return (
    <Route
      {...rest}
      render={props =>
        isSalesLead(user) ? (
          <Component {...props} />
        ) : (
          <Redirect to={appRoute.homePage()} />
        )
      }
    />
  );
};

export default PrivateSalesLeadRoute;
