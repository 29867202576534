import { PayloadAction } from '@reduxjs/toolkit';
import salesViewRoutes from 'app/SalesView/routes';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { commonActions } from 'store/slice/common';
import { selectUser } from 'store/slice/common/selectors';
import { apiSVGet, apiSVPost, apiSVPut, SVResponse } from 'utils/apiSV';
import { gtm } from 'utils/gtm';
import { notify } from 'utils/misc';
import { nextPreviousSteps } from 'utils/ptidStepsHelper';
import { userPageView } from 'utils/userPageView';
import { salesLeadActions as actions } from '.';
import { ID, UserDetails } from '../../../../../../types/GroupLeaderDetails';
import {
  ALTDATE,
  ANTICIPATED_WEDDING_DATE,
  CITY,
  CONFIRMATION,
  COUNTRY,
  DATE,
  DESTINATION,
  PACKAGE,
  RESERVATION,
  RESORT,
  ROOM_BOOKING_DATA,
  stateIds,
  VENUE,
} from '../constants';
import {
  selectAllData,
  selectCities,
  selectCountries,
  selectCurrentStep,
  selectNextData,
  selectPackages,
  selectResorts,
  selectUserDetails,
  selectVenues,
} from './selectors';
import {
  City,
  Country,
  Package,
  Resort,
  SalesLeadState,
  Step,
  Venue,
} from './types';
import { getUserInfo } from 'utils/userHelper';

function* __get(url: string, query: string) {
  const response = yield call(apiSVGet, `${url}?${query}`, {});
  if (response.isSuccess) {
    return response.data;
  } else {
    throw new Error(response.message);
  }
}

function getCountries() {
  const params = '';
  return __get(`Country/CountryList`, params);
}

function getCities(countryIdList: ID[]) {
  const idList = countryIdList.map(s => s.toString().trim()).filter(s => s);
  const params = idList.map(id => `Countryid=${id}`).join('&');
  return __get(`City/CityList`, params);
}

function getResorts(
  cityIdList: ID[],
  anticipatedDate?: string,
  showAvailableWeddingDate?: boolean,
) {
  const idList = cityIdList?.map(s => s.toString().trim()).filter(s => s) || [];
  const params = idList
    .map(id => `CityId=${id}`)
    .join('&')
    .concat(
      anticipatedDate
        ? `&AnticipatedWeddingDate=${anticipatedDate.substring(0, 10)}`
        : '',
    )
    .concat(
      anticipatedDate
        ? `&ShowWeddingDateAvailable=${showAvailableWeddingDate || false}`
        : '',
    );
  return __get(`Resort/ResortList`, params);
}

function getFavoriteResorts() {
  const params = '';
  return __get(`Resort/FavoriteResortList`, params);
}

function* mergeFavoriteResortsWithResorts(resorts: Resort[]) {
  const user = yield select(selectUser);
  if (user) {
    const favoriteResorts = yield getFavoriteResorts();
    // iterate over resorts and create a new array where if a resort
    // matches a resort in favoriteResorts, isFavorite is set to true
    return resorts.map(r => {
      const isFavoriteResort = favoriteResorts.find(
        fr => fr.resortId === r.resortId,
      );
      if (isFavoriteResort) {
        return { ...r, isFavorite: true };
      } else {
        return { ...r, isFavorite: false };
      }
    });
  } else {
    return resorts.map(r => ({
      ...r,
      isFavorite: false,
    }));
  }
}

function* addFavoriteResort(action: PayloadAction<ID>) {
  const response = yield apiSVPost('Resort/AddFavoriteResort', {
    ptidAdminHotelId: action.payload,
  });

  if (response.isSuccess) {
    const resorts: Resort[] = yield select(selectResorts);
    const mergedResorts = yield mergeFavoriteResortsWithResorts(resorts);
    yield put(actions.setResorts(mergedResorts));
    notify('Success!', 'Resort added to favorites!');
    yield put(commonActions.loadFavoriteResorts());
  } else {
    notify('Error!', response.error, 'danger');
  }

  yield put(actions.setIsAdding(false));
}

function* removeFavoriteResort(action: PayloadAction<ID>) {
  const response = yield apiSVPost('Resort/RemoveFavoriteResort', {
    ptidAdminHotelId: action.payload,
  });

  if (response.isSuccess) {
    const resorts: Resort[] = yield select(selectResorts);
    const mergedResorts = yield mergeFavoriteResortsWithResorts(resorts);
    yield put(actions.setResorts(mergedResorts));
    notify('Success!', 'Resort removed from favorites!');
    yield put(commonActions.loadFavoriteResorts());
  } else {
    notify('Error!', response.error, 'danger');
  }

  yield put(actions.setIsRemoving(false));
}

function getVenues(resortIdList: ID[]) {
  const idList = resortIdList.map(s => s.toString().trim()).filter(s => s);
  const params = idList.map(id => `ResortId=${id}`).join('&');
  return __get(`ResortVenue/ResortVenueList`, params);
}

function* doFastTravel(
  action: PayloadAction<{
    hotelId: string;
    date?: null | string;
    history: any;
  }>,
) {
  // search hotel
  const hotelId = action.payload.hotelId?.toLowerCase().trim() || '';
  const date = action.payload.date?.trim().substr(0, 10) || '';
  if (hotelId) {
    try {
      yield put(actions.setStatusMsg('Loading data...'));
      // request hotel ids
      const hotelData = yield __get(
        'Resort/ResortByHotelId',
        `hotelId=${hotelId}`,
      );
      if (hotelData) {
        //why were we getting and setting venues? did we previously want to land them on the venues screen in this case?
        //const venues = yield getVenues([hotelData.resortId]);
        const nextData = {
          resortId: [hotelData.resortId] as ID[],
          cityId: [hotelData.cityId] as ID[],
          countryId: [hotelData.countryId] as ID[],
        };

        let svFormattedDate = null;

        if (date) {
          const components = date.split('/');
          if (components.length === 3) {
            const year = components[2];
            const month = components[0];
            const day = components[1];

            svFormattedDate = year + '-' + month + '-' + day;
          }
        }

        yield put(
          actions.setFastTravelData({
            nextData: nextData,
            //venues: venues,
            anticipatedDate: svFormattedDate || null,
          }),
        );

        yield put(
          actions.doPreselectResort({
            resortId: hotelId,
            redirectSrc: 'TopRecommendationResort',
            history: action.payload.history,
          }),
        );

        return;
      }
    } catch (err) {
      // do nothing
    }
    yield actions.cleanNextData();
    yield put(actions.setSearching(false));
    //because of the hash comparisons in lines 92-116 on the SalesLead index.tsx the below line is necessary
    yield (window.location.hash = '');
    yield put(actions.setCurrentStep('Country'));
    yield action.payload.history.push(salesViewRoutes.salesLead() + '#Country');
  }
}

function* loadCountries() {
  try {
    const data = yield getCountries();
    yield put(actions.setCountries(data));
  } catch (e) {
    notify('Woops!', e.message, 'danger');
    console.log(e);
    yield put(actions.setCountries([]));
  }
}

function* loadCities() {
  try {
    const { countryId } = yield select(selectAllData);
    const user = yield select(selectUser);
    if (user && !countryId?.length) {
      yield put(actions.setCurrentStep('Destination'));
      return; // no stored data. reset all steps
    }
    const data = yield getCities(countryId || []);
    yield put(actions.setCities(data));
  } catch (e) {
    notify('Woops!', e.message, 'danger');
    console.log(e);
    yield put(actions.setCities([]));
  }
}

function* loadResorts(action) {
  try {
    const {
      anticipatedWeddingDate,
      cityId,
      showWeddingDateAvailable,
      eventDate,
    } = yield select(selectAllData);

    const anticipatedDate =
      action.payload || anticipatedWeddingDate || eventDate?.substr(0, 10);
    const data = yield getResorts(
      cityId,
      anticipatedDate,
      showWeddingDateAvailable,
    );
    const resorts = yield mergeFavoriteResortsWithResorts(data);
    yield put(actions.setResorts(resorts));
  } catch (e) {
    notify('Woops!', e.message, 'danger');
    console.log(e);
    yield put(actions.setResorts([]));
  }
}

function* loadVenues() {
  try {
    const { resortId } = yield select(selectAllData);
    if (!resortId?.length) {
      yield put(actions.setCurrentStep('Destination'));
      return; // no stored data. reset all steps
    }
    const data = yield getVenues(resortId);
    yield put(actions.setVenues(data));
  } catch (e) {
    notify('Woops!', e.message, 'danger');
    console.log(e);
    yield put(actions.setVenues([]));
  }
}

function* loadPackages() {
  try {
    const { resortVenueId } = yield select(selectAllData);
    if (!resortVenueId) {
      yield put(actions.setCurrentStep('Destination'));
      return; // no stored data. reset all steps
    }
    const url = `WeddingPackage/WeddingPackage?ResortVenueId=${resortVenueId}`;
    const response = yield call(apiSVGet, url);
    //sort packages from cheapest to most expensive
    const sortedPackages = response.data
      .filter(c => c)
      .sort((p1, p2) => p1.packageRate - p2.packageRate);
    yield put(actions.setPackages(sortedPackages));
  } catch (e) {
    notify('Woops!', e.message, 'danger');
    console.log(e);
    yield put(actions.setPackages([]));
  }
}

function* loadCeremonyTimes() {
  try {
    const url = `SalesLead/PreferredCeremonyTime`;
    const response = yield call(apiSVGet, url);
    yield put(actions.setCeremonyTimes(response.data));
  } catch (e) {
    notify('Woops!', e.message, 'danger');
    console.log(e);
    yield put(actions.setCeremonyTimes([]));
  }
}

function* loadConfirmationOptions() {
  const countries: Country[] = yield select(selectCountries);
  const cities: City[] = yield select(selectCities);
  const resorts: Resort[] = yield select(selectResorts);
  const venues: Venue[] = yield select(selectVenues);
  const packages: Package[] = yield select(selectPackages);
  const allData = yield select(selectAllData);

  const resortId = packages.find(
    pkg => pkg.weddingPackageId === allData.packageId,
  )?.resortId;
  const resort = resorts.find(item => item.resortId === resortId);
  const country = countries.find(
    country => country.countryId === resort?.countryId,
  );
  const city = cities.find(city => city.cityId === resort?.cityId);
  function generateDisplayNamesForAllData(allData) {
    const data = {
      country: country?.countryName || '',
      city: city?.cityName || '',
      resort: resort?.resortName,
      resortVenue: venues.find(
        item => allData.resortVenueId === item.resortVenueId,
      ).venueName,
      packageName: packages.find(
        item => allData.packageId === item.weddingPackageId,
      ).packageName,
      packageAmount: packages.find(
        item => allData.packageId === item.weddingPackageId,
      ).packageRate,
      eventDate: allData.eventDate,
      preferPartOfDay: allData.preferPartOfDay,
      eventAlternativeDate: allData.eventAlternativeDate,
      preferAlternativePartOfDay: allData.preferAlternativePartOfDay,
      ...stateIds,
    };
    return data;
  }
  try {
    if (!allData.countryId || !allData.cityId) {
      const newAllData = {
        ...allData,
        countryId: [resort.countryId],
        cityId: [resort.cityId],
      };
      yield put(actions.setAllData(newAllData));
    }
    // check if all user selectedValues are available
    const hasStepsDataMissing = !!Object.keys(allData).find(
      key =>
        allData[key] === null &&
        key !== ROOM_BOOKING_DATA &&
        key !== ANTICIPATED_WEDDING_DATE,
    );
    if (!hasStepsDataMissing) {
      const data = generateDisplayNamesForAllData(allData);

      yield put(actions.setEditOptions(data));
    } else {
      yield put(actions.doBack(null));
    }
  } catch (e) {
    notify('Woops!', e.message, 'danger');
    console.log(e);
    yield put(actions.doBack(null));
  }
}

//updates google tag manager with data related to user selections
function* updateGTMDataLayer(currentStep: string, nextData: any) {
  let value;
  if (currentStep === COUNTRY) {
    const countriesList = yield select(selectCountries);
    value = nextData.countryId.map(
      id => countriesList.find(item => item.countryId === id).countryName,
    );
  } else if (currentStep === CITY) {
    const citiesList = yield select(selectCities);
    value = nextData.cityId.map(
      id => citiesList.find(item => item.cityId === id).cityName,
    );
  } else if (currentStep === RESORT) {
    const resortsList = yield select(selectResorts);
    value = nextData.resortId.map(
      id => resortsList.find(item => item.resortId === id).resortName,
    );
  } else if (currentStep === VENUE) {
    const venuesList = yield select(selectVenues);
    value = venuesList.find(
      item => item.resortVenueId === nextData.resortVenueId,
    ).venueName;
  } else if (currentStep === PACKAGE) {
    const packagesList = yield select(selectPackages);
    value = packagesList.find(
      item => item.weddingPackageId === nextData.packageId,
    ).packageName;
  } else {
    return;
  }
  gtm(currentStep, { [currentStep]: value });
}

function* doNext(action: PayloadAction<Step> = null) {
  let nextData = (yield select(selectNextData)) || {};
  const currentStep = (yield select(selectCurrentStep)) || '';
  const nextStepName =
    nextPreviousSteps[currentStep]?.next?.path || action.payload || '';
  yield put(actions.updateAllData(nextData));
  yield put(actions.cleanListData(nextData));

  yield updateGTMDataLayer(currentStep, nextData);

  const user = yield select(selectUser);
  if (user) {
    if (nextData.eventDate) {
      const userDetails: UserDetails = yield select(selectUserDetails);
      const _eventDateStr = nextData.eventDate.substr(0, 10);
      const _antDateStr = userDetails?.anticipatedWeddingDate?.substr(0, 10);
      if (_antDateStr !== _eventDateStr) {
        // need to additionally sync anticipatedDate and eventDate
        yield updateAnticipatedWeddingDate(_eventDateStr + 'T00:00:00');
      }
    }

    try {
      const response = yield call(
        apiSVPost,
        'SalesLead/SalesLeadNext',
        nextData,
      );
      if (response.isSuccess) {
        // store into store
        yield put(actions.cleanNextData());
        yield put(actions.setCurrentStep(nextStepName));
      } else {
        notify('', response.message || 'Something wrong', 'danger');
      }
    } catch (e) {
      console.log(e);
    }
  } else {
    yield put(actions.cleanNextData());
    yield put(actions.setCurrentStep(nextStepName));
  }
}

function* doBack(action: PayloadAction<any> = null) {
  console.log({ action });
  yield put(actions.cleanNextData());

  const currentStep = yield select(selectCurrentStep);
  const data = action && action.payload ? { stateId: action.payload } : {};
  const user = yield select(selectUser);
  if (user) {
    const response = yield call(apiSVPost, 'SalesLead/SalesLeadBack', data);
    if (response.isSuccess) {
      yield put(actions.loadCurrentStep());
    } else {
      //AT 09/29/2022 the endpoint above is deceptive. rather than return a bad HTTP code, it returns a 200  with a .isSuccess = false
      //so we need to deal with that.💀
      const previousStepName = nextPreviousSteps[currentStep].back.path;
      yield put(actions.setCurrentStep(action.payload || previousStepName));
    }
  } else {
    // const data = action && action.payload ? { stateId: action.payload } : {};

    const previousStepName = nextPreviousSteps[currentStep].back.path;
    yield put(actions.setCurrentStep(action.payload || previousStepName));
  }
}

function* loadAllData() {
  const user = yield select(selectUser);
  if (user) {
    try {
      const response = yield call(apiSVGet, 'SalesLead/SalesLeadEditOptions');
      if (response.isSuccess) {
        yield put(actions.updateAllData(response.data));
      } else {
        notify('', response.message || 'Something wrong', 'danger');
      }
    } catch (e) {
      console.log(e);
    }
  }
}

function* loadDataForCurrentState(data: any) {
  const currentStep = (yield select(selectCurrentStep)) || '';
  switch (currentStep) {
    case COUNTRY:
      if (data) {
        yield put(actions.setCountries(data));
      } else {
        yield put(actions.loadCountries());
      }
      break;
    case CITY:
      if (data) {
        yield put(actions.setCities(data));
      } else {
        yield put(actions.loadCities());
      }
      break;
    case RESORT:
      if (data) {
        yield put(actions.setLoading(true));
        const mergedResorts = yield mergeFavoriteResortsWithResorts(data);
        yield put(actions.setResorts(mergedResorts));
        yield put(actions.setLoading(false));
      } else {
        yield put(actions.loadResorts());
      }
      break;
    case VENUE:
      if (data) {
        yield put(actions.setVenues(data));
      } else {
        yield put(actions.loadVenues());
      }
      break;
    case PACKAGE:
      if (data) {
        yield put(actions.setPackages(data));
      } else {
        yield put(actions.loadPackages());
      }
      break;
    case DATE:
      if (data) {
        yield put(actions.setCeremonyTimes(data));
      } else {
        yield put(actions.loadCeremonyTimes());
      }
      break;
    case ALTDATE:
      if (data) {
        yield put(actions.setCeremonyTimes(data));
      } else {
        yield put(actions.loadCeremonyTimes());
      }
      break;
    case CONFIRMATION:
      if (data) {
        yield put(actions.setEditOptions({ ...data }));
      } else {
        yield put(actions.loadConfirmationOptions());
      }
      break;
  }
}

function* loadCurrentStep() {
  const user = yield select(selectUser);
  let step: Step = 'Destination',
    currentStepData: SalesLeadState['currentStepData'] = null,
    previousData;
  const { showWeddingDateAvailable } = yield select(selectAllData);

  if (user) {
    try {
      // looks like for the new user "menu" request should come first
      const menuResponse = yield call(apiSVGet, 'SalesLead/SalesLeadMenu', {});
      if (menuResponse.isSuccess) {
        const _m1 = menuResponse.data.find(datum => datum.isCurrentPage);
        const _m2 = _m1?.saleSubJourneys.find(datum => datum.isCurrentPage);
        previousData = _m2?.value;
      }

      const response = yield call(
        apiSVGet,
        `SalesLead/SalesLeadPage?ShowWeddingDateAvailable=${
          showWeddingDateAvailable || false
        }`,
      );
      if (response.isSuccess) {
        currentStepData = response.data || null;

        const msg = response.message || '';
        step = getStepNameFromMessage(msg);
      } else {
        if (false === response.isSuccess) {
          step = 'Destination';
          currentStepData = null;
        }
        notify('', response.message || 'Something wrong', 'danger');
      }
    } catch (e) {
      notify('', e.message, 'danger');
      console.log(e);
    }
  } else {
    const currentStep = (yield select(selectCurrentStep)) || '';
    step = getStepNameFromMessage(currentStep || 'Destination');
  }
  if (previousData) {
    yield put(actions.setPreviousData(previousData));
    yield put(actions.updateAllData(previousData));
  }
  yield put(actions.setCurrentStep(step));
  yield loadDataForCurrentState(currentStepData);
}

function* doSendRequest() {
  try {
    const response = yield call(apiSVPost, 'SalesLead/SalesLeadSave', {});
    gtm('WeddingDateRequestAttempted');
    userPageView('WeddingDateRequestAttempted');
    if (response.isSuccess) {
      gtm('WeddingDateRequestSuccess', {
        approvalType: response.data.approvalType,
      });
      userPageView('WeddingDateRequestSuccess');
      if (response.data.approvalType === 'automatic') {
        gtm('auto-approval');
      }
      yield put(actions.cleanNextData());
      yield put(actions.getCurrentProgress());
      setTimeout(() => (window.location.href = salesViewRoutes.ptid()), 50);
    } else {
      notify('Request not sent', response.message, 'danger');
      gtm('WeddingDateRequestFailed', { message: response.message });
      yield put(actions.requestError(response.message));
    }
  } catch (e) {
    yield put(actions.requestError(e.message));
    console.log(e);
  }
}

function* updateAnticipatedWeddingDate(date: string) {
  try {
    const response: SVResponse<null> = yield call(
      apiSVPut,
      'WeddingDetails/UpdateWeddingAnticipatedDate',
      {
        anticipatedWeddingDate: date,
      },
    );
    if (response.isSuccess) {
      yield put(commonActions.fetchUserDetails());
    }
  } catch (e) {
    notify('', e.message, 'danger');
  }
}

function* doChangeAnticipatedWeddingDate(action: PayloadAction<string>) {
  const user = yield select(selectUser);

  if (user) {
    yield updateAnticipatedWeddingDate(action.payload);
    yield put(actions.loadCurrentStep());
  } else {
    yield put(actions.loadResorts());
  }

  yield put(actions.setSearching(false));
}

function* doChangeShowWeddingDateAvailable(action: PayloadAction<boolean>) {
  const user = yield select(selectUser);

  if (user) {
    yield put(actions.loadCurrentStep());
  } else {
    yield put(actions.loadResorts());
  }

  yield put(actions.setSearching(false));
}

function* doSendPreselectedResort(action: PayloadAction<any>) {
  if (getUserInfo())
    yield call(apiSVPost, 'SalesLead/SalesLeadNext', {
      resortId: [action.payload.resortId],
      redirectSrc: action.payload.redirectSrc,
    });

  yield put(actions.setPreselectedResort());

  if (getUserInfo()) yield action.payload.history.push(salesViewRoutes.ptid());
}

function getStepNameFromMessage(msg) {
  if (msg.includes(CONFIRMATION)) {
    return CONFIRMATION;
  } else if (msg === RESERVATION) {
    return RESERVATION;
  } else if (msg.includes(PACKAGE)) {
    return PACKAGE;
  } else if (msg.includes(DATE)) {
    return DATE;
  } else if (msg === DESTINATION) {
    return DESTINATION;
  } else if (msg.includes(VENUE)) {
    return VENUE;
  } else if (msg.includes(RESORT)) {
    return RESORT;
  } else if (msg.includes(CITY)) {
    return CITY;
  } else if (msg.includes(COUNTRY)) {
    return COUNTRY;
  }
}

export function* salesLeadSaga() {
  yield takeLatest(actions.doPreselectResort, doSendPreselectedResort);
  // yield takeLatest(actions.getCurrentProgress.type, getProgressInfo);
  yield takeLatest(actions.doNext.type, doNext);
  yield takeLatest(actions.doBack.type, doBack);
  yield takeLatest(actions.doSendRequest.type, doSendRequest);
  yield takeLatest(actions.loadCurrentStep.type, loadCurrentStep);
  yield takeLatest(actions.loadAllData.type, loadAllData);

  yield takeLatest(actions.loadCountries.type, loadCountries);
  yield takeLatest(actions.loadCities.type, loadCities);
  yield takeLatest(actions.loadResorts.type, loadResorts);
  yield takeLatest(actions.addFavoriteResort.type, addFavoriteResort);
  yield takeLatest(actions.removeFavoriteResort.type, removeFavoriteResort);
  yield takeLatest(actions.loadVenues.type, loadVenues);
  yield takeLatest(actions.loadCeremonyTimes.type, loadCeremonyTimes);
  yield takeLatest(actions.loadPackages.type, loadPackages);

  yield takeLatest(
    actions.loadConfirmationOptions.type,
    loadConfirmationOptions,
  );
  yield takeLatest(
    actions.doChangeAnticipatedWeddingDate.type,
    doChangeAnticipatedWeddingDate,
  );
  yield takeLatest(
    actions.doChangeShowWeddingDateAvailable.type,
    doChangeShowWeddingDateAvailable,
  );
  yield takeLatest(actions.doFastTravel.type, doFastTravel);
}
