import * as React from 'react';

export const IconBackArrow = () => {
  return (
    <svg
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.645L7.28745 0L0 7L7.28745 14L9 12.355L3.43725 7L9 1.645Z"
        fill="#192338"
        fillOpacity="0.87"
      />
    </svg>
  );
};
