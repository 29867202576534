import * as React from 'react';
import ButtonUnstyled, {
  buttonUnstyledClasses,
} from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';
import { Box, CircularProgress } from '@mui/material';

const violet = {
  500: '#785aff',
  600: '#6543FF',
  700: '#512AFF',
};

interface RoundedButtonProps
  extends React.ComponentPropsWithoutRef<typeof ButtonUnstyled> {
  loading?: boolean;
  confirm?: string;
}

const CustomButtonRoot = styled('button')`
  box-sizing: border-box;
  font-family: ${props => props.theme.typography['fontFamily']};
  font-weight: bold;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  background: ${props => props.theme.palette.secondary.main};
  border-radius: 65px;
  color: #21272a;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
  position: relative;
  white-space: nowrap;

  min-width: 150px;
  padding: 0 16px;

  &.red {
    background-color: rgba(230, 19, 82, 0.99);
    color: #fff;

    &.Mui-active {
      background-color: rgba(230, 19, 82, 0.8);
    }
  }

  &.violet {
    background-color: ${violet[500]};
    color: #fff;

    &.${buttonUnstyledClasses.active} {
      background-color: ${violet[600]};
    }
  }

  &.facebookLogin {
    width: 200px;
    background-color: #4267b2;
    color: #fff;
  }

  &.googleLogin {
    width: 200px;
    background-color: #4285f4;
    color: #fff;
  }

  &.fullWidth {
    width: 100%;
  }

  &.transparent {
    background-color: transparent;
    border: thin solid #2d3031;
    color: #2d3031;

    &:hover {
      background-color: #e4e8e8;
    }
    &:active {
      background-color: #f2f2f2;
    }
    &:disabled {
      background-color: #f2f2f2;
    }
  }

  &.white {
    background-color: ${props => props.theme.palette.primary.white};
    border: thin solid #2d3031;
    color: #2d3031;

    &:hover {
      background-color: #e4e8e8;
    }
    &:active {
      background-color: #f2f2f2;
    }
    &:disabled {
      background-color: #f2f2f2;
    }
  }

  &.black {
    background-color: #2d3031;
    color: #fff;

    &--outlined {
      background-color: white;
      color: #2d3031;
      border: 1px solid #2d3031;
    }
  }

  &.${buttonUnstyledClasses.active} {
    background-color: ${props => props.theme.palette.secondary.light};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    color: #949696;
    background-color: #f2f2f2;
    cursor: not-allowed;
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -10px;
  }

  &.medium {
    height: 40px;
    line-height: 40px;

    .loading {
      margin-top: -15px;
    }
  }
`;

export const RoundedButton = (props: RoundedButtonProps) => {
  let onClick = props.onClick;
  const children = props.loading ? (
    <Box position={'relative'}>
      <Box sx={{ opacity: 0.3 }}>{props.children}</Box>
      <Box position={'absolute'} top={0} bottom={0} left={0} right={0}>
        <div className="loading">
          <CircularProgress style={{ color: 'white' }} size={20} />
        </div>
      </Box>
    </Box>
  ) : (
    props.children
  );
  const { loading, confirm, ...buttonUnstyledProps } = props;
  const disabled = loading ? true : props.disabled;

  if (confirm) {
    onClick = event => {
      if (window.confirm(confirm)) {
        props.onClick(event);
      }
    };
  }

  return (
    <ButtonUnstyled
      {...buttonUnstyledProps}
      children={children}
      disabled={disabled}
      onClick={onClick}
      component={CustomButtonRoot}
    />
  );
};
