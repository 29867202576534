import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Box, Typography } from '@mui/material';
import { Carousel } from 'app/components/Carousel';
import { AppModal } from 'app/components/AppModal';
import { RoundedButton } from 'app/components/RoundedButton';

export const ImageSelectionModal = ({
  open,
  onClose,
  images,
  onSelectImageSave,
}) => {
  const [selectedImage, setSelectedImage] = React.useState<number>(0);

  if (!images) {
    return <></>;
  }
  // cache the first image in the browser so it loads immediately when the modal opens
  const firstImage = new window.Image();
  firstImage.src = `${images[0]?.url}?width=768`;

  return (
    <AppModal open={open} onClose={onClose}>
      <Typography variant={'h5'} mb={2}>
        Select an image
      </Typography>
      <Box maxWidth={346}>
        <Carousel
          images={images.map(item => ({
            ...item,
            url: `${item.url}?width=768`,
          }))}
          onChange={setSelectedImage}
          selectedItem={selectedImage}
        />
        <Box sx={{ maxHeight: '16rem', overflow: 'scroll' }}>
          <ImageList cols={3} gap={10}>
            {images.map((item, idx) => (
              <ImageListItem
                data-testid={`save-the-date-modal-image-${idx}`}
                key={item.url}
                onClick={() => setSelectedImage(idx)}
                sx={{
                  cursor: 'pointer',
                  border: idx === selectedImage ? '2px solid #00BFC8' : 'none',
                  borderRadius: '10%',
                  display: 'flex',
                  overflow: 'hidden',
                  width: '100%',
                  height: 0,
                  paddingBottom: '100%',
                  position: 'relative',
                }}
              >
                <img
                  src={`${item.url}?width=320`}
                  // srcSet={`${item.url}`}
                  alt={item.title}
                  style={{
                    borderRadius: '10%',
                    padding: idx === selectedImage ? '2px' : '0px',
                    position: 'absolute',
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                  }}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Box>
      <Box
        display={'flex'}
        alignContent={'center'}
        justifyContent={'center'}
        mt={'1rem'}
      >
        <RoundedButton
          onClick={() => onSelectImageSave(selectedImage)}
          data-testid="save-the-date-modal-save-button"
        >
          Save
        </RoundedButton>
      </Box>
    </AppModal>
  );
};
