import React from 'react';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import type { SubmitHandler } from 'react-hook-form';
import type { EmailSignUpData } from '../../types';
import { SignUpForRoomLayout } from '../../layout';
import { useDispatch } from 'react-redux';
import { useSignUpForRoomSlice } from '../../slice';
import { SignUpForm } from '../../components/signUpForm';
import { SignUpDataValidationSchema } from '../../validations/signUpDataValidation';

YupPassword(yup);

export interface EmailSignUpForm extends EmailSignUpData {}

const schema = SignUpDataValidationSchema.concat(
  yup.object({
    password: yup
      .string()
      .password()
      .required()
      .min(8, 'Password must be at least 8 characters')
      .minUppercase(1, 'Password must be contain 1 uppercase')
      .minNumbers(1, 'Password must be contain 1 number')
      .minSymbols(1, 'Password must be contain 1 symbol')
      .label(''),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], 'Both passwords must match')
      .label(''),
  }),
).required();

export type SignUpForRoomEmailProps = {};

export const SignUpForRoomEmail = (_: SignUpForRoomEmailProps) => {
  const dispatch = useDispatch();
  const { actions } = useSignUpForRoomSlice();

  const onSubmit: SubmitHandler<EmailSignUpForm> = data => {
    dispatch(actions.emailSignUp(data));
  };

  return (
    <SignUpForRoomLayout title="Create Your Account">
      <SignUpForm onSubmit={onSubmit} validationSchema={schema} />
    </SignUpForRoomLayout>
  );
};
