import * as React from 'react';

export const Rooms = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="13.5" width="23" height="5" rx="1.5" stroke="#2D3031" />
      <path
        d="M2.79571 19.1213C2.71681 18.8057 2.95549 18.5 3.28078 18.5H5.71922C6.04451 18.5 6.28319 18.8057 6.20429 19.1213L5.70429 21.1213C5.64865 21.3439 5.44866 21.5 5.21922 21.5H3.78078C3.55134 21.5 3.35135 21.3439 3.29571 21.1213L2.79571 19.1213Z"
        stroke="#2D3031"
      />
      <path
        d="M17.7957 19.1213C17.7168 18.8057 17.9555 18.5 18.2808 18.5H20.7192C21.0445 18.5 21.2832 18.8057 21.2043 19.1213L20.7043 21.1213C20.6486 21.3439 20.4487 21.5 20.2192 21.5H18.7808C18.5513 21.5 18.3514 21.3439 18.2957 21.1213L17.7957 19.1213Z"
        stroke="#2D3031"
      />
      <path
        d="M2.5 5C2.5 4.17157 3.17157 3.5 4 3.5H20C20.8284 3.5 21.5 4.17157 21.5 5V13.5H2.5V5Z"
        stroke="#2D3031"
      />
      <path
        d="M11.5 12C11.5 12.2954 11.2859 12.6606 10.7268 12.9801C10.1818 13.2915 9.39561 13.5 8.5 13.5C7.60439 13.5 6.81816 13.2915 6.2732 12.9801C5.71409 12.6606 5.5 12.2954 5.5 12C5.5 11.7046 5.71409 11.3394 6.2732 11.0199C6.81816 10.7085 7.60439 10.5 8.5 10.5C9.39561 10.5 10.1818 10.7085 10.7268 11.0199C11.2859 11.3394 11.5 11.7046 11.5 12Z"
        stroke="#2D3031"
      />
      <path
        d="M18.5 12C18.5 12.2954 18.2859 12.6606 17.7268 12.9801C17.1818 13.2915 16.3956 13.5 15.5 13.5C14.6044 13.5 13.8182 13.2915 13.2732 12.9801C12.7141 12.6606 12.5 12.2954 12.5 12C12.5 11.7046 12.7141 11.3394 13.2732 11.0199C13.8182 10.7085 14.6044 10.5 15.5 10.5C16.3956 10.5 17.1818 10.7085 17.7268 11.0199C18.2859 11.3394 18.5 11.7046 18.5 12Z"
        stroke="#2D3031"
      />
    </svg>
  );
};
