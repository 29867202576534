import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.signUpForRoom || initialState;

export const selectSignUpForRoom = createSelector(
  [selectSlice],
  state => state,
);

export const selectRoomId = createSelector(
  [selectSlice],
  state => state.roomId,
);

export const selectRoom = createSelector([selectSlice], state => state.room);

export const selectError = createSelector([selectSlice], state => state.error);

export const selectSignUpResult = createSelector(
  [selectSlice],
  state => state.signUpResult,
);

export const selectIsFetchingRoom = createSelector(
  [selectSlice],
  state => state.isFetchingRoom,
);
