class StorageHelper {
  private state: Record<string, any> = {};
  constructor(private key: string) {
    this.state = this.parse();
    this.getState = this.getState.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  private parse() {
    const item = localStorage.getItem(this.key);
    if (item === null) {
      return {};
    }
    return JSON.parse(item);
  }

  private write() {
    localStorage.setItem(this.key, JSON.stringify(this.state));
  }

  getState(key: string) {
    return this.state[key] || null;
  }
  updateState<T>(key: string, value: T) {
    this.state[key] = value;
    this.write();
  }
}

export default StorageHelper;
