const root = '/live-booking';
const liveBookingRoutes = {
  liveBooking: (groupId?: string) =>
    groupId ? `${root}/${groupId}` : `${root}/:groupId?`,
  liveBookingRoomExtras: (groupId?: string) =>
    groupId ? `${root}/${groupId}/extras` : `${root}/:groupId?/extras`,
  liveBookingRoomArrangements: (groupId?: string) =>
    groupId
      ? `${root}/${groupId}/arrangements`
      : `${root}/:groupId?/arrangements`,
  liveBookingConfirm: (groupId?: string) =>
    groupId ? `${root}/${groupId}/confirm` : `${root}/:groupId?/confirm`,
  liveBookingPayment: (groupId?: string) =>
    groupId ? `${root}/${groupId}/payment` : `${root}/:groupId?/payment`,
  liveBookingCompleted: (groupId?: string) =>
    groupId ? `${root}/${groupId}/completed` : `${root}/:groupId?/completed`,
};
export default liveBookingRoutes;
