import * as React from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { AppTopBar } from '../AppTopBar';
import { Footer } from '../Footer';
import { PageTitle } from '../PageTitle';
import { ReactNode } from 'react';

interface Props {
  title: string;
  children?: any;
  pageTitle?: ReactNode;
  showPageTitle?: boolean;
  showVideo?: boolean;
  bottomContent?: ReactNode;
}

export function LoginLayout({
  title,
  children,
  pageTitle,
  showPageTitle = false,
  showVideo = false,
  bottomContent,
}: Props) {
  return (
    <Wrapper>
      {showVideo && (
        <video autoPlay muted loop playsInline>
          <source src="/login-bg-loop-optimized.mp4" type="video/mp4" />
        </video>
      )}
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppTopBar title={title} />
      {pageTitle ||
        (showPageTitle && (
          <PageTitle
            style={{ paddingLeft: '17px', paddingRight: '17px' }}
            text={title}
            withBack={true}
          />
        ))}
      <LoginContentWrapper>
        <div className="content">{children}</div>
      </LoginContentWrapper>
      {bottomContent && <div>{bottomContent}</div>}
      <Footer />
    </Wrapper>
  );
}

const LoginContentWrapper = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    width: 90vw;
    padding: 1rem;
    margin: auto auto;
    background-color: rgba(255, 255, 255);
    border-radius: 18px;
    margin: 1rem auto;
    @media (min-width: 481px) {
      padding: 22px 42px;
    }

    @media (min-width: 920px) {
      width: 460px;
      padding: 22px 22px;
    }
  }
`;

const Wrapper = styled.div`
  z-index: 1;
  position: relative;

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: -1;
  }

  min-height: 100vh;
  margin: 0 auto;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;
