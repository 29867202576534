const guestViewRoutes = {
  welcomePage: () => '/welcome',
  // Group Leader
  weddingWebsitePage: () => '/wedding-website',
  weddingWebsiteDesignPage: () => '/wedding-website-design',
  weddingWebsiteAboutUsPage: () => '/wedding-website-about-us',
  weddingWebsiteCustomPage: () => '/wedding-website-custom-page',
  weddingWebsiteSaveTheDatePage: () => '/wedding-website-save-the-date',
  weddingWebsiteWeddingPartyPage: () => '/wedding-website-wedding-party',
  weddingWebsiteWeddingItineraryPage: () =>
    '/wedding-website-wedding-itinerary',
  weddingWebsiteRegistriesPage: () => '/wedding-website-registries',
  weddingReservationPage: () => '/wedding-reservation',
  rsvpsPage: () => '/rsvps',
  guestListPage: () => '/guest-list',
  // Group Guest
  weddingsPage: (groupId?: string) =>
    groupId ? `/weddings/${groupId}` : '/weddings/:groupId',
  roomsPage: (roomId?: string) =>
    roomId ? `/rooms/${roomId}` : '/rooms/:roomId?',
  roomExtrasPage: (roomId?: string) =>
    roomId ? `/rooms-extras/${roomId}` : '/rooms-extras/:roomId?',
  roomCancellationPage: (roomId?: string) =>
    roomId ? `/rooms-cancellation/${roomId}` : '/rooms-cancellation/:roomId?',
  paymentPage: (roomId?: string) =>
    roomId ? `/payment/${roomId}` : '/payment/:roomId',
  checkoutPage: () => '/CheckOut',
  modifyReservationTravelDates: (roomId?: string) =>
    roomId
      ? `/modify-reservation/${roomId}?mode=ChangeTravelDates`
      : `/modify-reservation/:roomId?mode=ChangeTravelDates`,
  modifyReservationIndexPage: (roomId?: string) =>
    roomId ? `/modify-reservation/${roomId}` : '/modify-reservation/:roomId',
  modifyReservationRoomsPage: (roomId?: string) =>
    roomId
      ? `/modify-reservation-rooms/${roomId}`
      : '/modify-reservation-rooms/:roomId',
  modifyReservationRoomPage: (roomId?: string) =>
    roomId
      ? `/modify-reservation-room/${roomId}`
      : '/modify-reservation-room/:roomId',
  profilePage: () => '/profile',
  faqsPage: () => '/faqs',
  contactUsPage: () => '/contact-us',
};
export default guestViewRoutes;
