import {
  CITY,
  CONFIRMATION,
  COUNTRY,
  DATE,
  PACKAGE,
  RESORT,
  VENUE,
} from 'app/SalesView/pages/Ptid/SalesLead/constants';

// export const nextStep = {
//   Country: {
//     back: null,
//     next: {
//       label: "Let's Get Started",
//       path: COUNTRY,
//     },
//   },
// };
export const nextPreviousSteps = {
  Destination: {
    back: null,
    next: null,
  },
  Country: {
    back: null,
    next: {
      label: 'Next: Explore Cities',
      path: CITY,
    },
  },
  City: {
    back: {
      label: 'Back: Explore Destinations',
      path: COUNTRY,
    },
    next: {
      label: 'Next: Explore Resorts',
      path: RESORT,
    },
  },
  Resort: {
    back: {
      label: 'Back: Explore Cities',
      path: CITY,
    },
    next: {
      label: 'Next: Wedding Venues',
      path: VENUE,
    },
  },
  Venue: {
    back: {
      label: 'Back: Explore Resorts',
      path: RESORT,
    },
    next: {
      label: 'Next: Wedding Packages',
      path: PACKAGE,
    },
  },

  Package: {
    back: {
      label: 'Back: Wedding Venues',
      path: VENUE,
    },
    next: {
      label: 'Next: Wedding Date',
      path: DATE,
    },
  },
  Date: {
    back: {
      label: 'Back: Wedding Packages',
      path: PACKAGE,
    },
    next: {
      label: 'Next: Review Request',
      path: CONFIRMATION,
    },
  },
  Reservation: {
    back: {
      label: 'Back: Date',
      path: DATE,
    },
    next: {
      label: 'Next: Review Request',
      path: CONFIRMATION,
    },
  },
  Confirmation: {
    back: {
      label: 'Back: Date',
      path: DATE,
    },
    next: null,
  },
};
