import React from 'react';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { gtm } from 'utils/gtm';
import { UserDetails, UserSVLoginInfo } from 'types/GroupLeaderDetails';
import { UserInfo } from 'types/UserInfo';
import { apiPost, apiPut } from 'utils/api';
import { apiSVGet, apiSVPost, SVResponse } from 'utils/apiSV';
import appRoute from 'utils/appRoute';
import { isLeader, isLiveBooking, isSalesLead, logOut } from 'utils/userHelper';
import { detailsActions } from '../../../../SalesView/pages/Details/slice';
import { logInActions as actions } from './index';
import { commonActions } from 'store/slice/common';
import { notify } from 'utils/misc';
import { HTTPError } from 'ky';
import { SalesJourneyResponseStates } from 'types/SalesJourneyResponseStates';

const DEFAULT_ERROR_MESSAGE =
  'Unexpected error. Please try again or contact support using the live chat feature, in the bottom right corner of your screen.';

/**
 * This will do authentication through SalesJourney
 * to ensure a WeddingDetails record and a CRM Lead Id properly exist,
 * on top to verifying the entered credentials.
 * @param userName
 * @param password
 * @returns
 */
async function attemptSalesJourneyUserAuthentication(
  userName: string,
  password: string,
): Promise<SVResponse<UserInfo>> {
  let userAuthResponse: SVResponse<UserInfo> = {
    isSuccess: false,
    data: null,
    message: null,
  };
  try {
    userAuthResponse = await apiSVPost('Auth/AuthenticateUser', {
      userName,
      password,
    });
  } catch (e) {
    userAuthResponse.isSuccess = false;
    userAuthResponse.data = null;
    if (e instanceof HTTPError) {
      try {
        const jsonParsedError = await e.response.json();
        userAuthResponse.message =
          jsonParsedError.message ??
          'Unexpected error. Please try again or contact support.';
        if (
          'validationMessage' in jsonParsedError &&
          Array.isArray(jsonParsedError.validationMessage)
        ) {
          userAuthResponse.message +=
            jsonParsedError.validationMessage.join(', ');
        }
        if ('resultState' in jsonParsedError)
          userAuthResponse.resultState = jsonParsedError.resultState;
      } catch {}
    }
    if (e instanceof Error && !userAuthResponse.message)
      userAuthResponse.message = e.message;
  }
  return userAuthResponse;
}
async function afterLogin(
  userInfo: UserInfo,
  skipSalesHome: boolean,
  attemptRedirectingUser: boolean = true,
) {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
  localStorage.setItem('jwt', userInfo.identityToken);

  let redirectTo = appRoute.homePage();
  if (isLeader(userInfo)) {
    try {
      const response: SVResponse<UserDetails> = await apiSVGet(
        'Auth/GetUserDetails',
      );
      if (response?.isSuccess) {
        const progress = response.data?.userProgress;
        if (progress && progress !== 'BookingConfirmation') {
          redirectTo = appRoute.ptid();
        } else {
          redirectTo = appRoute.homePage();
        }
      }
    } catch (e) {
      // do nothing
    }
  }
  if (isLiveBooking()) {
    notify('You are logged in!', 'success');
    return;
  }

  if (isSalesLead(userInfo)) {
    if (skipSalesHome) {
      redirectTo = appRoute.ptid();
    } else {
      redirectTo = appRoute.homePage();
    }
  }

  if (attemptRedirectingUser) {
    setTimeout(() => (window.location.href = redirectTo), 50);
  }
}

function* impersonateLogin(action) {
  const data = action.payload;

  try {
    const userInfo: UserInfo = yield call(
      apiPost,
      'ValidateImpersonation',
      data,
    );
    if (!userInfo.isSuccess) {
      yield put(
        actions.authenticateUserErrorResult({ message: 'Invalid credentials' }),
      );
    } else {
      // see /app/pages/Profile/slice/saga.ts `localStorage.setItem`
      yield put(actions.authenticateUserSuccessResult());
      yield call(afterLogin, userInfo, false);
    }
  } catch (e) {
    yield put(
      actions.authenticateUserErrorResult({ message: DEFAULT_ERROR_MESSAGE }),
    );
  }
}

function* socialLogin(action) {
  const data = action.payload;

  try {
    const userInfo: UserInfo = yield call(apiPost, 'socialauth', data);
    if (!userInfo.isSuccess) {
      yield put(
        actions.authenticateUserErrorResult({ message: 'Invalid credentials' }),
      );
    } else {
      yield put(actions.authenticateUserSuccessResult());

      yield call(afterLogin, userInfo, false);
      yield put(commonActions.loadedUser(userInfo));
    }
  } catch (e) {
    yield put(
      actions.authenticateUserErrorResult({ message: DEFAULT_ERROR_MESSAGE }),
    );
  }
}

function* authenticateSalesJourneyCreatedUser(
  action: PayloadAction<{ email: string; password: string }>,
) {
  const { email: userName, password } = action.payload;

  //this will authenticate them against the Auth endpoint in the SJ api, to ensure we have a proper weddingdetails record created
  const authResult: SVResponse<UserInfo> = yield call(
    attemptSalesJourneyUserAuthentication,
    userName,
    password,
  );

  if (!authResult.isSuccess) {
    yield put(
      actions.authenticateUserErrorResult({
        message: authResult.message,
        resultState: authResult.resultState,
      }),
    );
    return;
  }
  yield put(actions.authenticateUserSuccessResult());
  yield call(afterLogin, authResult.data, false);
  yield put(commonActions.loadedUser(authResult.data));
}

function* signUpForm(action) {
  const data = action.payload;
  try {
    const registerResult = yield call(apiSVPost, 'Auth/register', {
      ...data,
    });
    if (!registerResult.isSuccess) {
      let errors = '';
      if (registerResult.validationMessage) {
        errors = registerResult.validationMessage.join(`\n`);
      } else if (registerResult.errors) {
        const allErrors = Object.entries(registerResult.errors);
        if (allErrors.length) {
          allErrors.forEach((entity: any) => {
            errors += entity[1].join(`\n`);
          });
        }
      }
      throw new Error(errors || DEFAULT_ERROR_MESSAGE);
    } else {
      gtm('SalesLeadAccountCreation');
      //DWA-2409: now we need to log them in.
      const authResult: SVResponse<UserInfo> = yield call(
        attemptSalesJourneyUserAuthentication,
        data.email,
        data.password,
      );
      if (authResult.isSuccess) {
        yield put(actions.setUserId(registerResult.data));
        yield put(actions.signUpFormSuccess(registerResult.message));
        yield call(afterLogin, authResult.data, true, false);
        //it is important to call this step after login, because the call depends on the auth token
        //so that in Destify.Core when we're setting the CreatedBy id during SaveChanges(), on the record/column
        //it depends on the auth token/user service resolving a valid userId, for subsequent lookups
        yield updateOfflineUserProgress(registerResult.data);
        yield put(commonActions.loadedUser(authResult.data));
      } else {
        //this will happen in extreme cases, like if parts of the api are down/broken
        //but, should we fail to immediately log them in, after account creation
        //purge everything and send them back to the login page
        //that way, when/if they are able to re-auth, then we'll rehydrate all of their stuff and they should be able to continue
        //where they left off
        notify(
          'Unexpected Error!',
          <p>
            Your account was successfully created, but due to an unknown error,
            we couldn't automatically log you in.
            <br />
            You will be redirected to the login page, please manually login,
            there.
            <br />
            Additionally, please check your inbox for a confirmation email that
            your account was created.
            <br />
            <small>We apologize for any inconvenience</small>
          </p>,
          'danger',
          'center',
          5000,
        );
        localStorage.clear();
        setTimeout(() => (window.location.href = '/log-in'), 5000);
      }
    }
    yield put(actions.setLoading(false));
  } catch (e) {
    const result: {
      message: string;
      resultState?: SalesJourneyResponseStates;
    } = { message: (e instanceof Error && e.message) || DEFAULT_ERROR_MESSAGE };
    //KY sucks!!!!! https://github.com/sindresorhus/ky/issues/107
    if (e instanceof HTTPError) {
      try {
        const jsonParsedError = yield e.response.json();
        result.message =
          jsonParsedError.message ??
          'Unexpected error. Please try again or contact support.';
        if (
          'validationMessage' in jsonParsedError &&
          Array.isArray(jsonParsedError.validationMessage)
        ) {
          result.message += jsonParsedError.validationMessage.join(', ');
        }
        if ('resultState' in jsonParsedError)
          result.resultState = jsonParsedError.resultState;
      } catch {}
    }
    yield put(actions.signUpFormError({ ...result }));
  }
}

function* makeLogOut() {
  yield put(actions.clearResultData());
  logOut();
  yield put(commonActions.loadedUser(null));
}

// ROOM INVITATION
//

function* roomInvitationSocialLoginGoogle(action) {
  const data = action.payload;

  try {
    const userInfo: UserInfo = yield call(apiPost, 'SocialAuth', data);
    if (!userInfo.isSuccess) {
      yield put(actions.roomInvitationLoginFormError('Invalid credentials'));
    } else {
      try {
        const createInvitation = yield call(
          apiPost,
          'roomaccess/accept-invitation',
          {
            invitationId: data.token,
            email: data.email,
          },
        );

        if (!createInvitation.success) {
          yield put(
            actions.roomInvitationLoginFormError(
              createInvitation.error
                ? createInvitation.error.toString()
                : DEFAULT_ERROR_MESSAGE,
            ),
          );
        } else {
          yield put(actions.roomInvitationLoginFormSuccess());
          yield call(afterLogin, userInfo, false);
        }
      } catch (ex) {
        yield put(actions.roomInvitationLoginFormError(DEFAULT_ERROR_MESSAGE));
      }
    }
  } catch (e) {
    yield put(actions.roomInvitationLoginFormError(DEFAULT_ERROR_MESSAGE));
  }
}

function* roomInvitationLoginForm(action) {
  const data = action.payload;

  try {
    const userInfo: UserInfo = yield call(apiPost, 'AuthenticateUser', {
      userName: data.email,
      password: data.password,
    });
    if (!userInfo.isSuccess) {
      yield put(actions.roomInvitationLoginFormError('Invalid credentials'));
    } else {
      try {
        const createInvitation = yield call(
          apiPost,
          'roomaccess/accept-invitation',
          {
            invitationId: data.token,
            email: data.email,
          },
        );

        if (!createInvitation.success) {
          yield put(
            actions.roomInvitationLoginFormError(
              createInvitation.error
                ? createInvitation.error.toString()
                : DEFAULT_ERROR_MESSAGE,
            ),
          );
        } else {
          yield put(actions.roomInvitationLoginFormSuccess());
          yield call(afterLogin, userInfo, false);
        }
      } catch (ex) {
        yield put(actions.roomInvitationLoginFormError(DEFAULT_ERROR_MESSAGE));
      }
    }
  } catch (e) {
    yield put(actions.roomInvitationLoginFormError(DEFAULT_ERROR_MESSAGE));
  }
}

function* roomInvitationSignupForm(action) {
  const data = action.payload;

  try {
    const createInvitation = yield call(
      apiPost,
      'roomaccess/accept-invitation',
      {
        invitationId: data.token,
        email: data.email,
      },
    );
    if (!createInvitation.success) {
      yield put(
        actions.roomInvitationSignupFormError(
          createInvitation.error
            ? createInvitation.error.toString()
            : DEFAULT_ERROR_MESSAGE,
        ),
      );
    } else {
      try {
        const passwordReset = yield call(apiPost, 'InitResetPassword', {
          email: data.email,
          returnUrl: window.location.origin + '/create-password',
        });
        if (passwordReset === 'Ok') {
          yield put(actions.roomInvitationSignupFormSuccess());
          window.location.href = '/reset-password-check';
        } else if (passwordReset === 'NotFound') {
          yield put(
            actions.roomInvitationSignupFormError(
              'User not found, try again...',
            ),
          );
        } else {
          yield put(
            actions.roomInvitationSignupFormError(DEFAULT_ERROR_MESSAGE),
          );
        }
      } catch (e) {
        let message = DEFAULT_ERROR_MESSAGE;
        if (e instanceof Error) message = e.message;
        yield put(actions.roomInvitationSignupFormError(message));
      }
    }
  } catch (ex) {
    yield put(actions.roomInvitationSignupFormError(DEFAULT_ERROR_MESSAGE));
  }
}

// LEGACY
//

function* legacySocialLoginGoogle(action) {
  const data = action.payload;

  try {
    const userInfo: UserInfo = yield call(apiPost, 'SocialAuth', data);
    if (!userInfo.isSuccess) {
      yield put(actions.legacyLoginFormError('Invalid credentials'));
    } else {
      try {
        const addUserGroupInformation = yield call(
          apiPut,
          `user/addUserGroupInformation/${data.groupId}/${encodeURIComponent(
            data.email,
          )}/${data.roomId || data.crmRoomId || 'null'}`, //until the janky backend is fixed, the stupid routing needs a value for this route component, and if we got nothing, it's expecting us to send null 💀
          {},
        );

        if (addUserGroupInformation.isError) {
          yield put(
            actions.legacyLoginFormError(
              addUserGroupInformation.errors
                ? addUserGroupInformation.errors.toString()
                : DEFAULT_ERROR_MESSAGE,
            ),
          );
        } else {
          yield put(actions.legacyLoginFormSuccess());
          yield call(afterLogin, userInfo, false);
        }
      } catch (ex) {
        yield put(actions.legacyLoginFormError(DEFAULT_ERROR_MESSAGE));
      }
    }
  } catch (e) {
    yield put(actions.legacyLoginFormError(DEFAULT_ERROR_MESSAGE));
  }
}

function* legacyLoginForm(action) {
  const data = action.payload;

  try {
    const userInfo: UserInfo = yield call(apiPost, 'AuthenticateUser', {
      userName: data.email,
      password: data.password,
    });
    if (!userInfo.isSuccess) {
      yield put(actions.legacyLoginFormError('Invalid credentials'));
    } else {
      try {
        const addUserGroupInformation = yield call(
          apiPut,
          `user/addUserGroupInformation/${data.groupId}/${encodeURIComponent(
            data.email,
          )}/${data.crmRoomId || data.roomId || 'null'}`, //until the janky backend is fixed, the stupid routing needs a value for this route component, and if we got nothing, it's expecting us to send null 💀
          {},
        );

        if (addUserGroupInformation.isError) {
          yield put(
            actions.legacyLoginFormError(
              addUserGroupInformation.errors
                ? addUserGroupInformation.errors.toString()
                : DEFAULT_ERROR_MESSAGE,
            ),
          );
        } else {
          yield put(actions.legacyLoginFormSuccess());
          yield call(afterLogin, userInfo, false);
        }
      } catch (ex) {
        yield put(actions.legacyLoginFormError(DEFAULT_ERROR_MESSAGE));
      }
    }
  } catch (e) {
    yield put(actions.legacyLoginFormError(DEFAULT_ERROR_MESSAGE));
  }
}

function* authenticateUser(
  action: PayloadAction<{ email: string; password: string }>,
) {
  const data = action.payload;
  try {
    const userInfo: UserInfo = yield call(apiPost, 'AuthenticateUser', {
      userName: data.email,
      password: data.password,
    });
    if (userInfo.isSuccess) {
      yield call(afterLogin, userInfo, true);
      yield put(commonActions.loadedUser(userInfo));
    } else {
      throw Error('Invalid credentials');
    }
  } catch (e) {
    let message = DEFAULT_ERROR_MESSAGE;
    if (e instanceof Error) message = e.message;
    notify(message, '', 'danger');
  }
}

//This is the path to create an account from the new live-booking pages
function* signupUser(action: PayloadAction<any>) {
  const data = action.payload;
  try {
    // register
    const response = yield registerUser({
      ...data,
      source: 'LIVE_BOOKING_SIGNUP_FORM',
    });
    if (response.success) {
      yield put(actions.signupUserSuccess(data));
    } else {
      const msg = response.error?.toString() || DEFAULT_ERROR_MESSAGE;
      throw Error(msg);
    }
  } catch (e) {
    let message = DEFAULT_ERROR_MESSAGE;
    if (e instanceof Error) message = e.message;
    notify(message, '', 'danger');
  }
}

function* registerUser(data) {
  return yield call(apiPost, 'RegisterUser', {
    //this is to support how both normal sign up and legacy signup funnel through the same
    //DSA endpoint. DSA will process account creation differently, based on this arg, and defaults to LIVE_BOOKING_SIGNUP_FORM
    //which is the newer signup form
    source: data.source || 'LIVE_BOOKING_SIGNUP_FORM', // should be LIVE_BOOKING_SIGNUP_FORM or LEGACY_SIGNUP_FORM
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: data.phoneNumber || data.phone,
    password: data.password,
    confirmPassword: data.password,
    groupId: data.groupId,
    returnUrl: data.returnUrl || window.location.origin + '/legacy/log-in',
  });
}

//This is the path to create an account from some yet other legacy "dashboard", that was done in php. DSD-6396
function* legacySignupForm(action: PayloadAction<any>) {
  const data = action.payload;

  try {
    //create the account
    const registerUserResponse = yield registerUser({
      ...data,
      returnUrl: window.location.origin + '/legacy/log-in',
      source: 'LEGACY_SIGNUP_FORM',
    });

    if (!registerUserResponse.success) {
      yield put(
        actions.legacySignupFormError(
          registerUserResponse.error
            ? registerUserResponse.error.toString()
            : //at this point, i think it's ok to let them try account creation again
              //it might be api issues that got us here, so let them try again
              'Something went wrong attempting to create your account. Please try again or contact support for additional assistance.',
        ),
      );
      return;
    }

    //at this point an account should have been created. try to log them in behind the scenes
    //moved this above the addUserGroupInformation call, because if this fails
    //we shouldnt pollute the DB with accounts that cant even login
    const userInfo: UserInfo = yield call(apiPost, 'AuthenticateUser', {
      userName: data.email,
      password: data.password,
    });

    if (!userInfo.isSuccess) {
      yield put(
        actions.legacySignupFormError(
          //we dont want to encourage them to rage mash the signup attempt again, and they have no agency
          //ability to do anything else, but contact support
          'Something went wrong activating your account. Please contact support for additional assistance.',
        ),
      );
      return;
    }
    //now, if we got here, the account was created and we've verified we can login with the credentials
    const addUserGroupInformation = yield call(
      apiPut,
      `user/addUserGroupInformation/${data.groupId}/${encodeURIComponent(
        data.email,
      )}/${data.roomId || data.crmRoomId || 'null'}`, //until the janky backend is fixed, the stupid routing needs a value for this route component, and if we got nothing, it's expecting us to send null 💀
      {},
    );

    if (addUserGroupInformation.isError) {
      yield put(
        actions.legacySignupFormError(
          addUserGroupInformation.errors
            ? addUserGroupInformation.errors.toString()
            : //here we choked trying to tie their new account with the corresponding
              //groupId/roomId, they cant do anything, so again, only thing they can do is contact support
              'Something went wrong trying to associate your newly created account, to the corresponding wedding group. Please contact support for additional assistance.',
        ),
      );
      return;
    }

    try {
      yield put(actions.legacySignupFormSuccess());
      yield call(afterLogin, userInfo, false);
    } catch (ex) {
      yield put(actions.legacySignupFormError(DEFAULT_ERROR_MESSAGE));
    }
  } catch (e) {
    let message = DEFAULT_ERROR_MESSAGE;
    if (e instanceof Error) message = e.message;
    yield put(actions.legacySignupFormError(message));
  }
}

function* indirectUserSVLogin(action: PayloadAction<UserSVLoginInfo>) {
  const userSV = action.payload;
  try {
    const userInfo: UserInfo = {
      userName: userSV.userName,
      userId: userSV.userId as string,
      identityToken: userSV.accessToken,
      firstName: userSV.displayName,
      lastName: '',
      email: userSV.email,
      phoneNo: '',
      // isActive,
      // isEmailConfirmed
      userRole: userSV.role,
      isSuccess: true,
      // errors
      crmGroupIds: [],
      crmRoomIds: [],
    };
    yield call(afterLogin, userInfo, true);
  } catch (e) {
    setTimeout(() => (window.location.href = '/'), 100);
  }
}

function* updateOfflineUserProgress(userId) {
  try {
    const tmp = localStorage.getItem('redux');
    const userValues = tmp && JSON.parse(tmp)?.salesLead?.allData;
    if (userValues) {
      const result = yield call(
        apiSVPost,
        `SalesLead/SaveSalesLeadStep/${userId}`,
        {
          ...userValues,
        },
      );
      if (result.isSuccess) {
        localStorage.clear();
      }
    }
  } catch (e) {
    let message = DEFAULT_ERROR_MESSAGE;
    if (e instanceof Error) message = e.message;
    console.log(message);
  }
}

export function* logInSaga() {
  yield takeLatest(actions.impersonateLogin.type, impersonateLogin);

  yield takeLatest(actions.socialLogin.type, socialLogin);
  yield takeLatest(
    actions.submitForm.type,
    authenticateSalesJourneyCreatedUser,
  );
  yield takeLatest(actions.signUpForm.type, signUpForm);
  yield takeLatest(actions.logOut.type, makeLogOut);
  yield takeLatest(
    actions.roomInvitationSocialLoginGoogle.type,
    roomInvitationSocialLoginGoogle,
  );
  yield takeLatest(
    actions.roomInvitationLoginForm.type,
    roomInvitationLoginForm,
  );
  yield takeLatest(
    actions.roomInvitationSignupForm.type,
    roomInvitationSignupForm,
  );
  yield takeLatest(
    actions.legacySocialLoginGoogle.type,
    legacySocialLoginGoogle,
  );
  yield takeLatest(actions.legacyLoginForm.type, legacyLoginForm);
  yield takeLatest(actions.legacySignupForm.type, legacySignupForm);
  yield takeLatest(
    detailsActions.detailsAddedSuccessfully.type,
    indirectUserSVLogin,
  );
  yield takeLatest(actions.signupUser.type, signupUser);
  yield takeLatest(actions.signupUserSuccess.type, authenticateUser);
}
