import React from 'react';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';

const PhoneField = (props: MuiTelInputProps) => {
  return (
    <MuiTelInput
      defaultCountry="US"
      forceCallingCode
      inputProps={{
        maxLength: 17,
      }}
      {...props}
    />
  );
};

export default PhoneField;
