/**
 *
 * Asynchronously loads the component for Guests
 *
 */

import { lazyLoad } from 'utils/loadable';

export const WeddingWebsite = lazyLoad(
  () => import('./index'),
  module => module.WeddingWebsite,
);
