import { useState, useEffect, useMemo } from 'react';
import { useIsMobile } from './responsiveHelper';
import { match, notify } from 'utils/misc';
import { SORT } from 'app/SalesView/pages/Ptid/SalesLead/StepResort';

/**
 * @param {Array} cardData - array of card data, ie a list of rooms, countries, venues anything where we need to display a bunch of cards.
 * @returns {Array} - [visibleCards, loadNext, hasMore]
 * visibleCards subset of data to be used to populate the cards
 * loadNext: tells the component when to load more cards
 * hasMore: whether or not there are any more cards to render
 * This api is based on `react-infinite-scroll-component` (see <LazyCardContainer/> ) but it is flexible enough to use for other lazy loading needs (see <AppCarousel/> )
 * */

export const useLazyScroll = (
  cardData: any[],
  specifiedNumberToLoad?: number, // tell the lazy container how many things to load at once. If not specified, this hook decides a number based on screen size
) => {
  const isMobile = useIsMobile('sm');
  const isTablet = useIsMobile('md');
  // numbers are based off the number of visible cards on the screen at a given screen size
  const numberToLoad = specifiedNumberToLoad
    ? specifiedNumberToLoad
    : isMobile
    ? 2
    : isTablet
    ? 4
    : 6;

  const [visibleCards, setVisibleCards] = useState(
    cardData?.slice(0, numberToLoad),
  );

  useEffect(() => {
    try {
      // initialize the lazy load container
      if (!cardData?.length) return;
      if (!visibleCards?.length) {
        setVisibleCards(cardData.slice(0, numberToLoad));
      }
    } catch (e) {
      console.error(
        'an error occurred when trying to initialize the lazy scroll hook. All elements will be loaded at once. Please check the useEffect hook in the useLazyScroll hook.',
        e,
      );
      setVisibleCards(cardData);
    }
  }, [cardData]);

  const [hasMore, setHasMore] = useState(true);

  const loadNext = (_numberToLoadNext?: number) => {
    try {
      const numberToLoadNext = _numberToLoadNext
        ? _numberToLoadNext
        : isMobile
        ? 1
        : isTablet
        ? 2
        : 3;
      // Append new visibleCards to the existing list
      setVisibleCards(
        cardData?.slice(0, visibleCards?.length + numberToLoadNext),
      );
      if (visibleCards?.length >= cardData?.length) {
        setHasMore(false); // Set to true if there's more data to load
      }
    } catch (e) {
      console.error(
        'an error occurred when trying to load the next set of cards. All elements will be loaded at once. Please check the `loadNext` function returned by `useLazyScroll`.',
        e,
      );
      setVisibleCards(cardData);
    }
  };

  return [visibleCards, loadNext, hasMore] as const;
};

// same as above, but this is just for the resorts page because we need to
// lazy load resorts in a way that accommodates sorting.
export const useLazyScrollAndSort = (cardData, sort, filter) => {
  const isMobile = useIsMobile('sm');
  const isTablet = useIsMobile('md');
  const numberToLoad = isMobile ? 2 : isTablet ? 4 : 6;

  const sortedCardData = useMemo(() => {
    return sortAndFilterResorts(cardData, sort, filter);
  }, [cardData, sort, filter]);

  const [visibleCards, setVisibleCards] = useState(
    sortedCardData?.slice(0, numberToLoad),
  );

  useEffect(() => {
    try {
      setVisibleCards(
        sortedCardData.slice(0, visibleCards.length + numberToLoad),
      );
    } catch (e) {
      console.error(
        'an error occurred when trying to initialize the lazy scroll hook. All elements will be loaded at once. Please check the useEffect hook in the useLazyScroll hook.',
        e,
      );
      setVisibleCards(sortedCardData);
    }
  }, [sort, filter]);

  const loadNext = () => {
    try {
      setVisibleCards(
        sortedCardData.slice(0, visibleCards.length + numberToLoad),
      );
    } catch (e) {
      console.error(
        'an error occurred when trying to load the next set of cards. All elements will be loaded at once. Please check the `loadNext` function returned by `useLazyScroll`.',
        e,
      );
      setVisibleCards(sortedCardData);
    }
  };

  return [visibleCards, loadNext, true] as const;
};

const sortAndFilterResorts = (resorts, sort, filter) => {
  try {
    return resorts
      ?.filter(r => !filter || match(r.resortName, filter)) // resort without select element
      .sort((a, b) => {
        // Favorite resorts are always displayed on the first line
        const isAFavorite = a.isFavorite ? -1 : 1;
        const isBFavorite = b.isFavorite ? -1 : 1;

        if (isAFavorite !== isBFavorite) {
          return isAFavorite;
        }

        switch (sort) {
          case SORT.resortNameAsc:
            return a.resortName.localeCompare(b.resortName);
          case SORT.resortNameDesc:
            return b.resortName.localeCompare(a.resortName);
          case SORT.roomRateAsc:
            return a.roomRate === 0 || b.roomRate === 0
              ? a.roomRate === 0
                ? 1
                : -1
              : a.roomRate < b.roomRate
              ? -1
              : 1;
          case SORT.roomRateDesc:
            return a.roomRate === 0 || b.roomRate === 0
              ? a.roomRate === 0
                ? 1
                : -1
              : a.roomRate < b.roomRate
              ? 1
              : -1;
          default:
            return 0;
        }
      });
  } catch (e) {
    console.error(
      'an error occurred when trying to sort and filter the resorts.',
      e,
    );
    notify(
      'Error',
      'We are unable to sort and filter resorts at this time. If this issue persists, please contact support.',
      'danger',
    );
    return resorts;
  }
};
