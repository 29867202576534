import styled from 'styled-components';

export const GOContainer = styled.div.attrs(props => ({
  className: 'google-optimize-container',
}))`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  * {
    max-width: 100%;
    font-family: Nunito, sans-serif;
  }
  min-height: 4px;
  img {
    margin: 0 auto;
  }
  h1,
  h2,
  h3,
  h4 {
    font-family: 'Nunito', 'Source Sans Pro', 'Roboto', 'Helvetica', 'Arial',
      sans-serif;
  }
  h1 {
    font-size: 3.3125rem;
  }
  h2 {
    font-size: 2.25rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.375rem;
  }
`;
