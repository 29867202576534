import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Stack, Typography } from '@mui/material';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { AppModal } from '../../../components/AppModal';
import { AppTextField } from '../../../components/Form/AppTextField';
import { IconLaptop } from '../../../components/Icons/IconLaptop';
import { IconPencilSquare } from '../../../components/Icons/IconPencilSquare';
import { IconPlusCircled } from '../../../components/Icons/IconPlusCircled';
import { IconTrash } from '../../../components/Icons/IconTrash';
import { RoundedButton } from '../../../components/RoundedButton';
import './fonts.css';
import { useWeddingWebsiteSlice } from './slice';
import { selectIsUpdating, selectWebsiteDetail } from './slice/selectors';
import { WebsiteDetail, WeddingRegistry } from './slice/types';

import { ItemCard, AddButton, RoundedButtonStyled } from './sharedComponents';
import { WeddingWebsiteLayout } from './WeddingWebsiteLayout';
import { pageNameList } from 'app/components/EditYourWebsiteMenu';

interface RegistryAddForm {
  websiteName: string;
  websiteUrl: string;
}

const MAX_ADD_COUNT = 3;
const MAX_LENGTH_WEBSITE_NAME = 25;

const schema = yup
  .object({
    websiteName: yup
      .string()
      .required()
      .max(
        MAX_LENGTH_WEBSITE_NAME,
        `website name cannot be more than ${MAX_LENGTH_WEBSITE_NAME} characters long!`,
      ),
    websiteUrl: yup.string().required().url(),
  })
  .required();

export function WeddingWebsiteRegistries() {
  const dispatch = useDispatch();
  const { actions } = useWeddingWebsiteSlice();
  const websiteDetail: WebsiteDetail = useSelector(selectWebsiteDetail);
  const isUpdating = useSelector(selectIsUpdating);

  const [weddingRegistries, setWeddingRegistries]: [
    WeddingRegistry[],
    React.Dispatch<React.SetStateAction<WeddingRegistry[]>>,
  ] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editRegistryKey, setEditRegistryKey] = useState(null);
  const [visibleEditRegistryModal, setVisibleEditRegistryModal] =
    useState(false);

  const { control, handleSubmit, setValue } = useForm<RegistryAddForm>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!websiteDetail) return;
    setWeddingRegistries(websiteDetail.weddingRegistries);
  }, [websiteDetail]);

  const handleUpdate = () => {
    const formData = {
      // need to send to api without .destify.com for now
      domainName: websiteDetail.websiteUrl,
      weddingRegistries,
    };
    dispatch(
      actions.requestWebsiteRegistriesEdit({
        formData,
      }),
    );
  };

  const onSubmit: SubmitHandler<RegistryAddForm> = data => {
    setWeddingRegistries([
      ...weddingRegistries,
      { websiteName: data.websiteName, websiteUrl: data.websiteUrl },
    ]);
    setValue('websiteName', '');
    setValue('websiteUrl', '');
    setShowForm(false);
  };

  const onClickEditRegistry = (key: number) => {
    setEditRegistryKey(key);
    setVisibleEditRegistryModal(true);
  };

  const handleEditRegistry = (weddingRegistry: WeddingRegistry) => {
    if (editRegistryKey >= 0 && editRegistryKey < weddingRegistries.length) {
      const updatedWeddingRegistry = {
        ...weddingRegistries[editRegistryKey],
        ...weddingRegistry,
      };

      const updatedRegistries = [
        ...weddingRegistries.slice(0, editRegistryKey),
        updatedWeddingRegistry,
        ...weddingRegistries.slice(editRegistryKey + 1),
      ];

      setWeddingRegistries(updatedRegistries);
      setVisibleEditRegistryModal(false);
    }
  };

  const renderEditRegistryModal = useMemo(() => {
    return visibleEditRegistryModal ? (
      <EditRegistryModal
        key={Math.random()}
        open={visibleEditRegistryModal}
        weddingRegistries={weddingRegistries}
        editRegistryKey={editRegistryKey}
        onSave={handleEditRegistry}
        onClose={() => {
          setVisibleEditRegistryModal(false);
          setEditRegistryKey(null);
        }}
      />
    ) : null;
  }, [editRegistryKey, visibleEditRegistryModal]);

  const handleDeleteRegistry = useCallback(
    (key: number) => {
      const updatedWeddingRegistries = [...weddingRegistries];
      updatedWeddingRegistries.splice(key, 1);
      setWeddingRegistries(updatedWeddingRegistries);
    },
    [weddingRegistries],
  );

  return (
    <WeddingWebsiteLayout
      title={'Registries'}
      currentPageName={pageNameList.registries}
    >
      <>
        <Box
          sx={{
            display: 'grid',
            gap: '30px',
            flexDirection: 'column',
          }}
        >
          {weddingRegistries.length > 0 ? (
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              {weddingRegistries.map((item, key) => (
                <ItemCard key={key}>
                  <Stack direction={'row'} gap={2} mb={1}>
                    <Typography variant={'h6'} display={'flex'} flex={1}>
                      {item.websiteName}
                    </Typography>
                    <Stack direction={'row'} gap={2}>
                      <Box
                        onClick={() => onClickEditRegistry(key)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <IconPencilSquare />
                      </Box>
                      <Box
                        onClick={() => handleDeleteRegistry(key)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <IconTrash />
                      </Box>
                    </Stack>
                  </Stack>
                  <Box>
                    <Typography variant={'body1'}>{item.websiteUrl}</Typography>
                  </Box>
                </ItemCard>
              ))}
            </Box>
          ) : (
            !showForm && (
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Box mb={2}>
                  <IconLaptop />
                </Box>
                <Typography variant={'h6'} mb={1}>
                  No registries added
                </Typography>
                <Typography variant={'body1'} textAlign={'center'} mb={2}>
                  Click below to add your first registry to your website.
                </Typography>
                <AddButton
                  data-testid="wedding-registries-button-show-form"
                  onClick={() => setShowForm(true)}
                >
                  <IconPlusCircled />
                </AddButton>
              </Box>
            )
          )}
          {weddingRegistries.length < MAX_ADD_COUNT && showForm && (
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <form
                id="registry-add-form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  name="websiteName"
                  control={control}
                  defaultValue={''}
                  render={({ field, formState }) => (
                    <AppTextField
                      inputProps={{
                        'data-testid': 'wedding-registries-input-website-name',
                      }}
                      sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
                      placeholder="Website name"
                      size="medium"
                      type="email"
                      variant="outlined"
                      fullWidth
                      {...field}
                      ref={null}
                      inputRef={field.ref}
                      error={Boolean(formState.errors.websiteName)}
                      helperText={
                        formState.errors.websiteName &&
                        formState.errors.websiteName.message
                      }
                    />
                  )}
                />
                <Controller
                  name="websiteUrl"
                  control={control}
                  defaultValue={''}
                  render={({ field, formState }) => (
                    <AppTextField
                      inputProps={{
                        'data-testid': 'wedding-registries-input-website-link',
                      }}
                      sx={{ m: 0, bgcolor: 'white' }}
                      placeholder="Website link"
                      size="medium"
                      type="email"
                      variant="outlined"
                      fullWidth
                      {...field}
                      ref={null}
                      inputRef={field.ref}
                      error={Boolean(formState.errors.websiteUrl)}
                      helperText={
                        formState.errors.websiteUrl &&
                        formState.errors.websiteUrl.message
                      }
                    />
                  )}
                />
                <Box display={'flex'} justifyContent={'center'} mt={2}>
                  <RoundedButton
                    className={'white'}
                    type={'submit'}
                    data-testid="wedding-registries-button-save-registry"
                  >
                    Save Registry
                  </RoundedButton>
                </Box>
              </form>
            </Box>
          )}
          {weddingRegistries.length > 0 &&
            weddingRegistries.length < MAX_ADD_COUNT &&
            !showForm && (
              <Box display={'flex'} justifyContent={'center'} mt={2}>
                <AddButton
                  data-testid="wedding-registries-button-add-registry"
                  onClick={() => setShowForm(true)}
                >
                  <IconPlusCircled />
                </AddButton>
              </Box>
            )}
          <Box display={'flex'} justifyContent={'center'} mt={2}>
            <RoundedButtonStyled loading={isUpdating} onClick={handleUpdate}>
              Update Website
            </RoundedButtonStyled>
          </Box>
        </Box>
      </>

      {renderEditRegistryModal}
    </WeddingWebsiteLayout>
  );
}

const EditRegistryModal = ({
  weddingRegistries,
  editRegistryKey,
  open,
  onSave,
  onClose,
}: {
  weddingRegistries: WeddingRegistry[];
  editRegistryKey: number;
  open: boolean;
  onSave: Function;
  onClose: Function;
}) => {
  const { control, handleSubmit } = useForm<RegistryAddForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<RegistryAddForm> = data => {
    if (!data.websiteName && !data.websiteUrl) {
      return;
    }

    onSave({ websiteName: data.websiteName, websiteUrl: data.websiteUrl });
  };

  return (
    <AppModal open={open} onClose={onClose}>
      <form
        id="edit-registry-modal-form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography variant={'h5'} fontWeight={600} textAlign={'center'} mb={2}>
          Edit registry information
        </Typography>
        <Controller
          name="websiteName"
          control={control}
          defaultValue={weddingRegistries[editRegistryKey].websiteName}
          render={({ field, formState }) => (
            <Box
              sx={{
                position: 'relative',
                '&::after': {
                  position: 'absolute',
                  '--offset': '.25em',
                  content: `"${field.value.length} / ${MAX_LENGTH_WEBSITE_NAME}"`,
                  bottom: 0,
                  right: 'calc(1 * var(--offset))',
                  fontSize: '.5em',
                  zIndex: 1,
                  transform: 'translateY(calc(-100% - var(--offset)))',
                },
              }}
            >
              <AppTextField
                inputProps={{
                  'data-testid': 'edit-registry-modal-input-website-name',
                  maxLength: MAX_LENGTH_WEBSITE_NAME,
                }}
                sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
                placeholder="Website name"
                size="medium"
                variant="outlined"
                fullWidth
                {...field}
                ref={null}
                inputRef={field.ref}
                error={Boolean(formState.errors.websiteName)}
                helperText={
                  formState.errors.websiteName &&
                  formState.errors.websiteName.message
                }
              />
            </Box>
          )}
        />
        <Controller
          name="websiteUrl"
          control={control}
          defaultValue={weddingRegistries[editRegistryKey].websiteUrl}
          render={({ field, formState }) => (
            <AppTextField
              inputProps={{
                'data-testid': 'edit-registry-modal-input-website-url',
              }}
              sx={{ m: 0, bgcolor: 'white' }}
              placeholder="Website link"
              size="medium"
              type="email"
              variant="outlined"
              fullWidth
              {...field}
              ref={null}
              inputRef={field.ref}
              error={Boolean(formState.errors.websiteUrl)}
              helperText={
                formState.errors.websiteUrl &&
                formState.errors.websiteUrl.message
              }
            />
          )}
        />
        <Stack p={'22px 0'} alignItems={'center'} spacing={1}>
          <RoundedButton
            data-testid="edit-registry-modal-button-save"
            style={{ minWidth: '200px' }}
            type={'submit'}
          >
            Save
          </RoundedButton>
          <Box
            data-testid="edit-registry-modal-button-cancel"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => onClose()}
          >
            Cancel
          </Box>
        </Stack>
      </form>
    </AppModal>
  );
};
