import { Stack, Typography } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import { IconAngleRight } from '../Icons/IconAngleRight';

type Props = {
  title: string;
  className?: string;
  onClick?: Function;
  icon?: boolean;
};

const MenuCategoryItem = (props: Props) => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <Stack
      data-testid={`menu-category-item-button-${props.title
        .replace(/\s+/g, '-')
        .toLowerCase()}`}
      direction={'row'}
      alignItems={'left'}
      justifyContent={'space-between'}
      padding={'14px 20px'}
      borderBottom={'1px solid #f2f2f2'}
      color={'#2d3031'}
      className={props.className}
      onClick={handleClick}
    >
      <Typography
        fontFamily={'"Nunito", sans-serif'}
        fontSize={18}
        fontWeight={400}
      >
        {props.title}
      </Typography>
      {props.icon ? <IconAngleRight /> : null}
    </Stack>
  );
};

export default styled(MenuCategoryItem)`
  cursor: pointer;

  &:hover,
  &.active {
    background: #e2f5f6;
  }
`;
