import React from 'react';

export const IconSidebarList = ({
  width = 48,
  height = 48,
  shadowColor = '#E2F5F6',
  color = '#202223',
}: {
  width?: number;
  height?: number;
  shadowColor?: string;
  color?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={shadowColor}
        d="M32.1011 9.15527e-05L0.575195 5.45583L9.70114 55.6191L37.9085 49.9188L32.1011 9.15527e-05Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M19.25 13C19.25 12.0335 20.0335 11.25 21 11.25H38.7258C39.5537 11.25 40.3374 11.6229 40.8597 12.2653L47.9304 20.963C49.3913 22.76 48.1125 25.4477 45.7966 25.4477H38.7419C37.7754 25.4477 36.9919 24.6642 36.9919 23.6977V14.6047C36.9919 14.1904 37.3277 13.8547 37.7419 13.8547C38.1561 13.8547 38.4919 14.1904 38.4919 14.6047V23.6977C38.4919 23.8357 38.6039 23.9477 38.7419 23.9477H45.7966C46.8493 23.9477 47.4306 22.726 46.7665 21.9092L39.6957 13.2115C39.4584 12.9195 39.1021 12.75 38.7258 12.75H21C20.8619 12.75 20.75 12.8619 20.75 13V53C20.75 53.1381 20.8619 53.25 21 53.25H48.9612C49.103 53.25 49.2164 53.1322 49.211 52.9905L48.2828 28.6332C48.267 28.2193 48.5898 27.871 49.0037 27.8552C49.4176 27.8394 49.7659 28.1622 49.7817 28.5761L50.7099 52.9334C50.7477 53.9253 49.9538 54.75 48.9612 54.75H21C20.0335 54.75 19.25 53.9665 19.25 53V13ZM25.7016 34.1395C25.7016 33.7253 26.0374 33.3895 26.4516 33.3895H42.9032C43.3174 33.3895 43.6532 33.7253 43.6532 34.1395C43.6532 34.5537 43.3174 34.8895 42.9032 34.8895H26.4516C26.0374 34.8895 25.7016 34.5537 25.7016 34.1395ZM25.7016 38.6977C25.7016 38.2835 26.0374 37.9477 26.4516 37.9477H42.9032C43.3174 37.9477 43.6532 38.2835 43.6532 38.6977C43.6532 39.1119 43.3174 39.4477 42.9032 39.4477H26.4516C26.0374 39.4477 25.7016 39.1119 25.7016 38.6977ZM25.7016 43.907C25.7016 43.4928 26.0374 43.157 26.4516 43.157H42.9032C43.3174 43.157 43.6532 43.4928 43.6532 43.907C43.6532 44.3212 43.3174 44.657 42.9032 44.657H26.4516C26.0374 44.657 25.7016 44.3212 25.7016 43.907Z"
      />
    </svg>
  );
};
