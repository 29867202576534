import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Box, Link, Stack, Button, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { selectCurrentProgress } from 'app/SalesView/pages/Ptid/SalesLead/slice/selectors';
import React, { memo, useMemo } from 'react';
import { useIsMobile } from 'utils/responsiveHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarVisible } from 'store/slice/common';
import styled from 'styled-components';
import { selectUser } from '../../../store/slice/common/selectors';
import AppRoute from '../../../utils/appRoute';
import { AppTopBarMenu } from '../AppMenu';
import UserStringAvatar from '../Misc/UserStringAvatar';
import { useLocation } from 'react-router';

export interface Props {
  title?: string;
  variant?: 'light' | 'dark';
  logo?: JSX.Element;
  showMenu?: boolean;
}

export const AppTopBar = memo((props: Props) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const currentProgress = useSelector(selectCurrentProgress) || '';
  const { variant = 'light' } = props;
  const openSideBar = () => {
    dispatch(setSidebarVisible(true));
  };
  const location = useLocation();
  const isMobile = useIsMobile('md');
  const theme = useTheme();

  const isLoginRoute = location.pathname === '/log-in';

  const logo = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {props.logo || (
          <img src={`/img/DestifyLogo_2022 2.svg`} alt="Destify Logo" />
        )}
      </Box>
    );
  }, [props.logo]);

  const topBarLogo = useMemo(() => {
    return <Link href={AppRoute.homePage()}>{logo}</Link>;
  }, [logo]);

  const accountMenu = useMemo(() => {
    return (
      user && (
        <Box display={'flex'} alignItems={'center'}>
          {/* {isDesktop && !isGuest(user) && !isLeader(user) && (
            <Button
              size="small"
              variant="outlined"
              sx={{
                borderRadius: 20,
                marginRight: 2,
                borderColor: '#00BFC8',
              }}
              onClick={handleCall}
            >
              Schedule a Free Call
            </Button>
          )} */}
          <UserStringAvatar user={user} isSideBar={false} />
        </Box>
      )
    );
  }, [user]);

  const loginPageLink = useMemo(() => {
    return (
      !user &&
      !isLoginRoute && (
        <Box>
          <Button
            variant="outlined"
            href={AppRoute.logInPage()}
            size="medium"
            sx={{
              marginLeft: '5px',
              border: `2px solid ${theme.palette.secondary.main}`,
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: theme.palette.common.white,
                border: `2px solid ${theme.palette.secondary.dark}`,
                boxShadow: 'none',
              },
            }}
          >
            {' '}
            Log In
          </Button>
          {/* Commenting this out for now */}
          {/* <Button
            sx={{
              marginLeft: '5px',
              borderColor: '#00BFC8',
              borderRadius: '8px',
            }}
            variant="outlined"
            href={AppRoute.signUpAsPlanPage()}
            size="medium"
          >
            {' '}
            Sign Up
          </Button> */}
        </Box>
      )
    );
  }, [user, currentProgress]);

  return (
    <>
      <AppBarStyled
        position={isMobile ? 'static' : 'fixed'}
        sx={variantStyles[variant]}
      >
        <Toolbar>
          {isMobile && user && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={openSideBar}
            >
              <DragHandleIcon />
            </IconButton>
          )}
          <Box textAlign={isMobile ? `center` : 'left'}>{topBarLogo}</Box>
          <Stack
            direction={'row'}
            gap={2}
            alignItems={'flex-start'}
            justifyContent={isMobile ? 'flex-end' : 'space-between'}
            ml={2}
            flex={1}
          >
            <AppTopBarMenu />
            {user && accountMenu}
            {loginPageLink}
          </Stack>
        </Toolbar>
      </AppBarStyled>
      {!isMobile && <Box height={'65px'} />}
    </>
  );
});

const variantStyles = {
  light: {
    backgroundColor: 'primary.white',
    color: 'primary.main',
    ' p': {
      color: 'primary.main',
    },
    boxShadow: 'none',
  },
  dark: {
    backgroundColor: 'primary.main',
    color: 'primary.white',
    ' p': {
      color: 'primary.white',
    },
  },
};

const AppBarStyled = styled(AppBar)`
  .MuiToolbar-root {
    min-height: 52px;
    display: flex;
  }

  @media (min-width: 768px) {
    .MuiToolbar-root {
      padding: 0 16px;
      min-height: 65px;
    }
  }

  & {
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 68.23%,
      rgba(216, 216, 216, 0.16) 100%
    );
  }
`;
