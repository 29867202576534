import React, { memo, ReactNode, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { IconBackArrow } from '../Icons/IconBackArrow';
import { RoundedButton } from '../RoundedButton';
import { AppModal } from '../AppModal';

interface Props {
  text: string;
  withBackArrow?: boolean;
  withDuringTravelSupport?: boolean;
  withBack?: boolean;
  backClick?: Function;
  children?: ReactNode;
  className?: any;
  style?: object;
  variant?: 'sales' | 'guest' | undefined;
  backLikeButton?: {
    text: string;
    onClick: () => void;
  };
}

export const PageTitle = memo((props: Props) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const handleBackClick = () => {
    if (props.backClick) {
      props.backClick();
    } else {
      history.goBack();
    }
  };

  const DuringTravelSupportModal = () => {
    return (
      <AppModal open onClose={() => setShowModal(false)}>
        <Box sx={{ maxWidth: '380px', textAlign: 'center' }}>
          <Typography variant={'h6'} mb={1}>
            During travel support
          </Typography>
          <Typography mb={2}>
            Please reach out to our team for 24/7 support.
            <br />
            Call us at 1-855-599-0911.
          </Typography>
          <Link
            data-testid="during-travel-support-modal-button-make-call"
            href={'tel:1-855-599-0911'}
            underline="none"
          >
            <RoundedButton>Make call</RoundedButton>
          </Link>
        </Box>
      </AppModal>
    );
  };

  return (
    <Wrapper
      className={`${props.className} pageTitle--${props.variant}`}
      style={props.style}
    >
      <div className="titleWithArrow">
        {props.withBackArrow && (
          <div
            data-testid="page-title-button-back-arrow"
            className="arrow"
            onClick={() => handleBackClick()}
          >
            <IconBackArrow />
          </div>
        )}
        <Typography className="titleText" variant="h5" fontWeight={600}>
          {props.text}
        </Typography>
      </div>
      {props.children && props.children}
      {props.withBack && (
        <div
          data-testid="page-title-button-back"
          className="back"
          onClick={() => handleBackClick()}
        >
          Back
        </div>
      )}
      {props.backLikeButton && (
        <div
          data-testid="page-title-button-back-like"
          className="back"
          onClick={props.backLikeButton.onClick}
        >
          {props.backLikeButton.text}
        </div>
      )}
      {props.withDuringTravelSupport && (
        <RoundedButton
          data-testid="page-title-button-during-travel-support"
          className="violet"
          onClick={() => setShowModal(true)}
        >
          During travel support
        </RoundedButton>
      )}
      {showModal && <DuringTravelSupportModal />}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  background-color: #fff;
  line-height: 60px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .titleText {
    font-size: 28px;
  }

  .titleWithArrow {
    display: flex;
    align-items: center;

    .arrow {
      cursor: pointer;
      margin-right: 16px;
    }
  }

  .back {
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }

  &.pageTitle--sales {
    border-bottom: unset;
    padding: 0;

    .titleText {
      font-size: 22px;
    }

    .back {
      font-size: 15px;
    }
  }

  &.pageTitle--guest {
  }
`;
