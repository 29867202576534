import * as React from 'react';
import { Carousel as ReactResponsiveCarousel } from 'react-responsive-carousel';

import { IconCarouselLeft } from '../Icons/IconCarouselLeft';
import { IconCarouselRight } from '../Icons/IconCarouselRight';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Box } from '@mui/material';

const prevRender = (
  clickHandler: () => void,
  hasPrev: boolean,
  label: string,
) => {
  return (
    <>
      {hasPrev && (
        <div
          data-testid="wedding-website-image-prev"
          className="arrowLeft"
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '40%',
            left: '0.3rem',
            zIndex: 1,
          }}
          onClick={() => clickHandler()}
        >
          <IconCarouselLeft
            width={50}
            height={50}
            fillCircle={'#fff'}
            fillPath={'#4F5252'}
          />
        </div>
      )}
    </>
  );
};

const nextRender = (
  clickHandler: () => void,
  hasNext: boolean,
  label: string,
) => {
  return (
    <>
      {hasNext && (
        <div
          data-testid="wedding-website-image-next"
          className="arrowRight"
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '40%',
            right: '0.3rem',
            zIndex: 1,
          }}
          onClick={() => clickHandler()}
        >
          <IconCarouselRight
            width={50}
            height={50}
            fillCircle={'#fff'}
            fillPath={'#4F5252'}
          />
        </div>
      )}
    </>
  );
};

export const Carousel = ({
  images,
  onChange,
  selectedItem,
  showIndicators = false,
}: {
  images: { url: string; title: string }[];
  onChange: (index: number) => void;
  selectedItem: number;
  showIndicators?: boolean;
}) => {
  return (
    <ReactResponsiveCarousel
      showThumbs={false}
      showStatus={false}
      renderArrowPrev={prevRender}
      renderArrowNext={nextRender}
      selectedItem={selectedItem}
      showIndicators={showIndicators}
      onChange={onChange}
    >
      {images.map((item, index) => {
        return (
          <Box key={index} sx={{ height: '100%' }}>
            <img
              src={item.url}
              alt={item.title || ''}
              style={{
                objectFit: 'cover',
                height: '100%',
              }}
            />
          </Box>
        );
      })}
    </ReactResponsiveCarousel>
  );
};
