import * as yup from 'yup';

export const SignUpDataValidationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required').email(),
  phone: yup
    .string()
    .required('Phone is required')
    .test('len', 'Invalid phone number.', value => {
      const phone = (value ?? '').replace(/[^0-9.]/g, '');

      if (phone.charAt(0) === '1') {
        return phone.length === 11;
      }

      return phone.length > 6;
    }),
});
