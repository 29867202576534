/**
 *
 * Asynchronously loads the component for RoomsExtras
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RoomsExtras = lazyLoad(
  () => import('./index'),
  module => module.RoomsExtras,
);
