import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../../../types/RootState';

const selectSlice = (state: RootState) => state.weddingWebsite || initialState;
const commonSlice = (state: RootState) => state.common;

export const selectWeddingWebsiteState = createSelector(
  [selectSlice],
  state => state,
);

export const selectCheckStatus = createSelector(
  [selectSlice],
  state => state.checkStatus,
);

export const selectWebsiteImages = createSelector(
  [selectSlice],
  state => state.websiteImages,
);
export const selectWebsiteDetail = createSelector(
  [selectSlice],
  state => state.websiteDetail,
);

export const selectLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectIsUpdating = createSelector(
  [selectSlice],
  state => state.isUpdating,
);

export const selectWeddingDate = createSelector(
  [commonSlice],
  state => state.weddingDetails?.eventDate,
);

export const selectSendSaveTheDateStatus = createSelector(
  [selectSlice],
  state => state.sendSaveTheDateStatus,
);
