import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

const WeddingWebsiteSkeleton = () => {
  return (
    <Box ml={'2rem'}>
      <Skeleton
        width={'30%'}
        variant="text"
        height={'32px'}
        sx={{ mb: '10px' }}
      />
      <Skeleton
        width={'20%'}
        variant="text"
        height={'40px'}
        sx={{ mb: '10px' }}
      />
      <Skeleton
        width={'40%'}
        variant="text"
        height={'32px'}
        sx={{ mb: '15px' }}
      />
      <Skeleton
        width={'30%'}
        variant="text"
        height={'32px'}
        sx={{ mb: '15px' }}
      />

      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={75}
        sx={{ mb: '20px' }}
      />
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={75}
        sx={{ mb: '20px' }}
      />
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={75}
        sx={{ mb: '20px' }}
      />
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={75}
        sx={{ mb: '20px' }}
      />
      <Skeleton
        variant="rectangular"
        width={'100%'}
        height={75}
        sx={{ mb: '20px' }}
      />
    </Box>
  );
};

export default WeddingWebsiteSkeleton;
