import { call, put, takeLatest } from 'redux-saga/effects';
import { detailsActions as actions } from '.';
import { apiSVGet, apiSVPut, SVResponse } from '../../../../../utils/apiSV';
import { notify } from '../../../../../utils/misc';
import { UserSVLoginInfo } from '../../../../../types/GroupLeaderDetails';

async function loadFormOptionsFromAPI() {
  const [
    roleOptions,
    roleOptions2,
    ceremonyTypeOptions,
    budgetOptions,
    travelInformationOptions,
    luxuryOptions,
  ] = await Promise.all([
    apiSVGet('WeddingDetails/WeddingRoleList'),
    apiSVGet('WeddingDetails/WeddingRoleSpouseTwoList'),
    apiSVGet('WeddingDetails/CeremonyList'),
    apiSVGet('WeddingDetails/WeddingEstimatedBudgetList'),
    apiSVGet('WeddingDetails/TravelInformationList'),
    apiSVGet('WeddingDetails/LuxuryLevelList'),
  ]);

  return {
    roleOptions: roleOptions.data.map(o => ({
      value: o.weddingRoleId,
      title: o.roleName,
    })),
    roleOptions2: roleOptions2.data.map(o => ({
      value: o.weddingSpouseRoleId,
      title: o.roleName,
    })),
    ceremonyTypeOptions: ceremonyTypeOptions.data.map(o => ({
      value: o.ceremonyTypeId,
      title: o.ceremonyName,
    })),
    budgetOptions: budgetOptions.data.map(o => ({
      value: o.estimatedBudgetId,
      title: o.estimatedBudget,
    })),
    travelInformationOptions: travelInformationOptions.data.map(o => ({
      value: o.travelInformationId,
      title: o.travelInformation,
    })),
    luxuryOptions: luxuryOptions.data.map(o => ({
      value: o.luxuryLevelId,
      title: o.luxuryLevel,
    })),
  };
}

function* loadFormOptions() {
  const allOptions = yield call(loadFormOptionsFromAPI);
  yield put(actions.putFormOptions(allOptions));
}

function* putFormData(action) {
  const { history, userId, ...data } = action.payload;
  const payload = {
    anticipatedWeddingDate: data.anticipatedWeddingDate,
    weddingRoleId: data.role,
    WeddingSpouseTwoRoleId: data.role2,
    spouseNameOne: data.nameFirst,
    spouseNameTwo: data.nameSecond,
    ceremonyTypeId: data.ceremonyType,
    estimatedGuest: data.guests,
    estimatedBudgetId: data.budget,
    travelInformationId: data.travelInformation,
    luxuryIds: data.luxury,
    isGuestStayingResortProperty: !!data.isStayingResort,
    isGuestStayingMinimumThreeNights: !!data.isMinimumNights,
    LGBTQWedding: !!data.LGBTQWedding,
  };

  try {
    const updateResult: SVResponse<UserSVLoginInfo> = yield call(
      apiSVPut,
      'WeddingDetails/UpdateWeddingDetails/' + userId,
      payload,
    );

    if (updateResult.isSuccess) {
      notify('Success!', 'Wedding Details added successfully.');
      // logInSaga subscribed to this action
      yield put(actions.detailsAddedSuccessfully(updateResult.data));
    } else {
      notify('Error', updateResult.message, 'danger');
    }
  } catch (e) {
    notify('Error', e.message, 'danger');
  }
  yield put(actions.setLoading(false));
}

export function* detailsSaga() {
  yield takeLatest(actions.loadFormOptions.type, loadFormOptions);
  yield takeLatest(actions.putFormData.type, putFormData);
}
