import * as React from 'react';
import styled from 'styled-components/macro';
import { TextField, TextFieldProps } from '@mui/material';

export function AppTextField(props: TextFieldProps) {
  return <StyledTextField {...props} />;
}

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    //text-align: center;
  }

  .MuiInputBase-root {
    .MuiInputAdornment-positionStart {
      margin-right: 10px;
    }

    &.Mui-disabled {
      background: #f2f4f8;
      border-radius: 5px;

      .MuiInputBase-input {
        -webkit-text-fill-color: unset;
      }

      fieldset {
        border: unset;
      }
    }

    & .MuiInputBase-input {
      color: black;
    }
  }
`;
