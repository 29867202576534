import StorageHelper from './storageHelper';

const APP_KEY = 'DESTIFY_APP_STATE';

class AppStateHelper extends StorageHelper {
  private static instance: AppStateHelper;

  private constructor() {
    super(APP_KEY);
  }

  get keys() {
    return {
      HD_TICKETS: 'HD-tickets-new',
    } as const;
  }

  static getInstance(): AppStateHelper {
    if (!AppStateHelper.instance) {
      AppStateHelper.instance = new AppStateHelper();
    }
    return AppStateHelper.instance;
  }
}

export default AppStateHelper.getInstance();
