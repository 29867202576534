import * as React from 'react';

export const IconPencilSquare = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 12V17C19 19.2091 17.2091 21 15 21H7C4.79086 21 3 19.2091 3 17V9C3 6.79086 4.79086 5 7 5H12"
        stroke="#2D3031"
      />
      <path
        d="M17.5361 4.06066C17.7314 3.8654 18.0479 3.8654 18.2432 4.06066L20.3645 6.18198C20.5598 6.37724 20.5598 6.69383 20.3645 6.88909L11.8651 15.3885C11.8099 15.4437 11.7426 15.4852 11.6686 15.5097L8.49867 16.5582C8.10849 16.6873 7.73787 16.3167 7.86694 15.9265L8.9155 12.7566C8.94 12.6826 8.98149 12.6153 9.03665 12.5601L17.5361 4.06066Z"
        stroke="#2D3031"
      />
      <path d="M16.4077 5.18884L19.2361 8.01727" stroke="#2D3031" />
      <path d="M8.7251 12.8718L11.5535 15.7003" stroke="#2D3031" />
    </svg>
  );
};
