import {
  Box,
  Card,
  CardContent,
  Checkbox,
  InputAdornment,
  Link,
  OutlinedInput,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import AppAlert from 'app/components/AppAlert';
import { AppModal } from 'app/components/AppModal';
import AppSelect, { AppSelectOption } from 'app/components/Form/AppSelect';
import { AppTextField } from 'app/components/Form/AppTextField';
import { GOContainer } from 'app/components/GOContainer';
import { IconAlertWarning } from 'app/components/Icons/IconAlertWarning';
import { IconHeart } from 'app/components/Icons/IconHeart';
import LoadingModal from 'app/components/Misc/LoadingModal';
import { RoundedButton } from 'app/components/RoundedButton';
import { addMinutes, isSameDay } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUserDetails } from 'store/slice/common/selectors';
import { ID, ImageDTO } from 'types/GroupLeaderDetails';
import appRoute from '../../../../../utils/appRoute';
import { IconCheckBox } from '../../../../components/Icons/IconCheckBox';
import { IconCheckBoxChecked } from '../../../../components/Icons/IconCheckBoxChecked';
import { IconDestifyLogo } from '../../../../components/Icons/IconDestifyLogo';
import { IconMagnifier } from '../../../../components/Icons/IconMagnifier';
import { AnticipatedCalendar } from '../../Details/AnticipatedCalendar';
import { PtidCarousel } from '../PtidCarousel';
import { PtidFullImageModal } from '../PtidFullImageModal';
import { LazyCardContainer } from 'app/components/LazyCardContainer';
import { useSalesLeadSlice } from './slice';
import { selectLoading, selectPreSelectedResort } from './slice/selectors';
import {
  selectAllData,
  selectIsAdding,
  selectIsRemoving,
  selectResorts,
  selectSearching,
} from './slice/selectors';
import { Resort } from './slice/types';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useLazyScrollAndSort } from 'utils/infiniteScroll';

export enum SORT {
  resortNameAsc = 'resortNameAsc',
  resortNameDesc = 'resortNameDesc',
  roomRateAsc = 'roomRateAsc',
  roomRateDesc = 'roomRateDesc',
}

const sortOptions: AppSelectOption[] = [
  { value: SORT.resortNameAsc, label: 'Name A - Z' },
  { value: SORT.resortNameDesc, label: 'Name Z - A' },
  { value: SORT.roomRateAsc, label: 'Room Price Low to High' },
  { value: SORT.roomRateDesc, label: 'Room Price High to Low' },
];

const getCompPolicyValue = (compPolicy: string): string | boolean => {
  let value = compPolicy.split(' ')[0];
  if (value.charAt(0) === '$') return value;
  return false;
};

export const StepResort = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions } = useSalesLeadSlice();
  const resorts: Resort[] = useSelector(selectResorts) as Resort[];
  const userDetails = useSelector(selectUserDetails);
  const allData = useSelector(selectAllData);
  const searching = useSelector(selectSearching);
  const loading = useSelector(selectLoading);
  const isAdding = useSelector(selectIsAdding);
  const isRemoving = useSelector(selectIsRemoving);
  const preSelectedResort = useSelector(selectPreSelectedResort);

  const [country, setCountry]: [ID[], any] = useState([]);
  const [fullImageList, setFullImageList]: [ImageDTO[], any] = useState([]);
  const [warning, setWarning] = useState({
    message: '',
    hasContent: false,
  });
  const [showSeeMoreModal, setShowSeeMoreModal] = useState(false);
  const [seeMoreResort, setSeeMoreResort]: [Resort, any] = useState(null);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState(SORT.resortNameAsc);

  const [visibleResorts, loadNext, hasMore] = useLazyScrollAndSort(
    resorts,
    sort,
    filter,
  );

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: any) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    dispatch(actions.doChangeShowWeddingDateAvailable(checked));

    // The block below is commented out because presumeably we want to bring this back when the functionality around this is fixed.
    // see comment:
    // https://travelzap.atlassian.net/browse/DWA-2110?focusedCommentId=78134&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-78134
    // if (!userDetails && checked) {
    //   setWarning({
    //     message:
    //       'Create your free wedding profile to unlock instant wedding date availability.',
    //     hasContent: true,
    //   });
    // } else {
    //   dispatch(actions.doChangeShowWeddingDateAvailable(checked));
    // }
  };

  const handleClose = () => {
    setWarning({ message: '', hasContent: false });
    setIsChecked(false);
  };
  useEffect(() => {
    if (allData.resortId?.length > 0 && preSelectedResort)
      for (let i = 0; i < resorts.length; i++) {
        if (resorts[i].resortId === allData.resortId?.[0])
          setFilter(resorts[i].resortName);
      }
  }, [resorts, preSelectedResort]);

  const handleSeeMoreClick = (resort: Resort) => {
    setShowSeeMoreModal(true);
    setSeeMoreResort(resort);
  };

  const handleLikeClick = useCallback(
    (resort: Resort, index: number) => {
      if (!userDetails) {
        setWarning({
          message:
            'Create an account or log-in to access your Favorite Resorts.',
          hasContent: true,
        });
        return;
      }

      if (resort.isFavorite) {
        dispatch(actions.removeFavoriteResort(resort.ptidAdminHotelId));
      } else {
        dispatch(actions.addFavoriteResort(resort.ptidAdminHotelId));
      }
    },
    [actions, dispatch, isAdding, isRemoving, userDetails],
  );

  useEffect(() => {
    country.length
      ? dispatch(actions.setNextData({ resortId: country }))
      : dispatch(actions.cleanNextData());
  }, [country]);

  const anticipatedDate = useMemo(() => {
    const _dateStr =
      userDetails?.anticipatedWeddingDate?.substring(0, 10) ||
      allData?.eventDate?.substring(0, 10);
    return _dateStr ? new Date(_dateStr + 'T00:00:00') : null;
  }, [allData?.eventDate, userDetails?.anticipatedWeddingDate]);

  const _menuValues = allData?.resortId;
  useEffect(() => {
    if (_menuValues?.length) {
      const _preselected = resorts.filter(v =>
        _menuValues.includes(v.resortId),
      );
      setCountry(_preselected.map(v => v.resortId));
    }
  }, [resorts, _menuValues]);

  useEffect(() => {
    if (anticipatedDate) handleChangeAnticipatedDate(new Date(anticipatedDate));
  }, [anticipatedDate]);

  useEffect(() => {
    const hasQuery = window.location.href.split('?').length > 1;

    if (hasQuery && !anticipatedDate) {
      const queryString = new URLSearchParams(
        window.location.href.split('?')[1].split('#')[0],
      );
      const queryDate = queryString.get('wedding_date');
      if (queryDate) {
        const decoded = decodeURIComponent(queryDate);
        const components = decoded.split('/');
        if (components.length === 3) {
          const year = components[2];
          const month = components[0];
          const date = components[1];
          handleChangeAnticipatedDate(
            new Date(year + '-' + month + '-' + date + 'T00:00:00'),
          );
        }
      }
    }
  }, []);

  const handleChangeAnticipatedDate = (d: Date | null) => {
    if (d && !isSameDay(anticipatedDate, d)) {
      const dateString = addMinutes(
        d,
        -1 * d.getTimezoneOffset(),
      ).toISOString();
      dispatch(actions.doChangeAnticipatedWeddingDate(dateString));
    }
  };

  const ResortsToCompareBlock = useMemo(() => {
    if (!Array.isArray(resorts)) return [];

    return visibleResorts.map((r, i) => {
      return (
        <ResortCard
          key={`${r.resortName}--${r.resortVenueId}`}
          index={i}
          resort={r}
          onSeeMoreClick={handleSeeMoreClick}
          onLikeClick={handleLikeClick}
          onExpand={() => setFullImageList(r.images)}
          theme={theme}
          isFavoriteResort={r.isFavorite}
        />
      );
    });
  }, [country, filter, handleLikeClick, visibleResorts, resorts]);

  const seeMoreModal = useMemo(() => {
    return showSeeMoreModal ? (
      <SeeMoreModal
        item={seeMoreResort}
        open={showSeeMoreModal}
        onClose={() => {
          setShowSeeMoreModal(false);
          setSeeMoreResort(null);
        }}
        onCancel={() => {
          setShowSeeMoreModal(false);
          setSeeMoreResort(null);
        }}
      />
    ) : null;
  }, [showSeeMoreModal, seeMoreResort]);
  return (
    <Box>
      {warning.message && (
        <AppAlert
          onClose={handleClose}
          title={warning.message}
          icon={
            warning.hasContent ? (
              <IconDestifyLogo width={137} height={140} />
            ) : (
              <IconAlertWarning />
            )
          }
          hideCloseButton={warning.hasContent}
        >
          {warning.hasContent && (
            <Stack
              direction={'column'}
              alignItems={'center'}
              spacing={2}
              mt={2}
            >
              <RoundedButton
                data-testid="app-alert-button-create-account"
                type="submit"
                onClick={() => history.push(appRoute.signUpAsPlanPage())}
              >
                Create Account
              </RoundedButton>
              <RoundedButton
                data-testid="app-alert-button-log-in"
                type="submit"
                onClick={() => history.push(appRoute.logInPage())}
              >
                Log In
              </RoundedButton>
            </Stack>
          )}
        </AppAlert>
      )}

      {searching ||
        (loading && (
          <LoadingModal open>
            <Typography variant="body1" textAlign={'center'}>
              Loading... Please wait.
            </Typography>
          </LoadingModal>
        ))}

      <Box mb={2}>
        <Box mb={2}>
          <Box mb={2}>
            <Typography variant="h1" className={'stepTitle'}>
              Select Resort
            </Typography>
          </Box>

          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                [theme.breakpoints.up('md')]: {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                },
                '&:last-child': { paddingBottom: 2 },
              }}
            >
              <Stack direction={'row'} gap={1} alignItems={'center'}>
                {anticipatedDate ? (
                  <>
                    <Typography
                      variant={'body1'}
                      sx={{
                        [theme.breakpoints.up('md')]: {
                          fontSize: '20px',
                        },
                      }}
                    >
                      Preferred Wedding Date
                    </Typography>
                  </>
                ) : (
                  <Typography
                    variant={'body1'}
                    sx={{
                      [theme.breakpoints.up('md')]: {
                        fontSize: '20px',
                      },
                    }}
                  >
                    What is your anticipated wedding date?
                  </Typography>
                )}
              </Stack>
              <Box>
                <AnticipatedCalendar
                  weddingDate={anticipatedDate}
                  onAccept={handleChangeAnticipatedDate}
                  renderInput={params => (
                    <AppTextField
                      fullWidth
                      placeholder={'MM/DD/YYYY'}
                      className="weddingDateInput"
                      {...params}
                      inputProps={{
                        'data-testid': 'anticipated-wedding-date-input-date',
                        ...params.inputProps,
                      }}
                      sx={{
                        '& input': { width: '100%' },
                        '& fieldset': { padding: 0, border: 'none' },
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: '#f2f2f2',
                          borderRadius: '5px',
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </CardContent>
          </Card>

          {anticipatedDate && (
            <Stack direction={'row'} gap={1} alignItems={'center'} mt={2}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    title=" View resorts with your date available!*"
                    inputProps={{
                      // @ts-ignore
                      'data-testid': 'step-resort-checkbox-available-resorts',
                    }}
                    icon={<IconCheckBox />}
                    checkedIcon={<IconCheckBoxChecked />}
                    checked={allData.showWeddingDateAvailable || isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <Typography variant={'body1'}>
                    View resorts with your date available!*
                  </Typography>
                </Box>
                <Typography variant={'body2'}>
                  *Other resorts might be available upon request. Estimated rate
                  is for the room based on 2 adults.
                </Typography>
              </Box>
            </Stack>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            justifyContent: 'space-between',
          }}
        >
          <Box flexGrow={1}>
            <OutlinedInput
              inputProps={{ 'data-testid': 'step-resort-input-search' }}
              size={'medium'}
              value={filter}
              placeholder={'Search resort'}
              onChange={e => setFilter(e.target.value)}
              sx={{ width: '100%' }}
              startAdornment={
                <InputAdornment position="start">
                  <IconMagnifier />
                </InputAdornment>
              }
            />
          </Box>
          <Box
            flexGrow={1}
            sx={{
              [theme.breakpoints.up('md')]: {
                maxWidth: '220px',
              },
            }}
          >
            <AppSelect
              inputProps={{ 'data-testid': 'step-resort-select-sort' }}
              fullWidth
              size={'medium'}
              withCheckboxes
              options={sortOptions}
              value={sort}
              onChange={event => {
                setSort(event.target.value as SORT);
              }}
              sx={{ margin: 0 }}
            />
          </Box>
        </Box>
      </Box>
      <GOContainer />

      <LazyCardContainer
        loadNext={loadNext}
        hasMore={hasMore}
        visibleCards={visibleResorts}
        cardList={ResortsToCompareBlock}
        emptyText={'Resorts not found'}
      />

      {fullImageList.length > 0 && (
        <PtidFullImageModal
          fullImageList={fullImageList}
          onClose={() => setFullImageList([])}
        />
      )}

      {seeMoreModal}
      <GOContainer />
    </Box>
  );
};

export const ResortCard = ({
  index,
  resort,
  onSeeMoreClick,
  onLikeClick,
  onExpand,
  isFavoriteResort = false,
  theme,
  hideLike = false,
}: {
  index: number;
  resort: Resort;
  onSeeMoreClick: (resort: Resort) => void;
  onLikeClick?: (resort: Resort, index: number) => void;
  onExpand?: () => void;
  isFavoriteResort?: boolean;
  theme: Theme;
  hideLike?: boolean;
}) => {
  const onSeeMoreClickHandler = () => onSeeMoreClick(resort);

  const [_isLikingOrUnliking, _setIsLikingOrUnliking] = useState(false);
  const onLikeClickHandler = () => {
    _setIsLikingOrUnliking(true);
    return onLikeClick(resort, index);
  };

  useEffect(() => {
    _setIsLikingOrUnliking(false);
  }, [isFavoriteResort]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '320px',
          margin: '0 auto',
        },
      }}
    >
      <PtidCarousel
        imageList={resort.images}
        onExpand={onExpand}
        ratio={1.59}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Box mb={2}>
          <Stack direction={'row'} gap={1} justifyContent={'space-between'}>
            <Typography variant={'h5'}>{resort.resortName}</Typography>
            <Box
              sx={{
                display: 'flex',
                width: '16px',
                height: '16px',
              }}
            >
              <>
                {_isLikingOrUnliking ? (
                  <CircularProgress size={'16px'} />
                ) : (
                  <>
                    {!hideLike && (
                      <Box
                        sx={{
                          display: 'flex',
                          cursor: 'pointer',
                        }}
                        onClick={onLikeClickHandler}
                      >
                        <IconHeart
                          stroke={resort.isFavorite ? 'none' : '#000000'}
                          fill={resort.isFavorite ? '#ff7b74' : '#ffffff'}
                        />
                      </Box>
                    )}
                  </>
                )}
              </>
            </Box>
          </Stack>
          <Typography variant={'body1'} lineHeight={1.3}>
            {resort.city}, {resort.country}
          </Typography>
        </Box>
        <Box mx={'-4px'} mb={resort.description ? 2 : 0}>
          {[
            {
              img: '/img/WineGlass.svg',
              text: `${resort.numberofBars || 0} bars`,
              backgroundColor: 'transparent',
            },
            {
              img: '/img/ForkAndSpoon.svg',
              text: `${resort.numberofRestaurants || 0} restaurants`,
              backgroundColor: 'transparent',
            },
            {
              img: '/img/WeddingVenue.svg',
              text: `${resort.numberofVenues || 0} venues`,
              backgroundColor: 'transparent',
            },
            resort.isAdultsOnly
              ? {
                  img: '',
                  text: `Adults only (18+)`,
                  backgroundColor: '#F9E8EC',
                }
              : {
                  img: '',
                  text: `Family friendly`,
                  backgroundColor: '#E2F5F6',
                },
          ]
            .filter(r => r)
            .map((r, i) => (
              <Box
                key={i}
                display={'inline-flex'}
                alignItems={'center'}
                justifyContent={r.img ? 'left' : 'center'}
                m={0.5}
                padding={'3px 6px'}
                borderRadius={'4px'}
                bgcolor={r.backgroundColor}
              >
                {r.img && (
                  <img
                    src={r.img}
                    alt={r.text}
                    width={18}
                    height={18}
                    style={{ marginRight: '4px' }}
                  />
                )}
                <Typography variant={'body1'} whiteSpace={'nowrap'}>
                  {r.text}
                </Typography>
              </Box>
            ))}
        </Box>
        <Box height={'2rem'}>
          {resort.compPolicy && getCompPolicyValue(resort.compPolicy) && (
            <Typography color={'#FF7B74'}>
              <LocalOfferIcon fontSize="inherit"></LocalOfferIcon>{' '}
              {getCompPolicyValue(resort.compPolicy)}+ cash back
            </Typography>
          )}
        </Box>
        {resort.description && (
          <Box>
            <Link
              data-testid={`card-button-see-more-${index}`}
              sx={{
                cursor: 'pointer',
                fontSize: '16px',
                fontWeight: 600,
                color: '#00BFC8',
              }}
              underline={'none'}
              onClick={onSeeMoreClickHandler}
            >
              See More
            </Link>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export const SeeMoreModal = ({
  item,
  open,
  onClose,
  onCancel,
}: {
  item: Resort;
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}) => {
  return (
    <AppModal open={open} onClose={onClose}>
      <Box maxWidth={'400px'}>
        <Typography
          variant={'h5'}
          textAlign={'center'}
          fontWeight={600}
          fontSize={22}
          lineHeight={1.36}
        >
          {item.resortName}
        </Typography>
        <Typography
          variant={'body1'}
          textAlign={'center'}
          lineHeight={1.3}
          mb={3}
        >
          {item.city}, {item.country}
        </Typography>
        {item.compPolicy && item.compPolicy !== 'No Comps Entered' && (
          <Typography color={'#FF7B74'}>
            {item.compPolicy} Cash back is provided after return from travel.
          </Typography>
        )}
        <Box dangerouslySetInnerHTML={{ __html: item.description }} />
        <Stack direction={'column'} mt={'30px'} spacing={2} padding={'0 2em'}>
          <RoundedButton
            data-testid="see-more-modal-button-close"
            className="fullWidth transparent"
            onClick={onCancel}
          >
            Close
          </RoundedButton>
        </Stack>
      </Box>
    </AppModal>
  );
};
