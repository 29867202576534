import * as React from 'react';
import { ReactNode, useState } from 'react';
import { Box, CardMedia } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import styled from 'styled-components/macro';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IconExpand } from '../Icons/IconExpand';
import { IconCarouselLeft } from '../Icons/IconCarouselLeft';
import { IconCarouselRight } from '../Icons/IconCarouselRight';
import { ImageDTO } from '../../../types/GroupLeaderDetails';
import { RationalWrapper } from '../CardMediaRational';
import { AppFullImageModal } from '../AppFullImageModal';
import { useLazyScroll } from 'utils/infiniteScroll';

export const AppCarousel = ({
  imageList,
  onExpand = null,
  ratio = null,
  placeholder = <CardMedia component="img" image={`/img/placeholder2.webp`} />,
}: {
  imageList: ImageDTO[];
  onExpand?: () => void;
  placeholder?: null | ReactNode;
  ratio?: null | number;
}) => {
  const [fullImageList, setFullImageList] = useState(null);
  const theme = useTheme();

  const expand = () => {
    onExpand ? onExpand() : setFullImageList(imageList);
  };

  const [visibleImages, loadNext] = useLazyScroll(imageList, 2);

  if (imageList?.length === 0) {
    return <RationalWrapper ratio={ratio}>{placeholder}</RationalWrapper>;
  }

  const prevRender = (
    clickHandler: () => void,
    hasPrev: boolean,
    label: string,
  ) => {
    return (
      <>
        {hasPrev && (
          <div
            data-testid="carousel-button-prev"
            className="arrowLeft"
            onClick={clickHandler}
          >
            <IconCarouselLeft fillCircle={'white'} fillPath={'black'} />
          </div>
        )}
      </>
    );
  };

  const nextRender = (
    clickHandler: () => void,
    hasNext: boolean,
    label: string,
  ) => {
    return (
      <>
        {hasNext && (
          <div
            data-testid="carousel-button-next"
            className="arrowRight"
            onClick={e => {
              // loadNext function tells the carousel to render the next image when the user clicks next.
              loadNext(1);
              clickHandler();
            }}
          >
            <IconCarouselRight fillCircle={'white'} fillPath={'black'} />
          </div>
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      <Wrapper className={'carouselWrapper'}>
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={true}
          renderArrowPrev={prevRender}
          renderArrowNext={nextRender}
          renderItem={(item, options) => {
            return <RationalWrapper ratio={ratio}>{item}</RationalWrapper>;
          }}
        >
          {[...imageList]
            .sort((i1, i2) => i1.imageOrder - i2.imageOrder)
            .map((image, i) => (
              <Box
                sx={{
                  height: '100% !important',
                  width: '100% !important',

                  [theme.breakpoints.down('sm')]: {
                    minHeight: '200px',
                  },
                }}
                key={i}
              >
                <img
                  // use visibleCards from lazy scroll hook to determine which images to show.
                  // only load image for first two images by default and then load
                  // the rest as they are scrolled into view.
                  src={i < visibleImages.length ? image.imageURL : ''}
                  alt={image.altTagText}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            ))}
        </Carousel>
        <Box className={'previewOpener'} onClick={expand}>
          <IconExpand />
        </Box>
      </Wrapper>
      {fullImageList?.length > 0 && (
        <AppFullImageModal
          fullImageList={fullImageList}
          onClose={() => setFullImageList([])}
          data-testid="previewOpener"
        />
      )}
    </React.Fragment>
  );
};

const Wrapper = styled(Box)`
  position: relative;

  .arrowLeft {
    cursor: pointer;
    position: absolute;
    top: calc(50% - 10px);
    left: 11px;
    z-index: 1;
  }

  .arrowRight {
    cursor: pointer;
    position: absolute;
    top: calc(50% - 10px);
    right: 11px;
    z-index: 1;
  }

  .previewOpener {
    cursor: pointer;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
`;
