import React from 'react';
import type { SubmitHandler } from 'react-hook-form';
import type { SignUpData, SocialSignUpData } from '../types';
import { useDispatch } from 'react-redux';
import { useSignUpForRoomSlice } from '../slice';
import { SignUpForm } from '../components/signUpForm';
import { AppModal } from 'app/components/AppModal';
import { Typography } from '@mui/material';
import { SignUpDataValidationSchema } from '../validations/signUpDataValidation';

export type ConfirmSignUpModalProps = {
  open: boolean;
  defaultValues?: Partial<Record<keyof SignUpData, string | undefined>>;
  onClosed?: () => void;
};

export const ConfirmSignUpModal = ({
  open,
  defaultValues,
  onClosed,
}: ConfirmSignUpModalProps) => {
  const dispatch = useDispatch();
  const { actions } = useSignUpForRoomSlice();

  const onSubmit: SubmitHandler<SocialSignUpData> = data => {
    dispatch(actions.socialSignUp(data));
  };

  return (
    <AppModal open={open} onClose={onClosed}>
      <Typography variant="h4" align="center" sx={{ mb: 2 }}>
        Please, confirm that your details are correct.
      </Typography>
      <SignUpForm
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        hiddenFields={['password']}
        validationSchema={SignUpDataValidationSchema}
        readOnlyFields={['email']}
        submitButtonText="Confirm"
      />
    </AppModal>
  );
};
