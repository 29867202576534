import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export const WeddingPartySkeleton = () => {
  return (
    <>
      <Stack>
        <Skeleton
          variant="rectangular"
          width={'100%'}
          height={76}
          sx={{ marginBottom: '1rem' }}
        />
        <Skeleton
          variant="rectangular"
          width={'100%'}
          height={76}
          sx={{ marginBottom: '1rem' }}
        />
        <Skeleton
          variant="rectangular"
          width={'100%'}
          height={76}
          sx={{ marginBottom: '1rem' }}
        />
      </Stack>
    </>
  );
};
