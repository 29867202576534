import * as React from 'react';

export const IconCheckmark = ({
  fill = 'none',
  stroke = '#05ABB5',
  strokeWidth = 2,
  width = 20,
  height = 16,
}: {
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 8.5L6.25 14.5L19 1"
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
