import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { salesLeadSaga } from './saga';
import {
  SalesLeadState,
  Country,
  PtidNextData,
  SalesLeadEditOptions,
  City,
  Resort,
  Venue,
  CeremonyTime,
  Package,
  SelectedResortRedirectInfo,
} from './types';
import { ID } from 'types/GroupLeaderDetails';
import { Step } from './types';
import {
  allDataKeys,
  COUNTRY_ID,
  RESORT_VENUE_ID,
  SUB_COUNTRY_ID,
  RESORT_ID,
  CITIES,
  RESORTS,
  VENUES,
  PACKAGES,
} from '../constants';
import { loadState } from 'utils/localStorageHelper';
import { store } from 'store/configureStore';
import { saveState } from 'utils/localStorageHelper';

export const initialState: SalesLeadState = {
  statusMsg: null,
  searching: false,
  loading: false,
  currentProgress: 'SalesLead',
  currentStep: null,
  currentStepData: null,
  allData: {},
  nextData: null,
  countries: [],
  cities: [],
  resorts: [],
  isAdding: false,
  isRemoving: false,
  venues: [],
  ceremonyTimes: [],
  packages: [],
  previousData: null,
  userDetails: null,
  groupInfo: null,
  groupDetails: null,
  hotelInfo: null,
  salesLeadEditOptions: null,
  anticipatedWeddingDate: null,
  preSelectedResort: false,
};

// subscribe to store changes and save to localstorage
// localstorage is used to persist the state between page reloads
// and to share the state between tabs
store.subscribe(() => {
  const state = store.getState();
  const userIsLoggedIn = state.common.user;
  if (userIsLoggedIn) {
    saveState(null);
  } else {
    saveState(state);
  }
});

const slice = createSlice({
  name: 'salesLead',
  // load the state from local storage if available
  // otherwise default to the initial state defined above
  initialState: loadState()?.salesLead || initialState,
  reducers: {
    doPreselectResort(
      state,
      action: PayloadAction<SelectedResortRedirectInfo>,
    ) {},
    setPreselectedResort(state) {
      state.preSelectedResort = true;
    },
    loadCountries(state) {
      state.loading = true;
    },
    setCountries(state, action: PayloadAction<Country[]>) {
      state.countries = action.payload;
      state.loading = false;
    },
    loadCities(state) {
      state.loading = true;
    },
    setCities(state, action: PayloadAction<City[]>) {
      state.cities = action.payload;
      state.loading = false;
    },
    loadResorts(state, action: PayloadAction<string | null>) {
      state.loading = true;
    },
    setResorts(state, action: PayloadAction<Resort[]>) {
      state.resorts = action.payload;
      state.loading = false;
    },
    addFavoriteResort(state, action: PayloadAction<ID>) {
      state.isAdding = true;
    },
    removeFavoriteResort(state, action: PayloadAction<ID>) {
      state.isRemoving = true;
    },
    setIsAdding(state, action: PayloadAction<boolean>) {
      state.isAdding = action.payload;
    },
    setIsRemoving(state, action: PayloadAction<boolean>) {
      state.isRemoving = action.payload;
    },
    setShowWeddingDateAvailable(state, action: PayloadAction<boolean>) {
      state.showWeddingDateAvailable = action.payload;
    },
    loadVenues(state) {
      state.loading = true;
    },
    setVenues(state, action: PayloadAction<Venue[]>) {
      state.venues = action.payload;
      state.loading = false;
    },
    loadCurrentStep(state) {
      state.loading = true;
    },
    setCurrentStep(state, action: PayloadAction<Step>) {
      state.currentStep = action.payload;
      state.loading = false;
    },
    loadPackages(state) {
      state.loading = true;
    },
    setPackages(state, action: PayloadAction<Package[]>) {
      state.packages = action.payload;
      state.loading = false;
    },
    loadCeremonyTimes(state) {
      state.loading = true;
    },
    setCeremonyTimes(state, action: PayloadAction<CeremonyTime[]>) {
      state.ceremonyTimes = action.payload;
      state.loading = false;
    },
    loadConfirmationOptions(state) {
      state.loading = true;
    },
    setEditOptions(state, action: PayloadAction<SalesLeadEditOptions>) {
      state.salesLeadEditOptions = action.payload;
      state.loading = false;
    },
    doNext(state, action?: PayloadAction<Step>) {
      // state.loading = true;
    },
    doBack(state, action?: PayloadAction<any>) {
      // state.loading = true;
    },
    setNextData(state, action: PayloadAction<PtidNextData>) {
      state.nextData = action.payload;
    },
    loadAllData(state) {
      state.loading = true;
    },
    updateAllData(state, action: PayloadAction<PtidNextData>) {
      let payload: string[] | PtidNextData = action.payload;

      // if the user goes back and changes a step, all subsequent steps
      // should be reset in the allData object
      const strippedData = removeSubsequentStepValues(payload);
      // ensure that if a user edits a step, all subsequent lists
      // ie cities, resorts, packages are forced to refetch
      const allData = {
        ...state.allData,
        ...action.payload,
        ...strippedData,
      };
      state.allData = allData;
    },
    setAllData(state, action) {
      state.allData = action.payload;
    },
    setPreviousData(state, action: PayloadAction<PtidNextData>) {
      state.previousData = action.payload;
    },
    cleanListData(state, action: PayloadAction<string[]>) {
      removeSubsequentListValues(state, action.payload);
    },
    cleanNextData(state) {
      state.nextData = null;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSearching(state, action: PayloadAction<boolean>) {
      state.searching = action.payload;
    },
    getCurrentProgress(state) {
      state.loading = true;
    },
    doSendRequest(state) {
      state.loading = true;
    },
    requestSuccess(state) {
      state.loading = false;
    },
    requestError(state, action: PayloadAction<string>) {
      state.loading = false;
    },
    doSaveAlternativeWeddingDate(state, action: PayloadAction<ID>) {
      state.loading = true;
    },
    doFindNewDates(state) {
      state.loading = true;
    },
    doFindNewResort(state) {
      state.loading = true;
    },
    setHotelInfo(state, action: PayloadAction<any>) {
      state.hotelInfo = action.payload;
    },
    loadTransfers(state) {
      state.loading = true;
    },
    sendReservationRequest(state) {
      state.searching = true;
    },
    reservationRequestComplete(state) {
      state.searching = false;
    },
    setStatusMsg(state, action: PayloadAction<null | string>) {
      state.statusMsg = action.payload;
    },
    doChangeAnticipatedWeddingDate(state, action: PayloadAction<string>) {
      state.searching = true;
      state.allData = {
        ...state.allData,
        anticipatedWeddingDate: action.payload,
        eventDate: action.payload.substr(0, 10),
      };
    },
    doChangeShowWeddingDateAvailable(state, action: PayloadAction<boolean>) {
      state.searching = true;
      state.allData = {
        ...state.allData,
        showWeddingDateAvailable: action.payload,
      };
    },
    doFastTravel(
      state,
      action: PayloadAction<{
        hotelId: string;
        date?: null | string;
        history: any;
      }>,
    ) {
      state.searching = true;
    },
    setFastTravelData(
      state,
      action: PayloadAction<{
        nextData: PtidNextData;
        //venues: Venue[];
        anticipatedDate?: null | string;
      }>,
    ) {
      //state.venues = action.payload.venues;
      state.nextData = action.payload.nextData;
      state.allData = {
        ...state.allData,
        ...state.nextData,
        ...(action.payload.anticipatedDate && {
          anticipatedWeddingDate: action.payload.anticipatedDate,
        }),
      };
      if (action.payload.anticipatedDate) {
        state.anticipatedWeddingDate = action.payload.anticipatedDate;
      }
      state.currentStep = 'Venue';
      state.searching = false;
    },
  },
});

export const { actions: salesLeadActions } = slice;

export const useSalesLeadSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: salesLeadSaga });
  return { actions: slice.actions };
};

function removeSubsequentStepValues(payload: string[] | PtidNextData) {
  let strippedData = {};
  if (Object.keys(payload).length === 1) {
    const payloadKey = Object.keys(payload)[0];
    // make sure to reset all next step values when a step is changed
    const allDataKeysToRemove = allDataKeys.filter((key, index) => {
      const indexOfKey = allDataKeys.indexOf(payloadKey);
      return indexOfKey < index;
    });
    allDataKeysToRemove.forEach(key => {
      strippedData[key] = null;
    });
  }
  return strippedData;
}

function resetStateValue(arr, state) {
  arr.forEach(key => {
    state[key] = [];
  });
}

function removeSubsequentListValues(state, payload: string[] | PtidNextData) {
  if (Object.keys(payload).length === 1) {
    const payloadKey = Object.keys(payload)[0];
    if (payloadKey === COUNTRY_ID) {
      resetStateValue([CITIES, RESORTS, VENUES, PACKAGES], state);
    }
    if (payloadKey === SUB_COUNTRY_ID) {
      resetStateValue([RESORTS, VENUES, PACKAGES], state);
    }
    if (payloadKey === RESORT_ID) {
      resetStateValue([VENUES, PACKAGES], state);
    }
    if (payloadKey === RESORT_VENUE_ID) {
      resetStateValue([PACKAGES], state);
    }
  }
}
