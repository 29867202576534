import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material';

// returns a boolean based on whether the given screen is below a certain width.
export const useIsMobile = (screenSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  const theme = useTheme();
  const breakpoints = theme.breakpoints.values;
  const width = breakpoints[screenSize || 'sm'];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= width);

  useEffect(() => {
    // Function to update the state based on the current screen width
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= width);
    };

    // Add event listener to listen for screen size changes
    window.addEventListener('resize', updateIsMobile);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateIsMobile);
    };
  }, []);

  return isMobile;
};
