import * as React from 'react';
import { ReactNode } from 'react';
import { Dialog, Stack, SxProps, Theme } from '@mui/material';
import { RoundedButton } from '../RoundedButton';
import styled from 'styled-components';
import { IconAlertDefault } from '../Icons/IconAlertDefault';
import { IconClose } from '../Icons/IconClose';
import { isBrowser } from 'react-device-detect';

interface Props {
  className?: string;
  icon?: any;
  title: string;
  closeButtonTitle?: string;
  hideCloseButton?: boolean;
  type?: string;
  children?: ReactNode;
  onClose: () => void;
  sx?: SxProps<Theme>;
  dismissable?: boolean; //if false, we will not show the X button in the top left
}

const AppAlert = (props: Props) => {
  const {
    className,
    title,
    closeButtonTitle,
    hideCloseButton,
    icon,
    onClose,
    children,
    sx,
    dismissable = true, //default to true, for existing uses of this component
  } = props;

  return (
    <Dialog
      className={className}
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={sx}
    >
      {dismissable && (
        <div
          data-testid="app-alert-button-close-icon"
          className="close"
          onClick={onClose}
        >
          <IconClose />
        </div>
      )}
      <Stack
        className={isBrowser ? 'desktop' : undefined}
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <div className="iconWrapper">{icon || <IconAlertDefault />}</div>

        <div className="titleWrapper" data-testid="app-alert-button-title">
          {title}
        </div>

        {children && <div className="contentWrapper">{children}</div>}

        {!hideCloseButton && (
          <div className="buttonWrapper">
            <RoundedButton
              data-testid="app-alert-button-close"
              onClick={onClose}
            >
              {closeButtonTitle || 'Close'}
            </RoundedButton>
          </div>
        )}
      </Stack>
    </Dialog>
  );
};

export default styled(AppAlert)`
  .MuiDialog-paper {
    padding: 32px 16px;
    position: relative;
    min-width: 328px;
    max-width: 460px;
    box-sizing: border-box;
  }

  .close {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .titleWrapper {
    font-weight: 600;
    font-size: 23px;
    line-height: 29px;
    text-align: center;

    color: #4d5358;
    margin-bottom: 20px;
  }

  .contentWrapper {
  }

  .iconWrapper {
    margin: 40px 0;
  }

  .buttonWrapper {
    margin: 20px 0;
  }

  & .desktop {
    padding: 0 66px;

    .buttonWrapper {
      width: 100%;
      text-align: center;

      button {
        width: 100%;
      }
    }
  }
`;
