import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from '../../../../../types';

const selectSlice = (state: RootState) => state.rooms || initialState;

export const selectRoom = createSelector([selectSlice], state => state.room);

export const selectLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectRoomModDateChangeStatus = createSelector(
  [selectSlice],
  state => state.roomModDateChangeAllowed,
);

export const selectBookingUpdateLoading = createSelector(
  [selectSlice],
  state => state.bookingUpdateLoading,
);

export const selectResultTitle = createSelector(
  [selectSlice],
  state => state.resultTitle,
);

export const selectResultMessage = createSelector(
  [selectSlice],
  state => state.resultMessage,
);

export const selectResultStatus = createSelector(
  [selectSlice],
  state => state.resultStatus,
);

export const selectTransfer = createSelector(
  [selectSlice],
  state => state.transfer,
);

export const selectShareLoading = createSelector(
  [selectSlice],
  state => state.shareLoading,
);

export const selectDepositPending = createSelector(
  [selectSlice],
  state => state.depositPending,
);

export const selectRoomDetails = createSelector(
  [selectSlice],
  state => state.roomDetails,
);
