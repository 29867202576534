import * as React from 'react';
import { Alert, Box, Typography } from '@mui/material';
import { RoundedButton } from '../../../../../components/RoundedButton';
import appRoute from '../../../../../../utils/appRoute';
import { useHistory } from 'react-router-dom';
import { SignUpForRoomLayout } from '../../layout';
import { useQuery } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectError, selectSignUpResult } from '../../slice/selectors';
import { signUpForRoomActions } from '../../slice';

interface SignUpForRoomFailureProps {}

export function SignUpForRoomFailure(props: SignUpForRoomFailureProps) {
  const history = useHistory();
  const query = useQuery();
  const error = useSelector(selectError);
  const dispatch = useDispatch();

  const signUpResult = useSelector(selectSignUpResult);

  const goToPage = (path: string) => {
    history.push({ pathname: path, search: query.toString() });
  };

  const goBack = () => {
    dispatch(signUpForRoomActions.redirectTo(''));
    dispatch(signUpForRoomActions.signUpResult(undefined));
    history.goBack();
  };

  const errors = [
    error?.error,
    ...(signUpResult?.error?.map(m => m.message) ?? []),
  ].filter(Boolean);

  return (
    <SignUpForRoomLayout
      title="Oops! Looks like something went wrong"
      disableRedirect
    >
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        {errors.map((error, index) => (
          <Alert
            key={`${error}-${index}`}
            severity="error"
            sx={{ mb: 4, fontWeight: 600 }}
          >
            {error}
          </Alert>
        ))}

        <Typography
          align="center"
          variant="subtitle1"
          sx={{ mb: 4, fontWeight: 600 }}
        >
          Please contact our User Support Team so we can assist you.
        </Typography>
        <RoundedButton
          onClick={() => goToPage(appRoute.signUpForRoomUserSupport())}
        >
          Contact User Support
        </RoundedButton>
        <RoundedButton onClick={goBack}>Try again</RoundedButton>
      </Box>
    </SignUpForRoomLayout>
  );
}
