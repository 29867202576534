import React, { useEffect, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { useQuery } from 'hooks';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import appRoute from 'utils/appRoute';
import { LoginLayout } from '../../../components/Layout/login';
import { WeddingDetails } from './components/weddingDetails';
import { selectSignUpForRoom } from './slice/selectors';

export type SignUpForRoomLayoutProps = {
  title?: string;
  children?: React.ReactNode;
  disableRedirect?: boolean;
};

export function SignUpForRoomLayout({
  title,
  children,
  disableRedirect,
}: SignUpForRoomLayoutProps) {
  const query = useQuery();
  const history = useHistory();
  const roomId = query.get('roomId');
  const { room, redirectTo } = useSelector(selectSignUpForRoom);

  const goToPage = useCallback(
    (path: string) => {
      history.push({ pathname: path, search: query.toString() });
    },
    [history, query],
  );

  useEffect(() => {
    if (!roomId) {
      // If roomId query parameter is not present, redirect to login page
      goToPage(appRoute.logInPage());
    }

    if (!disableRedirect && redirectTo) {
      goToPage(redirectTo);
    }
  }, [roomId, disableRedirect, redirectTo, goToPage]);

  const roomHasBeenLoaded = !!roomId && roomId === room?.id;

  return (
    <LoginLayout title={`Sign Up for Room - ${title}`}>
      <Box sx={{ pt: 4, pr: 2, pl: 2, pb: 4 }}>
        <Box sx={{ mr: 'auto', ml: 'auto', mb: 4 }}>
          <img
            style={{ display: 'block', width: '300px', margin: 'auto' }}
            src="/img/DestifyLogo_2022 2.svg"
            alt="Destify"
          />
        </Box>

        {!!roomId && <WeddingDetails roomId={roomId} />}

        {roomHasBeenLoaded && (
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            {!!title && (
              <Typography
                align="center"
                variant="h3"
                sx={{ mb: 4, fontWeight: 600 }}
              >
                {title}
              </Typography>
            )}

            {children}
          </Box>
        )}
      </Box>
    </LoginLayout>
  );
}
