import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import { useEffect, PropsWithChildren } from 'react';

/**
 * `CardRowSkeleton` is a React component that displays a loading skeleton
 * until a specified timeout period has passed. Once the timeout expires,
 * it displays its `children` if they are provided.
 *
 * This is useful for scenarios where content is loaded asynchronously,
 * and a skeleton loader is needed to improve user experience while waiting
 * for the actual content to load.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} [props.timeoutHideMs] - The delay (in milliseconds) before the skeleton is hidden and `children` is displayed.
 * @param {ReactNode} [props.children] - The content to display after the timeout. If not provided, only the skeleton will display.
 *
 * @example
 * // Display a skeleton loader for 2 seconds, then show the content
 * <CardRowSkeleton timeoutHideMs={2000}>
 *   <div>Your loaded content here</div>
 * </CardRowSkeleton>
 *
 * @returns {JSX.Element} The rendered component, showing either the skeleton or the children based on timeout.
 */
const CardRowSkeleton = ({
  timeoutHideMs,
  children,
}: PropsWithChildren<{
  timeoutHideMs?: number;
}>) => {
  const [shouldHideAfterTimeout, setShouldHideAfterTimeout] =
    React.useState<boolean>(false);
  useEffect(() => {
    if (!timeoutHideMs) return;

    const timeout = setTimeout(() => {
      setShouldHideAfterTimeout(true);
    }, timeoutHideMs);
    return () => {
      clearTimeout(timeout);
    };
  }, [timeoutHideMs]);

  if (shouldHideAfterTimeout) return children ? <>{children}</> : null;
  return (
    <Box sx={{ marginBottom: '60px' }}>
      <Box ml={1.5}>
        <Skeleton variant="text" width={'30%'} height={36} />
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        p={2}
        width={'100%'}
      >
        <Stack width={'100%'}>
          <Skeleton variant="rectangular" width={'100%'} height={275} />
          <Stack spacing={0} p={2}>
            <Skeleton variant="text" width={'90%'} />
            <Skeleton variant="text" width={'90%'} />
            <Skeleton variant="text" width={'30%'} />
            <Skeleton variant="text" width={'90%'} />
          </Stack>
        </Stack>
        <Stack width={'100%'}>
          <Skeleton variant="rectangular" width={'100%'} height={275} />
          <Stack spacing={0} p={2}>
            <Skeleton variant="text" width={'90%'} />
            <Skeleton variant="text" width={'90%'} />
            <Skeleton variant="text" width={'30%'} />
            <Skeleton variant="text" width={'90%'} />
          </Stack>
        </Stack>
        <Stack width={'100%'}>
          <Skeleton variant="rectangular" width={'100%'} height={275} />
          <Stack spacing={0} p={2}>
            <Skeleton variant="text" width={'90%'} />
            <Skeleton variant="text" width={'90%'} />
            <Skeleton variant="text" width={'30%'} />
            <Skeleton variant="text" width={'90%'} />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CardRowSkeleton;
