import { PixelCrop } from 'react-image-crop';

const TO_RADIANS = Math.PI / 180;
const MAX_CANVAS_AREA = 16777216; // Safari max canvas area (width * height)

// Helper function to get optimal pixel ratio based on device
function getOptimalPixelRatio(): number {
  // Get device pixel ratio
  const devicePixelRatio = window.devicePixelRatio || 1;

  // Detect iOS Safari
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isIOSSafari = isIOS && isSafari;

  // Lower ratio specifically for iOS Safari
  if (isIOSSafari) {
    // Use 0.75 for iOS Safari to prevent canvas memory issues
    return Math.min(0.75, devicePixelRatio);
  }

  // For desktop Safari
  if (isSafari) {
    return Math.min(1.5, devicePixelRatio);
  }

  // For all other browsers, cap at 2
  const maxPixelRatio = 2;
  return Math.min(devicePixelRatio, maxPixelRatio);
}

export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0,
) {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  // Calculate pixel ratio dynamically
  const pixelRatio = getOptimalPixelRatio();

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  let canvasWidth = Math.floor(crop.width * scaleX * pixelRatio);
  let canvasHeight = Math.floor(crop.height * scaleY * pixelRatio);

  // Check if the canvas area exceeds the maximum limit
  if (canvasWidth * canvasHeight > MAX_CANVAS_AREA) {
    const scaleFactor = Math.sqrt(
      MAX_CANVAS_AREA / (canvasWidth * canvasHeight),
    );
    canvasWidth = Math.floor(canvasWidth * scaleFactor);
    canvasHeight = Math.floor(canvasHeight * scaleFactor);
  }

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  // Set canvas scale based on pixel ratio
  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();
}
