import React, { ReactElement } from 'react';
import ReactInfiniteScroll from 'react-infinite-scroll-component';
import { Box, Typography, styled } from '@mui/material';

export const LazyCardContainer = ({
  cardList,
  emptyText,
  visibleCards,
  loadNext,
  hasMore,
}: {
  cardList: ReactElement[];
  emptyText?: string;
  visibleCards?: any[];
  loadNext?: () => void;
  hasMore?: boolean;
}) => {
  // ensure backwards compatibility 🤷‍♂️
  if (!visibleCards || !loadNext || !hasMore) {
    return <CardsContainer>{cardList}</CardsContainer>;
  }

  if (cardList.length) {
    return (
      <ReactInfiniteScroll
        dataLength={visibleCards.length}
        next={loadNext}
        hasMore={hasMore}
        loader={<></>}
      >
        <CardsContainer>{cardList}</CardsContainer>
      </ReactInfiniteScroll>
    );
  }

  if (emptyText) {
    return (
      <Typography variant="h4" sx={{ padding: '10rem 0' }} textAlign={'center'}>
        {emptyText}
      </Typography>
    );
  }

  return <></>;
};
const CardsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '2em',
  gridTemplateColumns: '1fr',
  justifyItems: 'center',
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
}));
