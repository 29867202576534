import React, { memo } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';
import PaidOutlined from '@mui/icons-material/PaidOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import styled from 'styled-components';
import { useTheme } from '@mui/material';
import { AutoFixHigh } from '@mui/icons-material';

interface Props {}

export const AppSpeedDial = memo((props: Props) => {
  const theme = useTheme();

  const actions = [
    { icon: <HelpOutlineOutlinedIcon />, name: 'Support Center' },
    { icon: <PaidOutlined />, name: 'Make A Payment' },
    { icon: <EditLocationOutlinedIcon />, name: 'Modify Reservation' },
    { icon: <TravelExploreIcon />, name: 'Book a Trip' },
  ];

  return (
    <Div>
      <SpeedDialStyled
        theme={theme}
        ariaLabel="SpeedDial openIcon example"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<AutoFixHigh />}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen={true}
          />
        ))}
      </SpeedDialStyled>
    </Div>
  );
});

const SpeedDialStyled = styled(SpeedDial)`
  .MuiButtonBase-root,
  .MuiSpeedDialAction-staticTooltipLabel,
  .MuiSpeedDialAction-fab {
    background-color: ${props => props.theme.palette.secondary.main};
    color: white;
    white-space: nowrap;
  }

  .MuiSpeedDialAction-staticTooltipLabel {
    margin-right: 0;
  }

  .MuiSpeedDialAction-fab {
    right: -6px;
  }
`;

const Div = styled.div``;
