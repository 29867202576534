import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { logInSaga } from './saga';
import { LogInState } from './types';
import { SalesJourneyResponseStates } from 'types/SalesJourneyResponseStates';

export const initialState: LogInState = {
  loading: false,
  resultMessage: null,
  resultStatus: null,
  userId: null,
};

const slice = createSlice({
  name: 'logIn',
  initialState,
  reducers: {
    impersonateLogin(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    socialLogin(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    submitForm(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    authenticateUserSuccessResult(state) {
      state.loading = false;
    },
    authenticateUserErrorResult(
      state,
      action: PayloadAction<{
        message: string;
        resultState?: SalesJourneyResponseStates;
      }>,
    ) {
      state.loading = false;
      state.resultMessage = action.payload.message;
      state.resultStatus = 'error';
      state.resultState = action.payload.resultState;
    },
    signUpForm(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    signUpFormSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.resultMessage = action.payload;
      state.resultStatus = 'success';
    },
    signUpFormError(
      state,
      action: PayloadAction<{
        message: string;
        resultState?: SalesJourneyResponseStates;
      }>,
    ) {
      state.loading = false;
      state.resultMessage = action.payload.message;
      state.resultStatus = 'error';
      state.resultState = action.payload.resultState;
    },
    clearResultData(state) {
      state.resultMessage = null;
      state.resultStatus = null;
      state.resultState = null;
    },
    logOut(state) {},
    // Room invitation form
    roomInvitationSocialLoginGoogle(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    roomInvitationLoginForm(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    roomInvitationLoginFormSuccess(state) {
      state.loading = false;
    },
    roomInvitationLoginFormError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.resultMessage = action.payload;
      state.resultStatus = 'error';
    },
    roomInvitationSignupForm(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    roomInvitationSignupFormSuccess(state) {
      state.loading = false;
    },
    roomInvitationSignupFormError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.resultMessage = action.payload;
      state.resultStatus = 'error';
    },
    // Legacy form
    legacySocialLoginGoogle(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    legacyLoginForm(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    legacyLoginFormSuccess(state) {
      state.loading = false;
    },
    legacyLoginFormError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.resultMessage = action.payload;
      state.resultStatus = 'error';
    },
    legacySignupForm(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    signupUser(state, action: PayloadAction<any>) {
      state.loading = true;
    },
    signupUserComplete(state) {
      state.loading = false;
    },
    signupUserSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
    },
    legacySignupFormSuccess(state) {
      state.loading = false;
    },
    legacySignupFormError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.resultMessage = action.payload;
      state.resultStatus = 'error';
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;
    },
  },
});

export const { actions: logInActions } = slice;

export const useLogInSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: logInSaga });
  return { actions: slice.actions };
};
