/**
 *
 * Asynchronously loads the component for Payment
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Payment = lazyLoad(
  () => import('./index'),
  module => module.Payment,
);
