import * as React from 'react';

export const IconDelete = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_5570_23884)">
        <rect width="32" height="32" rx="16" fill="#2D3031" fillOpacity="0.7" />
      </g>
      <path
        d="M21.0324 10.9674L10.2715 21.7282"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0324 21.7282L10.2715 10.9674"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_b_5570_23884"
          x="-6"
          y="-6"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_5570_23884"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_5570_23884"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
