const authViewRoutes = {
  signUpPage: () => '/sign-up',
  signUpAsPage: () => '/sign-up-as',
  signUpAsPlanPage: () => '/sign-up-as-plan',
  signUpAsPlanPageSuccess: () => '/sign-up-as-plan-success',
  signUpAsFindPage: () => '/sign-up-as-find',
  signUpSetWeddingDetailsPage: (userId?: string) =>
    userId
      ? `/sign-up-set-wedding-details?userId=${userId}`
      : '/sign-up-set-wedding-details',
  logInPage: () => '/log-in',
  resetPasswordPage: () => '/reset-password',
  resetPasswordCheckPage: () => '/reset-password-check',
  createPasswordPage: () => '/create-password',
  roomInvitationPage: () => '/accept-invitation',
  legacyLogInPage: () => '/legacy/log-in',
  legacySignUpPage: () => '/legacy/sign-up',
  signUpForRoom: () => '/sign-up-for-room',
  signUpForRoomEmail: () => '/sign-up-for-room/email',
  signUpForRoomAccountCreated: () => '/sign-up-for-room/account-created',
  signUpForRoomFailure: () => '/sign-up-for-room/failure',
  signUpForRoomSuccess: () => '/sign-up-for-room/success',
  signUpForRoomUserSupport: () => '/sign-up-for-room/user-support',
};
export default authViewRoutes;
