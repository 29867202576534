/**
 *
 * Asynchronously loads the component for Profile
 *
 */

import { lazyLoad } from 'utils/loadable';

export const CreatePassword = lazyLoad(
  () => import('./index'),
  module => module.CreatePassword,
);
