import * as React from 'react';

export const ContactUs = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4.5" y="6.5" width="4" height="9" rx="2" stroke="#2D3031" />
      <path
        d="M17 7V7C17 4.79086 15.2091 3 13 3H11C8.79086 3 7 4.79086 7 7V7"
        stroke="#2D3031"
      />
      <rect
        x="-0.5"
        y="0.5"
        width="1"
        height="3"
        rx="0.5"
        transform="matrix(-1 0 0 1 4 9)"
        stroke="#2D3031"
      />
      <rect
        x="-0.5"
        y="0.5"
        width="1"
        height="3"
        rx="0.5"
        transform="matrix(-1 0 0 1 20 9)"
        stroke="#2D3031"
      />
      <rect x="15.5" y="6.5" width="4" height="9" rx="2" stroke="#2D3031" />
      <path
        d="M17.5 15.5V16.5C17.5 17.6046 16.6046 18.5 15.5 18.5H14.5"
        stroke="#2D3031"
      />
      <rect x="11.5" y="17.5" width="3" height="2" rx="1" stroke="#2D3031" />
    </svg>
  );
};
