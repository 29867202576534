// Step name strings
export const DESTINATION = 'Destination';
export const COUNTRY = 'Country';
export const CITY = 'City';
export const RESORT = 'Resort';
export const VENUE = 'Venue';
export const RESERVATION = 'Reservation';
export const PACKAGE = 'Package';
export const DATE = 'Date';
export const ALTDATE = 'AltDate';
export const CONFIRMATION = 'Confirmation';

export const allStepName = [
  DESTINATION,
  COUNTRY,
  CITY,
  RESORT,
  VENUE,
  RESERVATION,
  PACKAGE,
  DATE,
  'Alternative Date',
  CONFIRMATION,
];

// Options
export const COUNTRIES = 'countries';
export const CITIES = 'cities';
export const RESORTS = 'resorts';
export const VENUES = 'venues';
export const PACKAGES = 'packages';
export const ALL_DATA = 'allData';

//All Data Keys for user request. track these keys through the sales lead flow
export const COUNTRY_ID = 'countryId';
export const SUB_COUNTRY_ID = 'cityId';
export const RESORT_ID = 'resortId';
export const RESORT_VENUE_ID = 'resortVenueId';
export const PACKAGE_ID = 'packageId';
export const EVENT_DATE = 'eventDate';
export const PREFER_PART_OF_DAY = 'preferPartOfDay';
export const EVENT_ALTERNATIVE_DATE = 'eventAlternativeDate';
export const PREFER_ALTERNATIVE_PART_OF_DAY = 'preferAlternativePartOfDay';
export const ROOM_BOOKING_DATA = 'roomBookingData';
export const ANTICIPATED_WEDDING_DATE = 'anticipatedWeddingDate';

// Iterate over this array to get data
// keys that should be reset when a user goes back and
// changes a previous step
export const allDataKeys = [
  COUNTRY_ID,
  SUB_COUNTRY_ID,
  ANTICIPATED_WEDDING_DATE,
  RESORT_ID,
  RESORT_VENUE_ID,
  PACKAGE_ID,
  ROOM_BOOKING_DATA,
];

// Other strings
export const CURRENT_STEP = 'currentStep';
export const SALES_LEAD = 'SalesLead';

// State ids for front end state machine
export const stateIds = {
  countryStateId: COUNTRY,
  cityStateId: CITY,
  resortStateId: RESORT,
  resortVenueStateId: VENUE,
  packageStateId: PACKAGE,
  weddingDateStateId: DATE,
  alterNativeWeddingDateStateId: ALTDATE,
};

// Calendly generic link
export const ptidCalendlyLink = 'https://calendly.com/path-to-i-do';

export const resServicesUrl =
  `${process.env.REACT_APP_RESERVATION_SERVICES_API_WS_URL}${process.env.REACT_APP_API_KEY}` as string;
