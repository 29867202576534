import * as React from 'react';

export const IconPlus = ({
  color = '#949696',
  bgColor = '#f2f2f2',
  width = 29,
  height = 29,
}: {
  color?: string;
  bgColor?: string;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle r="14.5" transform="matrix(-1 0 0 1 14.5 14.5)" fill={bgColor} />
      <path
        d="M7.24978 13.4653H7.23521C6.6633 13.4653 6.19922 13.9284 6.19922 14.5013C6.19922 15.0741 6.66229 15.5373 7.23521 15.5373H7.25059H21.7496H21.7641C22.336 15.5373 22.8001 15.0743 22.8001 14.5013C22.8001 13.9286 22.337 13.4653 21.7641 13.4653H21.7487H7.24978Z"
        fill={color}
      />
      <path
        d="M15.5359 7.25027V7.2357C15.5359 6.66379 15.0728 6.19971 14.4999 6.19971C13.9271 6.19971 13.4639 6.66278 13.4639 7.2357V7.25108V7.25027V21.7503V21.7648C13.4639 22.3367 13.9269 22.8008 14.4999 22.8008C15.0726 22.8008 15.5359 22.3378 15.5359 21.7648V21.7495V21.7503V7.25027Z"
        fill={color}
      />
    </svg>
  );
};
