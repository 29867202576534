import { getUserInfo } from './userHelper';

/**
 * Updates the google tag manager data layer with relevant data requested by marketing team.
 * In most cases, this behavior is bound to the user clicking the next button on a page in the sales flow.
 * @param event
 * @param data
 */

export function gtm(event?: string, data?: Record<string, unknown>) {
  window.dataLayer.push({
    event: event || window.location.hash,
    ...data,
  });
}

export function gtmPageView() {
  const path = window.location.pathname + window.location.hash;
  const pathnameEventMapper = {
    '/sales-lead#Destination': 'DestinationView',
    '/sales-lead#Country': 'CountryView',
    '/sales-lead#City': 'CityView',
    '/sales-lead#Resort': 'ResortView',
    '/sales-lead#Venue': 'VenueView',
    '/sales-lead#Package': 'PackageView',
    '/sales-lead#Date': 'WeddingDateRequestView',
    '/sales-lead#AltDate': 'AlternativeWeddingDateView',
    '/sales-lead#Confirmation': 'WeddingDateConfirmation',
    '/sign-up-set-wedding-details': 'WeddingDetailsView',
    '/ptid#WeddingDateApproved': 'WeddingDateApprovedView',
    '/ptid#AlternativeDateApproved': 'WeddingAltDateApprovedView',
    '/ptid#UnAvailableDate': 'WeddingDateUnavailableView',
    '/ptid#Reservation/ConfirmReservation': 'ConfirmWeddingReservationView',
    '/ptid#ConfirmReservation': 'ConfirmWeddingReservationStep2',
    '/ptid#BookingProgress/SearchRoom': 'SearchRoomView',
    '/ptid#BookingProgress/RoomArrangements': 'RoomArrangementsView',
    '/ptid#BookingProgress/RoomExtras': 'RoomExtrasView',
    '/ptid#BookingProgress/ReservationConfirmation':
      'BookingReservationConfirmationView',
    '/ptid#BookingProgress/Payment': 'PriceSummaryView',
  };

  const userInfo = getUserInfo();

  if (pathnameEventMapper[path]) {
    gtm(pathnameEventMapper[path], {
      user_role:
        userInfo?.userRole?.length > 0
          ? userInfo.userRole[0] ?? 'SalesLeadNotLoggedIn'
          : 'SalesLeadNotLoggedIn',
    });
  }
}
