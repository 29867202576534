import * as React from 'react';

export const IconClose = ({ fill = 'black' }) => {
  return (
    <svg
      display="block"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="19.1848"
        height="1.91848"
        transform="matrix(0.737154 -0.675725 0.737154 0.675725 0 13.3804)"
        fill={fill}
      />
      <rect
        width="19.1848"
        height="1.91848"
        transform="matrix(-0.737154 -0.675725 -0.737154 0.675725 16 13.3804)"
        fill={fill}
      />
    </svg>
  );
};
