import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonState } from './types';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { commonSaga } from './saga';
import { UserInfo } from 'types/UserInfo';
import { GroupInfo } from 'types/GroupInfo';
import { IdToRole, UserTripsInfo } from 'types/UserTripsInfo';
import { EUserRoomRole } from 'types/EUserRoomRole';
import { GuestList, RoomInfo } from 'types/RoomInfo';
import { getUserInfo } from 'utils/userHelper';
import { GroupDetails, UserDetails } from 'types/GroupLeaderDetails';
import { RSVPInfo, UpdateRSVPRequestDTO } from 'types/RSVPinfo';
import he from 'he';
import { Resort } from 'app/SalesView/pages/Ptid/SalesLead/slice/types';

export const initialCommonState: CommonState = {
  loading: true,
  env: process.env,
  error: null,
  sidebarVisible: false,
  user: getUserInfo(),
  userTrips: [],
  rooms: [],
  userRoomRole: null,
  weddingDetails: null,
  groups: [],
  roomIdToRole: {},
  rsvps: null,
  guestList: {
    totalRoom: 0,
    totalNights: 0,
    totalGuests: 0,
    adults: 0,
    children: 0,
    guests: [],
    compPolicy: null,
  },
  userDetails: null,
  groupDetails: null,
  groupInfo: null,
  topRecommendedResorts: null,
  favoriteResorts: null,
  userRecommendedResorts: null,
  actions: {
    deleteActions: [],
    updateActions: [],
  },
};

const commonSlice = createSlice({
  name: 'common',
  initialState: initialCommonState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSidebarVisible(state, action: PayloadAction<boolean>) {
      state.sidebarVisible = action.payload;
    },
    // fetchUser(state) {},
    createBatchUpdate(state, action: PayloadAction<{ id: string }>) {
      state.actions.updateActions = [
        ...state.actions.updateActions,
        action.payload.id,
      ];
    },
    completeBatchUpdate(state, action: PayloadAction<{ id: string }>) {
      state.actions.updateActions = state.actions.updateActions.filter(
        ac => ac !== action.payload.id,
      );
    },

    setDeleteItem(state, action: PayloadAction<{ id: string }>) {
      state.actions.deleteActions.push(action.payload.id);
    },

    removeDeleteItem(state, action: PayloadAction<{ id: string }>) {
      state.actions.deleteActions = state.actions.deleteActions.filter(
        ac => ac !== action.payload.id,
      );
    },

    getUserRoomRole(state, action: PayloadAction<string>) {},
    setUserRoomRole(state, action: PayloadAction<EUserRoomRole>) {
      state.userRoomRole = action.payload;
    },
    setRoomIdToRole(state, action: PayloadAction<IdToRole>) {
      state.roomIdToRole = action.payload;
    },
    fetchUserDetails(state) {
      state.loading = true;
    },
    fetchGroupDetails(state) {},
    fetchGroupInfo(state) {},
    setUserDetails(state, action: PayloadAction<UserDetails>) {
      state.userDetails = action.payload;
      if (
        state.user.firstName !== action.payload.firstName ||
        state.user.lastName !== action.payload.lastName ||
        state.user.phoneNo !== action.payload.phoneNumber ||
        !state.user.userRole.includes(action.payload.roles[0]?.name) ||
        state.user?.allowMessaging !== action.payload.allowMessaging
      ) {
        state.user = {
          ...state.user,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          phoneNo: action.payload.phoneNumber,
          userRole: [action.payload.roles[0].name],
          allowMessaging: action.payload.allowMessaging,
        };
      }
      state.loading = false;
    },
    setGroupDetails(state, action: PayloadAction<GroupDetails>) {
      state.groupDetails = action.payload;
    },
    setGroupInfo(state, action: PayloadAction<GroupInfo>) {
      state.groupInfo = action.payload;
    },
    fetchUserRelatedData(state) {},
    fetchUserTrips(state) {},
    loadLocalUser(state) {},
    loadedUser(state, action: PayloadAction<UserInfo | null>) {
      state.user = action.payload;
    },
    loadedUserTrips(state, action: PayloadAction<UserTripsInfo[]>) {
      state.userTrips = action.payload;
    },
    loadTrips(state) {
      state.loading = true;
      state.error = null;
    },
    loadWeddingDetails(state) {
      state.loading = true;
      state.error = null;
    },
    setWeddingDetails(state, action: PayloadAction<any>) {
      state.loading = false;
      state.weddingDetails = action.payload;
    },
    loadRsvps(state) {
      state.loading = true;
      state.error = null;
    },
    rsvpsLoaded(state, action: PayloadAction<null | RSVPInfo[]>) {
      state.rsvps = action.payload;
      state.loading = false;
    },
    updateRsvpStatus(state, action: PayloadAction<UpdateRSVPRequestDTO>) {},
    loadGuestList(state) {
      state.loading = true;
      state.error = null;
    },
    guestListLoaded(state, action: PayloadAction<GuestList>) {
      state.guestList = action.payload;
      state.loading = false;
    },
    downLoadGuestList(state) {
      state.loading = true;
      state.error = null;
    },
    guestListDownLoaded(state, action: PayloadAction<any>) {
      state.guestListFilename = action.payload;
      state.loading = false;
    },
    groupsLoaded(state, action: PayloadAction<GroupInfo[]>) {
      state.groups = action.payload;
      state.loading = false;
    },
    roomsLoaded(state, action: PayloadAction<any[]>) {
      // Some of the room info is encoded in html entities. They need to be decoded
      // before being displayed to prevent special characters from being displayed incorrectly.
      // example: roomName: Ad&#039ult O&#039neharris, Jr. converts to Ad'ult O'neharris, Jr.
      const rooms = action.payload.length
        ? action.payload.map(room => {
            return {
              ...room,
              room: room.room.length
                ? room.room.map(_room => {
                    // for each key in _room, decode the html entities in the value
                    let __room = {};
                    for (const key in _room) {
                      __room[key] =
                        typeof _room[key] === 'string'
                          ? he.decode(_room[key])
                          : _room[key];
                    }
                    return __room;
                  })
                : room.room,
            };
          })
        : action.payload;
      state.rooms = rooms;
      state.loading = false;
    },
    updateRoomInfo(state, action: PayloadAction<string>) {},
    roomInfoUpdated(state, action: PayloadAction<RoomInfo>) {
      const roomInfo = action.payload;
      state.rooms = state.rooms.map(r =>
        r.room[0].id === roomInfo.room[0].id ? roomInfo : r,
      );
    },
    loadTopRecommendedResorts() {},
    setTopRecommendedResorts(state, action: PayloadAction<Resort[]>) {
      state.topRecommendedResorts = action.payload;
      state.loading = false;
    },
    loadUserRecommendedResorts() {},
    setUserRecommendedResorts(state, action: PayloadAction<Resort[]>) {
      state.userRecommendedResorts = action.payload;
      state.loading = false;
    },
    loadFavoriteResorts() {},
    setFavoriteResorts(state, action: PayloadAction<Resort[]>) {
      state.favoriteResorts = action.payload;
    },
  },
});

export const { setSidebarVisible } = commonSlice.actions;
export const { actions: commonActions } = commonSlice;

export default commonSlice.reducer;

export const useCommonSlice = () => {
  useInjectReducer({ key: commonSlice.name, reducer: commonSlice.reducer });
  useInjectSaga({ key: commonSlice.name, saga: commonSaga });
  return { actions: commonSlice.actions };
};
