import React from 'react';
import * as yup from 'yup';
import { Box, Alert, TextFieldProps } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectSignUpForRoom } from '../slice/selectors';
import { AppTextField } from '../../../../components/Form/AppTextField';
import { RoundedButton } from '../../../../components/RoundedButton';
import PhoneField from './../../../../components/PhoneField';
import { EmailSignUpData } from '../types';

export interface EmailSignUpForm extends EmailSignUpData {}
export type FormFields = keyof EmailSignUpForm;
export type SignUpFormProps = {
  defaultValues?: Partial<Record<keyof EmailSignUpForm, string | undefined>>;
  validationSchema?: yup.AnyObjectSchema;
  onSubmit: (data: any) => void;
  hiddenFields?: FormFields[];
  readOnlyFields?: FormFields[];
  submitButtonText?: string;
};

const SignUpTextField = (props: TextFieldProps) => (
  <AppTextField
    sx={{ mb: '8px', bgcolor: 'white' }}
    InputLabelProps={{
      style: { color: '#949494' },
    }}
    size="medium"
    variant="outlined"
    fullWidth
    {...props}
  />
);

export const SignUpForm = ({
  defaultValues,
  validationSchema,
  onSubmit,
  hiddenFields,
  readOnlyFields,
  submitButtonText = 'Create My Account',
}: SignUpFormProps) => {
  const { room, group, isSigningUp, signUpResult } =
    useSelector(selectSignUpForRoom);

  const { control, handleSubmit, formState } = useForm<EmailSignUpForm>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<EmailSignUpForm> = data => {
    const signUpData: EmailSignUpData = {
      ...data,
      roomId: room?.id,
      groupId: group?.id,
    };

    onSubmit(signUpData);
  };

  const showField = (field: FormFields) => !hiddenFields?.includes(field);

  return (
    <form id="sign-up-form" noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <div>
        <Controller
          name="firstName"
          control={control}
          render={({ field, formState }) => (
            <SignUpTextField
              label="First Name"
              {...field}
              disabled={readOnlyFields?.includes(field.name)}
              ref={null}
              inputRef={field.ref}
              error={Boolean(formState.errors.firstName)}
              helperText={formState.errors.firstName?.message}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field, formState }) => (
            <SignUpTextField
              label="Last Name"
              {...field}
              disabled={readOnlyFields?.includes(field.name)}
              ref={null}
              inputRef={field.ref}
              error={Boolean(formState.errors.lastName)}
              helperText={formState.errors.lastName?.message}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field, formState }) => (
            <SignUpTextField
              label="Email Address"
              type="email"
              {...field}
              disabled={readOnlyFields?.includes(field.name)}
              ref={null}
              inputRef={field.ref}
              error={Boolean(formState.errors.email)}
              helperText={formState.errors.email?.message}
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field, formState }) => (
            <PhoneField
              label="Phone Number"
              sx={{ mb: '8px', bgcolor: 'white' }}
              InputLabelProps={{
                style: { color: '#949494' },
              }}
              size="medium"
              variant="outlined"
              fullWidth
              {...field}
              disabled={readOnlyFields?.includes(field.name)}
              ref={null}
              inputRef={field.ref}
              error={Boolean(formState.errors.phone)}
              helperText={formState.errors.phone?.message}
            />
          )}
        />
      </div>
      {showField('password') && (
        <div>
          <Controller
            name="password"
            control={control}
            render={({ field, formState }) => (
              <SignUpTextField
                label="Password"
                type="password"
                {...field}
                disabled={readOnlyFields?.includes(field.name)}
                ref={null}
                inputRef={field.ref}
                error={Boolean(formState.errors.password)}
                helperText={
                  formState.errors.password?.message ??
                  'Password must be at least 8 characters and contain 1 uppercase, 1 number, and 1 symbol'
                }
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field, formState }) => (
              <SignUpTextField
                label="Confirm Password"
                type="password"
                {...field}
                disabled={readOnlyFields?.includes(field.name)}
                ref={null}
                inputRef={field.ref}
                error={Boolean(formState.errors.confirmPassword)}
                helperText={formState.errors.confirmPassword?.message}
              />
            )}
          />
        </div>
      )}

      <Box sx={{ mb: 2, mt: 1, textAlign: 'center' }}>
        {signUpResult?.error?.map((error, index) => (
          <Alert key={`${error.code}-${index}`} severity="error" sx={{ mb: 2 }}>
            {error.message}
          </Alert>
        ))}

        <RoundedButton
          type="submit"
          loading={formState.isSubmitting}
          className=""
          disabled={formState.isSubmitting || isSigningUp}
        >
          {submitButtonText}
        </RoundedButton>
      </Box>
    </form>
  );
};
