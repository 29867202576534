import { put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { WeddingWebsiteActions as actions } from './index';
import { selectGroups, selectUser } from 'store/slice/common/selectors';
import { apiDelete, apiGet, apiPost } from 'utils/api';
import { notify } from 'utils/misc';
import { defaultImage } from '../defaultImage';
import { GroupInfo } from 'types/GroupInfo';
import { UserInfo } from 'types/UserInfo';
import { selectWebsiteDetail } from './selectors';
import {
  EditWebsiteRegistriesPayload,
  EditWebsiteItinerariesPayload,
  CustomPageContent,
} from './types';
import { commonActions } from 'store/slice/common';

function* checkWebsite(action: PayloadAction<string>) {
  // console.log('DEBUG: checking: ends with 6 - error otherwise - success');
  // console.log('DEBUG: registering: ends with 7 - success otherwise - error');
  const website = action.payload.trim();
  if (!website) {
    // empty string
    return;
  }

  try {
    const resp = yield apiGet(
      `Site/CheckWebsiteDomainAvailibilty/${website}.com`,
    );

    if (resp && resp.statusCode === 200) {
      yield put(actions.checkWebsiteSuccess(website));
    } else {
      yield put(actions.checkWebsiteError(website));
    }
  } catch (e) {
    console.error(e);
    yield put(actions.checkWebsiteError(website));
  }
}

function* registerWebsite(action: PayloadAction<string>) {
  const website = action.payload.trim();
  if (!website) {
    // empty string
    return;
  }

  const groupInfo: GroupInfo[] = yield select(selectGroups);
  const userInfo: UserInfo = yield select(selectUser);

  const {
    groupId,
    groupLeaderName,
    groupName,
    minimumNights,
    hotelNames,
    spouseOneName,
    spouseTwoName,
  } = groupInfo[0];

  const spouse1FirstName = spouseOneName.split(' ')[0];
  const spouse2FirstName = spouseTwoName.split(' ')[0];
  const spouse1LastName =
    spouseOneName.split(' ')[spouseOneName.split(' ').length - 1];
  const spouse2LastName =
    spouseTwoName.split(' ')[spouseTwoName.split(' ').length - 1];

  // hotel names may have trailing spaces. remove them. dwa-1148
  function trim(str: string) {
    return str.replace(/^\s+|\s+$/gm, '');
  }

  try {
    const payload = {
      // At present, the CRM expects the subdomain to be formatted in this way.
      // so if the full wedding domain is "mywedding.destify.com", the subdomain
      // should be "mywedding.com"
      subDomain: `${website}.com`,
      title: groupName,
      groupName,
      groupLeaderName:
        groupLeaderName ||
        `${userInfo.firstName} ${userInfo.lastName}` ||
        'Group Leader',
      groupId,
      featuredImageFileName: 'beach2.jpeg',
      featuredImageBase64Data: defaultImage,
      destination: { name: trim(hotelNames[0]) },
      destinationName: trim(hotelNames[0]),
      agent: { name: 'Guest Services' },
      minimumNights,
      secondDestination: { name: hotelNames[1] ? trim(hotelNames[1]) : '' },
      thirdDestination: { name: hotelNames[2] ? trim(hotelNames[2]) : '' },
      spouse1FirstName,
      spouse1LastName,
      spouse2FirstName,
      spouse2LastName,
      // DWA-1314 Change the defaulted selections
      headerColor: '#2e4511',
      secondaryColor: '#FFFFFF',
      backgroundImageKey: '3dc781e9-e8bd-4558-adb7-d0d9184d4e1c',
      mainColor: '#7A9B8F',
      themeName: 'One Page No Border',
      userId: userInfo.userId,
    };

    const resp = yield apiPost('site/CMSCreateSite', payload);

    if (resp && resp.success) {
      const websiteUrl = `${website}.destify.com`;
      yield put(actions.registerWebsiteSuccess(websiteUrl));
      yield put(actions.loadWebsiteDetail());
    } else {
      yield put(actions.registerWebsiteError());
    }
  } catch (e) {
    console.error(e);
    yield put(actions.registerWebsiteError());
    notify(
      'Error',
      'Please reach out to Guest Services at guestservices@destify.com',
      'danger',
    );
  }
}

function* loadWebsiteImages() {
  try {
    let images = yield apiGet('Site/WebsiteBackgroundImages');

    yield put(actions.setWebsiteImages(images));
  } catch (e: any) {
    notify('error', 'Error loading images', e.message);
  }
}

function* requestWebsiteRegistriesEdit(
  action: PayloadAction<EditWebsiteRegistriesPayload>,
) {
  const formattedUrl = action.payload.formData.domainName
    .toLowerCase()
    .replace('.destify.com', '.com')
    .replace('https://', '');
  const weddingRegistries = action.payload.formData.weddingRegistries;
  try {
    const response = yield apiPost('site/CreateUpdateWeddingRegistries', {
      WeddingRegistry: weddingRegistries,
      DomainName: formattedUrl,
    });
    if (response.success) {
      yield put(actions.loadWebsiteDetail());
      notify(
        'success',
        'Website updated! Your changes may take a minute to become visible.',
      );
    } else {
      notify('error', response.error, 'danger');
    }
  } catch (error) {
    notify('error', 'An unexpected error occurred.', 'danger');
  }
  yield put(actions.setIsUpdating(false));
}

function* requestWebsiteItinerariesEdit(
  action: PayloadAction<EditWebsiteItinerariesPayload>,
) {
  const formattedUrl = action.payload.formData.domainName
    .toLowerCase()
    .replace('.destify.com', '.com')
    .replace('https://', '');
  const weddingItineraries = action.payload.formData.weddingItineraries;
  try {
    const response = yield apiPost('site/createUpdateWeddingItineraries', {
      weddingItinerary: weddingItineraries,
      domainName: formattedUrl,
    });
    if (response.success) {
      yield put(actions.loadWebsiteDetail());
      notify(
        'success',
        'Your changes have been accepted for processing. Please check back in a few minutes to see them reflected.',
      );
    } else {
      notify('error', response.error, 'danger');
    }
  } catch (error) {
    notify('error', 'An unexpected error occurred.', 'danger');
  } finally {
    yield put(actions.setIsUpdating(false));
  }
}

function* requestWebsiteEdit(action: PayloadAction<any>) {
  const { key, ...formData } = action.payload.formData;
  const showAlert = action.payload.showAlert ?? true;
  yield put(commonActions.createBatchUpdate({ id: key }));
  const userInfo: UserInfo = yield select(selectUser);
  yield put(actions.setIsUpdating(true));
  const response = yield apiPost('site/CMSUpdateSite', {
    ...formData,
    userId: userInfo.userId,
  });
  if (response.success) {
    if (showAlert) {
      notify(
        'success',
        'Website updated! Your changes may take a minute to become visible.',
      );
    }
    yield put(actions.loadWebsiteDetail());
  } else {
    notify('error', response.error, 'danger');
  }
  yield put(commonActions.completeBatchUpdate({ id: key }));
  yield put(actions.setIsUpdating(false));
}

function* requestWebsiteCustomPageEdit(
  action: PayloadAction<{
    formData: CustomPageContent;
    showAlert: boolean;
  }>,
) {
  const { domainName } = action.payload.formData;
  const formattedUrl = domainName
    .toLowerCase()
    .replace('.destify.com', '.com')
    .replace('https://', '');
  const showAlert = action.payload.showAlert ?? true;

  const data = {
    ...action.payload.formData,
    domainName: formattedUrl,
  };

  yield put(actions.setIsUpdating(true));
  const response = yield apiPost('site/createCustomPage', data);
  if (response.success) {
    if (showAlert) {
      notify(
        'success',
        'Website updated! Your changes may take a minute to become visible.',
      );
    }
    yield put(actions.loadWebsiteDetail());
  } else {
    notify('error', response.error, 'danger');
  }

  yield put(actions.setIsUpdating(false));
}
function* deleteWeddingParty(action) {
  const websiteDetail = yield select(selectWebsiteDetail);
  const contentKey = action.payload.contentKey;
  const isRight = action.payload.isRight;
  const response = yield apiDelete(
    `site/deleteweddingparty/${websiteDetail.websiteName}/${contentKey}/${isRight}`,
  );
  if (response && response.success) {
    yield put(actions.loadWebsiteDetail());
    notify('success', 'wedding Party deleted successfully');
  } else {
    notify('error', 'failed to delete wedding party');
  }
  yield put(actions.setIsUpdating(false));
}

function* loadWebsiteDetail() {
  let groups: GroupInfo[] = yield select(selectGroups);
  const group = groups[0];
  if (group) {
    const response = yield apiGet(`Site/CMSGetWebsiteInfo/${group?.groupId}`);
    yield put(actions.setWebsiteDetail({ ...response }));
  } else {
    yield put(actions.setLoading(false));
  }
}

function* removeWebsiteImage(action) {
  const websiteDetail = yield select(selectWebsiteDetail);
  yield put(commonActions.setDeleteItem({ id: action.payload }));
  try {
    const response = yield apiDelete(
      `Site/DeleteWebsiteImages/${websiteDetail.websiteName}/${action.payload}`,
    );
    if (response.success) {
      yield put(actions.loadWebsiteDetail());
      yield put(commonActions.removeDeleteItem({ id: action.payload }));
    }
  } catch (error) {}
}

function* sendSaveTheDates(action) {
  const groupInfo: GroupInfo[] = yield select(selectGroups);
  interface SendSaveTheDatesPayload {
    groupId: string;
    imageData: string;
    guests: string[];
  }

  const request: SendSaveTheDatesPayload = {
    groupId: groupInfo[0].groupId,
    imageData: action.payload.imageData,
    guests: action.payload.guests,
  };

  yield put(actions.sendSaveTheDatesStatus('pending'));

  try {
    yield apiPost('guestlist/send-save-the-dates', request);
    // if (!response.success) throw new Error('Failed to send save the dates');
    yield put(actions.sendSaveTheDatesStatus('success'));
  } catch (e) {
    console.log(e);
    yield put(actions.sendSaveTheDatesStatus('error'));
  }
}

export function* WeddingWebsiteSaga() {
  yield takeLatest(actions.checkWebsite.type, checkWebsite);
  yield takeLatest(actions.registerWebsite.type, registerWebsite);
  yield takeLatest(actions.requestWebsiteEdit.type, requestWebsiteEdit);
  yield takeLatest(
    actions.requestWebsiteRegistriesEdit.type,
    requestWebsiteRegistriesEdit,
  );
  yield takeLatest(
    actions.requestWebsiteCustomPageEdit.type,
    requestWebsiteCustomPageEdit,
  );
  yield takeLatest(actions.deleteWeddingParty.type, deleteWeddingParty);
  yield takeLatest(actions.loadWebsiteImages.type, loadWebsiteImages);
  yield takeLatest(actions.loadWebsiteDetail.type, loadWebsiteDetail);
  // yield takeLatest(actions.removeWebsiteImage.type, removeWebsiteImage);
  yield takeEvery(actions.removeWebsiteImage.type, removeWebsiteImage);
  yield takeLatest(actions.sendSaveTheDates.type, sendSaveTheDates);
  // yield takeLatest(actions.sendSaveTheDatesEmails.type, sendSaveTheDatesEmails);
  yield takeLatest(
    actions.requestWebsiteItinerariesEdit.type,
    requestWebsiteItinerariesEdit,
  );
}
