import * as React from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import styled from 'styled-components/macro';
import { Box, InputAdornment } from '@mui/material';
import { addMonths, addYears, endOfYear, startOfToday } from 'date-fns';
import { IconCalendar } from '../../../components/Icons/IconCalendar';
import { AppTextField } from '../../../components/Form/AppTextField';
import { TextFieldProps as MuiTextFieldPropsType } from '@mui/material/TextField/TextField';
import { MuiTextFieldProps } from '@mui/lab/internal/pickers/PureDateInput';
import PickersDay from '@mui/lab/PickersDay/PickersDay';
import { IconHeart } from '../../../components/Icons/IconHeart';

export const AnticipatedCalendar = ({
  weddingDate,
  onAccept,
  onChange = (d, _) => void 0,
  renderInput,
  InputProps,
}: {
  weddingDate: null | Date;
  onAccept: (date: Date | null) => void;
  onChange?: (date: Date | null, keyboardInputValue?: string) => void;
  renderInput?: (props: MuiTextFieldPropsType) => React.ReactElement;
  InputProps?: MuiTextFieldProps['InputProps'];
}) => {
  const today = startOfToday();
  const minDate = addMonths(today, 2);
  const maxDate = endOfYear(addYears(today, 3));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <LoveWrapper className="datesWrapper">
        <MobileDatePicker
          showToolbar={false}
          views={['year', 'month', 'day']}
          // openTo={'month'}
          minDate={minDate}
          maxDate={maxDate}
          inputFormat="EEEE, LLL do, yyyy"
          value={weddingDate}
          defaultCalendarMonth={minDate}
          InputProps={InputProps || defaultInputProps}
          onAccept={onAccept}
          onChange={onChange}
          renderInput={renderInput || defaultRenderInput}
          renderDay={(day, selectedDates, pickersDayProps) => {
            return pickersDayProps.selected ? (
              <LovePickersDay day={day.getDate()} key={pickersDayProps.key} />
            ) : (
              <PickersDay {...pickersDayProps} />
            );
          }}
        />
      </LoveWrapper>
    </LocalizationProvider>
  );
};

const defaultInputProps = {
  startAdornment: (
    <InputAdornment position="start">
      <IconCalendar />
    </InputAdornment>
  ),
};

const defaultRenderInput = params => (
  <AppTextField
    className="weddingDateInput"
    {...params}
    inputProps={{
      'data-testid': 'anticipated-wedding-date-input-date',
      ...params.inputProps,
    }}
    size="medium"
    fullWidth
    placeholder={'Select Date'}
  />
);

const LovePickersDay = ({ day }: { day: string | number }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '36px',
        height: '36px',
        margin: '0 2px',
        ' svg': { position: 'absolute', top: '3px', left: 0 },
      }}
    >
      <IconHeart fill={'#FF9E99'} height={35} width={35} />
      <Box
        sx={{
          position: 'absolute',
          left: '0',
          top: '0',
          bottom: 0,
          right: 0,
          lineHeight: '36px',
          fontSize: '13px',
          textAlign: 'center',
        }}
      >
        {day}
      </Box>
    </Box>
  );
};

const LoveWrapper = styled(Box)``;
