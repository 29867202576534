import React from 'react';
import styled from 'styled-components';

import { Box, Link } from '@mui/material';
import { IconPhone } from 'app/components/Icons/IconPhone';
import { useLocation } from 'react-router-dom';
import { ptidCalendlyLink } from '../../SalesView/pages/Ptid/SalesLead/constants';

const routesWithBanner = [
  '#Country',
  '#City',
  '#Resort',
  '#Venue',
  '#Package',
  '#Date',
];

export const ScheduleACallLink = () => {
  const location = useLocation();
  return (
    <div>
      <ScheduleACall
        href={ptidCalendlyLink}
        target="_blank"
        underline="none"
        sx={{
          display:
            routesWithBanner.indexOf(location.hash) < 0 ? 'none' : 'flex',
        }}
      >
        <IconPhone height={24} width={16} />
        <Box ml={'.5rem'}>Schedule a Free Call</Box>
      </ScheduleACall>
    </div>
  );
};

const ScheduleACall = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#ebf8f9',
  padding: '.5rem',
});
