import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.salesLead || initialState;
const commonSlice = (state: RootState) => state.common;

export const selectSaleslead = createSelector([selectSlice], state => state);

export const selectCountries = createSelector(
  [selectSlice],
  state => state.countries,
);

export const selectCities = createSelector(
  [selectSlice],
  state => state.cities,
);

export const selectResorts = createSelector(
  [selectSlice],
  state => state.resorts,
);

export const selectVenues = createSelector(
  [selectSlice],
  state => state.venues,
);

export const selectPackages = createSelector(
  [selectSlice],
  state => state.packages,
);

export const selectCurrentStep = createSelector([selectSlice], state =>
  typeof state.currentStep === 'string'
    ? state.currentStep
    : //@ts-ignore
      state.currentStep?.step,
);

export const selectCeremonyTimes = createSelector(
  [selectSlice],
  state => state.ceremonyTimes,
);
export const selectCurrentProgress = createSelector(
  [selectSlice],
  state => state.currentProgress,
);

export const selectNextData = createSelector(
  [selectSlice],
  state => state.nextData,
);

export const selectAllData = createSelector(
  [selectSlice],
  state => state.allData,
);

export const selectPreviousData = createSelector(
  [selectSlice],
  state => state.previousData,
);

export const selectLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectIsAdding = createSelector(
  [selectSlice],
  state => state.isAdding,
);

export const selectIsRemoving = createSelector(
  [selectSlice],
  state => state.isRemoving,
);

export const selectCurrentStepData = createSelector(
  [selectSlice],
  state => state.currentStepData,
);

export const selectHotelInfo = createSelector(
  [selectSlice],
  state => state.hotelInfo,
);

export const selectSearching = createSelector(
  [selectSlice],
  state => state.searching,
);

export const selectEditOptions = createSelector(
  [selectSlice],
  state => state.salesLeadEditOptions,
);

export const selectStatusMsg = createSelector(
  [selectSlice],
  state => state.statusMsg,
);

export const selectPreSelectedResort = createSelector(
  [selectSlice],
  state => state.preSelectedResort,
);

export const selectUserDetails = createSelector(
  [commonSlice],
  state => state.userDetails,
);
