import { Box, Typography } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  className?: string;
  onClick?: Function;
};

const MenuItem = (props: Props) => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <Box
      data-testid={`edit-your-website-item-button-${props.title
        .replace(/\s+/g, '-')
        .toLowerCase()}`}
      padding={'12px 20px'}
      color={'#2d3031'}
      className={props.className}
      onClick={handleClick}
    >
      <Typography fontFamily={'"Nunito", sans-serif'} fontSize={18}>
        {props.title}
      </Typography>
    </Box>
  );
};

export default styled(MenuItem)`
  cursor: pointer;
  font-weight: 400;

  &:hover,
  &.active {
    background: #e2f5f6;
    font-weight: 600;
  }
`;
