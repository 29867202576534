import * as React from 'react';

export const IconLaptop = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 54"
      fill="none"
    >
      <path
        d="M61.4633 25.1157L58.5283 56.9754L7.79872 51.8619L11.241 23.2909L61.4633 25.1157Z"
        fill="#E2F5F6"
      />
      <path
        d="M15 27V10.8212C15 9.99274 15.6716 9.32117 16.5 9.32117H47.5C48.3284 9.32117 49 9.99274 49 10.8212V29.792C49 30.6204 48.3284 31.292 47.5 31.292H13.0261C12.1976 31.292 11.5261 30.6204 11.5261 29.792V7.5C11.5261 6.67157 12.1976 6 13.0261 6H50.4668C51.2952 6 51.9668 6.67157 51.9668 7.5V32.6022C51.9668 33.4306 51.2952 34.1022 50.4668 34.1022H12.2464C11.7911 34.1022 11.3604 34.309 11.0757 34.6643L7.95304 38.5621C7.16635 39.5441 7.86546 41 9.1237 41H55.3787C56.715 41 57.3843 39.3843 56.4393 38.4393L54 36"
        stroke="#202223"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
