import { createTheme, ThemeOptions } from '@mui/material/styles';

// override typescript augmentation for palette configuration
declare module '@mui/material/styles' {
  interface PaletteColor {
    main: string;
    light: string;
    dark: string;
    grey?: string;
    white?: string;
  }
  interface PaletteColorOptions {
    main: string;
    light: string;
    dark: string;
    grey?: string;
    white?: string;
  }
}

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#21272a',
      light: '#E3F7FA',
      dark: '#00566f',
      grey: '#F2F4F8',
      white: '#fff',
    },
    secondary: {
      main: '#00BFC8',
      dark: '#0098A0',
      light: '#E3F7FA',
    },
    text: {
      primary: 'rgba(25,35,56,0.87)',
      secondary: 'rgba(25,35,56,0.6)',
      disabled: 'rgba(25,35,56,0.38)',
      // @ts-ignore
      hint: 'rgba(25,35,56,0.38)',
    },
    error: {
      main: '#eb4523',
      dark: '#b10000',
      light: '#ff794f',
    },
    warning: {
      main: '#9d0884',
      dark: '#6a0057',
      light: '#d14bb4',
    },
    info: {
      main: '#1f56d1',
      dark: '#002e9f',
      light: '#6782ff',
    },
    success: {
      main: '#089d6b',
      light: '#46b88a',
      dark: '#037a52',
    },
    brand: {
      teal: '#00BFC8',
      red: '#FF7B74',
      yellow: '#EEE6C8',
      pink: '#FFB9CC',
      white: '#FFFFFF',
    },
  },
  typography: {
    fontFamily:
      '"Nunito", "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 100,
    fontWeightRegular: 300,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    button: {
      textTransform: 'none',
    },
    h1: {
      fontFamily:
        '"Nunito", "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '3.3125rem',
    },
    h2: {
      fontFamily:
        '"Nunito", "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '2.25rem',
    },
    h3: {
      fontFamily:
        '"Nunito", "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '1.5rem',
      marginBottom: '1rem',
    },
    h4: {
      fontFamily:
        '"Nunito", "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '1.375rem',
      fontWeight: 'bold',
      marginBottom: '0.5rem',
    },
    h5: {
      fontFamily:
        '"Nunito", "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '1.125rem',
    },
    body1: {
      color: 'rgba(25,35,56,0.87)',
      fontFamily:
        '"Nunito", "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '0.9375rem',
    },
    body2: {
      color: 'rgba(25,35,56,0.6)',
      fontFamily:
        '"Nunito", "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '0.8125rem',
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiRadio: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'dense',
        size: 'small',
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    //@ts-ignore
    MuiPickersDay: {
      defaultProps: {
        sx: { fontFamily: 'Nunito' },
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '4rem',
      },
    },
  },
};

export default createTheme(themeOptions);
