import React, { useEffect, useState } from 'react';
import { RationalWrapper } from 'app/components/CardMediaRational';
import { Box } from '@mui/material';

const TIMEOUT = 10_000; //10 sec;

export const WeddingWebsitePreviewIFrame = ({ websiteUrl }) => {
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(false);

  const handleLoadIframe = e => {
    setError(false);
    setLoading(false);
    // chrome
    e.currentTarget.style.left = 0;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(false);
      setLoading(false);
    }, TIMEOUT);
    return () => clearTimeout(timer);
  }, []);

  const handleErrorIframe = () => {
    setError(true);
    console.error('Failed to load the iframe.');
  };
  return (
    <Box
      sx={{
        backgroundColor: '#f1f1f1',
      }}
    >
      <RationalWrapper
        ratio={16 / 9}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <iframe
          onLoad={handleLoadIframe}
          onLoadedData={handleLoadIframe}
          onError={handleErrorIframe}
          allow="true"
          scrolling="no"
          sandbox="allow-same-origin allow-scripts"
          id="wedding-website-preview-iframe"
          src={websiteUrl}
          title="wedding-website-preview"
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: '0',
            top: '0',
            opacity: loading ? 0 : 1,
            transition: 'opacity .2s linear',
            pointerEvents: 'none',
            userSelect: 'none',
            overflow: 'hidden',
          }}
          aria-hidden="false"
        >
          unable to load the iframe.
        </iframe>
        {loading && (
          <img
            src="/img/loader.svg"
            alt="loader"
            style={{ width: '100%', opacity: 0.1 }}
          />
        )}
      </RationalWrapper>
    </Box>
  );
};
