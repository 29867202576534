import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import React from 'react';
import { UserInfo } from '../../../types/UserInfo';
import { stringToColor } from '../../../utils/misc';
import AccountMenu from './AccountMenu';

type Props = {
  user: UserInfo;
  isSideBar: boolean;
};

export default function UserStringAvatar({ user, isSideBar }: Props) {
  const userName = user.firstName + ' ' + user.lastName;
  const bgColor = stringToColor(userName);
  const abbr =
    userName.split(' ').shift().charAt(0).toUpperCase() +
    userName.split(' ').pop().charAt(0).toUpperCase();
  const avatar = (
    <StyledAvatar sx={{ backgroundColor: bgColor }}>
      <div className="abbr">{abbr}</div>
    </StyledAvatar>
  );

  return isSideBar ? avatar : <AccountMenu user={user}>{avatar}</AccountMenu>;
}

const StyledAvatar = styled(Avatar)`
  & {
    position: relative;
    width: 35px;
    height: 35px;
  }

  & .abbr {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
`;
