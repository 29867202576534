/**
 *
 * Asynchronously loads the component for Profile
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ResetPasswordCheck = lazyLoad(
  () => import('./index'),
  module => module.ResetPasswordCheck,
);
