import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { RoundedButton } from '../../../../../components/RoundedButton';
import { AppTextField } from '../../../../../components/Form/AppTextField';
import { SignUpForRoomLayout } from '../../layout';
import { selectSignUpForRoom } from '../../slice/selectors';
import { SupportRequestData } from '../../types';
import { signUpForRoomActions } from '../../slice';

const validationSchema = yup.object().shape({
  fullName: yup.string().required('Full Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().required('Phone is required'),
});

type ContactForm = SupportRequestData;

type SupportResultProps = {
  title: string;
  message: string;
};

const SupportResult = ({ title, message }: SupportResultProps) => (
  <Box>
    <Typography align="center" variant="h3" sx={{ mb: 4, fontWeight: 600 }}>
      {title}
    </Typography>
    <Typography align="center" sx={{ mb: 4 }}>
      {message}
    </Typography>
  </Box>
);

interface SignUpForRoomUserSupportProps {}

export function SignUpForRoomUserSupport(props: SignUpForRoomUserSupportProps) {
  const dispatch = useDispatch();

  const [phoneMask, setPhoneMask] = useState('+9 (999) 999-9999');
  const [supportResult, setSupportResult] =
    useState<SupportResultProps | null>();

  const { supportRequestResult } = useSelector(selectSignUpForRoom);

  const { control, handleSubmit, formState, watch } = useForm<ContactForm>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitHandler: SubmitHandler<ContactForm> = data => {
    dispatch(signUpForRoomActions.createSupportRequest(data));
  };

  const phoneValue = watch('phone');

  useEffect(() => {
    if (phoneValue) {
      if (phoneValue.charAt(1) === '1') {
        setPhoneMask('+9 (999) 999-9999');
      } else if (phoneValue.charAt(1) === '5') {
        setPhoneMask('+99 (999) 999-9999');
      }
    }
  }, [phoneValue]);

  useEffect(() => {
    if (supportRequestResult?.success === undefined) return;

    supportRequestResult.success ? setSuccessResult() : setFailureResult();
  }, [supportRequestResult]);

  const setSuccessResult = () => {
    setSupportResult({
      title: 'Support Request Sent',
      message:
        'Thank you for reaching out. Our user support team will contact you soon.',
    });
  };

  const setFailureResult = () => {
    setSupportResult({
      title: 'Oops something went wrong',
      message:
        'There was an issue sending your support request. Our technical team has been notified that there is an issue. Please try again later.',
    });
  };

  return (
    <SignUpForRoomLayout title="" disableRedirect>
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        {supportResult?.title && (
          <SupportResult
            title={supportResult.title}
            message={supportResult.message}
          />
        )}
        {!supportResult?.title && (
          <form
            id="sign-up-form"
            noValidate
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <div>
              <Controller
                name="fullName"
                control={control}
                render={({ field, formState }) => (
                  <AppTextField
                    sx={{ mb: '8px', bgcolor: 'white' }}
                    InputLabelProps={{
                      style: { color: '#949494' },
                    }}
                    label="Full Name"
                    size="medium"
                    type="text"
                    variant="outlined"
                    fullWidth
                    {...field}
                    ref={null}
                    inputRef={field.ref}
                    disabled={formState.isSubmitting}
                    error={Boolean(formState.errors.fullName)}
                    helperText={formState.errors.fullName?.message}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field, formState }) => (
                  <AppTextField
                    sx={{ mb: '8px', bgcolor: 'white' }}
                    label="Email Address"
                    InputLabelProps={{
                      style: { color: '#949494' },
                    }}
                    size="medium"
                    type="email"
                    variant="outlined"
                    fullWidth
                    {...field}
                    ref={null}
                    inputRef={field.ref}
                    disabled={formState.isSubmitting}
                    error={Boolean(formState.errors.email)}
                    helperText={formState.errors.email?.message}
                  />
                )}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field, formState }) => (
                  <InputMask
                    mask={phoneMask}
                    value={field.value}
                    onChange={field.onChange}
                    maskChar=""
                    sx={{ mb: '8px', bgcolor: 'white' }}
                    InputLabelProps={{
                      style: { color: '#949494' },
                    }}
                    label="Phone"
                    size="medium"
                    variant="outlined"
                    fullWidth
                    {...field}
                    error={Boolean(formState.errors.phone)}
                    disabled={formState.isSubmitting}
                    helperText={formState.errors.phone?.message}
                  >
                    {inputProps => (
                      <AppTextField
                        ref={null}
                        inputRef={field.ref}
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />

              <Controller
                name="extraInfo"
                control={control}
                defaultValue=""
                render={({ field, formState }) => (
                  <AppTextField
                    placeholder="Include any details that may help us assist you better."
                    inputProps={{
                      'data-testid': 'contact-us-input-messsage',
                    }}
                    aria-describedby="details-description"
                    multiline
                    fullWidth
                    rows={6}
                    {...field}
                    ref={null}
                    inputRef={field.ref}
                    error={Boolean(formState.errors.extraInfo)}
                    helperText={formState.errors.extraInfo?.message}
                    disabled={formState.isSubmitting}
                  />
                )}
              />
            </div>

            <Box sx={{ mb: 2, mt: 1, textAlign: 'center' }}>
              <RoundedButton
                type="submit"
                loading={formState.isSubmitting}
                className=""
                disabled={formState.isSubmitting}
              >
                Send Support Request
              </RoundedButton>
            </Box>
          </form>
        )}
      </Box>
    </SignUpForRoomLayout>
  );
}
