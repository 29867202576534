import authViewRoutes from '../app/AuthView/routes';
import guestViewRoutes from '../app/GuestView/routes';
import homeViewRoutes from '../app/HomeView/routes';
import salesViewRoutes from '../app/SalesView/routes';
import liveBookingRoutes from '../app/LiveBooking/routes';

const appRoute = {
  ...authViewRoutes,
  ...guestViewRoutes,
  ...salesViewRoutes,
  ...liveBookingRoutes,
  ...homeViewRoutes,
};
export default appRoute;
