import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { AgentDetailsState } from './types';
import { agentDetailsSaga } from './saga';

export const initialState: AgentDetailsState = {
  loading: false,
  isFirstLoading: true,
  agentDetails: {
    firstName: null,
    lastName: null,
    assignedAgentId: null,
    calendlyLink: null,
    profilePicture: null,
  },
  error: null,
};

const slice = createSlice({
  name: 'agentDetails',
  initialState,
  reducers: {
    fetchAgentDetails(state) {
      if (state.isFirstLoading) state.loading = true;
      state.error = '';
    },
    fetchAgentDetailsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.isFirstLoading = false;
      state.agentDetails = action.payload;
    },
    fetchAgentDetailsError(state, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { actions: faqActions } = slice;

export const useSupportSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: agentDetailsSaga });
  return { actions: slice.actions };
};
