import { Box, Button } from '@mui/material';

import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material';
import appRoute from 'utils/appRoute';
import { IconLetter } from 'app/components/Icons/IconLetter';
import { IconSidebarList } from 'app/components/Icons/IconSidebarList';
import { IconCellPhone } from 'app/components/Icons/IconCellPhone';
import { IconLaptop } from 'app/components/Icons/IconLaptop';

const allQuickLinks = [
  {
    title: 'Wedding Website',
    route: appRoute.weddingWebsitePage,
    Icon: <IconLaptop />,
  },
  {
    title: 'RSVPs',
    route: appRoute.rsvpsPage,
    Icon: <IconLetter />,
  },
  {
    title: 'Guest List',
    route: appRoute.guestListPage,
    Icon: <IconSidebarList />,
  },
  {
    // title: 'Contact Agent',
    // route: () => ptidCalendlyLink,
    title: 'Contact',
    route: appRoute.contactUsPage,
    Icon: <IconCellPhone />,
  },
  //Commenting this out for now, but we may want to add it back in the future.
  // {
  //   title: 'Resorts',
  //   route: () => '/sales-lead#Resort',
  //   Icon: <IconVenue />,
  // },
];

export const QuickLinks = () => {
  const theme = useTheme();

  const renderQuickLinks = () =>
    allQuickLinks.map(
      ({
        Icon,
        title,
        route,
      }: {
        Icon: React.ReactNode;
        title: string;
        route: () => string;
      }) => (
        <QuickLink
          href={route()}
          theme={theme}
          length={allQuickLinks.length}
          className="quickLink"
        >
          {Icon}
          <p>{title}</p>
        </QuickLink>
      ),
    );

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'scroll',
        scrollSnapType: 'x mandatory',
        WebkitOverflowScrolling: 'touch', // for smooth scrolling on iOS
        width: '100%',
        padding: '10px',
        '&::-webkit-scrollbar': {
          display: 'none', // Hide scrollbar in Webkit (Chrome, Safari)
        },
        scrollbarWidth: 'none', // Hide scrollbar in Firefox
        msOverflowStyle: 'none',
        [theme.breakpoints.up('md')]: {
          justifyContent: 'center',
        },
      }}
    >
      {renderQuickLinks()}
    </Box>
  );
};

const QuickLink = styled(Button)<{ theme: Theme; length: number }>(
  ({ theme, length }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'flex-end',
    marginRight: '2rem !important',
    backgroundColor: '#fff',
    borderRadius: '16px',
    borderColor: '#fff',
    '&:last-child': {
      marginRight: '0 !important',
    },
    padding: '0.5rem 0',

    boxShadow: '2px 4px 25px 0px rgba(60, 110, 121, 0.08)',
    transition: 'all 0.5s ease-in-out',

    height: '9.25em',
    width: '8.5em',
    fontSize: '0.8rem',
    svg: {
      width: '64px',
      height: '64px',
    },

    // need to ensure next button is always peaking on smaller screens so user knows to scroll/swipe.
    // we do this by setting the button to a specific percentage width at each acreen size.
    flex: '0 0 36%',

    [`@media (min-width: 700px)`]: {
      flex: length < 5 ? 'inherit' : '0 0 25%',
    },
    [theme.breakpoints.up('md')]: {
      flex: 'inherit',
      fontSize: '1rem',
      height: '8.45rem',
    },

    border: '1px solid #E0E0E0',
    scrollSnapAlign: 'start',
  }),
);
