import React, { ReactNode } from 'react';
import { AppModal } from '../AppModal';
import { Box } from '@mui/material';

type Props = {
  children?: ReactNode;
  open?: boolean;
  onClose?: () => void;
  hideClose?: boolean;
};

export default function LoadingModal({
  children,
  open = true,
  onClose = () => void 0,
  hideClose = true,
}: Props) {
  return (
    <AppModal open={open} isLoading onClose={onClose} hideClose>
      <Box display="flex" justifyContent="center" pb={6}>
        {/*<CircularProgress size={80} color={'secondary'} />*/}
        <img width={200} src={'/img/loader.svg'} alt="Loading" />
      </Box>
      {children}
    </AppModal>
  );
}
