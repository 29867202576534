import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { detailsSaga } from './saga';
import { DetailsData, DetailsState } from './types';
import { UserSVLoginInfo } from '../../../../../types/GroupLeaderDetails';

export const initialState: DetailsState = {
  loading: false,
  resultMessage: null,
  resultStatus: null,
  roleOptions: [],
  roleOptions2: [],
  ceremonyTypeOptions: [],
  budgetOptions: [],
  travelInformationOptions: [],
  luxuryOptions: [],
};

const slice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    loadFormOptions(state) {},
    putFormOptions(state, action: PayloadAction<any>) {
      state.roleOptions = action.payload.roleOptions;
      state.roleOptions2 = action.payload.roleOptions2;
      state.ceremonyTypeOptions = action.payload.ceremonyTypeOptions;
      state.budgetOptions = action.payload.budgetOptions;
      state.travelInformationOptions = action.payload.travelInformationOptions;
      state.luxuryOptions = action.payload.luxuryOptions;
    },
    putFormData(
      state,
      action: PayloadAction<
        Omit<DetailsData, 'weddingDate'> & {
          anticipatedWeddingDate: string; // replace Date field with string one
          userId: string;
        }
      >,
    ) {
      state.loading = true;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    detailsAddedSuccessfully(state, action: PayloadAction<UserSVLoginInfo>) {},
  },
});

export const { actions: detailsActions } = slice;

export const useDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: detailsSaga });
  return { actions: slice.actions };
};
