import * as React from 'react';

export const GuestList = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5.5" y="2.5" width="7" height="9" rx="3.5" stroke="#2D3031" />
      <path
        d="M14 3H14.8V3C16.5673 3 18 4.43269 18 6.2V7C18 9.20914 16.2091 11 14 11V11"
        stroke="#2D3031"
      />
      <path
        d="M6.14286 14.5C3.02639 14.5 0.5 17.0264 0.5 20.1429C0.5 20.8924 1.10761 21.5 1.85714 21.5H16.1429C16.8924 21.5 17.5 20.8924 17.5 20.1429C17.5 17.0264 14.9736 14.5 11.8571 14.5H6.14286Z"
        stroke="#2D3031"
      />
      <path
        d="M20.1818 21H22.1429C22.6162 21 23 20.6162 23 20.1429V20C23 17.2386 20.7614 15 18 15V15"
        stroke="#2D3031"
      />
    </svg>
  );
};
