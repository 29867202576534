import * as React from 'react';

export const IconCarouselRight = ({
  width = 41,
  height = 41,
  fillCircle = 'black',
  strokeCircle = 'none',
  fillPath = 'white',
  strokePath = 'none',
}: {
  width?: number;
  height?: number;
  fillCircle?: string;
  strokeCircle?: string;
  fillPath?: string;
  strokePath?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.9" filter="url(#filter0_d_9140_122157)">
        <circle
          r="12.5"
          transform="matrix(-1 0 0 1 20.5 15.5)"
          fill={fillCircle}
          stroke={strokeCircle}
        />
        <path
          d="M17.4826 11.3146L18.9589 9.89648L25.2412 15.931L18.9589 21.9655L17.4826 20.5473L22.2781 15.931L17.4826 11.3146Z"
          fill={fillPath}
          stroke={strokePath}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_9140_122157"
          x="0"
          y="0"
          width="41"
          height="41"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9140_122157"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9140_122157"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
