import * as React from 'react';
import { useMemo } from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import { ImageDTO } from '../../../types/GroupLeaderDetails';
import { AppModal } from '../AppModal';
import { sanitizeHTML } from 'utils/misc';
import { selectRoomDetails } from 'app/GuestView/pages/Rooms/slice/selectors';
import { useSelector } from 'react-redux';

import { Carousel } from 'react-responsive-carousel';
import { RationalWrapper } from '../CardMediaRational';

export const AppFullImageModal = ({
  fullImageList,
  onClose,
  roomId = '',
}: {
  fullImageList: ImageDTO[];
  onClose: () => void;
  roomId?: string;
}) => {
  const theme = useTheme();
  const roomDetails = useSelector(selectRoomDetails);

  const roomDetail = useMemo(() => {
    return roomDetails[roomId] || null;
  }, [roomId, roomDetails]);

  const roomFeatures = useMemo(() => {
    if (!roomDetail) return null;
    return roomDetail.roomFeatures.split(',').map((feature, i) => {
      return (
        <Box
          key={`${feature}-${i}`}
          display={'flex'}
          alignItems={'center'}
          gap={2}
        >
          <input id={feature} type="checkbox" checked={true} readOnly />
          <label htmlFor={feature}>{feature}</label>
        </Box>
      );
    });
  }, [roomDetail]);

  if (!fullImageList.length) return null;
  return (
    <AppModal
      open={true}
      onClose={onClose}
      sx={{
        '& .modalContainer': {
          [theme.breakpoints.down('sm')]: {
            width: '98%',
            top: '50%',
            left: '1%',
            padding: '25px 10px',
            maxWidth: 'unset',
            maxHeight: '98%',
            transform: 'translateY(-50%)',
          },
        },
        '& .modalContent': {
          // marginTop: '40px',
          width: '100%',
          position: 'relative',
          padding: '0',
        },
        '& .modalContent img': {
          width: '100%',
          height: 'auto',
        },
      }}
    >
      {roomDetail ? (
        <Box>
          <Typography
            variant="h4"
            textAlign={'center'}
            fontWeight={'bolder'}
            marginBottom={'1em'}
          >
            {roomDetail.roomTypeFriendlyName}
          </Typography>
        </Box>
      ) : (
        <Box height={'2rem'} />
      )}
      <Box
        borderRadius={'1em'}
        overflow={'hidden'}
        display={'flex'}
        flexDirection={'column'}
        gap={'1rem'}
      >
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          renderItem={(item, options) => {
            if (roomDetail)
              return <RationalWrapper ratio={16 / 9}>{item}</RationalWrapper>;
            return item;
          }}
        >
          {[...fullImageList]
            .sort((i1, i2) => i1.imageOrder - i2.imageOrder)
            .map((image, i) => (
              <img key={i} src={image.imageURL} alt={image.altTagText} />
            ))}
        </Carousel>
      </Box>
      {roomDetail ? (
        <Box>
          <Typography margin={'1em 0'} variant="h5" fontWeight={'bolder'}>
            {roomDetail.roomTypeFriendlyName}
          </Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHTML(roomDetail.descriptions || ''),
            }}
          />
        </Box>
      ) : null}
      <Box>{roomFeatures}</Box>
    </AppModal>
  );
};
