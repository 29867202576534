import * as React from 'react';
import { ImageDTO } from '../../../../types/GroupLeaderDetails';
import { AppFullImageModal } from '../../../components/AppFullImageModal';

export const PtidFullImageModal = ({
  fullImageList,
  onClose,
  ...props
}: {
  fullImageList: ImageDTO[];
  onClose: () => void;
  roomId?: string;
}) => (
  <AppFullImageModal
    fullImageList={fullImageList}
    onClose={onClose}
    {...props}
  />
);
