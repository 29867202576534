import { format, parse } from 'date-fns';
import { ReactNode } from 'react';
import {
  NOTIFICATION_CONTAINER,
  NOTIFICATION_TYPE,
  Store as Notifier,
} from 'react-notifications-component';
import html2canvas from 'html2canvas';
import DOMPurify from 'dompurify';

const cmsBaseUrl = process.env.REACT_APP_CMS_URL;

export const notify = (
  title: string,
  text: string | ReactNode,
  type: NOTIFICATION_TYPE = 'success',
  container: NOTIFICATION_CONTAINER = 'top-right',
  duration: number = 5000,
  pauseOnHover: boolean = true,
) => {
  Notifier.addNotification({
    title: title,
    message: text,
    type: type,
    container: container,
    dismiss: {
      duration,
      pauseOnHover,
    },
    slidingEnter: {
      duration: 300,
      timingFunction: 'ease-out',
      delay: 0,
    },
    slidingExit: {
      duration: 300,
      timingFunction: 'ease-out',
      delay: 0,
    },
  });
};

export const moneyFormatter = (value: number | string) => {
  value = parseFloatExt(value);
  return value.toLocaleString('en-US', {
    style: 'currency',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
  });
};

export const flightDateTimeFormatter = (value: string) => {
  const [datePart, timePart] = value.split('T');
  let t = timePart ? parse(timePart, 'hh:mma', new Date()) : null;
  let d = new Date(datePart + 'T00:00:00');
  if (!d?.valueOf()) {
    d = new Date(datePart);
  }

  let result = [
    d?.valueOf() ? format(d, 'MM/dd/yyyy') : '',
    t?.valueOf() ? format(t, 'p') : '',
  ]
    .filter(s => s)
    .join(' ');

  return result ? result : 'N/A';
};

export const parseFloatExt = (value: number | string): number => {
  value = value ? value : 0;
  value =
    typeof value === 'number' ? value : parseFloat(value.replace(',', ''));
  return value;
};

export const numberFormatter = (value: number | string) => {
  value = parseFloatExt(value);
  return value.toLocaleString('en-US');
};

export const roundToTwo = (num: number) => {
  return Math.round(num * 100) / 100;
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children:
      name.split(' ').shift().charAt(0).toUpperCase() +
      name.split(' ').pop().charAt(0).toUpperCase(),
  };
};

export const deepEqual = (x, y) => {
  return x && y && typeof x === 'object' && typeof y === 'object'
    ? Object.keys(x).length === Object.keys(y).length &&
        Object.keys(x).reduce(function (isEqual, key) {
          return isEqual && deepEqual(x[key], y[key]);
        }, true)
    : x === y;
};

export const camelObjectToPascalObject = (obj: object) => {
  const entries = Object.entries(obj);
  const pascalEntries = entries.map(item => {
    const key = item[0];
    return [key.charAt(0).toUpperCase() + key.slice(1), item[1]];
  });
  return Object.fromEntries(pascalEntries);
};

export const transferTypeMap = {
  public: 'Public',
  private: 'Private',
  PrivateCarMax2: 'Private Car',
  PrivateShuttleMax6: 'Private Shuttle',
  SharedShuttleMax6: 'Shared Shuttle',
  PrivateCarMax4: 'Private Car',
  // typo in api ?
  PrivateShutteMax10: 'Private Shuttle',
  PrivateShuttleMax10: 'Private Shuttle',
};

export const getCMSImageFullURL = (relativePath: string) => {
  return cmsBaseUrl + relativePath;
};

export const addOrRemove = (arr, item) =>
  arr.includes(item) ? arr.filter(i => i !== item) : [...arr, item];

export const getUrlParameter = (
  name: string,
  caseInsensitiveSearch: boolean = false,
) => {
  const hayStack = window.location.search.substring(1);
  const needle = caseInsensitiveSearch ? name.toLowerCase() : name;

  for (let i = 0; i < hayStack.split('&').length; i++) {
    const queryStringKeyValuePair = hayStack.split('&')[i].split('=');

    const querystringKey = caseInsensitiveSearch
      ? queryStringKeyValuePair[0].toLowerCase()
      : queryStringKeyValuePair[0];

    if (querystringKey === needle) {
      return queryStringKeyValuePair[1] === undefined
        ? null
        : decodeURIComponent(queryStringKeyValuePair[1]);
    }
  }
  return null;
};

export const storeToStorage = (key: string, data: any) => {
  if (data) {
    const dataString = JSON.stringify(data);
    localStorage.setItem(key, dataString);
  }
};

export const readFromStorage = (key: string): null | any => {
  const dataString = localStorage.getItem(key);
  if (dataString) {
    return JSON.parse(dataString);
  }
  return null;
};

export const deleteFromStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const makeGuestsSummaryText = (
  adultCount: null | number,
  childCount?: null | number,
) => {
  return [
    1 === adultCount && `1 Adult`,
    1 < adultCount && `${adultCount} Adults`,
    1 === childCount && `1 Child`,
    1 < childCount && `${childCount} Children`,
  ]
    .filter(s => s)
    .join(', ');
};

export const match = (haystack: string, needle: string): boolean => {
  if (0 === needle.trim().length) {
    return true;
  }
  // return haystack.toLowerCase().includes(needle.toLowerCase());
  const needlePattern = needle.trim().replaceAll(/\s+/g, '.*\\s+.*');
  const re = new RegExp(`^.*${needlePattern}.*$`, 'i');
  return haystack.match(re)?.length > 0;
};

export const capitalizeFirstLetter = (string: any): string | null => {
  if (string) {
    return string
      .toLowerCase()
      .split(' ')
      .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' ');
  }
  return null;
};

export const generateFileNameForBase64EncodedImage = (base64Image: string) => {
  const format = base64Image.substring(
    'data:image/'.length,
    base64Image.indexOf(';base64'),
  );

  const randomString = Math.random().toString(36).substring(2, 8);

  const timestamp = new Date().getTime();

  return `${randomString}_${timestamp}.${format}`;
};

export const downloadURLData = (url: string, name: string) => {
  if (!url) return;
  var anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = name;
  anchor.click();
};

export const htmlToCanvas = async targetEl => {
  try {
    const canvas = await html2canvas(targetEl, {
      letterRendering: 1,
      allowTaint: false,
      useCORS: true,
    } as any);
    return canvas;
  } catch (err) {
    throw new Error('error generating the image. Please reload the page');
  }
};

/**
 * Returns a list of keys from the obj, absent the ones specified in keysToOmit
 * @param obj
 * @param keysToOmit the list of keys to omit from the object
 * @returns
 */
export function extractKeysFromObject<T extends {}, K extends keyof T & string>(
  obj: T,
  keysToOmit: K[],
): string[] {
  return (Object.keys(obj) as (keyof T)[]).filter(
    key => !keysToOmit.includes(key as K),
  ) as string[];
}

export const sanitizeHTML = (text: string) => {
  return DOMPurify.sanitize(text, { USE_PROFILES: { html: true } });
};
