/**
 *
 * Asynchronously loads the component for RoomsCancellation
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RoomsCancellation = lazyLoad(
  () => import('./index'),
  module => module.RoomsCancellation,
);
