import * as React from 'react';

export const IconAlertDefault = () => {
  return (
    <svg
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62 124C27.8114 124 0 96.1886 0 62C0 27.8114 27.8114 0 62 0C96.1886 0 124 27.8114 124 62C124 96.1886 96.1886 124 62 124ZM62 5.16593C30.6634 5.16593 5.16593 30.6634 5.16593 62C5.16593 93.3366 30.6634 118.834 62 118.834C93.3366 118.834 118.834 93.3366 118.834 62C118.834 30.6634 93.3366 5.16593 62 5.16593V5.16593Z"
        fill="#785AFF"
      />
      <path
        d="M55.1224 81.3397C54.4357 81.3397 53.7792 81.0655 53.2931 80.5854L36.3978 63.6902C35.3902 62.6826 35.3902 61.0453 36.3978 60.0375C37.4055 59.0299 39.0428 59.0299 40.0505 60.0375L55.1163 75.1033L86.7983 43.4169C87.806 42.4092 89.4433 42.4092 90.451 43.4169C91.4587 44.4246 91.4587 46.0619 90.451 47.0696L56.9444 80.5806C56.4661 81.0667 55.8096 81.34 55.122 81.34L55.1224 81.3397Z"
        fill="#73D9DF"
      />
    </svg>
  );
};
