import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { apiGet, apiPost } from 'utils/api';
import { signUpForRoomActions as actions } from './index';
import { RoomInfo } from 'types/RoomInfo';
import appRoute from 'utils/appRoute';
import { HTTPError } from 'ky';
import { EmailSignUpData, SupportRequestData } from '../types';
import { SignUpResult } from './types';
import { getAuthErrorDescription } from '../utils/auth';
import { UserInfo } from 'types/UserInfo';
import { selectSignUpForRoom } from './selectors';
import { apiRSPost } from 'utils/apiRS';
import { beforeCreateTicket } from 'utils/helpDeskHelper';
import { notify } from 'utils/misc';

const pageToRedirectOnError = appRoute.signUpForRoomFailure();

function* fetchRoomDetails(action: PayloadAction<string>) {
  try {
    const response: { roomInfo: RoomInfo[] } = yield call(
      apiGet,
      'rooms',
      undefined,
      {
        roomIds: action.payload,
      },
    );
    const roomDetails = response.roomInfo[0];

    const error =
      (!roomDetails && 'Room not found') ||
      (!roomDetails.room?.length && 'Room information not found') ||
      (!roomDetails.group?.length && 'Wedding group not found');

    if (error) {
      throw new Error(error);
    }

    yield put(
      actions.fetchRoomSuccess({
        room: roomDetails.room[0],
        group: roomDetails.group[0],
      }),
    );
  } catch (err) {
    let errorMessage =
      err instanceof Error ? err.message : 'An error occurred.';

    if (err instanceof HTTPError) {
      // Handle Http errors
      errorMessage = `Failed with status code: ${err.response.status}`;
    }

    console.error(
      `Failed to fetch room details for sign up form: ${action.payload}`,
      err,
    );
    yield put(
      actions.fetchRoomError({
        error: errorMessage,
      }),
    );
    yield redirectToErrorPage();
  }
}

function* emailSignUp(action: PayloadAction<EmailSignUpData>) {
  try {
    const response: { success: boolean; error: string[] } = yield call(
      apiPost,
      'registeruser',
      { ...action.payload, returnUrl: '/sign-up-for-room/success' },
    );

    const signUpResult: SignUpResult = {
      success: response.success,
      error: response.error?.map(code => ({
        code,
        message: getAuthErrorDescription(code),
      })),
    };

    yield put(actions.signUpResult(signUpResult));
    if (signUpResult.success) {
      yield put(actions.redirectTo(appRoute.signUpForRoomSuccess()));
    }
  } catch (err: any) {
    console.error('Failed to sign up for room', err);
    yield put(
      actions.signUpResult({
        success: false,
        error: [
          {
            code: 'unknown',
            message: `An unhandled exception occurred: ${
              err.message ?? 'Unknown error'
            }`,
          },
        ],
      }),
    );
    yield redirectToErrorPage();
  }
}

function* socialSignUp(action: PayloadAction<EmailSignUpData>) {
  try {
    const response: UserInfo = yield call(
      apiPost,
      'SocialAuth',
      action.payload,
    );

    const signUpResult: SignUpResult = {
      success: response.isSuccess,
      error: response.errors?.map(code => ({
        code,
        message: getAuthErrorDescription(code),
      })),
    };

    yield put(actions.signUpResult(signUpResult));
    if (signUpResult.success) {
      yield put(actions.redirectTo(appRoute.signUpForRoomSuccess()));
    }
  } catch (err: any) {
    console.error('social failed to sign up for room', err);
    yield put(
      actions.signUpResult({
        success: false,
        error: [
          {
            code: 'unknown',
            message: `An unhandled exception occurred: ${
              err.message ?? 'Unknown error'
            }`,
          },
        ],
      }),
    );
    yield redirectToErrorPage();
  }
}

function* createSupportRequest(action: PayloadAction<SupportRequestData>) {
  const request = action.payload;
  const { roomId, error, room, group } = yield select(selectSignUpForRoom);
  const reservationState = 'ErrorCreatingAccountForRoom';

  const paragraphs = [
    {
      value: group?.id,
      content: `Group ID: ${group?.id}`,
    },
    {
      value: group?.groupName,
      content: `Group Name: ${group?.groupName}`,
    },
    {
      value: roomId,
      content: `Room ID: ${roomId}`,
    },
    {
      value: room?.roomName,
      content: `Room Name: ${room?.roomName}`,
    },
    {
      value: error?.error,
      content: `Error: ${error?.error}`,
    },
    {
      value: request.extraInfo,
      content: `User message: ${request.extraInfo}`,
    },
  ]
    .filter(p => p.value)
    .map(p => p.content);

  const ticketPayload = {
    roomID: roomId,
    reservationState,
    requester: {
      name: request.fullName,
      email: request.email,
    },
    details: paragraphs.join('\n'),
  };

  try {
    const [canCreateTicket, message] = beforeCreateTicket({
      id: roomId,
      state: reservationState,
    });
    if (!canCreateTicket) {
      notify('', message, 'info');
    } else {
      yield call(apiRSPost, 'HelpDeskTicketGenerator', ticketPayload);
    }
    yield put(
      actions.supportRequestResult({
        success: true,
      }),
    );
  } catch (err: any) {
    console.error('Failed to create support request', err);
    yield put(
      actions.supportRequestResult({
        success: false,
        error: err?.message ?? 'An unknown error occurred',
      }),
    );
  }
}

function redirectToErrorPage() {
  return put(actions.redirectTo(pageToRedirectOnError));
}

export function* signUpForRoomSaga() {
  yield takeLatest(actions.fetchRoom.type, fetchRoomDetails);
  yield takeLatest(actions.emailSignUp.type, emailSignUp);
  yield takeLatest(actions.socialSignUp.type, socialSignUp);
  yield takeLatest(actions.createSupportRequest.type, createSupportRequest);
}
