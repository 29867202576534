import { Box, Typography, FormControl, Select, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import * as yup from 'yup';
import { notify } from 'utils/misc';
import AppAlert from 'app/components/AppAlert';
import { pageNameList } from 'app/components/EditYourWebsiteMenu';
import { IconAlertWarning } from 'app/components/Icons/IconAlertWarning';
import { RoundedButton } from 'app/components/RoundedButton';
import './fonts.css';
import { useWeddingWebsiteSlice } from './slice';
import { CustomPageContent, WebsiteDetail } from './slice/types';

import {
  selectIsUpdating,
  selectLoading,
  selectWebsiteDetail,
} from './slice/selectors';

import { parseHtmlBackIntoText } from 'utils/formattingHelper';
import { AppTextField } from 'app/components/Form/AppTextField';
import { RichTextEditor } from 'app/components/Form/RichTextEditor';
import { WeddingWebsiteLayout } from './WeddingWebsiteLayout';

const PAGE_CONTENT_CHARS = 10000;
const PAGE_HEADING_CHARS = 35;

const schema = yup
  .object({
    pageName: yup.string().required('Page Name required'),
    pageHeading: yup
      .string()
      .required('Page Heading required')
      .max(
        PAGE_HEADING_CHARS,
        'Your "Page Heading" needs to be less than ' +
          PAGE_HEADING_CHARS +
          ' characters',
      ),
    pageContent: yup
      .string()
      .test(
        'max',
        'Your "Page Content" needs to be less than ' +
          PAGE_CONTENT_CHARS +
          ' thousand characters',
        pageContent =>
          parseHtmlBackIntoText(pageContent).length <= PAGE_CONTENT_CHARS,
      ),
    showTitleInNavigation: yup.boolean(),
    showTitleInPage: yup.boolean(),
  })
  .required();

export function WeddingWebsiteCustomPage() {
  const dispatch = useDispatch();
  const { actions } = useWeddingWebsiteSlice();
  const websiteDetail: WebsiteDetail = useSelector(selectWebsiteDetail);

  const loading = useSelector(selectLoading);
  const isUpdating = useSelector(selectIsUpdating);

  const [pageHeading, setPageHeading] = useState('');
  const [pageContent, setPageContent] = useState('');
  const [pageName, setPageName] = useState('');
  const [enableCustomPage, setEnableCustomPage] = useState<boolean>(true);

  const [defaultPageContent, setDefaultPageContent] = useState('');

  const [warnMessage, setWarnMessage] = useState('');
  const [saveEnabled, setSaveEnabled] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!websiteDetail) return;

    try {
      setPageName(websiteDetail.customPageContent.pageName);
      setPageHeading(websiteDetail.customPageContent.pageHeading);
      setDefaultPageContent(websiteDetail.customPageContent.pageContent);
      setEnableCustomPage(
        websiteDetail.customPageContent.showTitleInNavigation,
      );
    } catch (e) {}
  }, [websiteDetail]);

  const handleYupValidation = data => {
    try {
      schema.validateSync(data, { abortEarly: true });
    } catch (e) {
      const errors = (e as yup.ValidationError).errors;
      throw errors;
    }
  };

  const handleMakeRequest = useCallback(
    (formData: CustomPageContent, showAlert = false) => {
      try {
        dispatch(
          actions.requestWebsiteCustomPageEdit({
            formData,
            showAlert,
          }),
        );
      } catch (e) {
        const error = [
          (e as Error)?.message || 'Network error: something went wrong!',
        ];
        throw error;
      }
    },
    [actions, dispatch],
  );

  const handleUpdate = () => {
    if (loading || isUpdating) return;

    try {
      const data = {
        pageName: pageName,
        pageHeading: pageHeading,
        pageContent: pageContent,
        showTitleInNavigation: enableCustomPage,
        showTitleInPage: true,
      };
      handleYupValidation(data);

      handleMakeRequest(
        { ...data, domainName: websiteDetail.websiteUrl },
        true,
      );
      setDefaultPageContent(pageContent);
    } catch (e) {
      const errors = e as string[];
      const errorEl = (
        <div>
          {errors.map(error => (
            <div key={error}>{error}</div>
          ))}
        </div>
      );
      notify('', errorEl, 'danger');
    }
  };
  useEffect(() => {
    if (Object.values(errors).filter(item => !!item).length === 0)
      setSaveEnabled(true);
    else setSaveEnabled(false);
  }, [errors]);
  const change = useCallback(
    (property, value) => {
      let _value = parseHtmlBackIntoText(value) === '' ? '' : value;
      try {
        schema.validateSyncAt(property, {
          [property]: value,
        });
        if (errors[property]) {
          setErrors({ ...errors, [property]: undefined });
        }
      } catch (err) {
        setErrors({
          ...errors,
          [property]: (err as Error).message || 'something went wrong',
        });
        setSaveEnabled(false);
      }
      switch (property) {
        case 'pageHeading':
          setPageHeading(_value);
          break;
        case 'pageContent':
          setPageContent(_value);
          break;
      }
    },
    [errors, saveEnabled],
  );

  return (
    <WeddingWebsiteLayout
      title={'Custom Page'}
      currentPageName={pageNameList.customPage}
    >
      <Box mb={2}>
        <Typography
          fontFamily={'"Nunito", sans-serif'}
          fontWeight={700}
          fontSize={[16, 18]}
          lineHeight={1.364}
          mb={1}
        >
          Page Name
        </Typography>
        <AppTextField
          sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
          placeholder="Page Name"
          size="medium"
          type="text"
          variant="outlined"
          ref={null}
          value={pageName}
          onChange={e => setPageName(e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <Typography
          fontFamily={'"Nunito", sans-serif'}
          fontWeight={700}
          fontSize={[16, 18]}
          lineHeight={1.364}
          mb={1}
        >
          Page Heading
        </Typography>
        <AppTextField
          sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
          placeholder="Page Heading"
          size="medium"
          type="text"
          variant="outlined"
          ref={null}
          fullWidth
          multiline
          rows={2}
          value={pageHeading}
          error={errors['pageHeading']}
          helperText={errors['pageHeading']}
          onChange={e => change('pageHeading', e.target.value)}
        />
      </Box>
      <Box mb={2}>
        <Typography
          fontFamily={'"Nunito", sans-serif'}
          fontWeight={700}
          fontSize={[16, 18]}
          lineHeight={1.364}
          mb={1}
        >
          Page Content
        </Typography>
        <RichTextEditor
          aria-label="Page Content"
          placeholder="Page Content"
          defaultValue={defaultPageContent}
          onChange={val => change('pageContent', val)}
          error={errors['pageContent']}
          maxCharacters={PAGE_CONTENT_CHARS}
          toolBar={true}
        />
      </Box>
      <Box mb={2}>
        <FormRow>
          <Typography
            fontFamily={'"Nunito", sans-serif'}
            fontWeight={500}
            fontSize={[14, 16]}
            lineHeight={1.364}
            mb={1}
          >
            Enable Custom Page
          </Typography>
          <FormControl>
            <Select
              id="hide-wedding-date"
              value={enableCustomPage.toString()}
              onChange={e => setEnableCustomPage(e.target.value === 'true')}
            >
              <MenuItem value={'true'} key={'True'}>
                True
              </MenuItem>
              <MenuItem value={'false'} key={'False'}>
                False
              </MenuItem>
            </Select>
          </FormControl>
        </FormRow>
      </Box>
      <RoundedButtonStyled
        loading={isUpdating}
        disabled={!saveEnabled}
        onClick={handleUpdate}
      >
        Save & Proceed
      </RoundedButtonStyled>

      {warnMessage && (
        <AppAlert
          onClose={() => setWarnMessage('')}
          title={warnMessage}
          icon={<IconAlertWarning />}
          hideCloseButton={false}
        />
      )}
    </WeddingWebsiteLayout>
  );
}

const RoundedButtonStyled = styled(RoundedButton)`
  min-width: 180px;

  &[disabled] {
    background-color: #d0d0d0;
  }
`;
const FormRow = ({ children, mt = 0 }) => {
  return (
    <Box
      mt={mt}
      sx={{
        display: 'grid',
        gridTemplateColumns: '160px 160px',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {children}
    </Box>
  );
};
