import { NotFoundPage } from 'app/components/NotFoundPage/Loadable';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  selectUser,
  selectUserDetails,
  selectWeddingInfo,
} from '../../store/slice/common/selectors';
import { isLeader, isSalesLead } from '../../utils/userHelper';
import PrivateLeaderRoute from '../components/PrivateLeaderRoute';
import PrivateRoute from '../components/PrivateRoute';
import { Home } from '../HomeView/pages/Home';
import homeViewRoutes from '../HomeView/routes';
import { CheckOut } from './pages/CheckOut/Loadable';
import { GuestList } from './pages/GuestList/Loadable';
import { ModifyReservationIndex } from './pages/ModifyReservation/Index/Loadable';
import { ModifyReservationRoom } from './pages/ModifyReservation/Room/Loadable';
import { ModifyReservationRooms } from './pages/ModifyReservation/Rooms/Loadable';
import { Payment } from './pages/Payment/Loadable';
import { Profile } from './pages/Profile/Loadable';
import { Rooms } from './pages/Rooms/Loadable';
import { RoomsCancellation } from './pages/RoomsCancellation/Loadable';
import { RoomsExtras } from './pages/RoomsExtras/Loadable';
import { RSVPS } from './pages/RSVPS/Loadable';
import { ContactUs } from './pages/SupportCenter/ContactUs/Loadable';
import { FAQs } from './pages/SupportCenter/FAQs/Loadable';
import { WeddingReservation } from './pages/WeddingReservation/Loadable';
import { WeddingWebsite } from './pages/WeddingWebsite/Loadable';
import { useWeddingWebsiteSlice } from './pages/WeddingWebsite/slice';
import { selectWebsiteDetail } from './pages/WeddingWebsite/slice/selectors';
import { WebsiteDetail } from './pages/WeddingWebsite/slice/types';
import { WeddingWebsiteAboutUs } from './pages/WeddingWebsite/WeddingWebsiteAboutUs';
import { WeddingWebsiteDesign } from './pages/WeddingWebsite/WeddingWebsiteDesign';
import { WeddingWebsiteRegistries } from './pages/WeddingWebsite/WeddingWebsiteRegistries';
import { WeddingWebsiteWeddingItinerary } from './pages/WeddingWebsite/WeddingWebsiteWeddingItinerary';
import { WeddingWebsiteWeddingParty } from './pages/WeddingWebsite/WeddingWebsiteWeddingParty';
import { Welcome } from './pages/Welcome/Loadable';
import guestRoutes from './routes';
import PrivateAnyUserRoute from 'app/components/PrivateAnyUserRoute';
import { WeddingWebsiteCustomPage } from './pages/WeddingWebsite/WeddingWebsiteCustomPage';

export const GuestView = () => {
  const userDetails = useSelector(selectUserDetails);
  const userInfo = useSelector(selectUser);
  const dispatch = useDispatch();
  const { actions } = useWeddingWebsiteSlice();
  const websiteDetail: WebsiteDetail = useSelector(selectWebsiteDetail);
  const weddingInfo = useSelector(selectWeddingInfo);

  useEffect(() => {
    if (userInfo && isSalesLead(userInfo)) {
      // TODO: disable this redirect for allow salesLead go in to /rooms and /welcome pages.
      // window.location.href = appRoute.ptid();
    }
  }, [userDetails, userInfo]);

  useEffect(() => {
    if (userInfo && isLeader(userInfo) && !websiteDetail) {
      dispatch(actions.loadWebsiteDetail());
    }
  }, [weddingInfo, websiteDetail]);

  return (
    <Switch>
      {/* LEADER */}
      <Route
        exact
        path={guestRoutes.weddingWebsitePage()}
        component={WeddingWebsite}
      />
      <PrivateRoute
        exact
        path={guestRoutes.weddingWebsiteDesignPage()}
        component={WeddingWebsiteDesign}
      />
      <PrivateRoute
        exact
        path={guestRoutes.weddingWebsiteSaveTheDatePage()}
        component={() => <Redirect to={homeViewRoutes.homePage()} />}
      />
      <PrivateRoute
        exact
        path={guestRoutes.weddingWebsiteAboutUsPage()}
        component={WeddingWebsiteAboutUs}
      />
      <PrivateRoute
        exact
        path={guestRoutes.weddingWebsiteCustomPage()}
        component={WeddingWebsiteCustomPage}
      />
      <PrivateRoute
        exact
        path={guestRoutes.weddingWebsiteWeddingPartyPage()}
        component={WeddingWebsiteWeddingParty}
      />
      <PrivateRoute
        exact
        path={guestRoutes.weddingWebsiteWeddingItineraryPage()}
        component={WeddingWebsiteWeddingItinerary}
      />
      <PrivateRoute
        exact
        path={guestRoutes.weddingWebsiteRegistriesPage()}
        component={WeddingWebsiteRegistries}
      />
      <PrivateLeaderRoute
        exact
        path={guestRoutes.weddingReservationPage()}
        component={WeddingReservation}
      />
      <PrivateAnyUserRoute
        exact
        path={guestRoutes.rsvpsPage()}
        component={RSVPS}
      />
      <PrivateAnyUserRoute
        exact
        path={guestRoutes.guestListPage()}
        component={GuestList}
      />
      {/* LEADER and GUEST */}
      <Route exact path={guestRoutes.welcomePage()} component={Welcome} />
      <Route exact path={homeViewRoutes.homePage()} component={Home} />
      <Route exact path={guestRoutes.roomsPage()} component={Rooms} />
      <PrivateRoute
        exact
        path={guestRoutes.roomExtrasPage()}
        component={RoomsExtras}
      />
      <PrivateRoute
        component={RoomsCancellation}
        path={guestRoutes.roomCancellationPage()}
        exact
      />

      <PrivateRoute
        component={Payment}
        path={guestRoutes.paymentPage()}
        exact
      />
      <PrivateRoute
        component={CheckOut}
        path={guestRoutes.checkoutPage()}
        exact
      />
      <PrivateRoute
        component={ModifyReservationIndex}
        path={guestRoutes.modifyReservationIndexPage()}
        exact
      />
      <PrivateRoute
        component={ModifyReservationRooms}
        path={guestRoutes.modifyReservationRoomsPage()}
        exact
      />
      <PrivateRoute
        component={ModifyReservationRoom}
        path={guestRoutes.modifyReservationRoomPage()}
        exact
      />
      <PrivateRoute
        component={Profile}
        path={guestRoutes.profilePage()}
        exact
      />
      <PrivateRoute exact path={guestRoutes.faqsPage()} component={FAQs} />
      <PrivateRoute
        component={ContactUs}
        path={guestRoutes.contactUsPage()}
        exact
      />

      <PrivateRoute component={Rooms} path="/" exact />
      <Route component={NotFoundPage} />
    </Switch>
  );
};
