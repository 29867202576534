import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Route,
  Switch,
  useLocation,
  useHistory,
  Redirect,
} from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
import { gtmPageView } from 'utils/gtm';
import { useCommonSlice } from '../store/slice/common';

import baseTheme from '../theme/baseTheme';
import appRoute from '../utils/appRoute';
import { LegacyLogIn } from './AuthView/pages/LegacyLogIn/Loadable';
import { LegacySignUp } from './AuthView/pages/LegacySignUp/Loadable';
import { LogIn } from './AuthView/pages/LogIn/Loadable';
import { CreatePassword } from './AuthView/pages/PasswordRecovering/CreatePassword/Loadable';
import { ResetPassword } from './AuthView/pages/PasswordRecovering/ResetPassword/Loadable';
import { ResetPasswordCheck } from './AuthView/pages/PasswordRecovering/ResetPasswordCheck/Loadable';
import { RoomInvitation } from './AuthView/pages/RoomInvitation/Loadable';
import { SignUp } from './AuthView/pages/SignUp/Loadable';
import { SignUpAs } from './AuthView/pages/SignUpAs/Loadable';
import { SignUpAsFind } from './AuthView/pages/SignUpAsFind/Loadable';
import { SignUpAsPlan } from './AuthView/pages/SignUpAsPlan/Loadable';
import { SignUpAsPlanSuccess } from './AuthView/pages/SignUpAsPlanSuccess/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';

import { GuestView } from './GuestView';
import { LiveBooking } from './LiveBooking/Loadable';
import { userPageView } from 'utils/userPageView';
import { SalesView } from './SalesView';
import { Details } from './SalesView/pages/Details/Loadable';
import { getUserInfo, isSalesLeadAny, logOut } from 'utils/userHelper';
import {
  SignUpForRoomHome,
  SignUpForRoomEmail,
  SignUpForRoomAccountCreated,
  SignUpForRoomFailure,
  SignUpForRoomSuccess,
  SignUpForRoomUserSupport,
} from './AuthView/pages/SignUpForRoom/pages';

export function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useCommonSlice();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.fetchUserRelatedData());
  }, []);

  useEffect(() => {
    if (window.dataLayer) {
      const user = getUserInfo();
      window.dataLayer.push({
        user_role:
          user?.userRole?.length > 0
            ? user.userRole[0] ?? 'SalesLeadNotLoggedIn'
            : 'SalesLeadNotLoggedIn',
      });
    }
  });

  // call gtmPageView on each route change
  useEffect(() => {
    gtmPageView();
    userPageView(null);
  }, [location.pathname, location.hash]);

  useEffect(() => {
    // user should be brought to the home page if they
    // navigated away and are just now returning,
    window.addEventListener('beforeunload', storeNavigateAwayTime);
    const user = getUserInfo();
    const isBaseRoute = location.pathname === '/';
    const reloadTime = new Date().getTime();
    const navigatedAwayTime = parseInt(
      localStorage.getItem('navigatedAwayTime'),
    );
    const timeDiff = reloadTime - navigatedAwayTime;
    // take user to the home page if they navigated away for longer than a minute and returned
    const shouldNavigateToHome =
      (timeDiff > 60000 || isBaseRoute) && isSalesLeadAny(user);

    const shouldLogUserOut = timeDiff > 3600000 && !!user;

    if (shouldLogUserOut) {
      logOut();
      history.push(appRoute.logInPage());
    } else if (shouldNavigateToHome) {
      history.push(appRoute.homePage());
    }
    return () => {
      window.removeEventListener('beforeunload', storeNavigateAwayTime);
    };
  }, []);

  return (
    <>
      <Helmet
        titleTemplate="%s | DESTIFY"
        defaultTitle="Destify Guest"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta
          name="description"
          content="View and Manage Your Trip with Destify Destination Wedding Experts"
        />
        <meta
          http-equiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        ></meta>
      </Helmet>
      <ThemeProvider theme={baseTheme}>
        <Switch>
          {/* <Route component={SignUp} path={appRoute.signUpPage()} exact /> */}
          <Route component={SignUp} path={appRoute.signUpPage()} exact>
            {<Redirect to={appRoute.logInPage()} />}
          </Route>
          <Route component={SignUpAs} path={appRoute.signUpAsPage()} exact />
          <Route
            component={SignUpAsFind}
            path={appRoute.signUpAsFindPage()}
            exact
          />
          <Route
            component={SignUpAsPlan}
            path={appRoute.signUpAsPlanPage()}
            exact
          >
            {<Redirect to={appRoute.logInPage()} />}
          </Route>
          <Route
            component={SignUpAsPlanSuccess}
            path={appRoute.signUpAsPlanPageSuccess()}
            exact
          />
          <Route
            component={LegacyLogIn}
            path={appRoute.legacyLogInPage()}
            exact
          />
          <Route
            component={LegacySignUp}
            path={appRoute.legacySignUpPage()}
            exact
          />
          <Route component={LogIn} path={appRoute.logInPage()} exact />
          <Route
            component={SignUpForRoomHome}
            path={appRoute.signUpForRoom()}
            exact
          />
          <Route
            component={SignUpForRoomEmail}
            path={appRoute.signUpForRoomEmail()}
            exact
          />
          <Route
            component={SignUpForRoomAccountCreated}
            path={appRoute.signUpForRoomAccountCreated()}
            exact
          />
          <Route
            component={SignUpForRoomFailure}
            path={appRoute.signUpForRoomFailure()}
            exact
          />
          <Route
            component={SignUpForRoomSuccess}
            path={appRoute.signUpForRoomSuccess()}
            exact
          />
          <Route
            component={SignUpForRoomUserSupport}
            path={appRoute.signUpForRoomUserSupport()}
            exact
          />
          <Route
            component={RoomInvitation}
            path={appRoute.roomInvitationPage()}
            exact
          />
          <Route
            component={ResetPassword}
            path={appRoute.resetPasswordPage()}
            exact
          />
          <Route
            component={ResetPasswordCheck}
            path={appRoute.resetPasswordCheckPage()}
            exact
          />
          <Route
            component={CreatePassword}
            path={appRoute.createPasswordPage()}
            exact
          />
          <Route
            component={Details}
            path={appRoute.signUpSetWeddingDetailsPage()}
            exact
          />
          <Route component={LiveBooking} path={appRoute.liveBooking()} />
          <Route component={SalesView} path={appRoute.ptid()} />
          <Route component={SalesView} path={appRoute.salesLead()}>
            {<Redirect to={appRoute.logInPage()} />}
          </Route>
          <Route component={SalesView} path={appRoute.support()} />
          <Route component={GuestView} path="/" />
          <Route component={NotFoundPage} />
        </Switch>
      </ThemeProvider>
      <GlobalStyle />
    </>
  );
}
function storeNavigateAwayTime(event) {
  var d = new Date();
  var time = d.getTime();
  localStorage.setItem('navigatedAwayTime', time.toString());
}
