import * as React from 'react';

export const IconCalendar = ({
  width = 20,
  height = 22,
  fill = 'black',
}: {
  width?: number | string;
  height?: number | string;
  fill?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5739 21.1549H4.42611C1.98563 21.1549 0 19.1693 0 16.7288V6.61654C0 4.17606 1.98558 2.19043 4.42611 2.19043H15.5739C18.0144 2.19043 20 4.17601 20 6.61654V16.7288C20 19.1693 18.0144 21.1549 15.5739 21.1549V21.1549ZM4.42611 3.17405C2.52798 3.17405 0.98362 4.71845 0.98362 6.61654V16.7288C0.98362 18.6269 2.52802 20.1713 4.42611 20.1713H15.5739C17.472 20.1713 19.0164 18.6269 19.0164 16.7288V6.61654C19.0164 4.71841 17.472 3.17405 15.5739 3.17405H4.42611Z"
        fill={fill}
      />
      <path
        d="M6.06017 4.86465C5.78863 4.86465 5.56836 4.6444 5.56836 4.37283V0.99181C5.56836 0.720271 5.78861 0.5 6.06017 0.5C6.33171 0.5 6.55198 0.720249 6.55198 0.99181V4.37283C6.55198 4.64455 6.33191 4.86465 6.06017 4.86465Z"
        fill={fill}
      />
      <path
        d="M13.9405 4.86465C13.669 4.86465 13.4487 4.6444 13.4487 4.37283V0.99181C13.4487 0.720271 13.669 0.5 13.9405 0.5C14.2121 0.5 14.4324 0.720249 14.4324 0.99181V4.37283C14.4324 4.64455 14.2123 4.86465 13.9405 4.86465Z"
        fill={fill}
      />
      <path
        d="M19.5082 8.20237H0.49181C0.220271 8.20237 0 7.98212 0 7.71056C0 7.439 0.220249 7.21875 0.49181 7.21875H19.5082C19.7797 7.21875 20 7.439 20 7.71056C20 7.98212 19.7798 8.20237 19.5082 8.20237Z"
        fill={fill}
      />
      <path
        d="M5.75929 12.0082C5.75929 12.4406 5.40889 12.791 4.97666 12.791C4.54441 12.791 4.19385 12.4406 4.19385 12.0082C4.19385 11.576 4.54442 11.2256 4.97666 11.2256C5.40891 11.2256 5.75929 11.576 5.75929 12.0082Z"
        fill={fill}
      />
      <path
        d="M10.783 12.0082C10.783 12.4406 10.4326 12.791 10.0004 12.791C9.56817 12.791 9.21777 12.4406 9.21777 12.0082C9.21777 11.576 9.56817 11.2256 10.0004 11.2256C10.4326 11.2256 10.783 11.576 10.783 12.0082Z"
        fill={fill}
      />
      <path
        d="M15.8062 12.0082C15.8062 12.4406 15.4556 12.791 15.0234 12.791C14.5911 12.791 14.2407 12.4406 14.2407 12.0082C14.2407 11.576 14.5911 11.2256 15.0234 11.2256C15.4556 11.2256 15.8062 11.576 15.8062 12.0082Z"
        fill={fill}
      />
      <path
        d="M5.75929 16.3656C5.75929 16.7981 5.40889 17.1485 4.97666 17.1485C4.54441 17.1485 4.19385 16.7981 4.19385 16.3656C4.19385 15.9334 4.54442 15.583 4.97666 15.583C5.40891 15.583 5.75929 15.9334 5.75929 16.3656Z"
        fill={fill}
      />
      <path
        d="M10.783 16.3656C10.783 16.7981 10.4326 17.1485 10.0004 17.1485C9.56817 17.1485 9.21777 16.7981 9.21777 16.3656C9.21777 15.9334 9.56817 15.583 10.0004 15.583C10.4326 15.583 10.783 15.9334 10.783 16.3656Z"
        fill={fill}
      />
      <path
        d="M15.8062 16.3656C15.8062 16.7981 15.4556 17.1485 15.0234 17.1485C14.5911 17.1485 14.2407 16.7981 14.2407 16.3656C14.2407 15.9334 14.5911 15.583 15.0234 15.583C15.4556 15.583 15.8062 15.9334 15.8062 16.3656Z"
        fill={fill}
      />
    </svg>
  );
};
