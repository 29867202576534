import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { WeddingWebsiteSaga } from './saga';
import { WeddingWebsiteState, WebsiteImage, CustomPageContent } from './types';
import {
  SendSaveTheDatesEmailPayload,
  EditWebsiteItinerariesPayload,
} from './types';

export const initialState: WeddingWebsiteState = {
  checking: null,
  registering: null,
  checkStatus: null,
  loading: true,
  websiteDetail: null,
  websiteImages: [],
  isUpdating: false,
  sendSaveTheDateStatus: null,
};

const slice = createSlice({
  name: 'weddingWebsite',
  initialState,
  reducers: {
    loadWebsiteDetail(state) {},
    setWebsiteDetail(state, action) {
      state.loading = false;
      state.websiteDetail = action.payload;
    },
    setChecking(state, action: PayloadAction<boolean>) {
      state.checking = action.payload;
    },
    setCheckStatus(
      state,
      action: PayloadAction<WeddingWebsiteState['checkStatus']>,
    ) {
      state.checkStatus = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setRegisterStatus(
      state,
      action: PayloadAction<WeddingWebsiteState['registerStatus']>,
    ) {
      state.registerStatus = action.payload;
    },
    setWebsiteImages(state, action: PayloadAction<WebsiteImage[]>) {
      state.websiteImages = action.payload;
    },
    loadWebsiteImages(state) {},
    checkWebsite(state, action: PayloadAction<string>) {
      state.checking = true;
      state.checkStatus = null;
    },
    checkWebsiteError(state, action: PayloadAction<string>) {
      state.checking = false;
      state.checkStatus = 'error';
    },
    checkWebsiteSuccess(state, action: PayloadAction<string>) {
      state.checking = false;
      state.checkStatus = 'success';
    },
    resetCheckWebsite(state) {
      state.checking = null;
      state.checkStatus = null;
    },
    registerWebsite(state, action: PayloadAction<string>) {
      state.registering = true;
    },
    registerWebsiteError(state) {
      state.registering = false;
      state.registerStatus = 'error';
    },
    registerWebsiteSuccess(state, action: PayloadAction<string>) {
      state.registering = false;
      state.registerStatus = 'success';
      state.websiteUrl = action.payload;
    },
    sendSaveTheDates(
      state,
      action: PayloadAction<SendSaveTheDatesEmailPayload>,
    ) {},

    sendSaveTheDatesStatus(
      state,
      action: PayloadAction<'success' | 'error' | 'pending' | null>,
    ) {
      state.loading = false;
      state.sendSaveTheDateStatus = action.payload;
    },
    requestWebsiteEdit(state, action: PayloadAction<Object>) {},
    requestWebsiteRegistriesEdit(state, action: PayloadAction<Object>) {},
    requestWebsiteItinerariesEdit(
      state,
      action: PayloadAction<EditWebsiteItinerariesPayload>,
    ) {},
    requestWebsiteCustomPageEdit(
      state,
      action: PayloadAction<{
        formData: CustomPageContent;
        showAlert: boolean;
      }>,
    ) {},
    setIsUpdating(state, action: PayloadAction<boolean>) {
      state.isUpdating = action.payload;
    },

    deleteWeddingParty(state, action) {
      state.isUpdating = true;
    },
    removeWebsiteImage(state, action) {},
  },
});

export const { actions: WeddingWebsiteActions } = slice;

export const useWeddingWebsiteSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: WeddingWebsiteSaga });
  return { actions: slice.actions };
};
