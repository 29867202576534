import { Box, Link, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components/macro';
import footer from '../../../locales/en/footer.json';

export const Footer = () => {
  const theme = useTheme();

  const year = new Date().getFullYear();

  // this would ideally be in the json as well and can pass the {{ year }} as a value.
  const copyRightBody = [
    `Destify | Copyright © ${year}.`,
    'Iowa #1190 California',
    '#2117614-40 Florida',
    '#39458 Washington #603591447',
  ];

  return (
    <Wrapper theme={theme}>
      <LogoWrapper>
        <img src={footer.logo.src} alt={footer.logo.alt} />
      </LogoWrapper>
      <LinkRow>
        <FooterColumnContainer>
          {footer.links?.map((link, i) => {
            return (
              <Link
                key={i}
                href={link?.href}
                target={link?.target}
                underline="hover"
              >
                {link?.title}
              </Link>
            );
          })}
        </FooterColumnContainer>
        <FooterColumnContainer>
          {copyRightBody.map((copyright, i) => {
            return (
              <Typography key={i} variant="body2">
                {copyright}
              </Typography>
            );
          })}
        </FooterColumnContainer>
      </LinkRow>
    </Wrapper>
  );
};

const FooterColumnContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
`;

const LogoWrapper = styled(Box)`
  display: flex;

  @media (min-width: 768px) {
    padding-top: 15px;
    img {
      height: 50px;
      width: 50px;
    }
  }
`;

const LinkRow = styled(Box)`
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  line-height: 16px;

  @media (min-width: 768px) {
    padding-top: 15px;
    gap: 96px;
  }
`;

const Wrapper = styled.div`
  background-color: ${props => props.theme.palette.primary.main};

  box-sizing: border-box;
  padding: 16px;
  height: 150px;
  display: flex;
  flex-direction: column;

  .MuiTypography-root {
    color: white !important;
    font-size: 12px;
  }

  .MuiLink-root {
    font-weight: 600;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;
