import styled from 'styled-components/macro';
import { Box, ButtonUnstyled } from '@mui/material';
import { RoundedButton } from 'app/components/RoundedButton';
import { FadeInContainer } from 'app/components/Misc/FadeInContainer';

export const ItemCard = styled(Box)`
  padding: 16px;
  border: 1px solid #e4e8e8;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;

export const AddButton = styled(ButtonUnstyled)`
  padding: 12px;
  background-color: #e3f7fa;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  & svg {
    width: 22px;
    height: 22px;
  }
`;

export const RoundedButtonStyled = styled(RoundedButton)`
  min-width: 180px;

  &[disabled] {
    background-color: #d0d0d0;
  }
`;

export const FadeInContainerStyled = styled(FadeInContainer)`
  display: flex;
  flex: 1;
`;
