import React from 'react';
import { Box } from '@mui/material';
import { IconPhone } from '../Icons/IconPhone';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { IconChevronRight } from '../Icons/IconChevronRight';
import { useIsMobile } from 'utils/responsiveHelper';
import { ptidCalendlyLink } from 'app/SalesView/pages/Ptid/SalesLead/constants';

export const ScheduleACallBottomBanner = ({ theme }) => {
  const isMobile = useIsMobile('sm');
  return (
    <Box
      pt={isMobile ? '0.5rem' : '1.75rem'}
      pb={'2.6rem'}
      px={'2.25rem'}
      sx={{
        background: theme.palette.primary.light,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',
          gridTemplateRows: '1fr',
          gridTemplateAreas: '"left right"',
        },
      }}
    >
      <Box
        sx={{
          [theme.breakpoints.up('sm')]: {
            gridArea: 'left',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          },
          display: 'flex',
          marginBottom: isMobile ? '1rem' : '0',
        }}
      >
        <Box>
          <IconPhone width={45} />
        </Box>
        <Typography mx={1.5} fontSize={'1.25rem'}>
          Want to talk to one of our experts first? No problem!
        </Typography>
      </Box>
      <Box
        gridArea={'right'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={isMobile ? 'center' : 'flex-start'}
      >
        <Button
          sx={{
            background: '#fff',
            padding: '0.75rem 1rem',
            height: 'fit-content',
            width: isMobile ? '100%' : 'fit-content',
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: isMobile ? '4rem' : '0',
          }}
          href={ptidCalendlyLink}
        >
          <Typography pr={3}>Schedule a Free Call</Typography>
          <IconChevronRight width={10} />
        </Button>
      </Box>
    </Box>
  );
};
