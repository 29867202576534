import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { Route } from 'react-router-dom';

import routes from './routes';

import { Layout } from 'app/SalesView/Layout';
import { NotFoundPage } from 'app/components/NotFoundPage/Loadable';

import { Ptid } from './pages/Ptid/Loadable';
import { SalesLead } from 'app/SalesView/pages/Ptid/SalesLead/Loadable';
import { Support } from 'app/SalesView/pages/Ptid/Support/Loadable';

import PrivateSalesLeadRoute from '../components/PrivateSalesLeadRoute';

export const SalesView = () => {
  const { pathname } = useLocation();

  const title =
    pathname === routes.salesLead() ? 'Destify - Destination Weddings' : null;

  return (
    <Layout title={title}>
      <Switch>
        <Route path={routes.salesLead()} component={SalesLead} />
        <PrivateSalesLeadRoute component={Ptid} path={routes.ptid()} exact />
        <Route path={routes.support()} component={Support} />
        <Route component={NotFoundPage} />
      </Switch>
    </Layout>
  );
};
