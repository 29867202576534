import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { Resort } from '../../../SalesView/pages/Ptid/SalesLead/slice/types';
import { AppModal } from 'app/components/AppModal';
import { RoundedButton } from '../../../components/RoundedButton';

interface ModalProps {
  resort: Resort;
  resortType: 'TopRecommendationResort' | 'FavoriteResort';
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

export const ConfirmationModal = ({
  open,
  onClose,
  onContinue,
}: ModalProps) => {
  return (
    <AppModal open={open} onClose={onClose}>
      <Box maxWidth={'400px'}>
        <Typography
          variant={'h5'}
          textAlign={'center'}
          fontWeight={600}
          fontSize={22}
          lineHeight={1.36}
        >
          See Resorts
        </Typography>
        <Typography
          variant={'body1'}
          textAlign={'center'}
          lineHeight={1.3}
          mb={3}
        >
          Selecting this resort will reset any pending wedding date requests you
          may have. You will be able to continue to explore various Resorts on
          the Select Resort page and submit a new request.
        </Typography>
        <Stack direction={'column'} mt={'30px'} spacing={2} padding={'0 2em'}>
          <RoundedButton
            data-testid="confirmation-modal-button-continue"
            className="fullWidth"
            onClick={onContinue}
          >
            Continue
          </RoundedButton>
        </Stack>
      </Box>
    </AppModal>
  );
};
