import * as React from 'react';

type CheckBoxBackgroundColor = 'outlined' | 'filled';

interface CheckboxProps {
  variant?: CheckBoxBackgroundColor;
}

export const IconCheckBoxChecked = ({ variant }: CheckboxProps) => {
  const checkMarkColor = variant === 'filled' ? '#FFFFFF' : '#00BAC4';
  const checkboxBGColor = variant === 'filled' ? '#00BAC4' : 'none';

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={checkboxBGColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="19" stroke="#00BAC4" />
      <path
        d="M7.36237 14.9999C7.10281 14.9999 6.85469 14.8935 6.67098 14.7074L0.28563 8.15526C-0.0952099 7.76448 -0.0952099 7.12952 0.28563 6.73872C0.666469 6.34794 1.28526 6.34794 1.66612 6.73872L7.36005 12.5814L19.3339 0.293089C19.7147 -0.0976965 20.3335 -0.0976965 20.7144 0.293089C21.0952 0.683875 21.0952 1.31883 20.7144 1.70963L8.05096 14.7055C7.87018 14.894 7.62207 15 7.36218 15L7.36237 14.9999Z"
        fill={checkMarkColor}
      />
    </svg>
  );
};
