export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 900 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 900, min: 600 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const RESERVATION_STATUS = {
  BOOKED: 'booked',
  PENDING: 'pending',
};

export const STATUS_COLOR = {
  [RESERVATION_STATUS.BOOKED]: '#B3D295',
  [RESERVATION_STATUS.PENDING]: '#FFC693',
};
