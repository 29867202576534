import * as React from 'react';
import { SVGAttributes } from 'react';

export const IconMagnifier = ({
  fill = '#2D3031',
  stroke = 'none',
  width = 21,
  height = 22,
  ...attributes
}: SVGAttributes<any> & {
  fill?: string;
  stroke?: string;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 22"
      fill="none"
      {...attributes}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5516 18.7634C11.5015 18.7641 13.404 18.1741 15.0009 17.0735L18.6574 21.1908C18.8393 21.4111 19.1047 21.5492 19.392 21.5732C19.6796 21.5975 19.9646 21.5054 20.1818 21.3188C20.399 21.1319 20.5295 20.8663 20.5438 20.5829C20.5577 20.2993 20.4542 20.0225 20.2564 19.8158L16.5942 15.6929V15.6926C18.5716 13.5535 19.4356 10.6367 18.9362 7.78723C18.4365 4.93777 16.6293 2.47427 14.0376 1.11009C11.4463 -0.254267 8.3597 -0.366835 5.6728 0.804828C2.98548 1.9765 0.99764 4.30136 0.284813 7.10673C-0.428014 9.91169 0.213742 12.8834 2.02437 15.1613C3.83518 17.4389 6.61232 18.7681 9.55169 18.7639L9.5516 18.7634ZM9.5516 2.06011C11.5218 2.06011 13.4116 2.83001 14.8048 4.20045C16.198 5.5709 16.9807 7.4295 16.9807 9.36779C16.9807 11.3059 16.198 13.1645 14.8048 14.5351C13.4116 15.9056 11.5218 16.6755 9.5516 16.6755C7.58134 16.6755 5.69163 15.9056 4.29842 14.5351C2.90521 13.1644 2.12252 11.3059 2.12252 9.36779C2.12465 7.43021 2.90831 5.57275 4.30103 4.20278C5.69375 2.83281 7.58207 2.0622 9.5516 2.06011Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};
