import * as React from 'react';
import { Box } from '@mui/material';
import { RoundedButton } from '../../../../../components/RoundedButton';
import appRoute from '../../../../../../utils/appRoute';
import { useHistory } from 'react-router-dom';
import { SignUpForRoomLayout } from '../../layout';

interface SignUpForRoomAccountCreatedProps {}

export function SignUpForRoomAccountCreated(
  _: SignUpForRoomAccountCreatedProps,
) {
  const history = useHistory();

  const goToPage = (path: string) => {
    history.push({ pathname: path });
  };

  return (
    <SignUpForRoomLayout title="Account Created Successfully">
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        <RoundedButton onClick={() => goToPage(appRoute.logInPage())}>
          Login
        </RoundedButton>
      </Box>
    </SignUpForRoomLayout>
  );
}
