import * as React from 'react';

export const IconImageUpload = ({
  fill = 'none',
  stroke = 'none',
  width = 84,
  height = 71,
}: {
  fill?: string;
  stroke?: string;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 84 71"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2476 25.066H5C2.79086 25.066 1 26.8569 1 29.066V62.2C1 64.4091 2.79086 66.2 5 66.2H39.7408C41.9499 66.2 43.7408 64.4091 43.7408 62.2V56.8806"
        stroke="#CCF3F9"
        strokeWidth="2"
        strokeDasharray="5 5"
      />
      <rect
        x="12.6047"
        y="1"
        width="55.2019"
        height="55.2019"
        rx="3"
        stroke="#9DD3DB"
        strokeWidth="2"
      />
      <rect
        x="16.7466"
        y="5.14174"
        width="46.9184"
        height="46.9184"
        rx="4"
        fill="#CCF3F9"
      />
      <circle cx="28.6369" cy="21.5311" r="7.39126" fill="#9DD3DB" />
      <rect
        x="28.9583"
        y="21.8525"
        width="18.6388"
        height="21.2097"
        fill="white"
      />
      <path
        d="M58.8445 43.0622L51.1319 30.8505L44.062 43.0622H58.8445Z"
        fill="#9DD3DB"
      />
      <circle cx="69.1282" cy="56.2379" r="14.4612" fill="#9DD3DB" />
      <path
        d="M65.5933 53.9884L69.4496 50.1321L73.3059 53.9884"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.4496 50.1321L69.4496 62.9864"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
