import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { selectUser } from '../../../store/slice/common/selectors';
import appRoute from '../../../utils/appRoute';
import { IconAngleRight } from '../Icons/IconAngleRight';
import MenuItem from './MenuItem';
import { useIsMobile } from 'utils/responsiveHelper';

export enum pageNameList {
  weddingWebsite = 'Wedding Website',
  design = 'Design',
  saveTheDate = 'Save The Date',
  aboutUs = 'About Us',
  weddingParty = 'Wedding Party',
  weddingItinerary = 'Wedding Itinerary',
  registries = 'Registries',
  customPage = 'Custom Page',
}

interface Props {
  className?: string;
  children?: any;
  currentPageName: pageNameList;
}

interface MenuProps {
  goToPage: Function;
}

interface MobileMenuProps {
  goToPage: Function;
  currentPageName: pageNameList;
}

type AppRoute = typeof appRoute;

type RouteMenuItemParams = {
  routeName: keyof AppRoute;
  routeParams: any[];
  title: string;
  goToPage: Function;
  altRouteNames?: Array<keyof AppRoute>;
};
const RouteMenuItem = (params: RouteMenuItemParams) => {
  const url = appRoute[params.routeName](...params.routeParams);
  const routeList = [
    appRoute[params.routeName](),
    ...(params.altRouteNames || []).map(rn => appRoute[rn]()),
  ];
  const className = useRouteMatch(routeList) ? 'active' : undefined;

  return (
    <MenuItem
      title={params.title}
      onClick={() => {
        params.goToPage(url);
      }}
      className={className}
    />
  );
};

export const MobileMenu = ({ goToPage, currentPageName }: MobileMenuProps) => {
  switch (currentPageName) {
    case pageNameList.weddingWebsite:
      return (
        <AccordionStyled
          key={pageNameList.weddingWebsite}
          variant={'outlined'}
          disableGutters={true}
          square={true}
          defaultExpanded={false}
        >
          <AccordionSummaryStyled expandIcon={<IconAngleRight />}>
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontSize={18}
              fontWeight={400}
            >
              {pageNameList.weddingWebsite}
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteDesignPage'}
              routeParams={['']}
              title="Design"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteSaveTheDatePage'}
              routeParams={['']}
              title="Save The Date"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteAboutUsPage'}
              routeParams={['']}
              title="About Us"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteCustomPage'}
              routeParams={['']}
              title="Custom Page"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingPartyPage'}
              routeParams={['']}
              title="Wedding Party"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingItineraryPage'}
              routeParams={['']}
              title="Wedding Itinerary"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteRegistriesPage'}
              routeParams={['']}
              title="Registries"
            />
          </AccordionDetailsStyled>
        </AccordionStyled>
      );
    case pageNameList.design:
      return (
        <AccordionStyled
          key={pageNameList.design}
          variant={'outlined'}
          disableGutters={true}
          square={true}
          defaultExpanded={false}
        >
          <AccordionSummaryStyled expandIcon={<IconAngleRight />}>
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontSize={18}
              fontWeight={400}
            >
              {pageNameList.design}
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsitePage'}
              routeParams={['']}
              title="Wedding Website"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteSaveTheDatePage'}
              routeParams={['']}
              title="Save The Date"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteAboutUsPage'}
              routeParams={['']}
              title="About Us"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteCustomPage'}
              routeParams={['']}
              title="Custom Page"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingPartyPage'}
              routeParams={['']}
              title="Wedding Party"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingItineraryPage'}
              routeParams={['']}
              title="Wedding Itinerary"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteRegistriesPage'}
              routeParams={['']}
              title="Registries"
            />
          </AccordionDetailsStyled>
        </AccordionStyled>
      );
    case pageNameList.saveTheDate:
      return (
        <AccordionStyled
          key={pageNameList.saveTheDate}
          variant={'outlined'}
          disableGutters={true}
          square={true}
          defaultExpanded={false}
        >
          <AccordionSummaryStyled expandIcon={<IconAngleRight />}>
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontSize={18}
              fontWeight={400}
            >
              {pageNameList.saveTheDate}
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsitePage'}
              routeParams={['']}
              title="Wedding Website"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteDesignPage'}
              routeParams={['']}
              title="Design"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteAboutUsPage'}
              routeParams={['']}
              title="About Us"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteCustomPage'}
              routeParams={['']}
              title="Custom Page"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingPartyPage'}
              routeParams={['']}
              title="Wedding Party"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingItineraryPage'}
              routeParams={['']}
              title="Wedding Itinerary"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteRegistriesPage'}
              routeParams={['']}
              title="Registries"
            />
          </AccordionDetailsStyled>
        </AccordionStyled>
      );
    case pageNameList.aboutUs:
      return (
        <AccordionStyled
          key={pageNameList.aboutUs}
          variant={'outlined'}
          disableGutters={true}
          square={true}
          defaultExpanded={false}
        >
          <AccordionSummaryStyled expandIcon={<IconAngleRight />}>
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontSize={18}
              fontWeight={400}
            >
              {pageNameList.aboutUs}
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsitePage'}
              routeParams={['']}
              title="Wedding Website"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteDesignPage'}
              routeParams={['']}
              title="Design"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteCustomPage'}
              routeParams={['']}
              title="Custom Page"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteSaveTheDatePage'}
              routeParams={['']}
              title="Save The Date"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingPartyPage'}
              routeParams={['']}
              title="Wedding Party"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingItineraryPage'}
              routeParams={['']}
              title="Wedding Itinerary"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteRegistriesPage'}
              routeParams={['']}
              title="Registries"
            />
          </AccordionDetailsStyled>
        </AccordionStyled>
      );
    case pageNameList.customPage:
      return (
        <AccordionStyled
          key={pageNameList.customPage}
          variant={'outlined'}
          disableGutters={true}
          square={true}
          defaultExpanded={false}
        >
          <AccordionSummaryStyled expandIcon={<IconAngleRight />}>
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontSize={18}
              fontWeight={400}
            >
              {pageNameList.customPage}
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsitePage'}
              routeParams={['']}
              title="Wedding Website"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteDesignPage'}
              routeParams={['']}
              title="Design"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteAboutUsPage'}
              routeParams={['']}
              title="About Us"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteSaveTheDatePage'}
              routeParams={['']}
              title="Save The Date"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingPartyPage'}
              routeParams={['']}
              title="Wedding Party"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingItineraryPage'}
              routeParams={['']}
              title="Wedding Itinerary"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteRegistriesPage'}
              routeParams={['']}
              title="Registries"
            />
          </AccordionDetailsStyled>
        </AccordionStyled>
      );
    case pageNameList.weddingParty:
      return (
        <AccordionStyled
          key={pageNameList.weddingParty}
          variant={'outlined'}
          disableGutters={true}
          square={true}
          defaultExpanded={false}
        >
          <AccordionSummaryStyled expandIcon={<IconAngleRight />}>
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontSize={18}
              fontWeight={400}
            >
              {pageNameList.weddingParty}
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsitePage'}
              routeParams={['']}
              title="Wedding Website"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteDesignPage'}
              routeParams={['']}
              title="Design"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteSaveTheDatePage'}
              routeParams={['']}
              title="Save The Date"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteAboutUsPage'}
              routeParams={['']}
              title="About Us"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteCustomPage'}
              routeParams={['']}
              title="Custom Page"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingItineraryPage'}
              routeParams={['']}
              title="Wedding Itinerary"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteRegistriesPage'}
              routeParams={['']}
              title="Registries"
            />
          </AccordionDetailsStyled>
        </AccordionStyled>
      );
    case pageNameList.weddingItinerary:
      return (
        <AccordionStyled
          key={pageNameList.weddingItinerary}
          variant={'outlined'}
          disableGutters={true}
          square={true}
          defaultExpanded={false}
        >
          <AccordionSummaryStyled expandIcon={<IconAngleRight />}>
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontSize={18}
              fontWeight={400}
            >
              {pageNameList.weddingItinerary}
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsitePage'}
              routeParams={['']}
              title="Wedding Website"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteDesignPage'}
              routeParams={['']}
              title="Design"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteSaveTheDatePage'}
              routeParams={['']}
              title="Save The Date"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteAboutUsPage'}
              routeParams={['']}
              title="About Us"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteCustomPage'}
              routeParams={['']}
              title="Custom Page"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingPartyPage'}
              routeParams={['']}
              title="Wedding Party"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteRegistriesPage'}
              routeParams={['']}
              title="Registries"
            />
          </AccordionDetailsStyled>
        </AccordionStyled>
      );
    case pageNameList.registries:
      return (
        <AccordionStyled
          key={pageNameList.registries}
          variant={'outlined'}
          disableGutters={true}
          square={true}
          defaultExpanded={false}
        >
          <AccordionSummaryStyled expandIcon={<IconAngleRight />}>
            <Typography
              fontFamily={'"Nunito", sans-serif'}
              fontSize={18}
              fontWeight={400}
            >
              {pageNameList.registries}
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetailsStyled>
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsitePage'}
              routeParams={['']}
              title="Wedding Website"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteDesignPage'}
              routeParams={['']}
              title="Design"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteSaveTheDatePage'}
              routeParams={['']}
              title="Save The Date"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteAboutUsPage'}
              routeParams={['']}
              title="About Us"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteCustomPage'}
              routeParams={['']}
              title="Custom Page"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteCustomPage'}
              routeParams={['']}
              title="Custom Page"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingPartyPage'}
              routeParams={['']}
              title="Wedding Party"
            />
            <RouteMenuItem
              goToPage={goToPage}
              routeName={'weddingWebsiteWeddingItineraryPage'}
              routeParams={['']}
              title="Wedding Itinerary"
            />
          </AccordionDetailsStyled>
        </AccordionStyled>
      );
  }
};

const AccordionStyled = styled(Accordion)`
  position: relative;
  z-index: 99;
  border: none;

  &.MuiAccordion-root:before {
    content: none;
  }
`;

const AccordionSummaryStyled = styled(AccordionSummary)`
  margin: 0 !important;
  padding: 12px 20px !important;
  background-color: #e2f5f6 !important;

  & .MuiAccordionSummary-content {
    margin: 0;
  }

  & .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(90deg);
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(270deg);
  }
`;

const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0;
`;

export const DesktopMenu = ({ goToPage }: MenuProps) => {
  return (
    <>
      <Typography
        fontFamily={'"Nunito", sans-serif'}
        fontWeight={400}
        fontSize={22}
        lineHeight={1.364}
        mb={2}
      >
        Edit Your Website
      </Typography>
      <RouteMenuItem
        goToPage={goToPage}
        routeName={'weddingWebsiteDesignPage'}
        routeParams={['']}
        title="Design"
      />
      <RouteMenuItem
        goToPage={goToPage}
        routeName={'weddingWebsiteAboutUsPage'}
        routeParams={['']}
        title="About Us"
      />
      <RouteMenuItem
        goToPage={goToPage}
        routeName={'weddingWebsiteCustomPage'}
        routeParams={['']}
        title="Custom Page"
      />
      <RouteMenuItem
        goToPage={goToPage}
        routeName={'weddingWebsiteWeddingPartyPage'}
        routeParams={['']}
        title="Wedding Party"
      />
      <RouteMenuItem
        goToPage={goToPage}
        routeName={'weddingWebsiteWeddingItineraryPage'}
        routeParams={['']}
        title="Wedding Itinerary"
      />
      <RouteMenuItem
        goToPage={goToPage}
        routeName={'weddingWebsiteRegistriesPage'}
        routeParams={['']}
        title="Registries"
      />
    </>
  );
};

export const Menu = memo(({ currentPageName }: Props) => {
  const history = useHistory();
  const user = useSelector(selectUser);
  const isMobile = useIsMobile('md');

  const goToPage = (path: string) => {
    history.push(path);
  };

  return (
    <>
      {isMobile && user && (
        <MobileMenu currentPageName={currentPageName} goToPage={goToPage} />
      )}
      {!isMobile && user && <DesktopMenu goToPage={goToPage} />}
    </>
  );
});
