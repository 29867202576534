import * as React from 'react';

export const IconHeart = ({
  fill = '#FF7B74',
  stroke = 'none',
  width = 16,
  height = 14,
}: {
  fill?: string;
  stroke?: string;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0044 8.18486L8.53143 12.8276V12.6657L7.96245 13.2388L2.00212 8.18267L2.00115 8.18185C0.21982 6.67849 -0.0207796 3.95909 1.51952 2.16476L1.51957 2.1648L1.52454 2.15881C3.01175 0.370765 5.69947 0.123241 7.48023 1.5597L7.62282 1.70334L7.97766 2.06078L8.3325 1.70335L8.47804 1.55675C10.2519 0.0747922 12.8859 0.311148 14.4316 2.15981L14.4383 2.16783L14.4454 2.17558C16.0202 3.90641 15.7967 6.62107 14.0044 8.18486Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};
