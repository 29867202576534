import { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';

export function useDebounce<T extends (...args: any[]) => any>(
  callback: T,
  delay: number,
) {
  // Memoizing the callback because if it's an arrow function
  // it would be different on each render
  const memoizedCallback = useCallback(callback, []);
  const debouncedFn = useRef(debounce(memoizedCallback, delay));

  useEffect(() => {
    debouncedFn.current = debounce(memoizedCallback, delay);
  }, [memoizedCallback, debouncedFn, delay]);

  return debouncedFn.current;
}
