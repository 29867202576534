import { yupResolver } from '@hookform/resolvers/yup';
import { MobileTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { Box, InputAdornment, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { AppModal } from '../../../components/AppModal';
import { pageNameList } from '../../../components/EditYourWebsiteMenu';
import { AppTextField } from '../../../components/Form/AppTextField';
import { IconCalendar } from '../../../components/Icons/IconCalendar';
import { IconClock } from '../../../components/Icons/IconClock';
import { IconLaptop } from '../../../components/Icons/IconLaptop';
import { IconPencilSquare } from '../../../components/Icons/IconPencilSquare';
import { IconPlusCircled } from '../../../components/Icons/IconPlusCircled';
import { IconTrash } from '../../../components/Icons/IconTrash';
import { RoundedButton } from '../../../components/RoundedButton';
import './fonts.css';
import { useWeddingWebsiteSlice } from './slice';
import { selectIsUpdating, selectWebsiteDetail } from './slice/selectors';
import { WebsiteDetail, WeddingItinerary } from './slice/types';
import { ItemCard, AddButton, RoundedButtonStyled } from './sharedComponents';
import { WeddingWebsiteLayout } from './WeddingWebsiteLayout';

interface ItineraryAddForm {
  eventName: string;
  eventDate: Date | string;
  startTime: Date | string;
  endTime: Date | string;
  location: string;
  additionalText: string;
}

// TODO: max itinerary?
const MAX_ADD_COUNT = 10;

const schema = yup
  .object({
    eventName: yup.string().required(),
    eventDate: yup.string().required().nullable(),
    startTime: yup.string().required().nullable(),
    endTime: yup.string().required().nullable(),
    location: yup.string().required(),
    additionalText: yup.string(),
  })
  .required();

export function WeddingWebsiteWeddingItinerary() {
  const dispatch = useDispatch();
  const { actions } = useWeddingWebsiteSlice();
  const websiteDetail: WebsiteDetail = useSelector(selectWebsiteDetail);
  const isUpdating = useSelector(selectIsUpdating);

  const [weddingItineraries, setWeddingItineraries]: [WeddingItinerary[], any] =
    useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editItineraryKey, setEditItineraryKey] = useState(null);
  const [visibleEditItineraryModal, setVisibleEditItineraryModal] =
    useState(false);

  const { control, handleSubmit, reset } = useForm<ItineraryAddForm>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!websiteDetail) return;
    setWeddingItineraries(websiteDetail.weddingItinerary);
  }, [websiteDetail]);

  const handleUpdate = () => {
    const formData = {
      domainName: websiteDetail.websiteUrl,
      weddingItineraries: weddingItineraries,
    };
    dispatch(
      actions.requestWebsiteItinerariesEdit({
        formData,
      }),
    );
  };

  const onSubmit: SubmitHandler<ItineraryAddForm> = data => {
    setWeddingItineraries([
      ...weddingItineraries,
      {
        eventName: data.eventName,
        eventDate: format(new Date(data.eventDate), 'yyyy-MM-dd'),
        startTime: format(new Date(data.startTime), 'yyyy-MM-dd HH:mm:ss'),
        endTime: format(new Date(data.endTime), 'yyyy-MM-dd HH:mm:ss'),
        location: data.location,
        additionalText: data.additionalText,
      },
    ]);
    reset();
    setShowForm(false);
  };

  const onClickEditItinerary = (key: number) => {
    setEditItineraryKey(key);
    setVisibleEditItineraryModal(true);
  };

  const handleEditItinerary = (weddingItinerary: WeddingItinerary) => {
    if (editItineraryKey >= 0 && editItineraryKey < weddingItineraries.length) {
      const updatedWeddingItinerary = {
        ...weddingItineraries[editItineraryKey],
        ...weddingItinerary,
      };

      const updatedWeddingItineraries = [
        ...weddingItineraries.slice(0, editItineraryKey),
        updatedWeddingItinerary,
        ...weddingItineraries.slice(editItineraryKey + 1),
      ];

      setWeddingItineraries(updatedWeddingItineraries);
      setVisibleEditItineraryModal(false);
    }
  };

  const renderEditItineraryModal = useMemo(() => {
    return visibleEditItineraryModal ? (
      <EditItineraryModal
        key={Math.random()}
        open={visibleEditItineraryModal}
        weddingItineraries={weddingItineraries}
        editItineraryKey={editItineraryKey}
        onSave={handleEditItinerary}
        onClose={() => {
          setVisibleEditItineraryModal(false);
          setEditItineraryKey(null);
        }}
      />
    ) : null;
  }, [editItineraryKey, visibleEditItineraryModal]);

  const handleDeleteItinerary = useCallback(
    (key: number) => {
      const updatedWeddingItineraries = [...weddingItineraries];
      updatedWeddingItineraries.splice(key, 1);
      setWeddingItineraries(updatedWeddingItineraries);
    },
    [weddingItineraries],
  );

  return (
    <WeddingWebsiteLayout
      title={'Itineraries'}
      currentPageName={pageNameList.weddingItinerary}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '30px',
          marginBottom: '30px',
          flexDirection: 'column',
        }}
      >
        {weddingItineraries.length > 0 ? (
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            {weddingItineraries?.map((item, key) => (
              <ItemCard key={key}>
                <Stack direction={'row'} gap={2} mb={1}>
                  <Typography variant={'h6'} display={'flex'} flex={1}>
                    {item.eventName}
                  </Typography>
                  <Stack direction={'row'} gap={2}>
                    <Box
                      onClick={() => onClickEditItinerary(key)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <IconPencilSquare />
                    </Box>
                    <Box
                      onClick={() => handleDeleteItinerary(key)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <IconTrash />
                    </Box>
                  </Stack>
                </Stack>
                <Box>
                  <Typography variant={'body1'}>
                    {format(
                      new Date(item.eventDate.substr(0, 10) + 'T00:00:00'),
                      'MM/dd/yyyy',
                    )}
                  </Typography>
                  <Typography variant={'body1'}>
                    {item.startTime.length > 8
                      ? format(new Date(item.startTime), 'p')
                      : item.startTime}{' '}
                    -{' '}
                    {item.endTime.length > 8
                      ? format(new Date(item.endTime), 'p')
                      : item.endTime}
                  </Typography>
                  <Typography variant={'body1'}>{item.location}</Typography>
                  <Typography variant={'body1'}>
                    {item.additionalText}
                  </Typography>
                </Box>
              </ItemCard>
            ))}
          </Box>
        ) : (
          !showForm && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Box mb={2}>
                <IconLaptop />
              </Box>
              <Typography variant={'h6'} mb={1}>
                No events added
              </Typography>
              <Typography variant={'body1'} textAlign={'center'} mb={2}>
                Click below to add your first event to your itinerary.
              </Typography>
              <AddButton
                data-testid="wedding-itinerary-button-show-form"
                onClick={() => setShowForm(true)}
              >
                <IconPlusCircled />
              </AddButton>
            </Box>
          )
        )}

        {weddingItineraries.length < MAX_ADD_COUNT && showForm && (
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            <form
              id="itinerary-add-form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="eventName"
                control={control}
                defaultValue={''}
                render={({ field, formState }) => (
                  <AppTextField
                    inputProps={{
                      'data-testid': 'wedding-itinerary-input-event-name',
                    }}
                    sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
                    placeholder="Name"
                    size="medium"
                    type="email"
                    variant="outlined"
                    fullWidth
                    {...field}
                    ref={null}
                    inputRef={field.ref}
                    error={Boolean(formState.errors.eventName)}
                    helperText={
                      formState.errors.eventName &&
                      formState.errors.eventName.message
                    }
                  />
                )}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Controller
                  name="eventDate"
                  control={control}
                  defaultValue={''}
                  render={({ field, formState }) => (
                    <MobileDatePicker
                      {...field}
                      inputFormat="MM/dd/yyyy"
                      value={field.value}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconCalendar />
                          </InputAdornment>
                        ),
                      }}
                      onChange={field.onChange}
                      renderInput={params => (
                        <AppTextField
                          {...params}
                          id="wedding-itinerary-input-event-date"
                          label="Event Date"
                          sx={{
                            m: '0 0 16px 0',
                            bgcolor: 'white',
                          }}
                          size="medium"
                          variant="outlined"
                          fullWidth
                          ref={null}
                          inputRef={field.ref}
                          error={Boolean(formState.errors.eventDate)}
                          helperText={
                            formState.errors.eventDate &&
                            formState.errors.eventDate.message
                          }
                        />
                      )}
                    />
                  )}
                />
                <Stack direction={'row'} justifyContent={'center'} gap={2}>
                  <Controller
                    name="startTime"
                    control={control}
                    defaultValue={''}
                    render={({ field, formState }) => (
                      <MobileTimePicker
                        {...field}
                        ampm={true}
                        ampmInClock={true}
                        inputFormat="p"
                        value={field.value}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconClock />
                            </InputAdornment>
                          ),
                        }}
                        onChange={field.onChange}
                        renderInput={params => (
                          <AppTextField
                            {...params}
                            id="wedding-itinerary-input-start-time"
                            label="Event Start Time"
                            sx={{
                              m: '0 0 16px 0',
                              bgcolor: 'white',
                            }}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            ref={null}
                            inputRef={field.ref}
                            error={Boolean(formState.errors.startTime)}
                            helperText={
                              formState.errors.startTime &&
                              formState.errors.startTime.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="endTime"
                    control={control}
                    defaultValue={''}
                    render={({ field, formState }) => (
                      <MobileTimePicker
                        {...field}
                        ampm={true}
                        ampmInClock={true}
                        inputFormat="p"
                        value={field.value}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconClock />
                            </InputAdornment>
                          ),
                        }}
                        onChange={field.onChange}
                        renderInput={params => (
                          <AppTextField
                            {...params}
                            id="wedding-itinerary-input-end-time"
                            label="Event End Time"
                            sx={{
                              m: '0 0 16px 0',
                              bgcolor: 'white',
                            }}
                            size="medium"
                            variant="outlined"
                            fullWidth
                            ref={null}
                            inputRef={field.ref}
                            error={Boolean(formState.errors.endTime)}
                            helperText={
                              formState.errors.endTime &&
                              formState.errors.endTime.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
              <Controller
                name="location"
                control={control}
                defaultValue={''}
                render={({ field, formState }) => (
                  <AppTextField
                    inputProps={{
                      'data-testid': 'wedding-itinerary-input-location',
                    }}
                    sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
                    placeholder="Location"
                    size="medium"
                    variant="outlined"
                    fullWidth
                    {...field}
                    ref={null}
                    inputRef={field.ref}
                    error={Boolean(formState.errors.location)}
                    helperText={
                      formState.errors.location &&
                      formState.errors.location.message
                    }
                  />
                )}
              />
              <Controller
                name="additionalText"
                control={control}
                defaultValue={''}
                render={({ field, formState }) => (
                  <AppTextField
                    inputProps={{
                      'data-testid': 'wedding-itinerary-input-additional-text',
                    }}
                    sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
                    placeholder="Description"
                    size="medium"
                    variant="outlined"
                    fullWidth
                    {...field}
                    ref={null}
                    inputRef={field.ref}
                    error={Boolean(formState.errors.additionalText)}
                    helperText={
                      formState.errors.additionalText &&
                      formState.errors.additionalText.message
                    }
                  />
                )}
              />
              {weddingItineraries.length < MAX_ADD_COUNT && (
                <Box display={'flex'} justifyContent={'center'} mt={2}>
                  <RoundedButton
                    className={'white'}
                    data-testid="wedding-itinerary-button-save-registry"
                    type={'submit'}
                  >
                    Save Event
                  </RoundedButton>
                </Box>
              )}
            </form>
          </Box>
        )}
        {weddingItineraries.length > 0 &&
          weddingItineraries.length < MAX_ADD_COUNT &&
          !showForm && (
            <Box display={'flex'} justifyContent={'center'} mt={2}>
              <AddButton
                data-testid="wedding-itinerary-button-add-registry"
                onClick={() => setShowForm(true)}
              >
                <IconPlusCircled />
              </AddButton>
            </Box>
          )}
      </Box>

      <Box display={'flex'} justifyContent={'center'} mt={2}>
        <RoundedButtonStyled loading={isUpdating} onClick={handleUpdate}>
          Update Website
        </RoundedButtonStyled>
      </Box>
      {renderEditItineraryModal}
    </WeddingWebsiteLayout>
  );
}

const EditItineraryModal = ({
  weddingItineraries,
  editItineraryKey,
  open,
  onSave,
  onClose,
}: {
  weddingItineraries: WeddingItinerary[];
  editItineraryKey: number;
  open: boolean;
  onSave: Function;
  onClose: Function;
}) => {
  const { control, handleSubmit } = useForm<ItineraryAddForm>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<ItineraryAddForm> = data => {
    onSave({
      eventName: data.eventName,
      eventDate: format(new Date(data.eventDate), 'yyyy-MM-dd'),
      startTime: format(new Date(data.startTime), 'yyyy-MM-dd HH:mm:ss'),
      endTime: format(new Date(data.endTime), 'yyyy-MM-dd HH:mm:ss'),
      location: data.location,
      additionalText: data.additionalText,
    });
  };

  return (
    <AppModal open={open} onClose={onClose}>
      <form
        id="edit-itinerary-modal-form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography variant={'h5'} fontWeight={600} textAlign={'center'} mb={2}>
          Edit itinerary information
        </Typography>
        <Controller
          name="eventName"
          control={control}
          defaultValue={weddingItineraries[editItineraryKey].eventName}
          render={({ field, formState }) => (
            <AppTextField
              inputProps={{
                'data-testid': 'wedding-itinerary-input-event-name',
              }}
              sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
              placeholder="Name"
              size="medium"
              variant="outlined"
              fullWidth
              {...field}
              ref={null}
              inputRef={field.ref}
              error={Boolean(formState.errors.eventName)}
              helperText={
                formState.errors.eventName && formState.errors.eventName.message
              }
            />
          )}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            name="eventDate"
            control={control}
            defaultValue={weddingItineraries[editItineraryKey].eventDate}
            render={({ field, formState }) => (
              <MobileDatePicker
                {...field}
                inputFormat="yyyy-MM-dd"
                value={field.value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconCalendar />
                    </InputAdornment>
                  ),
                }}
                onChange={field.onChange}
                renderInput={params => (
                  <AppTextField
                    {...params}
                    id="wedding-itinerary-input-event-date"
                    label="Event Date"
                    sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
                    size="medium"
                    variant="outlined"
                    fullWidth
                    ref={null}
                    inputRef={field.ref}
                    error={Boolean(formState.errors.eventDate)}
                    helperText={
                      formState.errors.eventDate &&
                      formState.errors.eventDate.message
                    }
                  />
                )}
              />
            )}
          />
          <Stack direction={'row'} justifyContent={'center'} gap={2}>
            <Controller
              name="startTime"
              control={control}
              defaultValue={
                new Date(weddingItineraries[editItineraryKey].startTime)
              }
              render={({ field, formState }) => (
                <MobileTimePicker
                  {...field}
                  ampm={true}
                  ampmInClock={true}
                  inputFormat="p"
                  value={field.value}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconClock />
                      </InputAdornment>
                    ),
                  }}
                  onChange={field.onChange}
                  renderInput={params => (
                    <AppTextField
                      {...params}
                      id="wedding-itinerary-input-start-time"
                      label="Event Start Time"
                      sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
                      size="medium"
                      variant="outlined"
                      fullWidth
                      ref={null}
                      inputRef={field.ref}
                      error={Boolean(formState.errors.startTime)}
                      helperText={
                        formState.errors.startTime &&
                        formState.errors.startTime.message
                      }
                    />
                  )}
                />
              )}
            />
            <Controller
              name="endTime"
              control={control}
              defaultValue={
                new Date(weddingItineraries[editItineraryKey].endTime)
              }
              render={({ field, formState }) => (
                <MobileTimePicker
                  {...field}
                  ampm={true}
                  ampmInClock={true}
                  inputFormat="p"
                  value={field.value}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconClock />
                      </InputAdornment>
                    ),
                  }}
                  onChange={field.onChange}
                  renderInput={params => (
                    <AppTextField
                      {...params}
                      id="wedding-itinerary-input-end-time"
                      label="Event End Time"
                      sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
                      size="medium"
                      variant="outlined"
                      fullWidth
                      ref={null}
                      inputRef={field.ref}
                      error={Boolean(formState.errors.endTime)}
                      helperText={
                        formState.errors.endTime &&
                        formState.errors.endTime.message
                      }
                    />
                  )}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
        <Controller
          name="location"
          control={control}
          defaultValue={weddingItineraries[editItineraryKey].location}
          render={({ field, formState }) => (
            <AppTextField
              inputProps={{
                'data-testid': 'wedding-itinerary-input-location',
              }}
              sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
              placeholder="Location"
              size="medium"
              variant="outlined"
              fullWidth
              {...field}
              ref={null}
              inputRef={field.ref}
              error={Boolean(formState.errors.location)}
              helperText={
                formState.errors.location && formState.errors.location.message
              }
            />
          )}
        />
        <Controller
          name="additionalText"
          control={control}
          defaultValue={weddingItineraries[editItineraryKey].additionalText}
          render={({ field, formState }) => (
            <AppTextField
              inputProps={{
                'data-testid': 'wedding-itinerary-input-additional-text',
              }}
              sx={{ m: '0 0 16px 0', bgcolor: 'white' }}
              placeholder="Description"
              size="medium"
              variant="outlined"
              fullWidth
              {...field}
              ref={null}
              inputRef={field.ref}
              error={Boolean(formState.errors.additionalText)}
              helperText={
                formState.errors.additionalText &&
                formState.errors.additionalText.message
              }
            />
          )}
        />
        <Stack p={'22px 0'} alignItems={'center'} spacing={1}>
          <RoundedButton
            data-testid="edit-registry-modal-button-save"
            style={{ minWidth: '200px' }}
            type={'submit'}
          >
            Save
          </RoundedButton>
          <Box
            data-testid="edit-registry-modal-button-cancel"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => onClose()}
          >
            Cancel
          </Box>
        </Stack>
      </form>
    </AppModal>
  );
};
