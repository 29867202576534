import { apiSVPost } from './apiSV';
import { getUserInfo } from './userHelper';

/**
 * Sends a request to the sales API to track user page visits.
 * In most cases, this behavior is bound to the user clicking the next button on a page in the sales flow.
 * @param pageName
 */

function sendUserPageView(pageName: string, userId: string) {
  apiSVPost('UserPageVisit/AddUserPageVisit', {
    userId: userId,
    pageName: pageName,
  });
}

export function userPageView(pageName: string | null) {
  const path = window.location.pathname + window.location.hash;
  const pageNames = {
    '/sales-lead#Destination': 'DestinationView',
    '/sales-lead#Country': 'CountryView',
    '/sales-lead#City': 'CityView',
    '/sales-lead#Resort': 'ResortView',
    '/sales-lead#Venue': 'VenueView',
    '/sales-lead#Package': 'PackageView',
    '/sales-lead#Date': 'WeddingDateRequestView',
    '/sales-lead#AltDate': 'AlternativeWeddingDateView',
    '/sales-lead#Confirmation': 'WeddingDateConfirmation',
    '/sign-up-as-plan-success': 'SalesLeadAccountCreation',
    '/sign-up-set-wedding-details': 'WeddingDetailsView',
    '/ptid#WeddingDateApproved': 'WeddingDateApprovedView',
    '/ptid#AlternativeDateApproved': 'WeddingAltDateApprovedView',
    '/ptid#UnAvailableDate': 'WeddingDateUnavailableView',
    '/ptid#Reservation/ConfirmReservation': 'ConfirmWeddingReservationView',
    '/ptid#ConfirmReservation': 'ConfirmWeddingReservationStep2',
    '/ptid#BookingProgress/SearchRoom': 'SearchRoomView',
    '/ptid#BookingProgress/RoomArrangements': 'RoomArrangementsView',
    '/ptid#BookingProgress/RoomExtras': 'RoomExtrasView',
    '/ptid#BookingProgress/ReservationConfirmation':
      'BookingReservationConfirmationView',
    '/ptid#BookingProgress/Payment': 'PriceSummaryView',
  };

  /*
   *  When the user is redirected the first time upon user creation, or navigating from confirmation email, the user data is not yet stored.
   *  This is why we check for it in the query string, it's only one case but it's at a part of the sales flow that I assume marketing wants to track.
   */

  let userId = '';
  const user = getUserInfo();
  if (user) {
    userId = user.userId;
  } else {
    const urlSplit = window.location.href.split('?');
    if (urlSplit.length < 2) return;
    const query = new URLSearchParams(urlSplit[1]);
    userId = query.get('userId');
  }

  if (userId && (pageNames[path] || pageName)) {
    sendUserPageView(pageName ? pageName : pageNames[path], userId);
  }
}
