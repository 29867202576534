import heicy2any from 'heic2any';

/**
 * Formats text input by removing emojis and special characters, removing HTML tags, and wrapping each line in a <p> tag.
 * @param {string} text - The text to format.
 * @returns {string} The formatted text.
 * @example
 * // returns '<p>Line 1</p><p>Line 2</p>'
 * formatTextInput('Line 1\nLine 2');
 */
export function formatTextInput(text: string) {
  // Remove emojis and special characters
  text = text.replace(/[\uD800-\uDFFF]./g, '');
  // Remove HTML tags
  text = text.replace(/<[^>]*>/g, '');

  if (text.length > 0) {
    // Split the text into an array of lines
    const lines = text.split('\n');

    // Wrap each line in a <p> tag and join them together
    text = lines
      .map(line => {
        if (line.indexOf('\n') === -1 && line.indexOf('<p>') === -1) {
          // Check if the line has no newline character and no <p> tag
          return `<p>${line}</p>`;
        } else {
          return line; // Line already has a newline character or <p> tag, so leave it as is
        }
      })
      .join('<br>');
  } else {
    text = '';
  }

  return text;
}

export function parseHtmlBackIntoText(html: string | null): string {
  if (!html || !html.length) {
    return '';
  }

  // Remove HTML tags
  return html
    .replace(/<(?!br\s*\/?>)[^>]+>/g, '')
    .replace(/<br\s*\/?>/gi, '\n');
}

export function removeSpecialCharsAndTags(str: string) {
  const stringWithoutTags = str?.replace(/<\/br>/gi, '');

  const stringWithoutSpecialChars = stringWithoutTags.replace(
    /[^.,\ra-zA-Z]/gi,
    ' ',
  );

  return stringWithoutSpecialChars;
}
export async function handleHEICImage(file: File, type: string) {
  try {
    const resultBlobs = await heicy2any({
      blob: file,
      toType: type,
      quality: 0.6,
    });
    return resultBlobs;
  } catch (err) {
    throw err;
  }
}

export async function handleReadImage(file: Blob) {
  const reader = new FileReader();
  return new Promise<string>((resolve, reject) => {
    reader.addEventListener('load', () => {
      const img = new Image();
      img.onload = () => {
        // Set the maximum width and height (1920 pixels in this case)
        const maxWidth = 1920;
        const maxHeight = 1920;

        // Calculate the new width and height while maintaining the aspect ratio
        let newWidth, newHeight;
        if (img.width > img.height) {
          newWidth = Math.min(img.width, maxWidth);
          newHeight = (img.height / img.width) * newWidth;
        } else {
          newHeight = Math.min(img.height, maxHeight);
          newWidth = (img.width / img.height) * newHeight;
        }

        // Create a canvas element to draw the resized image
        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        // Get the canvas context
        const ctx = canvas.getContext('2d');

        // Draw the resized image on the canvas
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Get the Base64URL of the resized image
        const base64url = canvas.toDataURL('image/jpeg');

        // Use the base64url as needed, e.g., update state, display it, etc.
        resolve(base64url);
      };

      img.src = reader.result?.toString() || '';
      img.onerror = reject;
    });

    reader.readAsDataURL(file);
  });
}

/**
 * Formats date into mm/dd/yyyy and ensures timezones are consistent
 * @param date input format is yyyy-mm-dd
 * @returns date formated as mm/dd/yyyy
 * @todo: Need to refactor date function into class when we decide on ultimate fix. This code is also in src/app/SalesView/pages/Ptid/SalesLead/EditOptionsModal.tsx
 */

export const formatISODateStringToShortDateString = (date: string) => {
  //we're looking for a very specific notation.
  //the CRM gives us back yyyy-mm-dd
  //therefore, we should always expect a dash in position 4 (counting starts at 0)
  //if we dont findt it, return early, since we're liable to explode or get things
  //wrong
  if (date?.indexOf('-') !== 4) return '';

  const _date = date.indexOf('T') < 0 ? `${date}T00:00:00` : date;
  let d = new Date(`${_date}`),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('/');
};
