import { Box, Typography } from '@mui/material';
import { IconDelete } from 'app/components/Icons/IconDelete';
import { debounce } from 'debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import * as yup from 'yup';
import { generateFileNameForBase64EncodedImage, notify } from 'utils/misc';
import AppAlert from 'app/components/AppAlert';
import { pageNameList } from 'app/components/EditYourWebsiteMenu';
import { IconAlertWarning } from 'app/components/Icons/IconAlertWarning';
import { IconImageUpload } from 'app/components/Icons/IconImageUpload';
import { RoundedButton } from 'app/components/RoundedButton';
import './fonts.css';
import { ImageUploadModal } from './ImageUploadModal';
import { useWeddingWebsiteSlice } from './slice';
import { selectActions } from 'store/slice/common/selectors';
import { selectLoading, selectWebsiteDetail } from './slice/selectors';
import { WebsiteDetail } from './slice/types';

import { parseHtmlBackIntoText } from 'utils/formattingHelper';

import { RichTextEditor } from 'app/components/Form/RichTextEditor';
import { WeddingWebsiteLayout } from './WeddingWebsiteLayout';

const ABOUT_US_MAX_CHARS = 10000;
const MAX_IMAGE_COUNT = 10;

const schema = yup
  .object({
    aboutUs: yup
      .string()
      .test(
        'max',
        ' Your "About Us" message needs to be less than 10 thousand characters',
        function (item) {
          return (
            parseHtmlBackIntoText(this.parent.aboutUs).length <
            ABOUT_US_MAX_CHARS
          );
        },
      ),
    specialMessage: yup
      .string()
      .test(
        'max',
        ' Your Additional Special Message needs to be less than 10 thousand characters',
        function (item) {
          return (
            parseHtmlBackIntoText(this.parent.specialMessage).length <
            ABOUT_US_MAX_CHARS
          );
        },
      ),
  })
  .required();

interface IImage {
  itemIndex: number;
  fileName: string;
  fileSize: number;
  base64EncodedImageData: string;
}

export function WeddingWebsiteAboutUs() {
  const dispatch = useDispatch();
  const { actions } = useWeddingWebsiteSlice();
  const websiteDetail: WebsiteDetail = useSelector(selectWebsiteDetail);
  const { updateActions, deleteActions } = useSelector(selectActions);
  const loading = useSelector(selectLoading);

  const [aboutUs, setAboutUs] = useState('');
  const [specialMessage, setSpecialMessage] = useState('');
  const [defaultSpecialMessage, setDefaultSpecialMessage] = useState('');
  const [defaultAboutUs, setDefaultAboutUs] = useState('');

  const [clientPhotoUploadRequest, setClientPhotoUploadRequest] = useState<
    IImage[]
  >([]);
  const [clientPhotos, setClientPhotos] = useState([]);
  const [warnMessage, setWarnMessage] = useState('');
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [updateKeys, setUpdateKeys] = useState([]);
  const [errors, setErrors] = useState({});

  const isUpdating =
    updateKeys.some(item => updateActions.some(k => k === item.key)) ||
    clientPhotos.some(p => deleteActions.some(k => k === p.id));

  useEffect(() => {
    if (!websiteDetail) return;

    try {
      setDefaultAboutUs(websiteDetail.aboutUs);
      setDefaultSpecialMessage(websiteDetail.specialMessage);
    } catch (e) {}

    setClientPhotos(websiteDetail.clientPhotos);
  }, [websiteDetail]);

  const handleYupValidation = data => {
    try {
      schema.validateSync(data, { abortEarly: true });
    } catch (e) {
      const errors = (e as yup.ValidationError).errors;
      throw errors;
    }
  };

  const handleMakeRequest = (formData, showAlert = false) => {
    try {
      dispatch(
        actions.requestWebsiteEdit({
          formData,
          showAlert,
        }),
      );
    } catch (e) {
      const error = [
        (e as Error).message || 'Network error: something went wrong!',
      ];
      throw error;
    }
  };

  const handleUpdate = () => {
    if (loading || isUpdating) return;
    // this function will divide images to a group of no more than 5mb in size
    function divideImagesToGroups(
      images,
      size = 0,
      collection = [[]],
      index = 0,
    ) {
      if (!images.length) return collection;
      const [image, ...rest] = images;
      const newSize = size + image.fileSize;
      if (newSize < 5) {
        collection[index] = collection[index] ? collection[index] : [];
        collection[index].push(image);
        return divideImagesToGroups(rest, newSize, collection, index);
      } else {
        return divideImagesToGroups(images, 0, collection, index + 1);
      }
    }

    const parseImage = ({ fileSize, ...item }) => {
      return {
        ...item,
        base64EncodedImageData: item.base64EncodedImageData.split(',')[1],
      };
    };
    try {
      const data = {
        aboutUs: aboutUs,
        specialMessage: specialMessage,
      };
      handleYupValidation(data);

      const totalSize = clientPhotoUploadRequest.reduce(
        (acc, item) => (acc += item.fileSize),
        0,
      );
      if (totalSize > 5) {
        console.warn(
          'total image size is greater than 5 MB! Images will be grouped & broken into multiple requests!',
        );
      }
      const formData = {
        domainName: websiteDetail.websiteUrl
          ? websiteDetail.websiteUrl.toLowerCase().replace('https://', '')
          : '',
        ...data,
      };

      const collection = divideImagesToGroups([...clientPhotoUploadRequest]);
      const keys = [];
      // make request for each image group based on file size limit
      collection.forEach((group, i, self) => {
        const key = Math.random() * 10000 + '-U';
        keys.push({ key, group });
        const showAlert = i === self.length - 1;
        setTimeout(
          handleMakeRequest,
          i * 1000,
          {
            ...formData,
            key,
            clientPhotoUploadRequest: group.map(parseImage),
          },
          showAlert,
        );
      });

      setUpdateKeys(keys);
      setClientPhotoUploadRequest([]);
      setDefaultAboutUs(aboutUs);
      setDefaultSpecialMessage(specialMessage);
    } catch (e) {
      const errors = e as string[];
      const errorEl = (
        <div>
          {errors.map((error, i) => (
            <div key={i}>{error}</div>
          ))}
        </div>
      );
      notify('', errorEl, 'danger');
    }
  };

  const change = debounce((property, value) => {
    let _value = parseHtmlBackIntoText(value) === '' ? '' : value;

    try {
      schema.validateSyncAt(property, {
        [property]: parseHtmlBackIntoText(value),
      });
      if (errors[property]) {
        setErrors({ ...errors, [property]: undefined });
        setSaveEnabled(false);
      } else {
        if (saveEnabled === false) setSaveEnabled(true);
        switch (property) {
          case 'aboutUs':
            setAboutUs(_value);
            break;
          case 'specialMessage':
            setSpecialMessage(_value);
            break;
        }
      }
    } catch (err) {
      setErrors({
        ...errors,
        [property]: (err as Error).message || 'something went wrong',
      });
    }
  }, 300);

  const onUploadImage = useCallback(
    (base64Image, fileSize) => {
      if (clientPhotoUploadRequest.length < MAX_IMAGE_COUNT) {
        setClientPhotoUploadRequest(prev => [
          ...prev,
          {
            itemIndex: prev.length + 1,
            fileName: generateFileNameForBase64EncodedImage(base64Image),
            fileSize,
            base64EncodedImageData: base64Image,
          },
        ]);
      } else {
        setWarnMessage(`You may select up to ${MAX_IMAGE_COUNT}`);
      }
    },
    [clientPhotoUploadRequest],
  );

  const removeImageFromClientPhotoUploadRequest = key => {
    setClientPhotoUploadRequest(prev =>
      prev.filter(item => item.itemIndex !== key),
    );
  };

  return (
    <WeddingWebsiteLayout
      title={'About Us'}
      currentPageName={pageNameList.aboutUs}
    >
      <Box mb={2}>
        <RichTextEditor
          aria-label="About Us"
          placeholder="What would you like to tell your guests about your love story?"
          defaultValue={defaultAboutUs ? defaultAboutUs : null}
          onChange={val => change('aboutUs', val)}
          error={errors['aboutUs']}
          maxCharacters={ABOUT_US_MAX_CHARS}
          toolBar={true}
        />
      </Box>
      <Box mb={2}>
        <Typography
          fontFamily={'"Nunito", sans-serif'}
          fontWeight={700}
          fontSize={[16, 18]}
          lineHeight={1.364}
          mb={1}
        >
          Message from the Couple
        </Typography>
        <RichTextEditor
          aria-label="Message from the Couple"
          placeholder="Additional special message to guests"
          defaultValue={defaultSpecialMessage ? defaultSpecialMessage : null}
          onChange={val => change('specialMessage', val)}
          error={errors['specialMessage']}
          maxCharacters={ABOUT_US_MAX_CHARS}
          toolBar={true}
        />
      </Box>
      <Box mb={2}>
        <ImageGallery
          images={clientPhotos}
          loadingImages={updateKeys}
          handleRemoveImage={id => dispatch(actions.removeWebsiteImage(id))}
        />
      </Box>
      <Box mb={2}>
        {/* image upload component */}
        <ImageUploadComponent
          canUploadMore={
            clientPhotoUploadRequest.length + clientPhotos.length <
            MAX_IMAGE_COUNT
          }
          handleUpload={onUploadImage}
          imagePreviews={clientPhotoUploadRequest}
          removeImagePreview={removeImageFromClientPhotoUploadRequest}
        />
      </Box>
      <RoundedButtonStyled
        loading={isUpdating}
        disabled={!saveEnabled}
        onClick={handleUpdate}
      >
        Save & Proceed
      </RoundedButtonStyled>

      {warnMessage && (
        <AppAlert
          onClose={() => setWarnMessage('')}
          title={warnMessage}
          icon={<IconAlertWarning />}
          hideCloseButton={false}
        />
      )}
    </WeddingWebsiteLayout>
  );
}

const ImagePreview = ({
  image,
  handleRemoveImage,
}: {
  image: { url: string; id: string };
  handleRemoveImage: (id: string) => void;
}) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const { deleteActions, updateActions } = useSelector(selectActions);

  const isUpdating = deleteActions
    .concat(updateActions)
    .some(ac => ac === image.id);

  const handleClick = id => {
    if (isDeleted || isUpdating) return;
    setIsDeleted(true);
    handleRemoveImage(id);
  };
  return (
    <Box
      sx={{
        position: 'relative',
        width: 140,
        height: 140,
        borderRadius: '20px',
        backgroundImage: `url("${image.url}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        opacity: isUpdating ? 0.2 : 1,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          zIndex: 9,
          cursor: 'pointer',
        }}
        data-testid="save-the-date-delete-image-button"
        onClick={() => handleClick(image.id)}
      >
        {isUpdating || isDeleted ? null : <IconDelete />}
      </Box>
    </Box>
  );
};

const ImageGallery = ({
  images,
  handleRemoveImage,
  loadingImages,
}: {
  images: { url: string; id: string }[];
  handleRemoveImage: (id: string) => void;
  loadingImages: { key: string; group: IImage[] }[];
}) => {
  const { updateActions } = useSelector(selectActions);
  if (!images.length && !loadingImages.length) return null;
  return (
    <>
      <Typography
        fontFamily={'"Nunito", sans-serif'}
        fontWeight={700}
        fontSize={[16, 18]}
        lineHeight={1.364}
        mb={1}
      >
        Gallery
      </Typography>
      <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={2}>
        {images.map(item => (
          <ImagePreview
            key={item.id}
            image={item}
            handleRemoveImage={handleRemoveImage}
          />
        ))}
        {loadingImages
          .filter(item => updateActions.includes(item.key))
          .map(({ group, key }) =>
            group.map(item => {
              return (
                <ImagePreview
                  key={item.itemIndex}
                  image={{
                    id: key,
                    url: item.base64EncodedImageData,
                  }}
                  handleRemoveImage={() => {}}
                />
              );
            }),
          )}
      </Box>
    </>
  );
};

const ImageUploadComponent = ({
  imagePreviews,
  canUploadMore,
  removeImagePreview,
  handleUpload,
}: {
  imagePreviews: IImage[];
  canUploadMore: boolean;
  removeImagePreview: (id: number) => void;
  handleUpload: (base64Image: any, fileSize: number) => void;
}) => {
  const [openImageUploadModal, setOpenImageUploadModal] = useState(false);
  return (
    <>
      <Typography
        fontFamily={'"Nunito", sans-serif'}
        fontWeight={700}
        fontSize={[16, 18]}
        lineHeight={1.364}
        mb={1}
      >
        Upload Image to Gallery
      </Typography>
      <Typography variant={'body1'} mb={2}>
        Up to {MAX_IMAGE_COUNT} photos can be uploaded
      </Typography>
      <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={2}>
        {canUploadMore ? (
          <Box
            sx={{
              width: 140,
              height: 140,
              border: '1px dashed #949696',
              borderRadius: '20px',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              cursor: 'pointer',
            }}
            onClick={() => setOpenImageUploadModal(true)}
          >
            <IconImageUpload />
          </Box>
        ) : (
          <></>
        )}

        {imagePreviews.map(item => (
          <ImagePreview
            key={item.itemIndex}
            image={{
              id: item.itemIndex.toString(),
              url: item.base64EncodedImageData,
            }}
            handleRemoveImage={() => removeImagePreview(item.itemIndex)}
          />
        ))}
      </Box>
      <ImageUploadModal
        open={openImageUploadModal}
        onClose={() => setOpenImageUploadModal(false)}
        onUpload={handleUpload}
        aspect={null}
      />
    </>
  );
};

const RoundedButtonStyled = styled(RoundedButton)`
  min-width: 180px;

  &[disabled] {
    background-color: #d0d0d0;
  }
`;
