import * as React from 'react';

export const IconClock = ({
  fill = '#000000',
  stroke = 'none',
  width = 21,
  height = 21,
}: {
  fill?: string;
  stroke?: string;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.3998 10.5C1.3998 5.47428 5.47416 1.3998 10.5 1.3998C15.526 1.3998 19.6002 5.47416 19.6002 10.5C19.6002 15.526 15.5261 19.6002 10.5 19.6002C5.47428 19.6002 1.3998 15.5261 1.3998 10.5ZM10.5 0C4.70112 0 0 4.70112 0 10.5C0 16.2989 4.70112 21 10.5 21C16.2989 21 21 16.2989 21 10.5C21 4.70112 16.2989 0 10.5 0ZM11.2001 4.90008C11.2001 4.51336 10.8865 4.2 10.5 4.2C10.1135 4.2 9.79992 4.51336 9.79992 4.90008V10.2101C9.79992 10.5813 9.94757 10.9376 10.2101 11.2001L14.205 15.195C14.4783 15.4683 14.9217 15.4683 15.195 15.195C15.4683 14.9217 15.4683 14.4783 15.195 14.205L11.2001 10.2101L11.2001 4.90008Z"
        fill={fill}
        stroke={stroke}
      />
    </svg>
  );
};
