import * as React from 'react';

export const IconAngleRight = ({
  fill = 'none',
  stroke = '#343a3f',
  width = 24,
  height = 24,
}: {
  fill?: string;
  stroke?: string;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 17.5L15 11.5L9 5.5" stroke={stroke} />
    </svg>
  );
};
