import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactNode, useEffect } from 'react';
import { useState } from 'react';

interface Props {
  className?: string;
  children?: ReactNode;
}

export function FadeInContainer({ className, children }: Props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  return (
    <Wrapper
      className={className}
      style={{ animation: `${show ? 'fadeIn' : 'fadeOut'} .6s` }}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div``;
