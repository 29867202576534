import * as React from 'react';

export const RSVP = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9177 4.53979C21.562 4.19398 21.0862 4.00037 20.591 4.00003H3.40899C2.91309 3.99735 2.43636 4.19131 2.08228 4.53979C1.71224 4.90164 1.50251 5.39749 1.5 5.91604V17.9931C1.5 18.5013 1.70109 18.9886 2.05914 19.3479C2.4172 19.7071 2.90277 19.9091 3.40899 19.9091H20.591C21.0972 19.9091 21.5828 19.7071 21.9409 19.3479C22.2989 18.9886 22.5 18.5013 22.5 17.9931V5.91604C22.4997 5.39715 22.2896 4.90049 21.9177 4.53979ZM15.5507 10.8368L21.6599 5.22278C21.6972 5.27825 21.7292 5.33705 21.7555 5.39853C21.7566 5.41223 21.7566 5.4261 21.7555 5.43996C21.7793 5.49793 21.7984 5.55774 21.8127 5.61888V5.67953C21.8265 5.7542 21.834 5.83005 21.8349 5.90623V17.9927C21.8317 18.2184 21.7679 18.4389 21.6504 18.6314L15.5507 10.8368ZM20.5907 4.63858C20.8011 4.64109 21.0079 4.69606 21.192 4.79829L12.8588 12.4622C12.6244 12.6767 12.3184 12.7956 12.0013 12.7956C11.684 12.7956 11.3783 12.6767 11.1437 12.4622L2.80753 4.79829C2.9923 4.69789 3.19872 4.6441 3.40877 4.64176L20.5907 4.63858ZM2.32032 18.641C2.2028 18.4487 2.13905 18.228 2.13588 18.0023V5.91587C2.13672 5.83986 2.14421 5.76401 2.15802 5.68917V5.62535C2.17284 5.56538 2.19198 5.50674 2.21529 5.4496C2.21412 5.43591 2.21412 5.42204 2.21529 5.40817C2.24159 5.3467 2.27355 5.28789 2.31084 5.23259L8.44856 10.8368L2.32032 18.641ZM3.40847 19.2797C3.18409 19.2794 2.96401 19.2175 2.77208 19.1009L8.91959 11.2775L10.7111 12.9221C11.0631 13.246 11.5235 13.4257 12.0013 13.4257C12.479 13.4257 12.9393 13.246 13.2915 12.9221L15.0796 11.2775L21.2238 19.0915C21.0318 19.2083 20.8119 19.2701 20.5873 19.2704L3.40847 19.2797Z"
        fill="#2D3031"
        stroke="#2D3031"
        strokeWidth="0.3"
      />
    </svg>
  );
};
