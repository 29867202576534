import * as React from 'react';

export const IconChevronRight = ({
  fill = 'none',
  stroke = '#05ABB5',
  strokeWidth = 2,
  width = 24,
  height = 20,
}: {
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10 20"
      fill={fill}
    >
      <path
        d="M7.83055 9.99627L0.297848 2.46357C0.0991306 2.26486 0.00233936 2.03088 0.00747299 1.76165C0.0126066 1.49243 0.114532 1.25847 0.313249 1.05975C0.511949 0.861032 0.745914 0.761675 1.01515 0.761675C1.28438 0.761675 1.51835 0.861032 1.71705 1.05975L9.3882 8.71552C9.56898 8.89629 9.70296 9.09885 9.79012 9.3232C9.87731 9.54757 9.9209 9.77192 9.9209 9.99627C9.9209 10.2206 9.87731 10.445 9.79012 10.6694C9.70296 10.8937 9.56898 11.0963 9.3882 11.277L1.71705 18.9482C1.51835 19.1469 1.28181 19.2437 1.00745 19.2386C0.733098 19.2334 0.496565 19.1315 0.297848 18.9328C0.0991306 18.7341 -0.000226974 18.5001 -0.000226974 18.2309C-0.000226974 17.9617 0.0991306 17.7277 0.297848 17.529L7.83055 9.99627Z"
        fill="black"
      />
    </svg>
  );
};
