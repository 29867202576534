import * as React from 'react';
import { ReactNode } from 'react';
import { CardMedia, useMediaQuery, useTheme } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ImageDTO } from '../../../../types/GroupLeaderDetails';
import { AppCarousel } from '../../../components/AppCarousel';

export const PtidCarousel = ({
  imageList,
  onExpand,
  ratio = 1 / 1,
  placeholder = <CardMedia component="img" image={`/img/placeholder2.webp`} />,
  displayExpand = true,
}: {
  imageList: ImageDTO[];
  onExpand?: () => void;
  placeholder?: null | ReactNode;
  ratio?: number;
  displayExpand?: boolean;
}) => {
  const theme = useTheme();
  const multiColumn = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <AppCarousel
      imageList={imageList}
      onExpand={onExpand}
      ratio={multiColumn ? ratio : null}
      placeholder={placeholder}
    />
  );
};
