/**
 *
 * Asynchronously loads the component for Profile
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SignUpAs = lazyLoad(
  () => import('./index'),
  module => module.SignUpAs,
);
