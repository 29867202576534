import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  SignUpForRoomError,
  SignUpForRoomState,
  SignUpResult,
  SupportRequestResult,
} from './types';
import type { Group, Room } from 'types/RoomInfo';
import type {
  EmailSignUpData,
  SocialSignUpData,
  SupportRequestData,
} from '../types';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { signUpForRoomSaga } from './saga';

export const initialState: SignUpForRoomState = {
  isFetchingRoom: false,
  roomId: '',
  room: undefined,
  error: undefined,
  signUpResult: undefined,
  isSigningUp: false,
  redirectTo: undefined,
  supportRequestResult: undefined,
};

const slice = createSlice({
  name: 'signUpForRoom',
  initialState,
  reducers: {
    fetchRoom(state, action: PayloadAction<string>) {
      state.isFetchingRoom = true;
      state.roomId = action.payload;
    },
    fetchRoomSuccess(
      state,
      action: PayloadAction<{ room: Room; group: Group }>,
    ) {
      state.isFetchingRoom = false;
      state.room = action.payload.room;
      state.group = action.payload.group;
    },
    fetchRoomError(state, action: PayloadAction<SignUpForRoomError>) {
      state.isFetchingRoom = false;
      state.error = action.payload;
    },
    redirectTo(state, action: PayloadAction<string>) {
      state.redirectTo = action.payload;
    },
    emailSignUp(state, _: PayloadAction<EmailSignUpData>) {
      state.signUpResult = undefined;
      state.isSigningUp = true;
    },
    socialSignUp(state, _: PayloadAction<SocialSignUpData>) {
      state.signUpResult = undefined;
      state.isSigningUp = true;
    },
    signUpResult(state, action: PayloadAction<SignUpResult>) {
      state.isSigningUp = false;
      state.signUpResult = action.payload;
    },
    createSupportRequest(state, _: PayloadAction<SupportRequestData>) {
      state.supportRequestResult = undefined;
    },
    supportRequestResult(state, action: PayloadAction<SupportRequestResult>) {
      state.supportRequestResult = action.payload;
    },
  },
});

export const { actions: signUpForRoomActions, reducer } = slice;

export const useSignUpForRoomSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: signUpForRoomSaga });
  return { actions: slice.actions };
};
