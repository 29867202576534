import * as React from 'react';

export const WeddingWebsite = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.85849 11.0799C9.44601 10.3655 10.505 10.2648 11.2095 10.8294L11.2644 10.8853L11.6212 11.2487L11.978 10.8853L12.0359 10.8263C12.7338 10.2452 13.7679 10.3349 14.3847 11.0809L14.3914 11.0889L14.3983 11.0967C15.0182 11.7857 14.9382 12.8808 14.2186 13.517L11.6336 15.7346L9.04589 13.5147L9.04493 13.5139C8.33622 12.909 8.2394 11.8093 8.85351 11.0858L8.85356 11.0859L8.85849 11.0799Z"
        stroke="#2D3031"
      />
      <path
        d="M3.55331 4.94666C3.27336 4.94666 3.06323 5.15662 3.06323 5.43673C3.06323 5.71668 3.29663 5.92681 3.55331 5.92681C3.83326 5.92681 4.04339 5.71685 4.04339 5.43673C4.04323 5.15679 3.83327 4.94666 3.55331 4.94666Z"
        fill="#2D3031"
      />
      <path
        d="M4.90658 4.94666C4.62664 4.94666 4.4165 5.15662 4.4165 5.43673C4.4165 5.71668 4.62646 5.92681 4.90658 5.92681C5.18653 5.92681 5.39666 5.71685 5.39666 5.43673C5.3965 5.15679 5.18654 4.94666 4.90658 4.94666Z"
        fill="#2D3031"
      />
      <path
        d="M21.0999 3.5H2.90007C2.13008 3.5 1.5 4.13004 1.5 4.90007V19.6C1.5 20.3699 2.13004 21 2.90007 21H21.0999C21.8699 21 22.5 20.37 22.5 19.6V4.90007C22.4998 4.13008 21.8698 3.5 21.0998 3.5H21.0999ZM21.5667 19.5999C21.5667 19.8565 21.3568 20.0665 21.1001 20.0665L2.90024 20.0667C2.64357 20.0667 2.43362 19.8567 2.43362 19.6L2.43345 7.2568H21.5666L21.5667 19.5999ZM21.5667 6.32331H2.43353V4.89999C2.43353 4.64332 2.6435 4.43337 2.90016 4.43337H21.1C21.3567 4.43337 21.5666 4.64333 21.5666 4.89999L21.5667 6.32331Z"
        fill="#2D3031"
      />
      <path
        d="M6.25986 4.94666C5.97991 4.94666 5.76978 5.15662 5.76978 5.43673C5.76978 5.71668 5.97974 5.92681 6.25986 5.92681C6.5398 5.92681 6.74993 5.71685 6.74993 5.43673C6.74993 5.15679 6.53997 4.94666 6.25986 4.94666Z"
        fill="#2D3031"
      />
      <path
        d="M3.55331 5.92669C3.29664 5.92669 3.06323 5.71673 3.06323 5.43661C3.06323 5.15666 3.27319 4.94653 3.55331 4.94653C3.83326 4.94653 4.04339 5.15649 4.04339 5.43661C4.04323 5.71672 3.83327 5.92669 3.55331 5.92669ZM4.90663 5.92669C4.62669 5.92669 4.41655 5.71673 4.41655 5.43661C4.41655 5.15666 4.62652 4.94653 4.90663 4.94653C5.18658 4.94653 5.39671 5.15649 5.39671 5.43661C5.39655 5.71672 5.18659 5.92669 4.90663 5.92669ZM6.25983 5.92669C5.97988 5.92669 5.76975 5.71673 5.76975 5.43661C5.76975 5.15666 5.97971 4.94653 6.25983 4.94653C6.53978 4.94653 6.74991 5.15649 6.74991 5.43661C6.74991 5.71672 6.53995 5.92669 6.25983 5.92669Z"
        fill="#2D3031"
      />
    </svg>
  );
};
