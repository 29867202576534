import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

export const selectSlice = (state: RootState) => state.common;
const selectGuest = (state: RootState) => state.guestDetails;

export const selectLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectActions = createSelector(
  [selectSlice],
  state => state.actions,
);

export const selectSidebarVisible = createSelector(
  [selectSlice],
  state => state.sidebarVisible,
);

export const selectUserRoomRole = createSelector(
  [selectSlice],
  state => state.userRoomRole,
);

export const selectGroups = createSelector(
  [selectSlice],
  state => state.groups,
);

export const selectRooms = createSelector([selectSlice], state => state.rooms);

export const selectUser = createSelector([selectSlice], state => state.user);

export const selectUserTrips = createSelector(
  [selectSlice],
  state => state.userTrips,
);

export const selectRoomIdToRole = createSelector(
  [selectSlice],
  state => state.roomIdToRole,
);

export const selectWeddingInfo = createSelector(
  [selectSlice],
  state => state.groups[0]?.weddingInfo || null,
);

export const selectWeddingDetails = createSelector(
  [selectSlice],
  state => state.weddingDetails,
);

export const selectUserDetails = createSelector(
  [selectSlice],
  state => state.userDetails,
);

export const selectGroupDetails = createSelector(
  [selectSlice],
  state => state.groupDetails,
);

export const selectGroupInfo = createSelector(
  [selectSlice],
  state => state.groupInfo,
);

export const selectRSVPs = createSelector([selectSlice], state => state.rsvps);

export const selectGuestList = createSelector(
  [selectSlice],
  state => state.guestList,
);

export const selectTopRecommendedResorts = createSelector(
  [selectSlice],
  state => state.topRecommendedResorts,
);

export const selectSalesLeadUpsells = createSelector(
  [selectSlice],
  state => state.salesLeadUpsells,
);

export const selectFavoriteResorts = createSelector(
  [selectSlice],
  state => state.favoriteResorts,
);

export const selectUserRecommendedResorts = createSelector(
  [selectSlice],
  state => state.userRecommendedResorts,
);

export const selectGuests = createSelector(
  [selectGuest],
  state => state?.guestList,
);
