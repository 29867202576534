import React from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { salesLeadActions } from 'app/SalesView/pages/Ptid/SalesLead/slice';
import { useIsMobile } from 'utils/responsiveHelper';
import { Link, Typography } from '@mui/material';
import { Step } from 'app/SalesView/pages/Ptid/SalesLead/slice/types';

export const BreadCrumb = () => {
  const dispatch = useDispatch();

  const isMobile = useIsMobile('sm');

  const breadCrumbMap: {
    [key in Step]?: {
      displayName: string;
      stateId: string;
    };
  } = {
    Country: {
      displayName: 'Destinations',
      stateId: '1',
    },
    City: {
      displayName: 'Cities',
      stateId: '2',
    },
    Resort: {
      displayName: 'Resorts',
      stateId: '3',
    },
    Venue: {
      displayName: 'Venues',
      stateId: '4',
    },
    Package: {
      displayName: 'Packages',
      stateId: '5',
    },
    Date: {
      displayName: 'Date',
      stateId: '6',
    },
    Confirmation: {
      displayName: 'Confirmation',
      stateId: '',
    },
  };

  const currentHash = window.location.hash;
  const filteredRoutes = Object.keys(breadCrumbMap)
    .slice(
      0,
      Object.keys(breadCrumbMap).indexOf(currentHash.replace('#', '')) + 1,
    )
    .filter((route, i, arr) => {
      if (isMobile && i > 1 && i < arr.length - 2 && isMobile) {
        return false;
      } else {
        return true;
      }
    });
  filteredRoutes.forEach((route, i, arr) => {
    if (i > 0 && i < arr.length - 2 && isMobile) {
      breadCrumbMap[route] = '..';
    }
  });

  return (
    <Box display={'inline-flex'}>
      {filteredRoutes.map((route, i) => (
        <Typography
          sx={{
            color: i < filteredRoutes.length - 1 ? '#6E6F73' : '#000',
            fontSize: isMobile ? '0.8rem' : '1rem',
          }}
          key={route}
        >
          <Link
            sx={{
              textDecoration: 'none',
              paddingRight: '0.5rem',
              paddingLeft: i > 0 ? '0.5rem' : '0rem',
              color: i < filteredRoutes.length - 1 ? '#6E6F73' : '#000',
              cursor: 'pointer',
            }}
            onClick={() => {
              //@ts-ignore
              dispatch(salesLeadActions.doBack(breadCrumbMap[route].stateId));
            }}
          >
            {breadCrumbMap[route].displayName}
          </Link>
          {i < filteredRoutes.length - 1 ? '/' : ''}
        </Typography>
      ))}
    </Box>
  );
};
