import ky, { Options } from 'ky';
import { logOut } from './userHelper';
import { SalesJourneyResponseStates } from 'types/SalesJourneyResponseStates';

const baseUrl = process.env.REACT_APP_SALES_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const apiSV = ky.extend({
  prefixUrl: `${baseUrl}/api`,
  timeout: 360000,
  retry: 0,
  hooks: {
    beforeRequest: [
      request => {
        request.headers.set('x-functions-key', apiKey);
        request.headers.set(
          'Authorization',
          'Bearer ' + localStorage.getItem('jwt'),
        );
      },
    ],
    afterResponse: [
      (request, options, response) => {
        if (response.status === 401) {
          return logOut();
        }
      },
    ],
  },
});

export type SVResponse<T> = {
  isSuccess: boolean;
  message?: null | string;
  error?: any;
  data: T;
  resultState?: SalesJourneyResponseStates;
};

export async function apiSVGet(
  url: string,
  options?: Options,
  queryParams?: any,
): Promise<any> {
  const query = queryParams ? new URLSearchParams(queryParams).toString() : '';
  return await apiSV.get(url + (query ? `?${query}` : ''), options).json();
}

export async function apiSVPost(
  url: string,
  data: {},
  options?: Options,
): Promise<any> {
  return await apiSV
    .post(url, {
      ...options,
      json: data,
    })
    .json();
}

export async function apiSVPut(
  url: string,
  data: {},
  options?: Options,
): Promise<any> {
  return await apiSV
    .put(url, {
      ...options,
      json: data,
    })
    .json();
}

export async function apiSVDelete(
  url: string,
  options?: Options,
  queryParams?: {},
): Promise<any> {
  const query = queryParams ? new URLSearchParams(queryParams).toString() : '';
  return await apiSV.delete(url + (query ? `?${query}` : ''), options).json();
}

export default apiSV;
