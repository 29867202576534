import * as React from 'react';

export const IconAlertWarning = () => {
  return (
    <svg
      width="130"
      height="116"
      viewBox="0 0 130 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2067_13572)">
        <path
          d="M54.4802 13.75L6.51018 96.85C2.02018 104.63 7.63018 114.36 16.6202 114.36H112.57C121.55 114.36 127.17 104.63 122.68 96.85L74.7002 13.75C70.2102 5.97004 58.9802 5.97004 54.4802 13.75V13.75Z"
          fill="#FEEED8"
        />
        <path
          d="M64.5898 23.8301L64.5898 75.4292"
          stroke="#231F20"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.5898 85.4248L64.5898 99.8301"
          stroke="#231F20"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.4801 6.68998L2.4301 96.85C-2.0599 104.63 3.5501 114.36 12.5401 114.36H116.65C125.63 114.36 131.25 104.63 126.76 96.85L74.7001 6.68998C70.2101 -1.09002 58.9801 -1.09002 54.4801 6.68998V6.68998Z"
          stroke="#FCBC62"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_2067_13572">
          <rect width="129.18" height="115.2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
