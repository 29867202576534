import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import appRoute from '../../../utils/appRoute';
import { selectUser } from '../../../store/slice/common/selectors';

/**
 *
 * @param Component
 * @param rest
 * @constructor
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector(selectUser);
  return (
    <Route
      {...rest}
      render={props =>
        user ? <Component {...props} /> : <Redirect to={appRoute.logInPage()} />
      }
    />
  );
};

export default PrivateRoute;
