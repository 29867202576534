import _ from 'lodash';
const KEY = 'redux';

export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export async function saveState(state: any) {
  try {
    if (!state) {
      localStorage.setItem(KEY, null);
    } else {
      const existingState = loadState();
      const combinedState = _.merge(existingState, state);
      const serializedState = JSON.stringify(combinedState);
      localStorage.setItem(KEY, serializedState);
    }
  } catch (e) {
    console.error(e);
  }
}
