import { call, put, select, takeLatest } from 'redux-saga/effects';
import { faqActions as actions } from './index';
import { apiSVGet } from '../../../../../../utils/apiSV';
import { selectUser } from 'store/slice/common/selectors';

function* fetchAgentDetails() {
  const user = yield select(selectUser);

  if (!user) return;

  try {
    const response = yield call(
      apiSVGet,
      'SalesLead/GetAssignAgentDetail',
      {},
      {
        userId: user.userId,
      },
    );

    if (response.isSuccess) {
      yield put(actions.fetchAgentDetailsSuccess(response.data));
    }
  } catch (e) {
    console.log('There was an error fetching sales agent details.', e);
  }
}

export function* agentDetailsSaga() {
  yield takeLatest(actions.fetchAgentDetails.type, fetchAgentDetails);
}
