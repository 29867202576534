/**
 *
 * Asynchronously loads the component for ModifyReservation
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ModifyReservationRooms = lazyLoad(
  () => import('./index'),
  module => module.ModifyReservationRooms,
);
