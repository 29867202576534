import * as React from 'react';
import styled from 'styled-components/macro';
import { uniqueId } from 'lodash';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlPropsSizeOverrides,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { useMemo, useState } from 'react';
import { IconCheckmark } from '../../Icons/IconCheckmark';

export type AppSelectValue = string | number;

export interface AppSelectOption {
  label: string;
  value: AppSelectValue;
}

interface Props extends SelectProps<AppSelectValue | AppSelectValue[]> {
  options: AppSelectOption[];
  value?: AppSelectValue | AppSelectValue[];
  withCheckboxes?: boolean;
  className?: string;
  label?: string;
  helperText?: string;
  selectAllOption?: AppSelectOption;
  fullWidth?: boolean;
  withSelectAll?: boolean;
  selectAllLabel?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  size?: OverridableStringUnion<
    'small' | 'medium',
    FormControlPropsSizeOverrides
  >;
}

const SelectCheckIcon = ({ checked }) => {
  return (
    <Checkbox
      checkedIcon={<IconCheckmark stroke={'#FF7B74'} />}
      icon={<IconCheckmark stroke={'transparent'} />}
      checked={checked}
    />
  );
};

const AppSelectBase = (props: Props) => {
  const {
    options,
    value,
    withCheckboxes,
    className,
    label,
    helperText,
    selectAllOption,
    fullWidth,
    variant,
    size,
    sx,
    ...selectProps
  } = props;

  const [labelId] = useState(uniqueId('AppSelect-'));

  const isSelectAllOptionChecked = useMemo(() => {
    return value && typeof value === 'object' ? !value.length : false;
  }, [options, value]);

  const isOptionChecked = (option: AppSelectValue) => {
    return typeof value === 'object'
      ? value.includes(option)
      : value === option;
  };

  return (
    <FormControl
      className={className}
      fullWidth={fullWidth}
      variant={variant}
      size={size}
      sx={sx}
    >
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Select {...selectProps} value={value} labelId={labelId} label={label}>
        {selectProps.multiple && selectAllOption && (
          <MenuItem value={selectAllOption.value}>
            <Box display={'flex'} gap={1} alignItems={'center'}>
              {withCheckboxes && (
                <SelectCheckIcon checked={isSelectAllOptionChecked} />
              )}
              <ListItemText primary={selectAllOption.label} />
            </Box>
          </MenuItem>
        )}
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            <Box display={'flex'} gap={1} alignItems={'center'}>
              {withCheckboxes && (
                <SelectCheckIcon checked={isOptionChecked(option.value)} />
              )}
              <ListItemText primary={option.label} />
            </Box>
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText error={selectProps.error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

const AppSelect = styled(AppSelectBase)`
  .MuiInputBase-root {
    .MuiCheckbox-root {
      display: none;
    }

    .MuiListItemText-root {
      margin-top: 0;
      margin-bottom: 0;
    }

    .MuiInputAdornment-positionStart {
      margin-right: 10px;
    }

    &.Mui-disabled {
      background: #f2f4f8;
      border-radius: 5px;

      .MuiInputBase-input {
        -webkit-text-fill-color: unset;
      }

      fieldset {
        border: unset;
      }
    }

    & .MuiInputBase-input,
    & .MuiTypography-root {
      color: #2d3031 !important;
    }
  }
`;

export default AppSelect;
