/**
 *
 * Asynchronously loads the component for ModifyReservation
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ModifyReservationRoom = lazyLoad(
  () => import('./index'),
  module => module.ModifyReservationRoom,
);
