import { addDays, subDays } from 'date-fns';
import appStateHelper from './appStateHelper';

/**
 * Tags to be attached to HelpDesk tickets
 * These are important because they may or may not trigger workflow actions in the HelpDesk system
 * when they're detected attached to a ticket
 */
export const HELP_DESK_TAGs = {
  /**
   * The friendly name for the corresponding tag in the HelpDesk app is 'Destify Guest Dashboard'
   */
  MAIN: '5fa0fcc4-8e6e-4cfd-8d19-05b4be913d5c',
  CHANGE_TRAVEL_DATES: 'cc3b0b82-4504-4671-be65-821aa47082aa',
  GUEST_ADD: 'df11e4e6-fbf1-4751-82ce-b3d141b7fc0a',
  GUEST_CANCEL: '189f5eca-483e-45d0-a21e-73aa8ba10d16',
  GUEST_NAME_CHANGE: '327da0ea-c2d1-4562-a1c0-b683f5eb2d21',
  ROOM_UPGRADE: '6c9461ad-1fc1-4d54-8619-cf7f290b495b',
  ROOM_DOWNGRADE: '90b01c8c-e80a-4725-8755-6110639f21fd',
  SELF_SERVICE: 'ad3c47ab-666f-4f99-9a42-1ba0bec4ee5d',
  MULTIPLE_MODIFICATION: 'ff94dc76-6609-4a14-a02e-53e058e66c09',
  CONTACT_FORM: 'cca1ac39-0e67-4a74-8c2d-7d45ce24a52e',
  UNSUCCESSFUL_MODIFICATION: '820f426d-dcc7-4d54-a9fb-ad78d44bd8fa',
  UNSUCCESSFUL_CANCELLATION: '7982028a-3f8b-4375-baf2-7fbc31af6ea4',
  CANCELLATION_REQUEST: '3c174e37-e485-45b9-8ccb-d0b4d9370d12',
  TRANSFERS_ADD_TRANDFER: '94e8e89c-c088-4fbf-92c9-61eab86fd13a',
  TRANSFERS: 'ee446c3b-25ee-41eb-bb5e-b4d86e7f236e',
  HBSI_MODIFICATION: 'fa49ee6a-ad2a-4399-8f2f-60df8ed58c1e',
  AUTO_MODIFICATION: 'c945e539-edb6-46aa-8571-e443d981048d',

  //The following 2 tags are meant to be used when a user with the guest role or a room which passes the isSelfServiceForCancellation check
  SELF_SERVICE_CANCELLATION: '553965b4-2a6c-4ca0-a2af-85486c4fcc23',
  CANCEL_RESERVATION: '86e4d7be-eb1d-458a-a30e-03bc8b2a0ed7',
};

export const buildParagraph = (text: string) => {
  return {
    type: 'paragraph',
    children: [
      {
        text: text,
      },
    ],
  };
};

export const buildLink = (url: string, text: string) => {
  return {
    type: 'paragraph',
    children: [
      {
        url: url,
        type: 'link',
        children: [
          {
            text: text,
          },
        ],
      },
    ],
  };
};

export const isAvailableDuringTravelSupport = (roomData): boolean => {
  const now = new Date();
  const room = roomData.room[0];
  const travelStartDate = new Date(room.travelStartDate);
  const travelEndDate = new Date(room.travelEndDate);

  const duringTravelPeriodStart = subDays(travelStartDate, 2);
  const duringTravelPeriodEnd = addDays(travelEndDate, 1);

  return duringTravelPeriodStart <= now && now < duringTravelPeriodEnd;
};

interface ITicketState {
  state: string;
  expires?: number;
  isInValid?: boolean;
}

interface IHDTickets {
  [x: string]: ITicketState[];
}

function getHdTickets() {
  const { HD_TICKETS } = appStateHelper.keys;
  const HDTickets = (appStateHelper.getState(HD_TICKETS) || {}) as IHDTickets;
  return HDTickets;
}

function updateHdTickets(key: string, tickets: ITicketState[]) {
  const HDTickets = getHdTickets();
  appStateHelper.updateState(appStateHelper.keys.HD_TICKETS, {
    ...HDTickets,
    [key]: tickets,
  });
}

function serializeTicketCreation(key: string, value: string) {
  const HDTickets = getHdTickets();
  const roomTickets = HDTickets[key] || [];
  const TICKET_EXPIRE_DAY_OFFSET = 7;
  const expireDate = addDays(new Date(), TICKET_EXPIRE_DAY_OFFSET).getTime();
  const reservationState: ITicketState = {
    state: value,
    expires: expireDate,
  };
  const updatedHDTickets = roomTickets
    .filter(item => item.state !== value)
    .concat([reservationState]);
  updateHdTickets(key, updatedHDTickets);
}

function canCreateTicket(key: string, value: string) {
  const HDTickets = getHdTickets();
  const roomTickets = HDTickets[key] || [];
  if (!roomTickets.length) return true;
  const now = Date.now();
  const item =
    roomTickets.find(
      item => !item.isInValid && item.state === value && now < item.expires,
    ) || null;
  return item === null;
}

export function inValidateTicket(key: string, ticketName: string) {
  const HDTickets = getHdTickets();
  const roomTickets = HDTickets[key] || [];
  const updatedTickets = roomTickets.map(ticket => {
    if (ticket.state === ticketName) {
      return {
        ...ticket,
        isInValid: true,
      };
    }
    return ticket;
  });
  updateHdTickets(key, updatedTickets);
}

/**
 * @description determines wheather or not to send an api call for help-desk ticket creation
 * and prevents multiple of same ticket being created
 *
 * !!NOTE: scope of this restriction is limited to the user's browser.
 * @param id room_id
 * @param state reservation_status
 */
export function beforeCreateTicket({ id, state }): [boolean, string] {
  const message =
    'You have already created this request. Please communicate with Guest Services at guestservices@destify.com regarding your previous request.';
  try {
    if (!id) return [false, null];
    if (!canCreateTicket(id, state)) return [false, message];
    serializeTicketCreation(id, state);
    return [true, null];
  } catch (error) {
    throw new Error(
      `Error checking ticket creation eligibility: ${JSON.stringify(error)}`,
    );
  }
}
// [
//   {
//     "id": "005e183b-7b16-46af-b715-b0ca056c02b7",
//     "name": "WORKFLOW- GSM"
//   },
//   {
//     "id": "00d113d5-3a4d-40ea-a96a-a7d91bf38a5a",
//     "name": "GL Rooming List Request"
//   },
//   {
//     "id": "02c0e189-ae0b-490f-870a-a73905faa68d",
//     "name": "Guest Dashboard"
//   },
//   {
//     "id": "03935331-8bb3-454a-a76f-276042a26423",
//     "name": "Mods-MultipleMods"
//   },
//   {
//     "id": "04ba0d97-1dec-49c4-b556-a5af24132588",
//     "name": "GLS/CS Exception"
//   },
//   {
//     "id": "0595ae58-b1bd-48c0-a76b-0859eac83217",
//     "name": "PTID Issues"
//   },
//   {
//     "id": "0c0ae650-2be6-4c65-b7a3-be520ed7860a",
//     "name": "TestTag"
//   },
//   {
//     "id": "0dcf2514-5b23-4db1-a3a1-7c1b10ce6d19",
//     "name": "Cancel Wedding"
//   },
//   {
//     "id": "0e21202f-51ed-493b-9f0a-c48d55efbbe1",
//     "name": "Return from Trip"
//   },
//   {
//     "id": "0f7a10b9-63ba-432e-9078-6e59a4ba9ae6",
//     "name": "live-pricing"
//   },
//   {
//     "id": "0ff4c892-bfbb-4550-9881-85f1634f3b9e",
//     "name": "TestTag2"
//   },
//   {
//     "id": "10c3252e-c928-4573-991c-70120850b9e2",
//     "name": "ALG Action Required $1"
//   },
//   {
//     "id": "11b2ebc8-cf20-42b9-bc9b-5e176f23b62f",
//     "name": "BOL Denied Request"
//   },
//   {
//     "id": "11fc7e65-dae5-4a0a-a082-f44e56e8f86e",
//     "name": "Supplier Final Payment Notification"
//   },
//   {
//     "id": "161eec95-6412-4907-8c7b-92b9c2506af1",
//     "name": "Final Request to Reserve Transfers"
//   },
//   {
//     "id": "17567b74-404a-4c1f-96b3-9d737ecbb98c",
//     "name": "Flight Change from Supplier"
//   },
//   {
//     "id": "189f5eca-483e-45d0-a21e-73aa8ba10d16",
//     "name": "Cancel Guest"
//   },
//   {
//     "id": "197678b2-30f1-4a7d-ac70-25320cb1d45d",
//     "name": "Contract"
//   },
//   {
//     "id": "1ab845f3-6247-405e-affe-867eb611e2be",
//     "name": "Duplicate"
//   },
//   {
//     "id": "1ce7c5b6-9da0-4825-acce-500b102384c5",
//     "name": "Website Edit"
//   },
//   {
//     "id": "1ffa3729-ac08-476f-b636-1c59cbffa1b9",
//     "name": "DT Room Complaint"
//   },
//   {
//     "id": "254285ec-5acf-4af8-89ed-c8f6d0e8c86f",
//     "name": "Covid"
//   },
//   {
//     "id": "254d8fc2-6a62-4e4a-815b-54c37c9767ae",
//     "name": "GSM REVIEW"
//   },
//   {
//     "id": "2724a87b-2d1c-43c4-a172-6ceffa2142fd",
//     "name": "TP5a - Final PC"
//   },
//   {
//     "id": "297bd028-98d4-4abe-81bf-6d34b82ff923",
//     "name": "Transfers - Supplier Confirmation"
//   },
//   {
//     "id": "29ca8a6b-f31d-4a0e-97c2-bda34667e1f6",
//     "name": "GS - Platforms assistance"
//   },
//   {
//     "id": "2a286f3a-d255-4910-a5af-f62a8d2047f3",
//     "name": "Wedding - RSVPs/E-vites"
//   },
//   {
//     "id": "2f30ba9b-b81d-40b3-a2f7-a23f1214f8b8",
//     "name": "Payment Extension"
//   },
//   {
//     "id": "327da0ea-c2d1-4562-a1c0-b683f5eb2d21",
//     "name": "Mod - Name Change"
//   },
//   {
//     "id": "357890fa-f5fe-4825-afdc-384b0e2bacd0",
//     "name": "Transfer Exception"
//   },
//   {
//     "id": "3823e998-b5ff-41d8-a4e5-5af16962f72f",
//     "name": "Group Leader Phone Call - 1 hr"
//   },
//   {
//     "id": "38534e99-ffdf-4bda-b4b6-6fd898c0fd81",
//     "name": "Papertrail - ALG API ERROR"
//   },
//   {
//     "id": "3883bc7d-5121-41ac-adb5-e95b2677ec6f",
//     "name": "staging"
//   },
//   {
//     "id": "3b55cf85-fabc-456c-ab28-ec9e17c7ca2c",
//     "name": "AMRewards Certificate"
//   },
//   {
//     "id": "3b63d8ac-3734-4422-a739-2b741b18ac88",
//     "name": "New Booking: Too Far Out"
//   },
//   {
//     "id": "3c174e37-e485-45b9-8ccb-d0b4d9370d12",
//     "name": "Cancellation Questions"
//   },
//   {
//     "id": "3db4b2ed-3653-4c87-b497-a5eeab3decf4",
//     "name": "Error - Concierge"
//   },
//   {
//     "id": "3f245e5c-c4a7-41cc-960a-ef17d0fe0b99",
//     "name": "Quote"
//   },
//   {
//     "id": "3fb9dd96-dadb-4d54-8f69-8d98be15c089",
//     "name": "Not a Ticket"
//   },
//   {
//     "id": "40f213d7-783b-4e5b-9332-916d3caa0daa",
//     "name": "Group Leader"
//   },
//   {
//     "id": "418e77b4-3c4e-4ef2-b053-dde2ad8411cf",
//     "name": "Intro to GS"
//   },
//   {
//     "id": "41ef91f1-7e08-4eb7-91de-8936951cdcad",
//     "name": "Transfer - Car Seats"
//   },
//   {
//     "id": "4449bc6f-e9dd-4de1-bff9-377c18958d20",
//     "name": "Discrepancy Review"
//   },
//   {
//     "id": "4554a569-b2fb-4ddf-90f6-9c298693d49d",
//     "name": "Mod - Exception 14 Days"
//   },
//   {
//     "id": "462ce245-194d-4740-b67e-d7c17498501d",
//     "name": "Revenue - Over $500 Issue"
//   },
//   {
//     "id": "4711bf12-2eca-4e9f-bcaf-344c4f8ebafc",
//     "name": "chatbot"
//   },
//   {
//     "id": "495bba88-7ab2-4a0f-8eb0-f545cf6931a0",
//     "name": "Payment"
//   },
//   {
//     "id": "4ba0a663-a803-41f2-81ce-b0b68256bc45",
//     "name": "DT Check In Issue"
//   },
//   {
//     "id": "4e47d885-9721-4e58-92a5-13da52421cee",
//     "name": "Dashboard Login Issues"
//   },
//   {
//     "id": "4f72f376-bffd-460b-80c1-02227ef42fec",
//     "name": "Transfers - Update Flights"
//   },
//   {
//     "id": "5286bfd9-4ab3-43f1-8590-f2d2cc371faf",
//     "name": "Transfers - Update"
//   },
//   {
//     "id": "5776226e-c1e9-4a72-ab7d-d98056235c87",
//     "name": "Wedding Date Change"
//   },
//   {
//     "id": "59b2930f-0a2c-4ae7-9a37-73b50c0770c3",
//     "name": "Promo Code"
//   },
//   {
//     "id": "5a1661e6-6cf9-480e-b76b-6cef588ec710",
//     "name": "New Lead"
//   },
//   {
//     "id": "5b00d597-56ec-4576-9492-1ad8dc6852b9",
//     "name": "ALG Action Required"
//   },
//   {
//     "id": "5b835964-4c2a-42fc-ae13-45f5dfc1b88e",
//     "name": "Revenue - Room Price Drop"
//   },
//   {
//     "id": "5da5eeec-b772-4214-90a4-7a7459b585da",
//     "name": "Rebook: Too Far Out"
//   },
//   {
//     "id": "5e1a4088-6af7-4cf5-b719-3faf85028535",
//     "name": "TP3- NPS"
//   },
//   {
//     "id": "5e9a0449-7f62-4a88-99da-a67d7a949261",
//     "name": "TP - RSVP Reminder"
//   },
//   {
//     "id": "5fa0fcc4-8e6e-4cfd-8d19-05b4be913d5c",
//     "name": "Destify Guest Dashboard"
//   },
//   {
//     "id": "5fd5e9a2-d038-48ee-88ca-18ccfd5c5c97",
//     "name": "Cancellation Confirmed"
//   },
//   {
//     "id": "69c687fb-1558-4227-84a7-b21aaa3537dc",
//     "name": "After Travel"
//   },
//   {
//     "id": "6c4a0c0f-582a-47bd-b49d-52e8e221beb2",
//     "name": "Voicemail - No message"
//   },
//   {
//     "id": "6c9461ad-1fc1-4d54-8619-cf7f290b495b",
//     "name": "Mod - Room Upgrade"
//   },
//   {
//     "id": "6f0d9e87-8d5c-4ecd-ab24-882cf50d8dd5",
//     "name": "Assigned by Admin"
//   },
//   {
//     "id": "705b3a91-fbf7-4770-af96-773e5eefb6a6",
//     "name": "Rooming Request"
//   },
//   {
//     "id": "75f667ad-512d-439d-a555-9c0782a4c2f1",
//     "name": "voicemail"
//   },
//   {
//     "id": "77bfad99-76e8-40db-9609-19dbc9060ef2",
//     "name": "Reinstate Reservation"
//   },
//   {
//     "id": "7982028a-3f8b-4375-baf2-7fbc31af6ea4",
//     "name": "Unsuccessful Cancellation"
//   },
//   {
//     "id": "7a616df2-9cea-493a-bec1-7382d241ccef",
//     "name": "Refund Inquiry"
//   },
//   {
//     "id": "7abae3ad-ce88-48ac-8924-e3dd68df9c1a",
//     "name": "Non US (Reservations Tracking)"
//   },
//   {
//     "id": "7b44b94f-00ff-433a-89f4-9d69321d7b79",
//     "name": "Transfer Deadline Passed"
//   },
//   {
//     "id": "7c01bac3-c180-4311-97be-59cd943fe95b",
//     "name": "Playa_Confirmation"
//   },
//   {
//     "id": "7d9ec796-3221-4260-8d5a-a9ae8104d14e",
//     "name": "Static PTID"
//   },
//   {
//     "id": "7db8e5b8-b283-4f05-b54f-fb24e98d8b4d",
//     "name": "Site Visit"
//   },
//   {
//     "id": "7e3faa5f-407b-4742-84ad-56905f598370",
//     "name": "Final Payment Request"
//   },
//   {
//     "id": "803b81ab-964c-4335-8878-7baa7f3b72d3",
//     "name": "Revenue - Room Commission Zeroed"
//   },
//   {
//     "id": "820f426d-dcc7-4d54-a9fb-ad78d44bd8fa",
//     "name": "Unsuccessful Modification"
//   },
//   {
//     "id": "830724af-975f-439e-bbd2-a0061e1aeda9",
//     "name": "TP7 DT Appreciation"
//   },
//   {
//     "id": "837eb5de-0599-4cbf-8b22-5d669a2adc34",
//     "name": "Mod - Bedding Request"
//   },
//   {
//     "id": "847fa2a2-2e5f-496a-a345-a45eabd732f3",
//     "name": "URGENT - Last Look"
//   },
//   {
//     "id": "86e4d7be-eb1d-458a-a30e-03bc8b2a0ed7",
//     "name": "Cancel Reservation"
//   },
//   {
//     "id": "888982d4-5000-4f9c-ac2d-9261106a02dc",
//     "name": "TP8 - Welcome Back"
//   },
//   {
//     "id": "89b5ae12-aaf0-4cab-bd97-ef10e212492f",
//     "name": "Requesting a Call"
//   },
//   {
//     "id": "8d4ae8d0-7733-43f1-b68b-eb85f1590faa",
//     "name": "Group Leader Phone Call"
//   },
//   {
//     "id": "90b01c8c-e80a-4725-8755-6110639f21fd",
//     "name": "Mod - Room Downgrade"
//   },
//   {
//     "id": "931693d5-a2d5-4183-b30e-16e05c2c2eb8",
//     "name": "Change Suppliers"
//   },
//   {
//     "id": "93b28413-2865-472d-9f60-cb000e970d99",
//     "name": "TP3 - NPS Response"
//   },
//   {
//     "id": "94e8e89c-c088-4fbf-92c9-61eab86fd13a",
//     "name": "Transfers - Add Transfer"
//   },
//   {
//     "id": "95562c0f-078a-461e-9682-47120bf435a1",
//     "name": "Deposit"
//   },
//   {
//     "id": "95642a23-789d-46b2-ab50-44951a0c92ac",
//     "name": "Flight Information Received"
//   },
//   {
//     "id": "96500036-a178-4364-b12d-dceba348362c",
//     "name": "manuallybook"
//   },
//   {
//     "id": "9732b883-35a2-4067-9dda-97bb15cd0a93",
//     "name": "CS - Client Escalation"
//   },
//   {
//     "id": "98ef6e6a-2e56-4762-9575-358d00fd7d0b",
//     "name": "Group Leader Phone Call - 15 min"
//   },
//   {
//     "id": "99b78d86-d573-4521-b9dc-e358195ef125",
//     "name": "Transfers - Nexus Feedback"
//   },
//   {
//     "id": "9b015e7b-8a94-4a43-9598-afe25d4c99dd",
//     "name": "Assigned by Workflow"
//   },
//   {
//     "id": "9ee20e7e-56d0-4f1a-8ade-483c5687472e",
//     "name": "Contract - 2nd Deposit"
//   },
//   {
//     "id": "a1988a50-a111-4ffd-a540-8c895ae3eb49",
//     "name": "Error - Booking Room"
//   },
//   {
//     "id": "a25a1dd7-8fdf-4b18-b389-07069d2acacb",
//     "name": "Negative Feedback (CI Rock)"
//   },
//   {
//     "id": "a3be7c5e-5514-45e9-bd14-b62d6b2089c3",
//     "name": "Honeymoon"
//   },
//   {
//     "id": "a6e98e7e-4086-447e-80b6-90adf29689fa",
//     "name": "New Wedding"
//   },
//   {
//     "id": "a8b07ca3-88c4-4d3c-a5dc-847cfa0d7f7e",
//     "name": "Minimum Night Stay Increase"
//   },
//   {
//     "id": "a8f1f3e5-b413-44f1-8235-8178ecb7b8d0",
//     "name": "Revenue - Room Sold Out"
//   },
//   {
//     "id": "aac642a2-010b-458c-ac40-d8104f79d9b1",
//     "name": "CustomRequest"
//   },
//   {
//     "id": "acb3c905-63c1-471f-9449-5c5b10d97b0c",
//     "name": "Price Match"
//   },
//   {
//     "id": "ad3c47ab-666f-4f99-9a42-1ba0bec4ee5d",
//     "name": "SelfService"
//   },
//   {
//     "id": "ae2345eb-4290-4b7f-9364-680bd0a1145c",
//     "name": "Sales to GS Error"
//   },
//   {
//     "id": "af5f7ff1-7320-473a-a59b-63f6a7bbc489",
//     "name": "Payment - Balance Inquiry"
//   },
//   {
//     "id": "afa12480-ae3f-43ec-9530-b9486d95b2a6",
//     "name": "Res Manager Approval"
//   },
//   {
//     "id": "b0318f4e-550c-4fdf-91af-a7ca12619452",
//     "name": "CRMError"
//   },
//   {
//     "id": "b23e754a-e9cf-47b9-a47c-00c20ab02884",
//     "name": "DT Avoidable"
//   },
//   {
//     "id": "b5520715-3b74-44ca-8131-9f3b0c31df00",
//     "name": "Error - Itinerary Will Not Send"
//   },
//   {
//     "id": "b583aeb4-f441-48c3-8419-2c9ec3e6eebd",
//     "name": "Error - Inventory Issue"
//   },
//   {
//     "id": "b6feafad-3cbe-48b6-85a3-4ff5fd870278",
//     "name": "Mod - Modify Airfare"
//   },
//   {
//     "id": "b8a1fd3e-5f49-45d1-bb90-4c6965dbbee7",
//     "name": "PTIDGroupLeaderView"
//   },
//   {
//     "id": "bf29f726-0c85-413f-9037-d6e877eecda4",
//     "name": "Wedding Coordinator"
//   },
//   {
//     "id": "c1c42235-15b6-4899-9a0f-4d7675837223",
//     "name": "Pre Booking"
//   },
//   {
//     "id": "c1f5d852-5f70-434e-b8dc-5b0ace724e85",
//     "name": "Internal"
//   },
//   {
//     "id": "c21bbc0c-6083-48e0-a514-31a57795b3f3",
//     "name": "DT Audit Error"
//   },
//   {
//     "id": "c65213a3-ae39-4f68-8d70-cc796a7bd822",
//     "name": "Palace Booking"
//   },
//   {
//     "id": "c7018ca3-7c8a-4c10-a708-b5add70e3f33",
//     "name": "Accounting"
//   },
//   {
//     "id": "c945e539-edb6-46aa-8571-e443d981048d",
//     "name": "auto-modification"
//   },
//   {
//     "id": "ca539da9-836d-4086-b4aa-12a8b226a0c9",
//     "name": "DT Loss"
//   },
//   {
//     "id": "ca6471b0-3f3e-4aa7-a641-628827ef3013",
//     "name": "Wedding Dashboard"
//   },
//   {
//     "id": "cc3b0b82-4504-4671-be65-821aa47082aa",
//     "name": "Mod - Change Travel Dates"
//   },
//   {
//     "id": "cca1ac39-0e67-4a74-8c2d-7d45ce24a52e",
//     "name": "Contact Form"
//   },
//   {
//     "id": "cd07d435-4ff1-4cca-9ae1-4e5c431d90cc",
//     "name": "Group Leader Phone Call - 30 min"
//   },
//   {
//     "id": "cd1ba908-4f9d-4238-b7cb-86e84f0dbc71",
//     "name": "Refund Processing"
//   },
//   {
//     "id": "cf841ac0-f1ad-4e80-b12a-71ffc73e6e22",
//     "name": "During Travel"
//   },
//   {
//     "id": "d3d93cee-a297-4a1e-923e-7716127c8e41",
//     "name": "Reviews"
//   },
//   {
//     "id": "d46115b8-e426-424b-960f-286e2bc17441",
//     "name": "TP4 - GB Reminder"
//   },
//   {
//     "id": "d46e2a64-dae3-4932-b34b-010b1270e714",
//     "name": "Error - API Issues"
//   },
//   {
//     "id": "d5d00c7d-b7e3-4490-a851-f8e7d18b40ac",
//     "name": "Revenue Loss Confirmed"
//   },
//   {
//     "id": "d7ca8462-e759-4e4a-a285-87c4f0b0c046",
//     "name": "CS revenue loss"
//   },
//   {
//     "id": "d7d495c4-28a0-4512-a117-8d6442b2fcaa",
//     "name": "MOD- ALG ISSUES"
//   },
//   {
//     "id": "d86a51ee-8ed6-47f0-92ca-95f49a6d3236",
//     "name": "Reviews - Damage"
//   },
//   {
//     "id": "d94793ef-72db-4399-9821-7b84061404d2",
//     "name": "SRD -- Waiting"
//   },
//   {
//     "id": "d9bd3bf7-eadd-4c2c-b983-db3128fe9e58",
//     "name": "Payment Question"
//   },
//   {
//     "id": "daee550f-0e97-4aae-bbe5-eb0770303eed",
//     "name": "Comps"
//   },
//   {
//     "id": "dc0a82f1-777f-4740-892f-cb6c92d78d2b",
//     "name": "Payment - Charge Dispute"
//   },
//   {
//     "id": "de50b247-24b1-4bf7-9e08-e19f7f279d35",
//     "name": "Travel Voucher"
//   },
//   {
//     "id": "deb2c5b9-da47-4bc2-8ff1-7e079ac6c8db",
//     "name": "TP2a: GL Initial Call"
//   },
//   {
//     "id": "df11e4e6-fbf1-4751-82ce-b3d141b7fc0a",
//     "name": "Mod - Add A Guest"
//   },
//   {
//     "id": "e274e9f0-6c1a-4b81-93f7-a4d26f8cf60d",
//     "name": "DT Transfer Issue"
//   },
//   {
//     "id": "e310af19-c614-4487-bcee-349ccb16e67b",
//     "name": "Reservations Pending Rooms"
//   },
//   {
//     "id": "e551bfef-ae43-44d9-8e7f-d0bc8c5c7403",
//     "name": "New Booking"
//   },
//   {
//     "id": "e59a0060-bc3a-4d86-b383-c316a01bb29d",
//     "name": "reservations rev loss"
//   },
//   {
//     "id": "e6508c8e-25e4-401f-a295-59c5084cacac",
//     "name": "Reviews - GL Review Request"
//   },
//   {
//     "id": "e716184c-570f-4ffb-9097-fbc3a78500a2",
//     "name": "Supplier Confirmation"
//   },
//   {
//     "id": "e740f7bb-32d9-43bf-ace6-24236f0d2ae8",
//     "name": "Day Pass"
//   },
//   {
//     "id": "e8e58e6e-12da-40ac-a42d-95067941daa3",
//     "name": "Spam"
//   },
//   {
//     "id": "ecda8473-68c1-43b2-94d9-e6ef05ae6986",
//     "name": "Insurance"
//   },
//   {
//     "id": "ed00c2eb-e4c6-47d4-8f06-3a070fbe37ed",
//     "name": "Supplier Price Match"
//   },
//   {
//     "id": "ed0e2456-4599-48ed-93df-dda424961aff",
//     "name": "PTID Error during auto-booking"
//   },
//   {
//     "id": "ee446c3b-25ee-41eb-bb5e-b4d86e7f236e",
//     "name": "Transfers"
//   },
//   {
//     "id": "f2605b9a-5de3-452c-8fee-7f6e1c4d7f8f",
//     "name": "Pre Travel"
//   },
//   {
//     "id": "f2927a86-17c1-43c3-85db-8215bb0f0697",
//     "name": "Bugs - GS Caught"
//   },
//   {
//     "id": "f2ca9e82-b239-43c3-b5e5-7e09e58dc244",
//     "name": "TP6 - Concierge Pre Travel"
//   },
//   {
//     "id": "f35467da-7ea3-40f2-b3df-90efb2f745ad",
//     "name": "Mod - Bedding Request, Rooms Together"
//   },
//   {
//     "id": "f357ca4e-75fb-4299-9c91-16595f3b5c3e",
//     "name": "MOD- BOL DENIED"
//   },
//   {
//     "id": "f5e7f984-fd3f-45d9-8d9a-13ed45168ac7",
//     "name": "Transfers Denied"
//   },
//   {
//     "id": "f750190b-3155-4bfe-a3e0-42ea76d797c7",
//     "name": "URGENT Payment"
//   },
//   {
//     "id": "f9733f33-c3bb-4bc6-846d-b2c783f8861c",
//     "name": "TP5b - Final PC Follow-Up"
//   },
//   {
//     "id": "fb225e3a-919b-432c-bab0-9ecd1b211971",
//     "name": "Cancel Wedding - GLS Confirmed"
//   },
//   {
//     "id": "fba14da0-f753-4827-8d35-9572c075e207",
//     "name": "Unsuccessful Website Edit"
//   },
//   {
//     "id": "fcc06c3e-7d4c-472c-b5aa-ef8865f78515",
//     "name": "General Support Request"
//   },
//   {
//     "id": "fdc25963-a6b1-4fda-9084-97b226c52257",
//     "name": "Revenue - Room Rate Increased"
//   },
//   {
//     "id": "fe3c8c39-a67f-471c-8dfc-881f81465789",
//     "name": "Wedding Info"
//   },
//   {
//     "id": "ff0c33f8-a131-4afa-8428-5dddb0853e8a",
//     "name": "Transfers - Incorrect Flight Info Submitted"
//   }
// ]
