import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    html,
    body {
        height: 100%;
        width: 100%;
    }

    body {
        font-family: "Nunito", "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif;
        color: #343A3F;
    }

    #root {
        min-height: 100%;
        min-width: 100%;
        display: flex;
        flex-direction: column;
    }

    p,
    label {
        font-family: "Nunito", "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif;
        line-height: 1.5em;
    }

    input, select {
        font-family: inherit;
        font-size: inherit;
    }

    .rnc__notification-item--success {
        background-color: #00BAC4;
        border-left: 8px solid #00949e;
    }

    .MuiList-root {
        .MuiInputBase-input,
        .MuiTypography-root {
            color: #2d3031 !important;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes slideDown {
        0% {
            transform: translate3d(0,-100%,0);
          
          }
      
          to {
            transform: translateZ(0);
           
          }
    }

    .carousel-container {
        width: 100%;
    }

    .carousel-item {
        padding: 16px;
    }
`;
