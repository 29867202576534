import React from 'react';
import './styles.css';
import { $getRoot } from 'lexical';

import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import ToolbarPlugin from './Toolbar';
import { HeadingNode } from '@lexical/rich-text';
import { Box, Typography } from '@mui/material';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { parseHtmlBackIntoText } from 'utils/formattingHelper';

function Placeholder({ placeholder }: { placeholder?: string }) {
  return (
    <Typography
      variant={'body2'}
      sx={{
        pointerEvents: 'none',
        position: 'absolute',
        top: '2rem',
        left: '0.75rem',
        color: '#aaa',
      }}
    >
      {placeholder || 'Start typing here...'}
    </Typography>
  );
}

const editorConfig = {
  onError(error) {
    throw error;
  },
  namespace: 'editor',
  nodes: [HeadingNode],
};

// This function initializes the editor with the initial html string, if there is one
const LoadInitialContent = ({ initialHTML }) => {
  const [editor] = useLexicalComposerContext();
  React.useEffect(() => {
    editor.update(() => {
      try {
        const parser = new DOMParser();
        // remove all line breaks from the initial html string because some text entered via our old text inputs contain
        // improper line break tags and line break tags in general should not be appended directly to the root node when instantiating a new editor
        // https://lexical.dev/docs/concepts/nodes
        const formattedHtml = initialHTML.replace(/<br\s*\/?>/g, '');
        // parse the initial html string into a DOM object
        const dom = parser.parseFromString(formattedHtml, 'text/html');
        // generate nodes from the DOM object to pass to the editor
        const nodes = $generateNodesFromDOM(editor, dom);

        // Editor content defaults to a single <p> tag.
        // replace the first node in the editor with the first node from the generated nodes.
        const firstChild = $getRoot().getFirstChild();
        if (firstChild) {
          firstChild.replace(nodes[0]);
        } else {
          $getRoot().append(nodes[0]);
        }

        // select the new node after the transform
        nodes[0].select();

        // append the rest of the nodes to the editor
        if (nodes.slice(1).length > 0) {
          $getRoot().append(...nodes.slice(1));
        }
      } catch (error) {
        console.error(
          'something went wrong when trying to parse the default content for this rich text editor. It is likely that the html string passed to the editor is invalid.',
          error,
        );
      }
    });
  }, []);
  return null;
};

export function RichTextEditor({
  onChange,
  placeholder,
  defaultValue,
  maxCharacters,
  error,
  toolBar = true,
}: {
  onChange: (htmlString: string) => void;
  placeholder?: string;
  defaultValue?: string;
  maxCharacters?: number;
  error?: string;
  toolBar?: boolean;
}) {
  // pass the html from the editor to the passed in onChange handler
  function handleChange(editorState, editor) {
    editorState.read(() => {
      const htmlString = $generateHtmlFromNodes(editor, null);
      onChange(htmlString);
      const plainTextString = parseHtmlBackIntoText(htmlString);
      setPlainTextValue(plainTextString);
    });
  }

  const [plainTextValue, setPlainTextValue] = React.useState('');
  return (
    <LexicalComposer
      initialConfig={{
        ...editorConfig,
      }}
    >
      <Box className={`editor-container`}>
        {toolBar && <ToolbarPlugin />}
        <Box className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder placeholder={placeholder} />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin onChange={handleChange} />
          {!!defaultValue && <LoadInitialContent initialHTML={defaultValue} />}
          <HistoryPlugin />
        </Box>
      </Box>
      <Typography variant={'subtitle2'} color={error && '#eb4523'} mb={2}>
        {plainTextValue && maxCharacters
          ? plainTextValue.replace(/<[^>]+>/g, '').length + '/' + maxCharacters
          : ''}
        {error ? error : ''}
      </Typography>
    </LexicalComposer>
  );
}
