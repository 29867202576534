/**
 *
 * Asynchronously loads the component for Profile
 *
 */

import { lazyLoad } from 'utils/loadable';

export const LegacyLogIn = lazyLoad(
  () => import('./index'),
  module => module.LegacyLogIn,
);
