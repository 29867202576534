import * as React from 'react';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components/macro';
import { AppSideBarMenu } from '../AppMenu';
import { AppSpeedDial } from '../AppSpeedDial';
import { AppTopBar } from '../AppTopBar';
import { Footer } from '../Footer';
import { PageTitle } from '../PageTitle';

interface Props {
  title: string;
  children?: any;
  showPageTitle?: boolean;
  showSpeedDial?: boolean;
  showDuringTravelSupport?: boolean;
  pageTitle?: ReactNode;
  pageTitleWithBack?: boolean;
  hasContentPadding?: boolean;
  alignment?: 'left' | 'center' | 'right';
  fullBleed?: boolean;
}

export function Layout({
  title,
  children,
  showPageTitle = false,
  showSpeedDial = true,
  showDuringTravelSupport = false,
  pageTitle,
  pageTitleWithBack,
  hasContentPadding = true,
  alignment = 'center',
  fullBleed = false,
}: Props) {
  if (showSpeedDial) {
    showSpeedDial = false;
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppTopBar title={title} />
      <AppSideBarMenu />
      <ContentWrapper
        hasContentPadding={hasContentPadding}
        alignment={alignment}
        fullBleed={fullBleed}
      >
        {pageTitle ||
          (showPageTitle && (
            <PageTitle
              text={title}
              withBack={pageTitleWithBack}
              withDuringTravelSupport={showDuringTravelSupport}
            />
          ))}
        {children}
      </ContentWrapper>
      {showSpeedDial && <AppSpeedDial />}
      <Footer />
    </Wrapper>
  );
}

interface WrapperProps {
  alignment: 'left' | 'center' | 'right';
  hasContentPadding: boolean;
  fullBleed?: boolean;
}

const Wrapper = styled.div`
  min-height: 100vh;
  margin: 0 auto;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  margin-top: 2rem;
`;

const ContentWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  padding: ${({ hasContentPadding }) => (hasContentPadding ? '0 16px' : 0)};

  max-width: ${({ fullBleed }) => (fullBleed ? '100%' : '1000px')};
  margin: ${({ alignment }) => (alignment === 'left' ? '0' : '0 auto')};
`;
